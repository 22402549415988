import { feature, length } from '@turf/turf';
// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-new-admin-dashboard',
//   templateUrl: './new-admin-dashboard.component.html',
//   styleUrls: ['./new-admin-dashboard.component.scss']
// })
// export class NewAdminDashboardComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
import { Component, OnInit,Input, Output, EventEmitter, ElementRef, ViewChild, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import * as uuid from 'uuid';
import { MapService } from '../map.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Route, Router } from '@angular/router';
import { SitesService } from 'src/app/main-nav/sites/sites.service';
import { User } from 'src/app/models/users.model';
import { Site } from 'src/app/models/sites.model';
import { catchError, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { Chart } from 'chart.js'
import { ServerService } from 'src/app/server.service';
import * as echarts  from 'echarts';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
// import { DialogCommentComponent } from './dialog-comment/dialog-comment.component';
import { element } from 'protractor';
import { featureIcons } from 'src/app/model-on-map/geospatial.interfece';
import * as turf  from '@turf/turf'
import * as $ from 'jquery';
import { SlideInOutAnimation } from '../../animations';
import { PlaceSearchService } from 'src/app/heli-ai/place-search.service';
import { HttpClient } from '@angular/common/http';
import { Light } from 'three';


interface HierarchyNode {
  part: string;
  children: HierarchyNode[];
  data?: any
}

// @Component({
//   selector: 'app-admin-home',
//   templateUrl: './admin-home.component.html',
//   styleUrls: ['./admin-home.component.scss']
// })
// export class AdminHomeComponent implements OnInit, OnDestroy{
@Component({
  selector: 'app-new-admin-dashboard',
  templateUrl: './new-admin-dashboard.component.html',
  styleUrls: ['./new-admin-dashboard.component.scss'],
  animations: [SlideInOutAnimation]
})
export class NewAdminDashboardComponent implements OnInit {
  @Input() inputData: any;

 
  showProjectTables: boolean = true;
  showRunningStatus: boolean =false;
  currentUser: string;
  tableData:any[] = [];
  overAllTabledata = [
    // Example data structure
    { Department: 'Light', data: '...' },
    { Department: 'PWD', data: '...' }
  ];
  siteId: any 
  // overAllTabledata:any
  tableHeader: string[];
  totalProjectStarted: any;
  budgetSanctioned:any = 0;
  budgetUtilised:any = 0;
  budgetTransferred:any = 0;
  projectStarted = 0;
  projectRetendered= 0;
  projectChange= 0;
  totalPlannedRoad = 0;
  totalCompleteRoad = 0;
  dropdownProjects = [];
  tempCompletedObj: any = {};
  kmlData :any = [];
  siteArray: any= [];
  totalPoles: any = 0;
  totalBulbs: any = 0;
  pwdCount: number = 0;
  Light:number = 0;
  lightBridgeCount :number =0;
  animationState = 'out';
  animationStates = 'out';
  animationStat ='out';
  completionChart ='out';
  animationStat2 =' out';
  animationState1='out';
  animationState2='out';
  animationState3='out';
  animationState4='out';
  animationState5='out';
  animationState6='out';
  animationState7='out';
  animationState8='out';
  animationState9='out';
  animationState10='out';
  animationState11='out';
  animationState12='out';
  animationState13='out';
  animationState14='out';
  animationState15='out';
  completion:boolean= false;
  completion1:boolean= false;
  completion2:boolean= false;
  completion3:boolean= false;
  completion4:boolean= true;
  completion5:boolean= true;
  completion6:boolean= true;
  completion7:boolean= true;
  completion8:boolean= true;
  completion9:boolean= true;
  completion10:boolean= true;
  tables:boolean= false;
  showButton: boolean = false;
  hideButten: boolean = true;
  featureObject: {};
  previousSelectedProject: string;
  Category : any;
  TotalRunningProjectsLight:number = 0;
  TotalRunningProjectsPwd:number = 0;
  TotalRunningProjectsLightBridge: number =0;
  placeHint: string = "";
  resultPlace: any[] = [];
  organization: string;
  Counts = 0;
  assignedProjects: any = [];
  temp: any[];
  @Output() SideNavToggle = new EventEmitter();
  selectedSiteCompletionDate: any;
  projetcPiArray = [];
  progressChartArray = [];
  siteIcon = false
  filterSiteData: any;
  resChart: ArrayBuffer;
  typeFinance: any;
  
    getCurrentDateTime(): string {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString();
      return formattedDate;
    }

  


  @Output() closeSideNav = new EventEmitter();
  @Output() childEvent = new EventEmitter<boolean>();
  
  

  @ViewChild('gmap', { static: true }) gMapElement: ElementRef;
  targetArraytemp: any=[];

  chartpwd:boolean = true;
  pwdCharts: boolean = true;
  projectCharts :boolean =false;
  months = [];
  days:any = {};
  chart1:boolean = false;
  chart2:boolean= false;
  chart3:boolean= false;
  chart4:boolean= false;
  chart5:boolean= false;
  chart6:boolean= false;
  chart7:boolean= false;
  chart8:boolean= false;
  chart9:boolean= false;
  chart10:boolean= false;
  chart11:boolean= false;
  chart12:boolean= false;
  chart13:boolean= false;
  chart14:boolean= false;
  chart15:boolean= false;
  chart16:boolean= false;
  showDiv:boolean =true;
  isVisible: boolean = true;
  isNotVisible: boolean = false;
  showStyle :boolean =false;
  chart :boolean =false;
  showPWD: boolean = true;
  showLight: boolean = true;
  showBridge:boolean =false;
  showProject: boolean = true;
  showBudget: boolean = false;
  icon: any;
  usersGraph: any;
  map: google.maps.Map<any>;
  map1: google.maps.Map;
  
  mappanel1: google.maps.Map;
  mappanel2: google.maps.Map;
  mappanel3: google.maps.Map;
  mappanel4: google.maps.Map;
  mappanel5: google.maps.Map<any>;
     darkModeStyle: google.maps.MapTypeStyle[] = [
    { "elementType": "geometry", "stylers": [{ "color": "#212121" }] },
    { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] },
    { "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] },
    { "elementType": "labels.text.stroke", "stylers": [{ "color": "#212121" }] },
    { "featureType": "administrative", "elementType": "geometry", "stylers": [{ "color": "#757575" }] },
    { "featureType": "administrative.country", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] },
    { "featureType": "administrative.land_parcel", "stylers": [{ "visibility": "off" }] },
    { "featureType": "administrative.locality", "elementType": "labels.text.fill", "stylers": [{ "color": "#bdbdbd" }] },
    { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] },
    { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#181818" }] },
    { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] },
    { "featureType": "poi.park", "elementType": "labels.text.stroke", "stylers": [{ "color": "#1b1b1b" }] },
    { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "color": "#2c2c2c" }] },
    { "elementType": "labels.text.fill", "stylers": [{ "color": "#8a8a8a" }] },
    { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#373737" }] },
    { "featureType": "road.highway", "elementType": "geometry", "stylers": [{ "color": "#3c3c3c" }] },
    { "featureType": "road.highway.controlled_access", "elementType": "geometry", "stylers": [{ "color": "#4e4e4e" }] },
    { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] },
    { "featureType": "transit", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] },
    { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#000000" }] },
    { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#3d3d3d" }] }
  ];
  loading: any = false
  ShapeLayerData: any = [];
  allDetectionProjectResp: Array<any> = [];
  data: google.maps.Data;
  creator: User;
  creatorid: string;
  sites: Site[];
  projects: any = [];
  loggedInUser: any
  // siteId: string
  unsubscribeSubject = new Subject();
  selectedSite: any;
  selectedProject: any;
  form: any;
  parentData: {}
  totalProgress: any;
  resultHierarchy: any[] = [];
  achivedTotalResult = 0;
  mileStoneTotalResult = 0;
  achivedArray = [];
  mileStoneArray = [];
  spinner= false;
  showChart : boolean = false
  savedItems: string[] = [];
  filteredItems : any[]=[];
  searchProject: boolean = false;
  users:any
  // showChart : boolean = false;
  userControl = new FormControl();
  filteredUsers: Observable<string[]> = of([]);
  projectsTargetData: string[] = [];
  projectsPercentageArray = [];
  userId : false;
  showProjectChart:boolean =false;
  showNoProject:boolean =false;
  showProjectTable: boolean = true;
  categoryChart : boolean = false
  commentForm: FormGroup;
  editComment = false;
  userComments: any;
  reply = false;
  selectedTableData: any;
  totalProjectDays: number; 
  projectName : string;
  excelNotFound = false;
  projectSummary :boolean=true;
  totalCount = 0;
  plannedRoad:any;
  selectProject:any;
  selectedUser: any;
  userInfoArray = []
  userFinanceExcel=[];
  milestones = [
    { key: "Milestone -1", value: "Milestone -1   ", resp: "Achieved Progress", mileStoneAmount: "Unnamed: 7", achivedAmount: "Unnamed: 9" },
    { key: "Milestone -2", value: "Milestone -2", resp: "Achieved Progress.1", mileStoneAmount: "Unnamed: 11", achivedAmount: "Unnamed: 13" },
    { key: "Milestone -3", value: "Milestone -3", resp: "Achieved Progress.2", mileStoneAmount: "Unnamed: 15", achivedAmount: "Unnamed: 17" },
    { key: "Milestone -4", value: "Milestone -4", resp: "Achieved Progress.3", mileStoneAmount: "Unnamed: 19", achivedAmount: "Unnamed: 21" },
    { key: "Milestone -5", value: "Milestone -5", resp: "Achieved Progress.4", mileStoneAmount: "Unnamed: 23", achivedAmount: "Unnamed: 25" },
    { key: "Milestone -6", value: "Milestone -6", resp: "Achieved Progress.5", mileStoneAmount: "Unnamed: 27", achivedAmount: "Unnamed: 29" },
    { key: "Milestone -7", value: "Milestone -7", resp: "Achieved Progress.6", mileStoneAmount: "Unnamed: 31", achivedAmount: "Unnamed: 33" },
    { key: "Milestone -8", value: "Milestone -8", resp: "Achieved Progress.7", mileStoneAmount: "Unnamed: 35", achivedAmount: "Unnamed: 37" },
  ]
  selectedMilestone :any;
  outerBackButton = false;
  amountOuterChart = false;
  outerChart = false;
  backButton = false;
  outChart = false;
  newArrayRes = [];
  newAchivedArray = [];
  totalAmountArray = []
  newResp = 0;
  newAcivedResp = 0;
  subArrayRes = [];
  subAchivedArray = [];
  totalAmountOuter = [];
  arrayDiscription = [];
  arrayPercentage = [];
  backChart: any;
  // exceldata: any;
  completionDate:any=[]
  showCompletionChart = false
    obj :any
    totalLength = 0;
    currentDate = new Date()
  constructor(
    private dialog: MatDialog,
    // private dialog: MatDialog,
    private mapService: MapService,
    private auth: AuthService,
    private router: Router,
    private sitesService: SitesService,
    private formBuilder: FormBuilder,
    private server: ServerService,
    private snackbar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    public placeSearch: PlaceSearchService,
    private http: HttpClient
  ) {
    this.currentUser = localStorage.getItem('email')
    
   }

   targetData: string[] = [];
   plannedData = [];
   achivedData = [];
   projectPlannedResult = [];
   progressPlannedResult = [];
   projectAchivedResult = [];
   selectedPlannedResult = [];
   selectedAchivedResult = [];
   onTimeProject = [];
   projectStartDate: Date;
   projectEndDate: Date;
   weeksChart = false;
   monthlyAchive = [];
   monthlyTraget = [];
  //  months = [];
   monthsObject = {};
   excelResult : any;
   selectedMonth: string;
   backWeeksChart = false;
   innerChart = false;
   showInnerChart: boolean = false;
   excelNotUploaded = false;
   newProjectPlannedResult = [];
   newProjectAchivedResult = [];
   parentTableData :boolean;
   selectedProcess = "twentyFive";
   percentage:number = 25;
   private myChart: any;
   private interval: any;
 
   tempKmlData: any =[]
   taskData: any = {}
   showingFirstChartCopy: any;
  

  ngOnChanges(): void {
    console.log(this.inputData,"this.inputData.projectId")
    this.showProjectChart=true;
    this.mapService.getProject(this.inputData.projectId)
    // let temp = []
    this.mapService.onceProjectChanged.subscribe(data => {
      console.log("data",data);
      

      this.completionDate =data.completionDate
      this.kmlData = data.kmlData
      this.tempKmlData =data.tempKmlData
      this.taskData =data.taskData



      data.kmlData.forEach(element => {
        console.log(element.name)
        if(element.name.includes('Planned')){
          this.plannedRoad = element.measurement
          // this.plannedRoad = 2
        }
      });
      this.excelData()
    })
   
  }

  ngOnInit(): void {
    if(localStorage.getItem('organization') === 'ADMIN'){
      this.getUserData()
    
    let id = localStorage.getItem('id')
    this.mapService.getMcgUsersById(id).subscribe((response: any) => {
      let geoJsonURL = response.data.geoJsonURL;

    if (geoJsonURL) {
      this.http.get(geoJsonURL).subscribe((res: any) => {
        console.log(res, "res====>");

        let placeId;
        this.mapService.searchOnMapData.next(res.features[0].geometry);
        console.log(placeId);
      });
    }
    });
    } else {
      this.getdataa();
      this.getAdminData();
    }
  
    

    
    // console.log(id,'dsadsadsada')
    this.organization = localStorage.getItem('organization');
    console.log(this.organization,"this.organization")
   
    setInterval(() => {
      this.currentDate = new Date();
    }, 1);

    this.icon = this.mapService.featureIcons;
    this.creator = this.auth.getUser();
    console.log("this.creator ==================>>>>",this.creator)
    this.creatorid = this.creator.id;
    // this.getProjectOverviewData().then(()=>{
      let temp = '';
      if (this.organization === "ADMIN" ) {
        temp = 'Pmis';
        this.fetchSiteData();

      } 
      else {
        // temp = " ";
        this.fetchAssignedData();
      }

      console.log(this.assignedProjects)
    // })
    // this.form = this.formBuilder.group({
    //   'selectedSite': new FormControl('', Validators.required),
    //   'selectedProject': new FormControl({ value: '', disabled: true }, Validators.required)
    // });
    this.form = this.formBuilder.group({
      selectedSite: [''],
      selectedProject: ['']
    });
    this.mapSetup();
    this.mapService.getAllDetectionProjects({ userId: this.creatorid }).then((response) => {
      this.allDetectionProjectResp = response['data'];
      // console.log(response,"this.allDetectionProjectResp")
    })
    this.mapService.spinner.on('loader', (load => {
      // console.log(load)
      this.loading = false;
    }))

    // this.createSiteCountChart();
    this.commentForm = this.formBuilder.group({
      name: [{value:'',disabled: this.editComment}, [Validators.required, ]],
      message: ['', [Validators.required]],
      projectId: ['']
    });
    if (this.commentForm) {
      this.filteredUsers = this.commentForm.get('message')!.valueChanges.pipe(
        startWith(''),
        map(value => this.filterUsers(value))
      );
    }
    this.getUserComments()
  }

  getSelectedData(event) {
    console.log(event,'dddddddddddddd')
    this.outChart = false
    this.backButton = false
    this.outerChart = true;
    this.amountOuterChart = true
    this.cdr.detectChanges();
    this.newAchivedArray = [];
    this.newArrayRes = [];
    this.resultHierarchy.forEach((resp) => {
      if (resp.children?.length) {
        let outTotal = 0;
        let outTotalAchive = 0;
        let mileStoneTotalAmount = 0
        let achivedTotalAmount = 0
        this.totalAmountArray = [];
        resp.children.forEach((child) => {
          if (child.data.properties[event.value])
            if (child.children?.length) {
              let insideData = [];
              let insideActualData = [];
              let mileStoneInnerTotalAmount = 0
              let achivedInnerTotalAmount = 0
              child.children.forEach((insideChild) => {
                if (insideChild.data.properties[event.mileStoneAmount] !== 'nan') {
                  mileStoneInnerTotalAmount = Number(insideChild.data.properties[event.mileStoneAmount]) + mileStoneInnerTotalAmount
                }
                if (insideChild.data.properties[event.achivedAmount] !== 'nan') {
                  achivedInnerTotalAmount = Number(insideChild.data.properties[event.value.achivedAmount]) + achivedInnerTotalAmount
                }
                if (insideChild.data.properties[event.value] !== 'nan') {
                  const result = (Number(insideChild.data.properties[event.value] * 100) / 100) * Number((insideChild.data.properties['%']) * 100)
                  insideData.push({ name: resp.part + "." + child.part + "." + insideChild.part + " " + insideChild.data.properties["Description of Items"], value: parseFloat(result.toString()).toFixed(2), totalAmount: parseFloat(Number(insideChild.data.properties[event.value.mileStoneAmount]).toString()).toFixed(2) })
                  this.newResp = this.newResp + result;
                }
                if (insideChild.data.properties[event.resp] !== 'nan') {
                  const result = (Number(insideChild.data.properties[event.resp] * 100) / 100) * Number((insideChild.data.properties['%']) * 100)
                  insideActualData.push({ name: resp.part + "." + child.part + "." + insideChild.part + " " + insideChild.data.properties["Description of Items"], value: parseFloat(result.toString()).toFixed(2), totalAmount: parseFloat(Number(insideChild.data.properties[event.value.achivedAmount]).toString()).toFixed(2) })
                  this.newAcivedResp = this.newAcivedResp + result;
                }
              })
              mileStoneTotalAmount = mileStoneTotalAmount + mileStoneInnerTotalAmount;
              achivedTotalAmount = achivedTotalAmount + achivedInnerTotalAmount;
              if (this.newResp) {
                this.subArrayRes.push({ name: resp.part + "." + child.part + " " + child.data.properties["Description of Items"], value: parseFloat(this.newResp.toString()).toFixed(2), innerArray: insideData, mileStoneInnerTotalAmount: parseFloat(mileStoneInnerTotalAmount.toString()).toFixed(2) })
                outTotal = this.newResp + outTotal
              }
              if (this.newAcivedResp || this.newResp) {
                this.subAchivedArray.push({ name: resp.part + "." + child.part + " " + child.data.properties["Description of Items"], value: parseFloat(this.newAcivedResp.toString()).toFixed(2), innerArray: insideActualData, achivedInnerTotalAmount: parseFloat(achivedInnerTotalAmount.toString()).toFixed(2) })
                if (this.newAcivedResp) {
                  outTotalAchive = this.newAcivedResp + outTotalAchive
                }
              }
              this.newResp = 0;
              this.newAcivedResp = 0;
            } else {
              if (child.data.properties[event.value] !== 'nan') {
                const result = (Number(child.data.properties[event.value] * 100) / 100) * Number((child.data.properties['%']) * 100)
                this.newResp = this.newResp + result;
              }
              if (child.data.properties[event.resp] !== 'nan') {
                const result = (Number(child.data.properties[event.resp] * 100) / 100) * Number((child.data.properties['%']) * 100)
                this.newAcivedResp = this.newAcivedResp + result;
              }
              if (this.newResp) {
                outTotal = this.newResp + outTotal
              }
              if (this.newAcivedResp) {
                outTotalAchive = this.newAcivedResp + outTotalAchive
              }
              this.newResp = 0;
              this.newAcivedResp = 0;
            }
          this.totalAmountArray.push({ value: child.part, resp: achivedTotalAmount })
        })
        this.newArrayRes.push({ value: resp.data.properties["Description of Items"], resp: outTotal !== 0 ? parseFloat((outTotal / 100).toString()).toFixed(2) : 0, subData: this.subArrayRes, mileStoneTotalAmount: parseFloat(mileStoneTotalAmount.toString()).toFixed(2) })
        this.subArrayRes = []
        this.newAchivedArray.push({ value: resp.data.properties["Description of Items"], resp: outTotal !== 0 ? parseFloat((outTotalAchive / 100).toString()).toFixed(2) : 0, subData: this.subAchivedArray, achivedTotalAmount: parseFloat(achivedTotalAmount.toString()).toFixed(2) })
        this.subAchivedArray = []
        this.totalAmountOuter.push({ value: resp.part, resp: this.totalAmountArray })
      }
      this.arrayDiscription = [];
      this.arrayPercentage = [];
      this.callActualChart();
    })
  }

  back(value) {
    console.log(value,'value')
    // this.outerChart=true;
    if (value) {
      this.outerBackButton = false;
      this.amountOuterChart = true;
      this.outChart = true;
      this.backButton=false;
      // this.toggleShowDiv16('divs7');
      this.selectedMilestone = this.milestones[0];
      this.outerChart = true;
      this.getSelectedData(this.selectedMilestone)
      this.callActualChart();
    } else {
      this.backButton = false;
      this.handleChartSeriesClick2(this.backChart);
    }
  }
  
  excelData() {  
 
    if(localStorage.getItem('email') === 'vishvaraj@heliware.co.in'){
      this.mapService.getSiteData(this.inputData.siteId).subscribe(res=>{
        this.completionDate = res['site'].completionDate
        console.log(new Date(this.selectedSiteCompletionDate))
        console.log(new Date() )
        var Difference_In_Time   = new Date(res['site'].completionDate).getTime() - new Date().getTime()
        this.selectedSiteCompletionDate = Difference_In_Time / (1000 * 3600 * 24);
        // console.log(Math.ceil(Difference_In_Days))
      })
      this.server.getSelectedItem(this.inputData).pipe(tap((res) =>  {
        this.selectedSite = res 
        console.log(res)}),
        switchMap(() => this.mapService.getXLSXData(this.selectedSite.data[0]?.table))
      ).subscribe((res) => {
        console.log(res,'pepepep')
        // this.showSpinner = false;
        this.resultHierarchy = this.buildHierarchy(res)
        console.log(this.resultHierarchy)
        let completeFirst = 0;
        let inProgressFirst = 0;
        let pendingFirst = 0;
        let completeSecond = 0;
        let inProgressSecond = 0;
        let pendingSecond = 0;
        let completeThird = 0;
        let inProgressThird = 0;
        let pendingThird = 0;
        let completeFourth = 0;
        let inProgressFourth = 0;
        let pendingFourth = 0;
        let completeFifth = 0;
        let inProgressFifth = 0;
        let pendingFifth = 0;
        let completeSixth = 0;
        let inProgressSixth = 0;
        let pendingSixth = 0;
        let completeSeventh = 0;
        let inProgressSeventh = 0;
        let pendingSeventh = 0;
        let completeEighth = 0;
        let inProgressEighth = 0;
        let pendingEighth = 0;
        
        this.projetcPiArray = [];
        this.progressChartArray = [];
        res.features.forEach((resp) => {
          if (resp.properties["Achieved Progress"] !== "nan" && resp.properties["Achieved Progress"] !== "%" && resp.properties["Milestone -1   "] !== "nan" && resp.properties["Milestone -1   "] !== "%") {
            const result = resp.properties["Achieved Progress"] - resp.properties["Milestone -1   "]
            if (result == 0) {
              completeFirst += 1;
            } else {
              inProgressFirst += 1;
            }
          } else if (resp.properties["SI. No."] !== "nan" && resp.properties["Milestone -1   "] !== "nan" && resp.properties["Milestone -1   "] !== "%" && resp.properties["Achieved Progress"] === "nan") {
            pendingFirst += 1;
          }
  
          if (resp.properties["Achieved Progress.1"] !== "nan" && resp.properties["Achieved Progress.1"] !== "%" && resp.properties["Milestone -2"] !== "nan" && resp.properties["Milestone -2"] !== "%") {
            const result = resp.properties["Achieved Progress.1"] - resp.properties["Milestone -2"]
            if (result == 0) {
              completeSecond += 1;
            } else {
              inProgressSecond += 1;
            }
          } else if (resp.properties["SI. No."] !== "nan" && resp.properties["Milestone -2"] !== "nan" && resp.properties["Milestone -2"] !== "%" && resp.properties["Achieved Progress.1"] === "nan") {
            pendingSecond += 1;
          }
  
          if (resp.properties["Achieved Progress.2"] !== "nan" && resp.properties["Achieved Progress.2"] !== "%" && resp.properties["Milestone -3"] !== "nan" && resp.properties["Milestone -3"] !== "%") {
            const result = resp.properties["Achieved Progress.2"] - resp.properties["Milestone -3"]
            if (result == 0) {
              completeThird += 1;
            } else {
              inProgressThird += 1;
            }
          } else if (resp.properties["SI. No."] !== "nan" && resp.properties["Milestone -3"] !== "nan" && resp.properties["Milestone -3"] !== "%" && resp.properties["Achieved Progress.2"] === "nan") {
            pendingThird += 1;
          }
  
          if (resp.properties["Achieved Progress.3"] !== "nan" && resp.properties["Achieved Progress.3"] !== "%" && resp.properties["Milestone -4"] !== "nan" && resp.properties["Milestone -4"] !== "%") {
            const result = resp.properties["Achieved Progress.3"] - resp.properties["Milestone -4"]
            if (result == 0) {
              completeFourth += 1;
            } else {
              inProgressFourth += 1;
            }
          } else if (resp.properties["SI. No."] !== "nan" && resp.properties["Milestone -4"] !== "nan" && resp.properties["Milestone -4"] !== "%" && resp.properties["Achieved Progress.3"] === "nan") {
            pendingFourth += 1;
          }
  
          if (resp.properties["Achieved Progress.4"] !== "nan" && resp.properties["Achieved Progress.4"] !== "%" && resp.properties["Milestone -5"] !== "nan" && resp.properties["Milestone -5"] !== "%") {
            const result = resp.properties["Achieved Progress.4"] - resp.properties["Milestone -5"]
            if (result == 0) {
              completeFifth += 1;
            } else {
              inProgressFifth += 1;
            }
          } else if (resp.properties["SI. No."] !== "nan" && resp.properties["Milestone -5"] !== "nan" && resp.properties["Milestone -5"] !== "%" && resp.properties["Achieved Progress.4"] === "nan") {
            pendingFifth += 1;
          }
  
          if (resp.properties["Achieved Progress.5"] !== "nan" && resp.properties["Achieved Progress.5"] !== "%" && resp.properties["Milestone -6"] !== "nan" && resp.properties["Milestone -6"] !== "%") {
            const result = resp.properties["Achieved Progress.5"] - resp.properties["Milestone -6"]
            if (result == 0) {
              completeSixth += 1;
            } else {
              inProgressSixth += 1;
            }
          } else if (resp.properties["SI. No."] !== "nan" && resp.properties["Milestone -6"] !== "nan" && resp.properties["Milestone -6"] !== "%" && resp.properties["Achieved Progress.5"] === "nan") {
            pendingSixth += 1;
          }
  
          if (resp.properties["Achieved Progress.6"] !== "nan" && resp.properties["Achieved Progress.6"] !== "%" && resp.properties["Milestone -7"] !== "nan" && resp.properties["Milestone -7"] !== "%") {
            const result = resp.properties["Achieved Progress.6"] - resp.properties["Milestone -7"]
            if (result == 0) {
              completeSeventh += 1;
            } else {
              inProgressSeventh += 1;
            }
          } else if (resp.properties["SI. No."] !== "nan" && resp.properties["Milestone -7"] !== "nan" && resp.properties["Milestone -7"] !== "%" && resp.properties["Achieved Progress.6"] === "nan") {
            pendingSeventh += 1;
          }
  
          if (resp.properties["Achieved Progress.7"] !== "nan" && resp.properties["Achieved Progress.7"] !== "%" && resp.properties["Milestone -8"] !== "nan" && resp.properties["Milestone -8"] !== "%") {
            const result = resp.properties["Achieved Progress.7"] - resp.properties["Milestone -8"]
            if (result == 0) {
              completeEighth += 1;
            } else {
              inProgressEighth += 1;
            }
          } else if (resp.properties["SI. No."] !== "nan" && resp.properties["Milestone -8"] !== "nan" && resp.properties["Milestone -8"] !== "%" && resp.properties["Achieved Progress.7"] === "nan") {
            pendingEighth += 1;
          }
  
  
          if (resp.properties["Description of Items"] == "Net Amount") {
            if (resp.properties["Unnamed: 9"]) {
              const total = parseFloat(((resp.properties["Unnamed: 9"] / resp.properties["Proposed Unit Rate"]) * 100).toString()).toFixed(2)
              this.projetcPiArray.push({ name: `Milestone -1 `, value: total })
            }
            if (resp.properties["Unnamed: 13"]) {
              const total = parseFloat(((resp.properties["Unnamed: 13"] / resp.properties["Proposed Unit Rate"]) * 100).toString()).toFixed(2)
              this.projetcPiArray.push({ name: `Milestone -2 `, value: total })
            }
            if (resp.properties["Unnamed: 17"]) {
              const total = parseFloat(((resp.properties["Unnamed: 17"] / resp.properties["Proposed Unit Rate"]) * 100).toString()).toFixed(2)
              this.projetcPiArray.push({ name: `Milestone -3 `, value: total })
            }
            if (resp.properties["Unnamed: 21"]) {
              const total = parseFloat(((resp.properties["Unnamed: 21"] / resp.properties["Proposed Unit Rate"]) * 100).toString()).toFixed(2)
              this.projetcPiArray.push({ name: `Milestone -4 `, value: total })
            }
            if (resp.properties["Unnamed: 25"]) {
              const total = parseFloat(((resp.properties["Unnamed: 25"] / resp.properties["Proposed Unit Rate"]) * 100).toString()).toFixed(2)
              this.projetcPiArray.push({ name: `Milestone -5 `, value: total })
            }
            if (resp.properties["Unnamed: 29"]) {
              const total = parseFloat(((resp.properties["Unnamed: 29"] / resp.properties["Proposed Unit Rate"]) * 100).toString()).toFixed(2)
              this.projetcPiArray.push({ name: `Milestone -6 `, value: total })
            }
            if (resp.properties["Unnamed: 33"]) {
              const total = parseFloat(((resp.properties["Unnamed: 33"] / resp.properties["Proposed Unit Rate"]) * 100).toString()).toFixed(2)
              this.projetcPiArray.push({ name: `Milestone -7 `, value: total })
            }
            if (resp.properties["Unnamed: 37"]) {
              const total = parseFloat(((resp.properties["Unnamed: 37"] / resp.properties["Proposed Unit Rate"]) * 100).toString()).toFixed(2)
              this.projetcPiArray.push({ name: `Milestone -8 `, value: total })
            }
          }
        }
        )
        
        const totalComplete = completeFirst + completeSecond + completeThird + completeFourth +completeFifth + completeSixth +completeSeventh + completeEighth;
        const totalInProgress = inProgressFirst + inProgressSecond + inProgressThird + inProgressFourth + inProgressFifth +inProgressSixth + inProgressSeventh +inProgressEighth ;
        const totalPendding = pendingFirst + pendingSecond + pendingThird + pendingFourth +pendingFifth +pendingSixth + pendingSeventh +pendingEighth;
        console.log(totalComplete,totalInProgress,totalPendding)
        this.progressChartArray.push({ name: "Completed", value: totalComplete }, { name: "In progress", value: totalInProgress }, { name: "Pending", value: totalPendding })
        this.totalProgress = ((totalComplete / (totalComplete + totalInProgress + totalPendding)) * 100).toFixed(2);
  
        // console.log(Math.ceil((completeFirst / (completeFirst + inProgressFirst + pendingFirst))*100));
        // console.log(Math.ceil((completeSecond / (completeSecond + inProgressSecond + pendingSecond))*100));
        // console.log(Math.ceil((completeThird / (completeThird + inProgressThird + pendingThird))*100));
        // console.log(Math.ceil((completeFourth / (completeFourth + inProgressFourth + pendingFourth))*100));
        // console.log(85+42+9+2/4)
        let data = ['Milestone 1','Milestone 2','Milestone 3','Milestone 4','Milestone 5','Milestone 6','Milestone 7','Milestone 8']
        let series = [
        (((completeFirst / (completeFirst + inProgressFirst + pendingFirst))*100)*125/1000).toFixed(2),
        (((completeSecond / (completeSecond + inProgressSecond + pendingSecond))*100)*125/1000).toFixed(2),
        (((completeThird / (completeThird + inProgressThird + pendingThird))*100)*125/1000).toFixed(2),
        (((completeFourth / (completeFourth + inProgressFourth + pendingFourth))*100)*125/1000).toFixed(2),
        (((completeFifth / (completeFifth + inProgressFifth + pendingFifth))*100)*125/1000).toFixed(2),
        (((completeSixth / (completeSixth + inProgressSixth + pendingSixth))*100)*125/1000).toFixed(2),
        (((completeSeventh / (completeSeventh + inProgressSeventh + pendingSeventh))*100)*125/1000).toFixed(2),
        (((completeEighth / (completeEighth + inProgressEighth + pendingEighth))*100)*125/1000).toFixed(2)
      ]
        this.callChart2(this.projetcPiArray, "chart-container-project")
        console.log(this.progressChartArray , this.totalProgress)
        this.callChart2(this.progressChartArray, "chart-container-progress")
        this.callMilestoneProgressChart('chart-container-milestone-progress',series,data)
        this.callProjectCompletionDays2(Math.ceil(this.selectedSiteCompletionDate))
      },
        (error) => {
          // Handle errors
          // this.showSpinner = false
          console.error('Error:', error);
        }
      )
    }else{
      console.log(this.inputData,'inputs')
      this.days.chartData=false
      this.childEvent.emit(this.days)
      this.targetData = [];
      this.months = [];
      this.server.getSelectedItem(this.inputData).pipe(
        switchMap((resp: any) =>  {
         return this.mapService.getXLSXData(resp.data[0]?.table)
        })
      ).subscribe((responses) => {
        this.excelResult = responses;
        if(responses?.features){
          this.excelNotUploaded = false;
          this.projectPlannedResult =[] 
          this.progressPlannedResult = [];
          this.projectAchivedResult = []
          this.targetArraytemp = []
          const order = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
          const currentDate = new Date();
          const currentMonth = currentDate.toLocaleString('en-US', { month: 'short' });
          const currentYear = currentDate.getFullYear();
          const monthProperties = Object.keys(responses.features[0]?.properties).filter(key => key.includes("'"));
          this.weeksChart = true;
          const [weekPart, _, month, yearPart] = monthProperties[0].split(/['.\s]/);
          const yearly = yearPart.length === 4 ? yearPart : `20${yearPart}`;
          const firstMonth = month;
          const firstYear = yearly;
          const firstWeek = parseInt(weekPart);
          const firstDayOfMonth = new Date(`${firstMonth} 01, ${firstYear}`);
          const dayOfWeek = firstDayOfMonth.getDay();
          const firstDayOfTargetWeek = new Date(firstDayOfMonth);
          firstDayOfTargetWeek.setDate(firstDayOfMonth.getDate() - dayOfWeek + firstWeek * 7);
          const [lastWeekPart, __, lastMonth, lastYearPart] = monthProperties[monthProperties.length - 1].split(/['.\s]/);
          const lastYearly = lastYearPart.length === 4 ? lastYearPart : `20${lastYearPart}`;
          const lastMonth1 = lastMonth;
          const lastYear1 = lastYearly;
          const lastWeek1 = parseInt(lastWeekPart);
          console.log(lastWeek1)
          // Calculate the end date of the last week
          const lastDayOfMonth = new Date(`${lastMonth1} 01, ${lastYear1}`);
          const lastdayOfWeek = lastDayOfMonth.getDay();
          const lastDayOfTargetWeek = new Date(this.completionDate);
          lastDayOfTargetWeek.setDate(lastDayOfMonth.getDate() - lastdayOfWeek + lastWeek1 * 7);
          lastDayOfTargetWeek.setDate(lastDayOfTargetWeek.getDate() + 6);
  
          this.projectStartDate = firstDayOfTargetWeek;
          this.projectEndDate = lastDayOfTargetWeek;
  
          // this.firstDayOfTargetWeek.emit(firstDayOfTargetWeek);
          // this.lastDayOfTargetWeek.emit(lastDayOfTargetWeek);
          this.days.projectStartDate = this.projectStartDate;
          this.days.projectEndDate = this.projectEndDate;
          this.childEvent.emit(this.days)
          
          console.log(this.projectStartDate,'this.completionDate')
          // this.projectEndDate =this.completionDate;
          // Calculate the difference in milliseconds
          const timeDifference = lastDayOfTargetWeek.getTime() - new Date().getTime();
          // console.log(Math.ceil(lastDayOfTargetWeek.getTime() / (1000 * 60 * 60 * 24 * 7)) ,firstDayOfTargetWeek,  lastDayOfTargetWeek)
          // Convert milliseconds to days
          const weeksLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24 * 7));
          const totalWeeks = Math.ceil((lastDayOfTargetWeek.getTime() - firstDayOfTargetWeek.getTime()) / (1000 * 60 * 60 * 24 * 7))
  
          console.log(totalWeeks,'totalweeks')
          let tillCurrentWeek = false;
          let tillCurrentMonth = false
          let tillCurrentMonthArray = [];
        // const header = responses.find((resp)=> resp?.features?.length)
          for (const key in responses.features[0]?.properties) {
  
            const parts = key.split("'");
            const monthYearPart = parts[0].split(' ');
            const month = monthYearPart[monthYearPart.length - 1];
            const year = parts[1];
  
  
            if (year !== undefined) {
              const numericalPart = year.match(/\d+/g).map(Number);
              if (numericalPart[1] === undefined) {
                numericalPart[1] = 0
              }
            }
            if (
              (parseInt(year, 10) + 2000 <= currentYear)
            ) {
            if (parseInt(year, 10) + 2000 == currentYear) {
                const currentWeekIndex = Math.ceil(currentDate.getDate() / 7);
                const findCurrentMonthIndex = order.findIndex((res) => res == currentMonth)
                const findMonthIndex = order.findIndex((res) => res == month)
                const numericPart = monthYearPart[0].match(/\d+/);
                const result = Number(numericPart[0]);
                if(!tillCurrentWeek){
                  this.targetData.push(key)
                } 
                if (findCurrentMonthIndex === findMonthIndex && ((result === currentWeekIndex) ||  (result === 4 && currentWeekIndex === 5)) ) {
                  tillCurrentWeek = true;
                }
                if(findCurrentMonthIndex+1 === findMonthIndex ){
                  tillCurrentMonth = true; 
                }
  
                if(!tillCurrentMonth){
                  tillCurrentMonthArray.push(key)
                }
  
              }
               else {
                if(!tillCurrentWeek){
                  this.targetData.push(key)
                }
                if(!tillCurrentMonth){
                  tillCurrentMonthArray.push(key)
                }
              }
  
            }
          }
          const monthsObj:any = {};
  
          tillCurrentMonthArray.forEach(item => {
            const parts = item.split(" ");
            const lastPart = parts[parts.length - 1].split("'").join("-")
            if (!monthsObj[lastPart]) {
              monthsObj[lastPart] = [];
            }
            monthsObj[lastPart].push(item);
          });
          this.months = Object.keys(monthsObj)
          const monthName = currentDate.toLocaleString('default', { month: 'short' }); // Get short month name
          const year = currentDate.getFullYear().toString().slice(-2); // Get last two digits of the year
  
          const result1 = `${monthName}-${year}`;
          this.selectedMonth = result1;
          this.monthsObject = monthsObj;
  
          const twentyFive = (monthProperties.length*25)/100
          const fifty = (monthProperties.length*50)/100
          const seventyFive = (monthProperties.length*75)/100
          const twentyFiveArray = monthProperties.slice(0,twentyFive);
          const fiftyArray = monthProperties.slice(0,fifty);
          const seventyFiveArray = monthProperties.slice(0,seventyFive);
  
          if(this.targetData.length <= twentyFive){
              this.selectedProcess = "twentyFive";
              this.percentage = 25;
          } else if(this.targetData.length <= fifty ){
            this.selectedProcess = "fifty"
            this.percentage = 50;
          } else if(this.targetData.length <= seventyFive) {
            this.selectedProcess = "seventyFive"
            this.percentage = 75;
          } else {
            this.selectedProcess = "hundred"
            this.percentage = 100;
          }
  
  
          this.fetchWeeksPIChartData(monthsObj, responses);
          responses.features.forEach((featureRes) => {
          
          let plannedArray = [];
          let progressPlannedArray = [];
          let achivedArray = [];
          let targetArray = []
          if (featureRes?.properties['Description'] !== 'nan') {
            let total = 0;
            let total1 = 0
            for (const property in featureRes.properties) {
              const findProperty = this.targetData.find((res) => res === property)
              if (findProperty && featureRes.properties[findProperty] !== 'nan') {
                plannedArray.push({
                  key: findProperty, value: Number(featureRes.properties[findProperty])
                })
                total = total + Number(featureRes.properties[findProperty])
              }
  
              const findProperty1 = this.targetData.find((res) => res === property)
                  if (findProperty1 && featureRes.properties[findProperty1] !== 'nan') {
                    total1 = total1 + Number(featureRes.properties[findProperty1])
                  }
            }
  
            for (const property in featureRes.properties) {
              const findProperty = monthProperties.find((res) => res === property)
              if (findProperty && featureRes.properties[findProperty] !== 'nan') {
                progressPlannedArray.push({
                  key: findProperty, value: Number(featureRes.properties[findProperty])
                })
              }
  
            }
            this.progressPlannedResult.push({ key: featureRes?.properties['Description'], value: Math.round(total), plannedArray: progressPlannedArray })
            this.targetArraytemp.push({ key: featureRes?.properties['Description'], value: Math.round(total1)})
            this.projectPlannedResult.push({ key: featureRes?.properties['Description'], value: Math.round(total), plannedArray: plannedArray })
          } else {
            // console.log(this.kmlData)
            let total = 0;
            for (const property in featureRes.properties) {
              const findProperty = monthProperties.find((res) => res === property)
              if (findProperty && featureRes.properties[findProperty] !== 'nan') {
                achivedArray.push({
                  key: findProperty, value: Number(featureRes.properties[findProperty])
                })
                total = total + Number(featureRes.properties[findProperty])
              }
            }
            
            this.projectAchivedResult.push({ key: featureRes?.properties['Description'], value: Math.round(total), achivedArray: achivedArray })
          }
        })
     
          this.newProjectPlannedResult = this.progressPlannedResult.map((resp) => {
            console.log(resp , 'dsadsadsa')
            const floatTwentyFive = (Number.isFinite(twentyFive) && twentyFive % 1 !== 0) && resp.plannedArray.some((dataItem) => dataItem.key.includes(monthProperties[Math.round(twentyFive) - 1])) ? 0.5 : 0;
            const floatFifty = (Number.isFinite(fifty) && fifty % 1 !== 0) && resp.plannedArray.some((dataItem) => dataItem.key.includes(monthProperties[Math.round(fifty) - 1])) ? 0.5 : 0;
            const floatSeventyFive = (Number.isFinite(seventyFive) && seventyFive % 1 !== 0) && resp.plannedArray.some((dataItem) => dataItem.key.includes(monthProperties[Math.round(seventyFive) - 1])) ? 0.5 : 0;
            const twentyFiveCount = resp.plannedArray.length ? (((twentyFiveArray.filter(monthItem => resp.plannedArray.some(dataItem => dataItem.key.includes(monthItem))).length) + floatTwentyFive) / resp.plannedArray.length) * 100 : 0;
            const fiftyCount = resp.plannedArray.length ? (((fiftyArray.filter(monthItem => resp.plannedArray.some(dataItem => dataItem.key.includes(monthItem))).length) + floatFifty) / resp.plannedArray.length) * 100 : 0; 
  
            const seventyFiveCount = resp.plannedArray.length ? (((seventyFiveArray.filter(monthItem => resp.plannedArray.some(dataItem => dataItem.key.includes(monthItem))).length) + floatSeventyFive) / resp.plannedArray.length) * 100 : 0;
            const hundredCount = resp.plannedArray.length ? ((monthProperties.filter(monthItem => resp.plannedArray.some(dataItem => dataItem.key.includes(monthItem))).length) / resp.plannedArray.length) * 100 : 0;
  
            return {
              ...resp,
              twentyFive: twentyFiveCount.toFixed(2),
              fifty: fiftyCount.toFixed(2),
              seventyFive: seventyFiveCount.toFixed(2),
              hundred: hundredCount.toFixed(2)
            }
          })
  
          this.targetArraytemp.forEach((e,i) => {
            if(e.value > this.projectAchivedResult[i].value){
                this.projectAchivedResult[i].color = '#deeb34'
                this.projectAchivedResult[i].name = e.key
              }else if (e.value == this.projectAchivedResult[i].value){
                this.projectAchivedResult[i].color = '#34eb34'
                this.projectAchivedResult[i].name = e.key
              }
      
              
            })
  
            let percentage 
            if(this.selectedProcess === 'twentyFive') {
              percentage = 25
            }else if (this.selectedProcess === 'fifty'){
              percentage = 50
            }else if (this.selectedProcess === 'seventyFive'){
              percentage = 75
            }else if (this.selectedProcess === 'hundred'){
              percentage = 100
            }
          this.tempKmlData.forEach(async(element) => {
            if(element.name.includes('Achieved') ){
              await this.projectAchivedResult.forEach(e => {
               
                if(e.name == element.name.split('_')[1]){
                  // e.value = ((element.measurement / this.plannedRoad)* percentage).toFixed(2) 
                  e.value = element.measurement 
                }
              })
            }
          })
  
          this.newProjectAchivedResult = this.projectAchivedResult.map((resp, index) => {
            const floatTwentyFive = (Number.isFinite(twentyFive) && twentyFive % 1 !== 0) && resp.achivedArray.some((dataItem) => dataItem.key.includes(monthProperties[Math.round(twentyFive) - 1])) ? 0.5 : 0;
            const floatFifty = (Number.isFinite(fifty) && fifty % 1 !== 0) && resp.achivedArray.some((dataItem) => dataItem.key.includes(monthProperties[Math.round(fifty) - 1])) ? 0.5 : 0;
            const floatSeventyFive = (Number.isFinite(seventyFive) && seventyFive % 1 !== 0) && resp.achivedArray.some((dataItem) => dataItem.key.includes(monthProperties[Math.round(seventyFive) - 1])) ? 0.5 : 0;
  
            // const twentyFiveCount = (((twentyFiveArray.filter(monthItem => resp.achivedArray.some(dataItem => dataItem.key.includes(monthItem))).length) + floatTwentyFive) / this.progressPlannedResult[index].plannedArray.length) * 100;
            // const fiftyCount = (((fiftyArray.filter(monthItem => resp.achivedArray.some(dataItem => dataItem.key.includes(monthItem))).length) + floatFifty) / this.progressPlannedResult[index].plannedArray.length) * 100;
            // const seventyFiveCount = (((seventyFiveArray.filter(monthItem => resp.achivedArray.some(dataItem => dataItem.key.includes(monthItem))).length) + floatSeventyFive) / this.progressPlannedResult[index].plannedArray.length) * 100;
            // const hundredCount = ((monthProperties.filter(monthItem => resp.achivedArray.some(dataItem => dataItem.key.includes(monthItem))).length) / this.progressPlannedResult[index].plannedArray.length) * 100;
            const twentyFiveCount = ((resp.value / this.plannedRoad)* 25).toFixed(2) 
            const fiftyCount = ((resp.value / this.plannedRoad)* 50).toFixed(2)
            const seventyFiveCount = ((resp.value / this.plannedRoad)* 75).toFixed(2)
            const hundredCount = ((resp.value / this.plannedRoad)* 100).toFixed(2)
  
        
  
            return {
              ...resp,
              twentyFive: twentyFiveCount,
              fifty: fiftyCount,
              seventyFive: seventyFiveCount,
              hundred: hundredCount
            }
          })
       
          console.log(this.newProjectAchivedResult ,this.projectAchivedResult,)
        this.chartData(this.selectedProcess)
        // this.projectCompletionweeks(weeksLeft , totalWeeks);
        this.projectCompletionweeks();
      }else{
        this.projectCompletionweeks();
        this.excelNotUploaded = true;
        this.snackbar.open('Currently excel is not uploaded in this project', 'Close', {
          duration: 3000,
          // panelClass: ['red-snackbar', 'custom-snackbar']
        });
        this.days.chartData=true;
        this.childEvent.emit(this.days)
        this.months = [];
        this.weeksChart = false;
        this.projectPlannedResult =[] 
          this.projectAchivedResult = []
      }
  
      if(this.inputData.selectedTableData && !this.excelNotUploaded){
        console.log(this.inputData.selectedTableData,'this.inputData.selectedTableData')
        const budgetData = [{
          name: "Budget Utilised", value: 0
        },
        {
          name: "Budget Transferred", value: 0
        },
        {
          name: "Budget Sanctioned", value: this.inputData.selectedTableData["Budget Sanctioned (in Lakhs)"] 
        },
  
        ]
        this.projectBudgetChart(budgetData)
      }
      })
    }
   
  }

  callProjectCompletionDays2(date){
    
    var chartDom = document.getElementById('chart-container-days-progress');
    var myChart = echarts.init(chartDom);
    let option = {
      tooltip: {
        formatter: '{a} <br/>{b} : {c}%'
      },
      legend:{
        textStyle: {
          color: '#ffffff'
        }
      },
      series: [
        {
          name: '',
          type: 'gauge',
          min: 0,
          max: 500,
          progress: {
            show: true
          },
          detail: {
            valueAnimation: true,
            formatter: '{value}'
          },
          data: [
            {
              value: date,
              name: 'Days Left'
            }
          ]
        }
      ]
    };

    myChart.setOption(option);
  }

  callMilestoneProgressChart(id,series,data){

    var myChart = echarts.init((document.getElementById(id)) as any);

    const option = {
      title: {
        text: ''
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        // formatter: function (params){
        //   return;
        // } 
      },
      legend: {
        textStyle: {
          color: '#ffffff'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        axisLabel: {
          color: '#ffffff' // Text color set to white
        },
      },
      yAxis: {
        type: 'category',
        data: ['Milestone 1','Milestone 2','Milestone 3','Milestone 4','Milestone 5','Milestone 6','Milestone 7','Milestone 8'],
        axisLabel: {
          color: '#ffffff' // Text color set to white
        },
      },
      series: [
        {
          name: 'Total',
          type: 'bar',
          data: [12.5,12.5,12.5,12.5,12.5,12.5,12.5,12.5]
        },
        {
          name: 'Archieved',
          type: 'bar',
          data: series
        }
      ]
    };
    myChart.setOption(option);
  }
  amountChart() {
    this.amountOuterChart = false;
    this.outerBackButton = true;
    this.backButton = false;
    this.outChart = true;
    this.callActualChart()
  }

  callActualChart() {
    this.cdr.detectChanges();
    let dataKey = []
    let dataPer = []
    let dataKeyResp = []
    this.newAchivedArray.forEach(element => {
      console.log(element,'element')
      if (element.resp !== 0) {
        dataKey.push(element.value)
        !this.outChart ? dataKeyResp.push(element.resp) : dataKeyResp.push(element.achivedTotalAmount)
      }

    });
    this.newArrayRes.forEach(element => {
      if (element.resp) {
        !this.outChart ? dataPer.push(element.resp) : dataPer.push(element.mileStoneTotalAmount)
      }
    });

    const formatNumberWithCommas = (number) => {
      return parseFloat(number).toLocaleString('en-US'); // You can customize this function as needed
    };

    const formattedDataKeyResp = dataKeyResp.map(number => {
      return formatNumberWithCommas(parseFloat(number).toLocaleString());
    });

    var app: any = {};
    this.innerChart = false;
    var chartDom = document.getElementById('chart-container-main');
    // chartDom.scrollIntoView();
    var myChart = echarts.init(chartDom);
    var option;

    const posList = [
      'left',
      'right',
      'top',
      'bottom',
      'inside',
      'insideTop',
      'insideLeft',
      'insideRight',
      'insideBottom',
      'insideTopLeft',
      'insideTopRight',
      'insideBottomLeft',
      'insideBottomRight'
    ];
    app.configParameters = {
      rotate: {
        max: 45
      },
      align: {
        options: {
          left: 'left',
          center: 'center',
          right: 'right'
        }
      },
      verticalAlign: {
        options: {
          top: 'top',
          middle: 'middle',
          bottom: 'bottom'
        }
      },
      position: {
        options: posList.reduce(function (map, pos) {
          map[pos] = pos;
          return map;
        }, {})
      },
      distance: {
        min: 0,
        max: 100
      }
    };
    app.config = {
      rotate: 90,
      align: 'left',
      verticalAlign: 'middle',
      position: 'insideBottom',
      distance: 15,
      onChange: function () {
        const labelOption = {
          rotate: app.config.rotate,
          align: app.config.align,
          verticalAlign: app.config.verticalAlign,
          position: app.config.position,
          distance: app.config.distance
        };
        myChart.setOption({
          series: [
            {
              label: labelOption
            },
            {
              label: labelOption
            },
          ]
        });
      }
    };
    const labelOption = {
      show: true,
      position: app.config.position,
      distance: app.config.distance,
      align: app.config.align,
      verticalAlign: app.config.verticalAlign,
      rotate: app.config.rotate,
      formatter: '{c}  {name|{a}}',
      fontSize: 16,
      rich: {
        name: {}
      }
    };
    option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: [
        {
          type: 'category',
          axisTick: { show: false },
          axisLabel: {
            rotate: 10, // Adjust the angle as needed
            color: '#ffffff' 
          },
          name: 'Description of Items',
          nameTextStyle: {
            color: '#ffffff' 
          },
          nameLocation: 'middle',
          nameGap: 50,
          data: dataKey
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: !this.outChart ? 'Percent (%)' : 'Amount (Rs.)',
          nameLocation: 'middle',
          nameGap: 100,
          axisLabel: {
            color: '#FFFFFF' 
          },
          nameTextStyle: {
            color: '#ffffff'
          },
        }
      ],
      series: [
        {
          name: 'Planned',
          type: 'bar',
          barGap: 0,
          label: {
            ...labelOption,
            formatter: (params) => {
              // Customize the label formatting here
              return formatNumberWithCommas(params.value) + " " + "Planned";
            },
          },
          emphasis: {
            focus: 'series'
          },
          data: dataPer
        },
        {
          name: 'Achived',
          type: 'bar',
          label: {
            ...labelOption,
            formatter: (params) => {
              // Customize the label formatting here
              return formatNumberWithCommas(params.value) + " " + "Achived";
            },
          },
          emphasis: {
            focus: 'series'
          },
          data: dataKeyResp
        },
      ]
    };
    myChart.setOption(option);

    myChart.on('click', 'series', (params) => {
      this.handleChartSeriesClick2(params)
    })
  }


  handleChartSeriesClick2(params) {
    this.showInnerChart = true;
    this.outerChart=false;
    this.innerChart = true;
    this.backChart = params
    this.cdr.detectChanges();
    let dataKey = []
    let dataPer = []
    let dataKeyResp = []
    const findAchived = this.newAchivedArray.find((element) => params.name === element.value)
    const findPlanned = this.newArrayRes.find((element) => params.name === element.value)
    findAchived.subData.forEach((resp) => {
      dataKey.push(resp.name)
      !this.outChart ? dataKeyResp.push(resp.value) : dataKeyResp.push(resp.achivedInnerTotalAmount)
    })
    findPlanned.subData.forEach((resp) => {
      !this.outChart ? dataPer.push(resp.value) : dataPer.push(resp.mileStoneInnerTotalAmount)
    })

    const formatNumberWithCommas = (number) => {
      return parseFloat(number).toLocaleString('en-US'); // You can customize this function as needed
    };

    let obj = this;
    var app: any = {};

    var chartDom = document.getElementById('chart-container-inner');

    // chartDom.scrollIntoView();
    var myChart = echarts.init(chartDom);
    const posList = [
      'left',
      'right',
      'top',
      'bottom',
      'inside',
      'insideTop',
      'insideLeft',
      'insideRight',
      'insideBottom',
      'insideTopLeft',
      'insideTopRight',
      'insideBottomLeft',
      'insideBottomRight'
    ];
    app.configParameters = {
      rotate: {
        min: -90,
        max: 90
      },
      align: {
        options: {
          left: 'left',
          center: 'center',
          right: 'right'
        }
      },
      verticalAlign: {
        options: {
          top: 'top',
          middle: 'middle',
          bottom: 'bottom'
        }
      },
      position: {
        options: posList.reduce(function (map, pos) {
          map[pos] = pos;
          return map;
        }, {})
      },
      distance: {
        min: 0,
        max: 100
      }
    };
    app.config = {
      rotate: 90,
      align: 'left',
      verticalAlign: 'middle',
      position: 'insideBottom',
      distance: 15,
      onChange: function () {
        const labelOption = {
          rotate: app.config.rotate,
          align: app.config.align,
          verticalAlign: app.config.verticalAlign,
          position: app.config.position,
          distance: app.config.distance
        };
        myChart.setOption({
          series: [
            {
              label: labelOption
            },
            {
              label: labelOption
            },
          ]
        });
      }
    };
    const labelOption = {
      show: true,
      position: app.config.position,
      distance: app.config.distance,
      align: app.config.align,
      verticalAlign: app.config.verticalAlign,
      rotate: app.config.rotate,
      formatter: '{c}  {name|{a}}',
      fontSize: 16,
      rich: {
        name: {}
      }
    };
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          const data = !this.outChart ? `${params.name}: ${params.value}%<br/>` : `${params.name}: ${params?.value}<br/>`
          return data;
        }
      },
      xAxis: [
        {
          axisLabel: {
            formatter: function (value) {
              return value.split(' ')[0];
            },
            color: '#ffffff'
          },
          name: 'Description of Items',
          nameTextStyle: {
            color: '#ffffff' 
          },
          nameLocation: 'middle',
          nameGap: 50,
          type: 'category',
          axisTick: { show: false },
          data: dataKey
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: !this.outChart ? 'Percent (%)' : 'Amount (Rs.)',
          nameLocation: 'middle',
          nameGap: 100,
          axisLabel: {
            color: '#FFFFFF' 
          },
          nameTextStyle: {
            color: '#ffffff'
          },
        }
      ],
      series: [
        {
          name: 'Planned',
          type: 'bar',
          barGap: 0,
          label: {
            ...labelOption,
            formatter: (params) => {
              // Customize the label formatting here
              return formatNumberWithCommas(params.value) + " " + "Achived";
            },
          },
          emphasis: {
            focus: 'series'
          },
          data: dataPer
        },
        {
          name: 'Achived',
          type: 'bar',
          label: {
            ...labelOption,
            formatter: (params) => {
              // Customize the label formatting here
              return formatNumberWithCommas(params.value) + " " + "Achived";
            },
          },
          emphasis: {
            focus: 'series'
          },
          data: dataKeyResp
        },
      ]
    };
    
    
    myChart.setOption(option);
    myChart.setOption({
      series: []
    });

    myChart.on('click', 'series', (params) => {
      // console.log(params)
      this.backButton = true;
      let findChartData
      if (params.seriesName === "Planned") {
        // Handle the click event for the Planned bar series
        findChartData = this.newArrayRes.find((outer) => outer.subData.find((element) => params.name === element.name))
      }

      if (params.seriesName === "Achived") {
        findChartData = this.newAchivedArray.find((outer) => outer.subData.find((element) => params.name === element.name))
      }
      const formatNumberWithCommas = (number) => {
        return parseFloat(number).toLocaleString('en-US'); // You can customize this function as needed
      };
      if (findChartData) {
        const innerData = findChartData.subData.find(
          (element) => params.name === element.name
        );
        let option;
        option = {
          // Configuration for the pie chart
          title: {
            text: params.name,
            textStyle: {
              fontSize: 18,
              fontWeight: 'bold',
              color: 'white'
            },
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: (params) => {
              const amount = formatNumberWithCommas(params?.data?.totalAmount)
              const data = !this.outChart ? `${params.name}: ${params.value}%<br/>` : `${params.name}: ${amount}<br/>`
              return data;
            }
          },
          legend: {
            orient: 'vertical',
            formatter: function (params) {
              return params;
            },
            textStyle: {
              color: 'white'  
            },
            top: 30,
            left: 10,
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: '50%',
              data: innerData.innerArray,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
              },
              label: {
                color: 'white',  
                formatter: function (params) {
                  var shortName = params.name;
                  return shortName;
                },
              }
            }
          ],
        };
        myChart.clear();
        // Set the pie chart option
        myChart.setOption(option);
        myChart.on('click', 'series', (params) => {
          // console.log(params,'dsadasd')
          if(params.componentSubType == 'pie'){
            this.router.navigate(['/gis/'+obj.inputData.projectId])
          }else{

          }
          // this.router.navigate(['/gis/'+obj.inputData.projectId])
        })
      }

    });
  }


  fetchWeeksPIChartData(result, responses) {
    const monthlyTraget = [];
    const monthlyAchive = [];
    if (result[this.selectedMonth]?.length) {
      result[this.selectedMonth].forEach((month) => {
        let targetResult = [];
        let achiveResult = [];
        let description = '';
        responses.features.forEach((featureRes) => {

          if (featureRes.properties["Unnamed: 2"] === "Target" && featureRes.properties[month] !== 'nan') {
            description = featureRes.properties["Description"]
            targetResult.push({ name: description, value: featureRes.properties[month] })
          } else if (featureRes.properties["Unnamed: 2"] === "Achieve" && featureRes.properties[month] !== 'nan') {
            achiveResult.push({ name: description, value: featureRes.properties[month] })
          }
        })
        const part = month.split(' ')[0].match(/\d+/);
        while (monthlyTraget.length < part) {
          monthlyTraget.push(null);
        }
        monthlyTraget[part - 1] = { name: month, value: targetResult }
        monthlyAchive[part - 1] = { name: month, value: achiveResult }
      })
      this.monthlyAchive = monthlyAchive
      this.monthlyTraget = monthlyTraget
      // this.weeksPIChart(monthlyTraget);
    }
  }
  


  
  projectBudgetChart(data) {
    console.log(data,'datschart')
    // alert();
    // this.inputData.parentShowTable= false;
    // console.log()
    this.cdr.detectChanges();
    var chartDom = document.getElementById("project-budget-chart");
    console.log(chartDom,'--------------oop')
    var myChart = echarts.init(chartDom);
    const option = {
      title: {
        text: 'Project Budget ( In Lakhs)',
        left:'center',
        top:'5%',
        textStyle:{
          color:'white'
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      label: {
        show: true,
        // position: 'top',
        color: "black",
        // fontSize:12,
      },
      legend: {
        top: '12%',
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      }, 
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01]
      },
      yAxis: {
        type: 'category',
        data: data.map(item => item.name),
        axisLabel: {
          color: 'white' 
        }
      },
      series: [
        {
          type: 'bar',
          data: data.map(item => item.value)
        },
      ]
    };
    myChart.setOption(option);
  }

  chartData(value){
    console.log(value,'value')
    console.log(this.projectAchivedResult ,this.newProjectPlannedResult , this.newProjectAchivedResult,'achievesss')
     this.cdr.detectChanges();
     var app: any = {};
     var chartDom = document.getElementById('chart-container-summary3');
    
 
     let achivedResult = [];
 
     this.newProjectAchivedResult.forEach((res,index)=>{
       // console.log(res ,index)
       // console.log(typeof(this.newProjectPlannedResult[index][value]) ,typeof(res['hundred']) , this.newProjectPlannedResult[index][value] > res['hundred'] )
       if(Number(this.newProjectPlannedResult[index][value]) > Number(res['hundred']) ){
         res[value] = res['hundred']
       }else{
         // alert(this.newProjectPlannedResult[index][value])
         res[value] = this.newProjectPlannedResult[index][value]
       }
       if(parseFloat(this.newProjectPlannedResult[index][value]) !== 0){
         achivedResult.push(res);
       }
     })
     
 
     console.log(achivedResult)
     const data = achivedResult.map(item => item.value)
     console.log("====================>>>>",data)
     var myChart = echarts.init(chartDom);
     const posList = [
       'left',
       'right',
       'top',
       'bottom',
       'inside',
       'insideTop',
       'insideLeft',
       'insideRight',
       'insideBottom',
       'insideTopLeft',
       'insideTopRight',
       'insideBottomLeft',
       'insideBottomRight'
     ];
     app.configParameters = {
       rotate: {
         min: -90,
         max: 90
       },
       align: {
         options: {
           left: 'left',
           center: 'center',
           right: 'right'
         }
       },
       verticalAlign: {
         options: {
           top: 'top',
           middle: 'middle',
           bottom: 'bottom'
         }
       },
       position: {
         options: posList.reduce(function (map, pos) {
           map[pos] = pos;
           return map;
         }, {})
       },
       distance: {
         min: 0,
         max: 100
       }
     };
     app.config = {
       rotate: 90,
       align: 'left',
       verticalAlign: 'middle',
       position: 'insideBottom',
       distance: 15,
       onChange: function () {
         const labelOption = {
           rotate: app.config.rotate,
           align: app.config.align,
           verticalAlign: app.config.verticalAlign,
           position: app.config.position,
           distance: app.config.distance
         };
         myChart.setOption({
           series: [
             {
               label: labelOption
             },
             {
               label: labelOption
             },
           ]
         });
       }
     };
     const labelOption = {
       show: true,
       position: app.config.position,
       distance: app.config.distance,
       align: app.config.align,
       verticalAlign: app.config.verticalAlign,
       rotate: app.config.rotate,
       formatter: '{c}  {name|{a}}',
       fontSize: 16,
       rich: {
         name: {}
       }
     };
     const option = {
       tooltip: {
         trigger: 'item',
         formatter: (params) => {
           const data = `${params.name}: ${params.value}%<br/>`
           return data;
         }
       },
       xAxis: [
         {
           axisLabel: {
             formatter: function (value) {
               return value.split(' ')[0];
             },
             textStyle: {
              color: 'White'
          }

           },
           name: 'Description of Items',
           nameLocation: 'middle',
           nameGap: 50,
           nameTextStyle: {
            color: 'White'
          },
           type: 'category',
           axisTick: { show: false },
           data: this.newProjectPlannedResult
           .filter(item => parseFloat(item[value]) !== 0)
           .map(item => item.key)
         }
       ],
       yAxis: [
        {
          type: 'value',
          name: 'Percent (%)',
          nameLocation: 'middle',
          nameGap: 100,
          axisLabel: {
            textStyle: {
              color: 'White'
            }
          }
        },
      ],
      
       series: [
         {
           name: 'Planned',
           type: 'bar',
           barGap: 0,
           label: {
             ...labelOption,
             formatter: (params) => {
               return params.value + "%  " + "Planned";
             },
           },
           emphasis: {
             focus: 'series'
           },
           data: this.newProjectPlannedResult.filter(item => parseFloat(item[value]) !== 0).map(item => item[value])
         },
         {
           name: 'Achived',
           type: 'bar',
           label: {
             ...labelOption,
             formatter: (params) => {
               return params.value + "%  " + "Achived";
             },
           },
           emphasis: {
             focus: 'series'
           },
           itemStyle: {
             color: (params)=>{
               const result = this.projectAchivedResult.find((res)=>res.name === params.name)
               return result?.color
             },
           },
           data: achivedResult.map(item => item[value])
         },
       ]
     };
     myChart.setOption(option);
     myChart.on('click', 'series', (params) => {   
       const projectIndex = this.newProjectPlannedResult.findIndex((resp) => resp.key === params.name)
       if (projectIndex) {
         this.innerChart = true;
        //  this.handleChartSeriesClick(projectIndex, params.name)
       }
     })
   }

   showingFirstChart: boolean = true;

   projectCompletionweeks() {
    // const taskData = {
    //   "startDate": "2024-11-15T18:30:00.000Z",
    //   "endDate": "2024-11-30T18:30:00.000Z",
    //   "additionalTimelines": [
    //     {
    //       "_id": {
    //         "$oid": "67358aa65efffe3dc0dad1df"
    //       },
    //       "startDate": "2024-11-16T18:30:00.000Z",
    //       "endDate": "2024-11-19T18:30:00.000Z",
    //       "description": "task 1",
    //       "time": {
    //         "$date": "2024-11-14T05:29:10.155Z"
    //       }
    //     },
    //     {
    //       "_id": {
    //         "$oid": "67358aa65efffe3dc0dad1e0"
    //       },
    //       "startDate": "2024-11-19T18:30:00.000Z",
    //       "endDate": "2024-11-20T18:30:00.000Z",
    //       "description": "task 2",
    //       "time": {
    //         "$date": "2024-11-14T05:29:10.155Z"
    //       }
    //     },
    //     {
    //       "_id": {
    //         "$oid": "67358aa65efffe3dc0dad1e1"
    //       },
    //       "startDate": "2024-11-20T18:30:00.000Z",
    //       "endDate": "2024-11-22T18:30:00.000Z",
    //       "description": "task 3",
    //       "time": {
    //         "$date": "2024-11-14T05:29:10.156Z"
    //       }
    //     },
    //     {
    //       "_id": {
    //         "$oid": "67358aa65efffe3dc0dad1e2"
    //       },
    //       "startDate": "2024-11-25T18:30:00.000Z",
    //       "endDate": "2024-11-27T18:30:00.000Z",
    //       "description": "task 4",
    //       "time": {
    //         "$date": "2024-11-14T05:29:10.156Z"
    //       }
    //     }
    //   ]
    // }

    const taskData = this.taskData;


    console.log("this.taskData",this.taskData);
    
    const chartDom = document.getElementById("weeks-chart-container") as HTMLDivElement;
    const myChart = echarts.init(chartDom);

    const startDate = new Date(taskData.startDate);
    const endDate = new Date(taskData.endDate);
    const currentDate = new Date();

    const totalDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    const elapsedDays = Math.min(totalDays, Math.ceil((currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));

    const dateFormatter = (date: Date, full: boolean = false) => {
      const options: Intl.DateTimeFormatOptions = full
        ? { day: '2-digit', month: 'short', year: 'numeric' }
        : { day: '2-digit', month: 'short' };
      return date.toLocaleDateString('en-GB', options).replace(/ /g, '/').toUpperCase();
    };

    // First Chart
    const firstChartOptions = {
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          const data = params.data;
          return `
            <strong>${data.name}</strong><br />
            Current Date: ${dateFormatter(currentDate, true)}
          `;
        },
        backgroundColor: '#333',
        borderColor: '#777',
        borderWidth: 1,
        textStyle: { color: '#fff' },
      },
      series: [
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: totalDays,
          progress: { show: true, width: 10, itemStyle: { color: '#00C853' } },
          data: [{ value: elapsedDays, name: '' }],
          axisLine: {
            lineStyle: {
              width: 12,
              color: [[elapsedDays / totalDays, '#00C853'], [1, '#DAE0E6']]
            },
          },
          axisLabel: {
            formatter: (value: number) => {
              if (value === 0) return `Start: ${dateFormatter(startDate, true)}`;
              if (value === totalDays) return `End: ${dateFormatter(endDate, true)}`;
              return '';
            },
            distance: 14,
            color: '#fff',
            fontSize: 12,
          },
          pointer: { length: '85%', width: 6 },
          detail: {
            show: true,
            formatter: `Current Date: ${dateFormatter(currentDate, true)}`,
            fontSize: 14,
            offsetCenter: [0, '30%'],
            color: '#fff',
          },
          anchor: { show: true, size: 10 },
        },
      ],
    };

    // Second Chart
    const additionalTimelineOptions = {
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          const data = params.data;
          return `
            <strong>${data.name}</strong><br />
            Start Date: ${dateFormatter(new Date(data.startDate), true)}<br />
            End Date: ${dateFormatter(new Date(data.endDate), true)}
          `;
        },
        backgroundColor: '#333',
        borderColor: '#777',
        borderWidth: 1,
        textStyle: { color: '#fff' },
      },
      series: [
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: startDate.getTime(),
          max: endDate.getTime(),
          axisLine: {
            lineStyle: {
              width: 22,
              color: (() => {
                const colors = [];
                taskData.additionalTimelines.forEach((timeline, index) => {
                  const segmentStart =
                    (new Date(timeline.startDate).getTime() - startDate.getTime()) /
                    (endDate.getTime() - startDate.getTime());
                  const segmentEnd =
                    (new Date(timeline.endDate).getTime() - startDate.getTime()) /
                    (endDate.getTime() - startDate.getTime());
                  colors.push([
                    segmentEnd,
                    `hsl(${(index * 360) / taskData.additionalTimelines.length}, 70%, 50%)`,
                  ]);
                  if (index < taskData.additionalTimelines.length - 1) {
                    const nextTimeline = taskData.additionalTimelines[index + 1];
                    const gapStart =
                      (new Date(timeline.endDate).getTime() - startDate.getTime()) /
                      (endDate.getTime() - startDate.getTime());
                    const gapEnd =
                      (new Date(nextTimeline.startDate).getTime() - startDate.getTime()) /
                      (endDate.getTime() - startDate.getTime());
                    if (gapEnd > gapStart) {
                      colors.push([gapEnd, '#DAE0E6']); // Gray for the gap
                    }
                  }
                });
                colors.push([1, '#DAE0E6']); // Gray for unused range at the end
                return colors;
              })(),
            },
          },
          axisLabel: {
            formatter: (value: number) => {
              const timeline = taskData.additionalTimelines.find(
                (t) => value === new Date(t.endDate).getTime()
              );
              // if (timeline) {
              //   return `End: ${dateFormatter(new Date(timeline.endDate), true)}`;
              // }
              if (value === startDate.getTime()) {
                return `Start: ${dateFormatter(startDate, true)}`;
              }
              if (value === endDate.getTime()) {
                return `End: ${dateFormatter(endDate, true)}`;
              }
              return '';
            },
            distance: 25,
            color: '#fff',
          },
          data: taskData.additionalTimelines.map((timeline) => ({
            value: new Date(timeline.endDate).getTime(),
            name: timeline.description,
            startDate: timeline.startDate,
            endDate: timeline.endDate,
          })),
          pointer: {
            show: true,
            length: '85%',
            width: 6,
          },
          anchor: {
            show: true,
            size: 10,
            itemStyle: {
              color: 'red', // Pointer color
            },
          },
          detail: {
            show: true,
            formatter: `Current Date: ${dateFormatter(currentDate, true)}`,
            fontSize: 14,
            offsetCenter: [0, '30%'],
          },
          title: { show: false },
        },
      ],
    };

    this.showingFirstChart = true;
    myChart.setOption(firstChartOptions);

    // Toggle charts on click
    myChart.getZr().on('click', () => {
      this.showingFirstChart = !this.showingFirstChart;
      myChart.setOption(this.showingFirstChart ? firstChartOptions : additionalTimelineOptions);
    });

    myChart.resize();
  }

  ngAfterViewInit(){
    let obj = this;
    setTimeout(() => {
      // $('#chart-no-of-project').addClass('minimized');
      // $('.maxDiv').addClass('maximize');
      // $('.minimize').addClass('minimizeButton');

      // $('#chartExcel').addClass('minimized2');
      // $('.maxDiv2').addClass('maximize2');
      // $('.minimize2').addClass('minimizeButton2');

      $('#pwd-light').addClass('minimized6');
      $('.maxDiv6').addClass('maximize6');
      $('.minimize6').addClass('minimizeButton6');
      
      $('#project-running').addClass('minimized5');
      $('.maxDiv5').addClass('maximize5');
      $('.minimize5').addClass('minimizeButton5');

    //   $('.minimize').on('click', function(){minimize();});
    //   $('.maxDiv').on('click', function(){maximize();});
    
    // function minimize(){
    //   $('#chart-no-of-project').addClass('minimized');
    //   $('.maxDiv').addClass('maximize');
    //   $('.minimize').addClass('minimizeButton');
    // }
    
    // function maximize(){
    //   $('#chart-no-of-project').removeClass('minimized');
    //   $('.maxDiv').removeClass('maximize');
    //   $('.minimize').removeClass('minimizeButton');
    // }

      // $('.minimize2').on('click', function(){minimize2();});
      // $('.maxDiv2').on('click', function(){maximize2();});
    
    // function minimize2(){
    //   $('#chartExcel').addClass('minimized2');
    //   $('.maxDiv2').addClass('maximize2');
    //   $('.minimize2').addClass('minimizeButton2');
    // }
    
    // function maximize2(){
    //   $('#chartExcel').removeClass('minimized2');
    //   $('.maxDiv2').removeClass('maximize2');
    //   $('.minimize2').removeClass('minimizeButton2');
    // }

      $('.minimize5').on('click', function(){minimize5();});
      $('.maxDiv5').on('click', function(){maximize5();});
    
    function minimize5(){
      $('#project-running').addClass('minimized5');
      $('.maxDiv5').addClass('maximize5');
      $('.minimize5').addClass('minimizeButton5');
    }

    function maximize5(){
      $('#project-running').removeClass('minimized5');
      $('.maxDiv5').removeClass('maximize5');
      $('.minimize5').removeClass('minimizeButton5');
    }

      $('.minimize6').on('click', function(){minimize6();});
      $('.maxDiv6').on('click', function(){maximize6();});
    
    function minimize6(){
      $('#pwd-light').addClass('minimized6');
      $('.maxDiv6').addClass('maximize6');
      $('.minimize6').addClass('minimizeButton6');
    }
    
    function maximize6(){
      $('#pwd-light').removeClass('minimized6');
      $('.maxDiv6').removeClass('maximize6');
      $('.minimize6').removeClass('minimizeButton6');
    }
    

    

  }, 1000);
  }

  
  saveData(result: string) {
    this.savedItems.push(result);
    // console.log(result,'resulttt')
  }

  createSiteCountChart(){
    // console.log(this.sites)
   
    let data = []
    let name = []
    this.showNoProject = true;
    this.temp = []
    let a =[]
    this.sites.forEach((res) => {
      if(localStorage.getItem('email') === 'osnagarnigam@rediffmail.com' || localStorage.getItem('email') === 'vishvaraj@heliware.co.in' || localStorage.getItem('organization') === 'ADMIN'){
        this.mapService.getProjects(res.id)
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((res1) => {
          
        // a.push(...res1)
          this.Counts = this.Counts+res1.length
          res1.forEach(async (w:any)=>{
            await this.temp.push(w)
          })
          name.push(res.name)
          data.push(res1.length)
          // console.log(data,name,'name')
          this.callChart(data,name)
          // this.filterTableData(temp)
        })
        

      }else{
        this.mapService.fetchAssignedProjects
        ({userid:localStorage.getItem('id'),siteid:res.id})
        // .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((resp:any)=>{
          console.log(resp,"resp======>")
          // a.push(...resp)
          this.Counts = this.Counts+resp.length
          resp.forEach(async (w:any)=>{
            await this.temp.push(w)
          }) 
          // console.log(temp)
          name.push(res.name)
          data.push(resp.length)
          // console.log(data,name,'name')
          this.callChart(data,name);
          // this.filterTableData(a);
          // this.filterTableData(temp)
        })
      }
      // console.log(res)
    })
    console.log(this.temp)
    // this.assignedProjects = temp
    // console.log(this.assignedProjects)
   
    setTimeout(() => {
      this.getProjectOverviewData(); 
      this.getPlannedLength()
      this.getCompletedLength() 
    }, 3000);
    
  }
  


  test3(res1 ,iconUrl ,cat ){
    let GeoJSONData:any
    res1.forEach((ele:any )=> {
      // console.log("ele",ele);
      let latitude = Number(ele.maps.metaData?.lat);
      let longitude = Number(ele.maps.metaData?.lng);

        GeoJSONData = {
        "type": "FeatureCollection",
        "features": [
          {
            "type": "Feature",
            "properties": {
              title: ele.item,
              data: ele,
              // cat: cat
            },
            "geometry": {
              "coordinates": [
                    longitude,
                    latitude,
              ],
              "type": "Point"
            }
          }
        ]
      };
      // console.log(GeoJSONData)
      this.map.data.addGeoJson(GeoJSONData);
      // const site = this.sites.find((site)=>site.id === res.id)
         
      // this.map.data.forEach(feature => {
      //   console.log(feature)
        
      // })
    //   if (res1.category === "PWD") {
    //      iconUrl = {url:'assets/icon/pwd.png',scaledSize: new google.maps.Size(80, 80)};
    //           } else if (res1.category === "Light") {
    //      iconUrl = {url:'assets/icon/light.png',scaledSize: new google.maps.Size(50, 30)};
    //           } else if (res1.category === "Light/Bridge Corporation"){
    //      iconUrl = {url:'assets/icon/light_bridge.png', scaledSize: new google.maps.Size(80, 80)}
    //           } else if (res1.category === "Jal Kal Vibhag"){
    //      iconUrl = { url:'assets/icon/jal_kal_vibhag.png', scaledSize: new google.maps.Size(50, 50)}
    //           } else if (res1.category === "STP") {
    //      iconUrl = { url:'assets/icon/stp.png', scaledSize: new google.maps.Size(30, 30)}
    //           }
    //            if(iconUrl){
    
    // this.map.data.setStyle(function (feature) {
    //   // console.log(feature)
    //   return {
    //     strokeWeight: 3,
    //     strokeColor: 'green',
    //     icon: iconUrl,
    //   };
    // });
    // } else {
    //   this.map.data.setStyle({
    //     strokeWeight: 3,
    //     strokeColor: 'green',
    //   });
    // }   
      // this.map.panTo(new google.maps.LatLng(latitude,longitudeP));
      // bounds.extend(new google.maps.LatLng(latitude,longitude));
    })
  }
  addGeojsonToMap(url,id){
    // alert("hello")
    if(url.length){

      console.log(url,"url")
      let obj = this
      this.map.data.forEach(function(feature:any) {
        console.log(feature ,"feature")
        if(feature['Fg'].title.includes('urlgeojson') && feature['Fg'].title !== 'urlgeojson'+url[0].measurement ){
          console.log(feature)
          obj.map.data.remove(feature);
        } 
      });
     
  
      this.map.data.loadGeoJson(url[0].url, {},
        function(features) {
          console.log(features,"features")
          obj.map.data.forEach(function(feature:any) {
            console.log(feature,"fearure")
            if(feature.getProperty('title') == undefined){
              feature.setProperty('title','urlgeojson'+url[0].measurement );
            }
          }); 
        })
    }
  }

  fetchSiteData() {
    // if (this.organization === 'ADMIN') {
      this.sitesService.fetchSitesData(this.creatorid).
        pipe(takeUntil(this.unsubscribeSubject)).subscribe((res) => {
           this.sites = res  
        //  console.log(this.sites,'this.sites')
         this.createSiteCountChart()
       })
    // }
  //  else{
  //     this.sitesService.fetchSitesData(this.creatorid).
  //       pipe(takeUntil(this.unsubscribeSubject)).subscribe((res) => {
  //         console.log("res",res)
  //          this.sites = res  
  //        console.log(this.sites,'this.sites')
  //        this.createSiteCountChart()
  //      })
  //   }
  }
//   


  fetchAssignedData(){
      // console.log(localStorage.getItem('id'),'ele')
      this.mapService.getAssignFetchSites({userid:localStorage.getItem('id')}).subscribe((resp:any) => {
           this.sites = resp.data
           this.createSiteCountChart()
           console.log(this.sites,"this.sites")
          })
  }

  getProjectData(e ) {
    this.chart11 = false
    this.chart8 = false
    this.chart5 = false
    this.chart7 = false
    this.chart3 = false
    this.chart4 = false
    this.chart10 = false

    this.animationState10 = 'out'
    this.animationState7 = 'out'
    this.animationState5 = 'out'
    this.animationState6  = 'out'
    this.animationState2 = 'out'
    this.animationState3 = 'out'
    this.animationState9 = 'out'
    
    
    this.siteIcon = true
    console.log("as",this.isNotVisible);


    $('#pwd-light').removeClass('minimized6');
    $('.maxDiv6').removeClass('maximize6');
    $('.minimize6').removeClass('minimizeButton6');

    setTimeout(() => {
      
      $('#chart-drag-summery').addClass('minimized7') ;
      $('.maxDiv7').addClass('maximize7');
      $('.minimize7').addClass('minimizeButton7');
  
    
      $('.minimize3').on('click', function(){minimize3();});
      $('.maxDiv3').on('click', function(){maximize3();});
    
    function minimize3(){
      $('#chart-container-status-summary').addClass('minimized3');
      $('.maxDiv3').addClass('maximize3');
      $('.minimize3').addClass('minimizeButton3');
    }
    
    function maximize3(){
      $('#chart-container-status-summary').removeClass('minimized3');
      $('.maxDiv3').removeClass('maximize3');
      $('.minimize3').removeClass('minimizeButton3');
    }

      $('.minimize4').on('click', function(){minimize4();});
      $('.maxDiv4').on('click', function(){maximize4();});
    
    function minimize4(){
      $('#chart-container-status-summary1').addClass('minimized4');
      $('.maxDiv4').addClass('maximize4');
      $('.minimize4').addClass('minimizeButton4');
    }
    
    function maximize4(){
      $('#chart-container-status-summary1').removeClass('minimized4');
      $('.maxDiv4').removeClass('maximize4');
      $('.minimize4').removeClass('minimizeButton4');
    }

      $('.minimize7').on('click', function(){minimize7();});
      $('.maxDiv7').on('click', function(){maximize7();});
    
    function minimize7(){
      $('#chart-drag-summery').addClass('minimized7');
      $('.maxDiv7').addClass('maximize7');
      $('.minimize7').addClass('minimizeButton7');
    }
    
    function maximize7(){
      $('#chart-drag-summery').removeClass('minimized7');
      $('.maxDiv7').removeClass('maximize7');
      $('.minimize7').removeClass('minimizeButton7');
    }

 
  
    }, 2000);
    console.log(this.showProjectTable,'shownoproject')
    this.completion6=false;
    this.completion7= false;
    this.completion8= false;
    this.completion9= false;
    this.completion10= false;

    this.completion= true
    this.showButton = false;
    this.hideButten = true;
    console.log(this.hideButten)
    this.showProjectTable =true

    this.showNoProject = true;
    this.isVisible = false;
    this.isNotVisible=true;
    this.chartpwd= true;
    this.selectedSite = e.value;

    this.showProjectChart = false;
    this.weeksChart = false;
    this.selectedTableData =false;
    this.tableData =this.overAllTabledata 
  

    
    if (this.selectedSite.name === 'Prayagraj - PWD') {
      this.showBridge=false;
      this.showPWD = true;
      this.showLight = false;
      this.showProject =false;
      this.showBudget =true;
      this.chart=true;
      this.showStyle= true;
      this.tableData = this.tableData.filter((item) => {
        return item.Department === 'PWD';
      });    
    }
     else if (this.selectedSite.name === 'Prayagraj - Light') {
      this.showBridge=false;
      this.showPWD = false;
      this.showLight = true;
      this.showProject =false;
      this.showBudget =true;
      this.chart=true;
      this.showStyle= true;
      this.tableData = this.tableData.filter((item) => {
        return item.Department === 'Light';
      });    
    }
    else if (this.selectedSite.name === 'Prayagraj - Light/Bridge Corporation') {
      this.showBridge=true;
      this.showPWD = false;
      this.showLight = false;
      this.showProject =false;
      this.showBudget =true;
      this.chart=true;
      this.showStyle= true;
      this.tableData = this.tableData.filter((item) => {
        return item.Department === 'Light/Bridge Corporation';
      });
    }
   
    else{
      this.showBridge=false;
      this.showPWD = false;
      this.showLight = false;
      this.showProject =false;
      this.showBudget =false;
      this.chart=true;
    }
    
    this.showCompletionChart = true;
    this.loading = true;
    // this.showNoProject = false;
    this.form.get('selectedProject').setValue('');
    this.form.get('selectedProject').enable();

    // console.log(this.form.controls.selectedSite.value)
    this.siteId = this.form.controls.selectedSite.value;
    // console.log(e.value.name,this.form.controls.selectedProject.value);
    // this.showChart = true
    this.showRunningStatus = true
    this.siteId = this.form.controls.selectedSite.value.id
    // this.panToOnMap(this.form.controls.selectedSite.value, 'site');
    let temp = '';
    if (localStorage.getItem('email') === 'osnagarnigam@rediffmail.com' || localStorage.getItem('email') === 'vishvaraj@heliware.co.in' || localStorage.getItem('organization') === 'ADMIN') {
      this.mapService.getProjects(this.siteId)
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((res) => {
          this.projects = res;
          // this.showProjectCompletion()
          let obj = {}
          res.forEach(element => {
            if (obj.hasOwnProperty(element['category'])){
              obj[element['category']].push(element)
            }else{
              obj[element['category']] = [element]
            }
          })
          this.chartShowStatus(obj)
          this.chartShowStatusPie(obj) 
          this.showProjectCompletion()
          // chartShowStatus(temp)
          this.loading = false
          // this.test().subscribe(() => {
  
          // })
          this.showProjectsOnMap(this.projects);
          // console.log(this.projects,"projects")
          // this.showProjectTable = false;
        }, (err)=>{
          this.loading = false;
  
        }
        );
        // console.log(this.parentData) 
   
    } 
    else {
      this.mapService.fetchAssignedProjects({userid:localStorage.getItem('id'), siteid: this.siteId}).subscribe((res:any) =>{
        this.projects = res;
        console.log(this.projects,"this.projects==>")
        let obj = {}
        res.forEach(element => {

          if (obj.hasOwnProperty(element['category'])){
            obj[element['category']].push(element)
          }else{
            obj[element['category']] = [element]
          }
          // this.mapService.assignedProjects(this.siteId).pipe(takeUntil(this.unsubscribeSubject)).subscribe((resp) =>{
          //   this.projects =resp;
          //   console.log(this.projects,'this.projects')
          // })
        })
        this.chartShowStatus(obj)
        this.chartShowStatusPie(obj) 
        this.loading = false
        this.showProjectCompletion()
        // this.test().subscribe(() => {
          
        // })
        this.showProjectsOnMap(this.projects);
        // console.log(this.projects,'===================>>>>project')
        // this.showProjectTable = false;
      }, (err)=>{
        this.loading = false;
      })
      

    }
  }

  showProjectCompletion(){
   this.showCompletionChart =true;
    this.loading = true;
    this.showRunningStatus = true;
    this.showChart = true
    let temp = [];
    let name = [];
    this.projects.forEach(e => {
      temp.push({ projectId: e.id, siteId: this.siteId });
      name.push(e.item);
    });
    this.mileStoneArray = [];
    this.achivedArray = [];
    this.test().subscribe(() => {
      console.log("Organization value:", this.organization);
        console.log("ADMIN detected, executing tempChart");
        this.tempChart();
    });
    this.loading = false;
  }
 
  childData(value) {
    this.excelNotFound = value.chartData;
    if (value) {
      this.totalProjectDays =
      value.projectStartDate !== "NaT" &&
        value.projectEndDate !== "NaT"
          ? Math.abs(
            (new Date( value.projectEndDate) as any) -
            (new Date(value.projectStartDate ) as any)
          ) / (1000 * 60 * 60 * 24)
          : 0;
    }
    console.log(this.totalProjectDays,'totalProjectDays')
  }

  

  getProjectChart() {
    setTimeout(() => {
      $('#progress_drag_chart').addClass('minimized10');
      $('.maxDiv10').addClass('maximize10');
      $('.minimize10').addClass('minimizeButton10');
      
      $('.minimize8').on('click', function(){minimize8();});
      $('.maxDiv8').on('click', function(){maximize8();});
    
    function minimize8(){
      $('#project-budget-chart').addClass('minimized8');
      $('.maxDiv8').addClass('maximize8');
      $('.minimize8').addClass('minimizeButton8');
    }
    
    function maximize8(){
      $('#project-budget-chart').removeClass('minimized8');
      $('.maxDiv8').removeClass('maximize8');
      $('.minimize8').removeClass('minimizeButton8');
    }

      $('.minimize9').on('click', function(){minimize9();});
      $('.maxDiv9').on('click', function(){maximize9();});
    
    function minimize9(){
      $('#project-detail').addClass('minimized9');
      $('.maxDiv9').addClass('maximize9');
      $('.minimize9').addClass('minimizeButton9');
    }
    
    function maximize9(){
      $('#project-detail').removeClass('minimized9');
      $('.maxDiv9').removeClass('maximize9');
      $('.minimize9').removeClass('minimizeButton9');
    }

      $('.minimize10').on('click', function(){minimize10();});
      $('.maxDiv10').on('click', function(){maximize10();});
    
    function minimize10(){
      $('#progress_drag_chart').addClass('minimized10');
      $('.maxDiv10').addClass('maximize10');
      $('.minimize10').addClass('minimizeButton10');
    }
    
    function maximize10(){
      $('#progress_drag_chart').removeClass('minimized10');
      $('.maxDiv10').removeClass('maximize10');
      $('.minimize10').removeClass('minimizeButton10');
    }

      $('.minimize11').on('click', function(){minimize11();});
      $('.maxDiv11').on('click', function(){maximize11();});
    
    function minimize11(){
      $('#project_timeline_drag').addClass('minimized11');
      $('.maxDiv11').addClass('maximize11');
      $('.minimize11').addClass('minimizeButton11');
    }
    
    function maximize11(){
      $('#project_timeline_drag').removeClass('minimized11');
      $('.maxDiv11').removeClass('maximize11');
      $('.minimize11').removeClass('minimizeButton11');
    }
    }, 2000);

    
    this.pwdCharts=false
    this.showProjectChart =true;
    this.showPWD= false;
    this.showLight=false;
    this.isNotVisible=false;
    this.completion= false;
    this.completion1= true;
    this.completion2= true;
    this.completion3= true;
    this.completion4= false;
    this.completion5= true;
    this.completion6=true;
    this.completion7= true;
    this.completion8= true;
    this.completion9= true;
    this.completion10= true;
    this.chart12=true;
    this.chart13=true;
    this.chart14=true;
    this.chart15=true;
    this.chart16=true;
    
    this.tables= true;
   
    
 console.log(this.form.controls.selectedProject.value,"==========,>>")
 console.log(this.projects,'dsdsad')
    this.selectProject = this.projects.find((res)=> res.item === this.form.controls.selectedProject.value)

    console.log(this.selectProject,'this.selectProject')
    this.hideButten = false;
    this.showButton = true;

    if(this.selectProject){
    this.pwdCharts=false
    this.excelNotFound = false;
    this.categoryChart = false
    this.showProjectTable= false;
    this.showNoProject =false;
    this.showProjectChart =true;
    this.showChart =false;
    this.showRunningStatus = false

    this.panToOnMap(this.selectProject, 'projects');

    const selectedProject = this.form.controls.selectedProject.value.split(',');

    this.selectedTableData = this.tableData.find((res)=> Number(res["Sl. No."]) === Number(selectedProject[0]))

    // this.showProjectsOnMap(this.projects)
    this.projectName = selectedProject[1]
    if(this.organization === "ADMIN"){
      this.inputData = {
        projectId: this.selectProject.id,
        siteId: this.form.controls.selectedSite.value.id,
        selectedTableData: this.selectedTableData
      }
    }else{
      this.inputData = {
        projectId: this.selectProject._id,
        siteId: this.form.controls.selectedSite.value.id,
        selectedTableData: this.selectedTableData
      }
    }
    
    console.log(this.inputData,"this.selectedTableData")
    console.log(this.creatorid)
    this.sitesService.fetchSitesData(this.creatorid)
  .pipe(
    tap((res)=> { 
      console.log(res)
      this.siteId = res[0].id
    }),
    switchMap(()=>{
      return this.mapService.getProjects(this.siteId) 
    }),
  )
  .subscribe((res)=> {
    console.log(this.projects,"projects==>")
    this.userId = this.projects.find((project) => project?.maps?.excelData?.userId === this.creator.email)
    console.log(this.userId)
    let selectedProject = this.projects.find((e)=> e.id == this.inputData['projectId'])
    this.addGeojsonToMap(selectedProject['kmlData'],selectedProject['id']);
  });
    }
    this.ngOnChanges();
  }

  onToggleClose() {
    this.closeSideNav.emit();
  }

  // onDropdownChange() {
  //   const selectedValue = this.myForm.get('selectedOption').value;
  //   console.log('Selected value:', selectedValue);
  //   // Add your logic or method call here based on the selected value
  // }

  ngOnDestroy(): void {
    if (this.myChart) {
      clearInterval(this.interval);
      this.myChart.dispose();
  }
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  ////setting map instance
  mapSetup() {
    this.map = new google.maps.Map(this.gMapElement.nativeElement, {
      center: new google.maps.LatLng(28.4601, 77.02635),
      zoom: 18,
      tilt: 45,
      styles: this.darkModeStyle,
      rotateControl: true,
      rotateControlOptions: { 
        position: google.maps.ControlPosition.LEFT_CENTER,
      },
      fullscreenControl: false,
      scaleControl: true,
      disableDefaultUI: true,
      gestureHandling: 'greedy'
    })
    this.mapService.init(this.map, this.map1, this.mappanel1,this.mappanel2, this.mappanel3, this.mappanel4,this.mappanel5, this.loading);
    this.data = new google.maps.Data({
      featureFactory: (geometry) => {
        const feature = new google.maps.Data.Feature({ geometry, id: uuid.v4() });
        return feature;
      }
    });
      //----------------add new route----  
      let temp=""
      if (localStorage.getItem('email') === 'osnagarnigam@rediffmail.com' || localStorage.getItem('email') === 'vishvaraj@heliware.co.in') {
        temp = 'Pmis';
        this.sitesService.fetchSitesData(this.creatorid).subscribe(res => {
          let sitesData = res;
          // console.log(this.creatorid,sitesData,'sites')
          let GeoJSONData:any;
  
          var bounds = new google.maps.LatLngBounds();
          sitesData.forEach(ele => {
            // console.log(ele)
              GeoJSONData = {
              "type": "FeatureCollection",
              "features": [
                {
                  "type": "Feature",
                  "properties": {
                    title: ele.name,
                  },
                  "geometry": {
                    "coordinates": [
                      ele?.longitude,
                      ele?.latitude,
                          
                    ],
                    "type": "Point"
                  }
                }
              ]
            };
         this.map.data.addGeoJson(GeoJSONData);
         this.map.panTo(new google.maps.LatLng(ele?.latitude,ele?.longitude));
         bounds.extend(new google.maps.LatLng(ele?.latitude,ele?.longitude));
          })
  
          this.map.fitBounds(bounds);
          var infowindow = new google.maps.InfoWindow({
            pixelOffset: new google.maps.Size(0, -40) // offset for icon
          });
      
          let obj = this
          this.map.data.addListener('mouseover', function(evt) {
            // infowindow.setContent(`${evt.feature.Fg.title}`);
            infowindow.setContent(` category: ${evt.feature.Fg.data.category} <br> ${evt.feature.Fg.title}`);
            infowindow.setPosition(evt.feature.getGeometry().get());
            infowindow.open(obj.map);
          });
          this.map.data.setStyle(function(feature) {
            return {
            strokeWeight: 3,
             strokeColor: 'yellow',
           }
         });
     })
      } 
      else {
        this.mapService.getAssignFetchSites({userid:localStorage.getItem('id')}).subscribe((resp:any) => {
          // this.sites = resp.data
          
          let sitesData = resp.data;
          console.log(this.creatorid,sitesData,'sites')
          let GeoJSONData:any;
  
          var bounds = new google.maps.LatLngBounds();
          sitesData.forEach(ele => {
            // console.log(ele)
              GeoJSONData = {
              "type": "FeatureCollection",
              "features": [
                {
                  "type": "Feature",
                  "properties": {
                    title: ele.name,
                  },
                  "geometry": {
                    "coordinates": [
                      ele?.longitude,
                      ele?.latitude,
                          
                    ],
                    "type": "Point"
                  }
                }
              ]
            };
         this.map.data.addGeoJson(GeoJSONData);
         this.map.panTo(new google.maps.LatLng(ele?.latitude,ele?.longitude));
         bounds.extend(new google.maps.LatLng(ele?.latitude,ele?.longitude));
          })
  
          this.map.fitBounds(bounds);
          var infowindow = new google.maps.InfoWindow({
            pixelOffset: new google.maps.Size(0, -40) // offset for icon
          });
      
          let obj = this
          this.map.data.addListener('mouseover', function(evt) {
            infowindow.setContent(`${evt.feature.Fg.title}`);
            infowindow.setPosition(evt.feature.getGeometry().get());
            infowindow.open(obj.map);
          });
          this.map.data.setStyle(function(feature) {
            return {
            strokeWeight: 3,
             strokeColor: 'yellow',
           }
         });
     })


      }
     //----------------end of add new route----
  }
  

   ///////////////////////////////////location///////////////////////////////////
   selectedPlace(i: number) {
    const place_id = this.resultPlace[i].raw.place_id;
    this.placeHint = this.resultPlace[i].label;
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        const response = JSON.parse(xhttp.responseText);
        if (response && response.geometry) {
          // Extract type and coordinates
          const geometry = response.geometry;
          const names = response.names || {}; // Handle case if names is not present

          // Pass type, coordinates, and names
          this.searchResult({
            type: geometry.type,
            coordinates: geometry.coordinates
          }, names);
          
          this.resultPlace = [];
        }
      }
    };
    
    xhttp.open(
      "GET",
      `https://nominatim.openstreetmap.org/details.php?place_id=${place_id}&addressdetails=1&hierarchy=0&group_hierarchy=1&polygon_geojson=1&format=json`,
      true
    );
    xhttp.send();
}

searchResult(geometry: any, names: any) {
  const data = {
      type: geometry.type,
      coordinates: geometry.coordinates,
      names: names.name || {} 
  };
  this.mapService.searchOnMapData.next(data);
}

  
  placesearch(){
    if (this.placeHint.length > 2) {
      setTimeout(() => {
        this.placeSearch.routeStart(this.placeHint).then((data) => {
          this.resultPlace = data;
        });
      }, 1500);
    }
  }

  navigateToHome() {
    this.router.navigateByUrl('/', {skipLocationChange:true}).then(()=>{
      this.router.navigate(['/new-dashboard']);
    })
   
  }
  onLogout(){
    this.mapService.logOut({email:localStorage.getItem('id')}).subscribe(res => {
      console.log(res)
    })
    this.auth.saveToken(null)
    this.router.navigate(['/'])
  }
  
  panToOnMap(data, type){
    // console.log("data",data);
    if(type == 'site'){
      // console.log("lat,long",data?.latitude, data?.longitude);
      this.map.panTo(new google.maps.LatLng(data?.latitude,data?.longitude));
      // this.map.setZoom(15)
    }
    else if(type == 'projects'){
      let latitude = Number(data?.maps.metaData?.lat);
      let longitude = Number(data?.maps.metaData?.lng);
      // console.log("lat,long",latitude, longitude);
      
      this.map.panTo(new google.maps.LatLng( latitude,longitude));
      this.map.setZoom(16.7)
    }
  }

  dateDiffInDays(currentDate: Date, updatedAtDate: Date): number {
    const diffInMilliseconds = currentDate.getTime() - updatedAtDate.getTime();
    return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  }

  showProjectsOnMap(data){
    // console.log("data",data);
    
    let sitesData = data;
    let GeoJSONData:any;
    this.filterSiteData =data;

    let this2 = this;
    this.map.data.forEach(function(feature) {
      // console.log(this.map.data,'this.map.data')
      // filter...
      
      this2.map.data.remove(feature);
  });




    // this.mapFeatures.forEach(ele => {
    //   console.log("ele",ele);
    //   // Setup event handler to remove GeoJSON features
    //     for (var i = 0; i < ele.length; i++){
    //      this.map.data.remove(ele[i]); 
    //     }
    // });
        // this.map.data.setMap(null);

    var bounds = new google.maps.LatLngBounds(); //for extend/fit slected projects on map default
    console.log(sitesData,'sitesdata')
    sitesData.forEach(ele => {
      let latitude = Number(ele.maps.metaData?.lat);
      let longitude = Number(ele.maps.metaData?.lng);

      
      const currentDate = new Date();
      const updatedAtDate = new Date(ele.updatedAt);

      const diffInDays = this.dateDiffInDays(currentDate, updatedAtDate);
      let t 
      if(diffInDays == 0){
        t = `${ele.item}<br>last-updated: Just Today`
      }else{
        t = `${ele.item}<br>last-updated: ${diffInDays} days ago`
      }
        GeoJSONData = {
        "type": "FeatureCollection",
        "features": [
          {
            "type": "Feature",
            "properties": {
              title: t,
              data: ele
            },
            "geometry": {
              "coordinates": [
                    longitude,
                    latitude,
              ],
              "type": "Point"
            }
          }
        ]
      };
      // console.log(GeoJSONData)
   this.map.data.addGeoJson(GeoJSONData);

   this.map.panTo(new google.maps.LatLng(latitude,longitude));
   bounds.extend(new google.maps.LatLng(latitude,longitude));
    })

    this.map.fitBounds(bounds);
    var infowindow = new google.maps.InfoWindow({
      pixelOffset: new google.maps.Size(0, -40) // offset for icon
    });

    let obj = this
    // this.map.data.addListener('mouseover', function(evt) {
    //   // console.log(evt.feature.h)
    //   infowindow.setContent(`${evt.feature.h.title}`);
    //   infowindow.setPosition(evt.feature.getGeometry().get());
    //   infowindow.open(obj.map);
    // });

    // this.map.data.addListener('click', function(evt) {
    //   // console.log(evt.feature.h.data)
    //   obj.form.controls.selectedProject.value = evt.feature.h.data
    //   obj.getProjectChart()
    // });

    // clickIcon 

    // this.map.data.addListener('click', (evt: any) => {
    //   console.log(evt.feature,"===>")
    //   if (evt.feature.Fg.data.category === "Light") {
    //     const selectedSiteValue = this.sites.find(site => site.name === 'Prayagraj - Light');
    //     if (selectedSiteValue) {
    //       this.form.get('selectedSite').setValue(selectedSiteValue);
    //       console.log("selectedSite value:", selectedSiteValue);
    //       this.getProjectData({ value: selectedSiteValue });
    //     }
    //     this.map.data.addListener('click', (evt) => {
    //       console.log("evt",evt)
    //       const  SelectProject = obj.form.controls.selectedProject.value = evt.feature.Fg.data.item;
    //       if(SelectProject){
    //         this.form.get('selectedProject').setValue(SelectProject);
    //         obj.getProjectChart()
    //       } 
          
    //     });      
    //   } 
    //   else if(evt.feature.Fg.data.category === "PWD"){
    //     const selectedSiteValue = this.sites.find(site => site.name === 'Prayagraj - PWD');
    //     if (selectedSiteValue) {
    //       this.form.get('selectedSite').setValue(selectedSiteValue);
    //       console.log("selectedSite value:", selectedSiteValue);
    //       this.getProjectData({ value: selectedSiteValue });
    //     }
    //     this.map.data.addListener('click', (evt) => {
    //       console.log("evt",evt)
    //       const  SelectProject = obj.form.controls.selectedProject.value = evt.feature.Fg.data.item;
    //       if(SelectProject){
    //         this.form.get('selectedProject').setValue(SelectProject);
    //         obj.getProjectChart()
    //       }   
    //     });
    //   }
    // });

    this.map.data.addListener('click', (evt: any) => {
      console.log(this.sites,"this.sites")
      const { category, item } = evt.feature.Fg.data;
      console.log(evt.feature.Fg.data,"evt.feature.Fg.data")

      const selectedSiteValue = this.sites.find(site => site.category.includes(category)); 
      console.log(selectedSiteValue,"selectedSiteValue") 
      if (selectedSiteValue) {
        this.form.get('selectedSite').setValue(selectedSiteValue);
        this.getProjectData({ value: selectedSiteValue });
      }
      if (item) {
        this.form.get('selectedProject').setValue(item);
        this.getProjectChart();
      }
    });
  
    this.map.data.addListener( "dblclick", function (evt) { 
      obj.router.navigate(['/gis/'+evt.feature.Fg.data.id],{ state: { isTrue: true } })
    });
    // if(iconUrl){
    
    this.map.data.setStyle((feature:any) => {
    
      const site = this.sites.find((site)=>site.id === this.siteId)
      let iconUrl : {
        url:string,
        scaledSize:any
      };
      if (site?.category === "PWD" || feature.Fg.data.category === "PWD") {
        iconUrl = {url:'assets/icon/pwd.png',scaledSize: new google.maps.Size(80, 80)};
      }
       else if (site?.category === "Light" || feature.Fg.data.category === "Light") {
        iconUrl = {url:'assets/icon/light.png',scaledSize: new google.maps.Size(50, 30)};
      }
       else if (site?.category === "Light/Bridge Corporation" || feature.Fg.data.category === "Light/Bridge Corporation" ){
        iconUrl = {url:'assets/icon/light_bridge.png', scaledSize: new google.maps.Size(80, 80)}
      }
       else if (site?.category === "Jal Kal Vibhag" ||  feature.Fg.data.category === "Jal Kal Vibhag" ){
        iconUrl = { url:'assets/icon/jal_kal_vibhag.png', scaledSize: new google.maps.Size(50, 50)}
      }
       else if (site?.category === "STP" ||  feature.Fg.data.category === "STP") {
        iconUrl = { url:'assets/icon/stp.png', scaledSize: new google.maps.Size(30, 30)}
      }
      return {
        strokeWeight: 3,
        strokeColor: 'green',
        icon: iconUrl,
      };
    });


    // this.map.data.setStyle((feature: any) => {
    //   console.log(feature, "feature====>");
    //   const site = this.sites.find((site) => site.id === this.siteId);

    //   let iconUrl: {
    //     url: string,
    //     scaledSize: any
    //   };
    
    //   if (site?.category === "PWD" || feature.Fg.data.category === "PWD") {
    //     iconUrl = { url: 'assets/icon/pwd.png', scaledSize: new google.maps.Size(80, 80) };
    //   } else if (site?.category === "Light" || feature.Fg.data.category === "Light") {
    //     iconUrl = { url: 'assets/icon/light.png', scaledSize: new google.maps.Size(50, 30) };
    //   } else if (site?.category === "Light/Bridge Corporation" || feature.Fg.data.category === "Light/Bridge Corporation") {
    //     iconUrl = { url: 'assets/icon/light_bridge.png', scaledSize: new google.maps.Size(80, 80) };
    //   } else if (site?.category === "Jal Kal Vibhag" || feature.Fg.data.category === "Jal Kal Vibhag") {
    //     iconUrl = { url: 'assets/icon/jal_kal_vibhag.png', scaledSize: new google.maps.Size(50, 50) };
    //   } else if (site?.category === "STP" || feature.Fg.data.category === "STP") {
    //     iconUrl = { url: 'assets/icon/stp.png', scaledSize: new google.maps.Size(30, 30) };
    //   }
    //   return {
    //     strokeWeight: 3,
    //     strokeColor: 'green',
    //     icon: iconUrl,
    //   };
    // });

// icon 
    // this.map.data.setStyle((feature: any) => {
    //   console.log(feature.Fg.data.categoryIcon,"categoryIcon")
    //   console.log(this.sites,"feature====>")
    //   const site = this.sites.find((site) => site.id === this.siteId);
    //   let iconUrl: {
    //     url: string,
    //     scaledSize: any
    //   };   
    //   if (site?.category === "PWD" || feature.Fg.data.category === "PWD") {
    //     iconUrl = { url: 'assets/icon/pwd.png', scaledSize: new google.maps.Size(80, 80) };
    //      if(feature.Fg.data.category === "Repairing of Road"){
    //       iconUrl = { url:feature.Fg.data.categoryIcon, scaledSize: new google.maps.Size(40, 40) };
    //      }
    //     else if(feature.Fg.data.category === "Construction"){
    //       iconUrl = { url:feature.Fg.data.categoryIcon, scaledSize: new google.maps.Size(40, 40) };
    //      }
    //     else if(feature.Fg.data.category === "Widening_Strengthening"){
    //       iconUrl = { url:feature.Fg.data.categoryIcon, scaledSize: new google.maps.Size(40, 40) };
    //     }
    //   }
    //   else if (site?.category === "Light" || feature.Fg.data.category === "Light") {
    //     iconUrl = { url: 'assets/icon/light.png', scaledSize: new google.maps.Size(50, 30) };
    //   } else if (site?.category === "Light/Bridge Corporation" || feature.Fg.data.category === "Light/Bridge Corporation") {
    //     iconUrl = { url: 'assets/icon/light_bridge.png', scaledSize: new google.maps.Size(80, 80) };
    //   } else if (site?.category === "Jal Kal Vibhag" || feature.Fg.data.category === "Jal Kal Vibhag") {
    //     iconUrl = { url: 'assets/icon/jal_kal_vibhag.png', scaledSize: new google.maps.Size(50, 50) };
    //   } else if (site?.category === "STP" || feature.Fg.data.category === "STP") {
    //     iconUrl = { url:this.sites[0].iconURL, scaledSize: new google.maps.Size(30, 30) };
    //   }
    //   else if (site?.category === "sgr" || feature.Fg.data.category === "sgr") {
    //     iconUrl = { url:this.sites[1].iconURL, scaledSize: new google.maps.Size(30, 30) };
    //     if(feature.Fg.data.category === "abc"){
    //       iconUrl = { url:feature.Fg.data.categoryIcon, scaledSize: new google.maps.Size(40, 40) };
    //     }
    //     if(feature.Fg.data.category === "xyz"){
    //       iconUrl = { url:feature.Fg.data.categoryIcon, scaledSize: new google.maps.Size(40, 40) };
    //     }
    //   }
      
    //   if(feature.Fg.data != undefined){
    //     // const tooltip = feature.Fg.title; 
    //   }
    //   return {
    //     strokeWeight: 3,
    //     strokeColor: 'yellow',
    //     icon: iconUrl,
    //     // title: feature.Fg.title 
    //   };
    // });


    
    
    // end icon 
    // } else {
    //   this.map.data.setStyle({
    //     strokeWeight: 3,
    //     strokeColor: 'green',
    //   });
    // }    

    this.map.data.setStyle((feature: any) => {
      console.log(this.sites, "feature====>");
      console.log(feature.Fg.data.categoryIcon, "categoryIcon");
      
      const site = this.sites.find((site) => site.id === this.siteId);
      let iconUrl: { url: string, scaledSize: any };
      const dynamicCategory = site?.category || feature.Fg.data.category;
      const matchingSite = this.sites.find(s => s.category === dynamicCategory);
      console.log(matchingSite,"matchingSite")
      if (matchingSite) {
        iconUrl = { url: matchingSite.iconURL, scaledSize: new google.maps.Size(30, 30) };
      }
      if(this.siteIcon){
        if (feature.Fg.data.categoryIcon) {
          iconUrl = { 
            url: feature.Fg.data.categoryIcon, 
            scaledSize: new google.maps.Size(40, 40) 
          };
        } 
      }
       
      return {
        strokeWeight: 3,
        strokeColor: 'yellow',
        icon: iconUrl,
        // title: feature.Fg.title
      };
    });
  }
 toggleProjectTable(divName: string): void {
    if (divName === 'divA') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
    }
  }
  
 toggleTable(divName: string): void {
  if (divName === 'divAA') {
    this.animationStates = this.animationStates === 'out' ? 'in' : 'out';
  }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'divA1') { 
      this.chart1 = !this.chart1;
      this.animationStat = this.animationStat === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv2(divName: string) {
    if (divName === 'divA2') { 
      this.chart2= !this.chart2;
      this.animationState1 = this.animationState1 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv3(divName: string) {
    if (divName === 'divA3') { 
      this.chart3= !this.chart3;
      this.animationState2 = this.animationState2 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv4(divName: string) {
    if (divName === 'divA4') { 
      this.chart4= !this.chart4;
      console.log(this.animationState3);
      this.animationState3= this.animationState3 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv5(divName: string) {
    if (divName === 'divA5') { 
      this.chart5=  !this.chart5;
      this.animationState4= this.animationState4 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv6(divName: string) {
    if (divName === 'divA6') { 
      this.chart6= !this.chart6;
      this.animationState5= this.animationState5 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv7(divName: string) {
    if (divName === 'divA7') { 
      this.chart7= !this.chart7;
      this.animationState6= this.animationState6 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv8(divName: string) {
    if (divName === 'divA8') { 
      this.chart8= !this.chart8;
      this.animationState7= this.animationState7 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv9(divName: string) {
    if (divName === 'divA9') { 
      this.chart9= !this.chart9;
      this.animationState8= this.animationState8 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv10(divName: string) {
    if (divName === 'divs1') { 
      this.chart10= !this.chart10;
      this.animationState9= this.animationState9 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv11(divName: string) {
    if (divName === 'divs2') { 
      this.chart11= !this.chart11;
      this.animationState10= this.animationState10 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv12(divName: string) {
    if (divName === 'divs3') { 
      this.chart12= !this.chart12;
      this.animationState11= this.animationState11 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv13(divName: string) {
    if (divName === 'divs4') { 
      this.chart13= !this.chart13;
      this.animationState12= this.animationState12 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv14(divName: string) {
    if (divName === 'divs5') { 
      this.chart14= !this.chart14;
      this.animationState13= this.animationState13 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv15(divName: string) {
    if (divName === 'divs6') { 
      this.chart15= !this.chart15;
      this.animationState14= this.animationState14 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv16(divName: string) {
    if (divName === 'divs7') { 
      this.chart16= !this.chart16;
      this.selectedMilestone = this.milestones[0];
      this.outerChart = true;
      this.getSelectedData(this.selectedMilestone)
      // console.log(this.selectedMilestone,'slectsss')
      this.animationState15= this.animationState15 === 'out' ? 'in' : 'out';
    }
  }
  test(){
    const observables: Observable<any> = this.projects.map((project) => {
    return this.mapService.getXLSXData(project.maps?.excelData?.excelName);  
  });
  

  return forkJoin(observables).pipe(
    switchMap((responses: any) => {
      this.projectsPercentageArray = [];
      // console.log(responses , 'sadsad')
      const percentage = [];
      this.userId = this.projects.find((project) => project?.maps?.excelData?.userId === this.creator.email)
      this.projectAchivedResult = [];
      this.projectPlannedResult = [];
      // console.log(this.userId , 'userId')
      
      // if (this.userId) {
        this.onTimeProject = []
        const order = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const currentDate = new Date();
        const currentMonth = currentDate.toLocaleString('en-US', { month: 'short' });
        const currentYear = currentDate.getFullYear();

        this.targetData = [];

        const header = responses.find((resp)=> resp?.features?.length)
        // console.log(header) 
        for (const key in header.features[0]?.properties) {

          const [month, year] = key.split("'").map(item => item.trim());

          if (year !== undefined) {
            const numericalPart = year.match(/\d+/g).map(Number);
            if (numericalPart[1] === undefined) {
              numericalPart[1] = 0
            }
          }
          if (
            (parseInt(year, 10) + 2000 <= currentYear)
          ) {

            if (parseInt(year, 10) + 2000 == currentYear) {
              const currentWeekIndex = Math.ceil(currentDate.getDate() / 7);
              const findCurrentMonthIndex = order.findIndex((res) => res == currentMonth)
              const findMonthIndex = order.findIndex((res) => res == month)
              let numericalPart
              if (year !== undefined) {
                numericalPart = year.match(/\d+/g).map(Number);
                if (numericalPart[1] === undefined) {
                  numericalPart[1] = 0
                }
              }
              this.targetData.push(key)

              if (findCurrentMonthIndex === findMonthIndex && numericalPart[1] + 1 === currentWeekIndex) {
                break;
              }
            } else {
              this.targetData.push(key)
            }

          }
        }
        responses.forEach((res, index) => {
          let totalPlannedResult = 0;
          let totalAchivedResult = 0;
          let milestoneOnTime = 0;
          let achivedOnTime = 0;
          let projectAchived = [];
          let projectPlanned = [];
          let tempObj = []
          let tempObj1 = []
          // console.log(res.features,"featuress");

          let projectPlannedTotal = 0;
          let projectAchivedTotal = 0;
          
          if(res.features == undefined){
            // this.snackbar.open('Excel not uploaded', 'Close', {
            //   duration: 3000,
            //   panelClass: ['green-snackbar', 'custom-snackbar']
            // });
            return
          }else{

          
          // if (res.features && Array.isArray(res.features)) {
          if(res?.features?.length){
            const percentageOfWork = 100 / (res.features.length / 2);
            this.projectsTargetData = [];
            for (const key in res.features[0].properties) {
              const [weekPart, _, month1, yearPart] = key.split(/['.\s]/);
              if (yearPart) {
                const yearly = yearPart.length === 4 ? yearPart : `20${yearPart}`;
                const currentYear = new Date().getFullYear();
                const currentMonth1 = new Date().getMonth() + 1; // Months are zero-based, so add 1

                if (Number(yearly) > currentYear || (Number(yearly) === currentYear && this.monthGreaterThanCurrentMonth(month1, currentMonth1))) {
                  break;
                }
              }
              const parts = key.split("'");
              const monthYearPart = parts[0].split(' ');
              const month = monthYearPart[monthYearPart.length - 1];
              const year = parts[1];


              if (year !== undefined) {
                const numericalPart = year.match(/\d+/g).map(Number);
                if (numericalPart[1] === undefined) {
                  numericalPart[1] = 0
                }
              }
              if (
                (parseInt(year, 10) + 2000 <= currentYear)
              ) {
                if (parseInt(year, 10) + 2000 == currentYear) {
                  const currentWeekIndex = Math.ceil(currentDate.getDate() / 7);
                  const findCurrentMonthIndex = order.findIndex((res) => res == currentMonth)
                  const findMonthIndex = order.findIndex((res) => res == month)
                  const numericPart = monthYearPart[0].match(/\d+/);
                  const result = numericPart ? Number(numericPart[0]) : null;
                  this.projectsTargetData.push(key)

                  if (findCurrentMonthIndex === findMonthIndex && (result === currentWeekIndex || (result === 4 && currentWeekIndex === 5))) {
                    break;
                  }
                } else {
                  this.projectsTargetData.push(key)
                }

              }
            }
            let perWork = res.features.length/2;
            res.features.forEach((featureRes) => {
              let total = 0;
              let totalCount = 0;
              let tillCurrentCount = 0;

            let count = 0
            let count1 = 0
            if(featureRes.properties['Description'] !== 'nan'){
              for (const key1 in featureRes.properties) {
                if(featureRes.properties[key1] !== 'nan'){
                  count = count + 1
                }
              }
              tempObj.push({name:featureRes.properties['Description'],planned:count })
            }else {
              // console.log(featureRes.properties)
              for (const key1 in featureRes.properties) {
                let findProperty = this.targetData.find((res) => res === key1)
                // console.log(findProperty,'dsadsad')
                if(findProperty && featureRes.properties[findProperty] !== 'nan'){
                  // console.log(featureRes.properties[findProperty])
                  count1 = count1 + 1
                }
              }
              tempObj1.push({achieved:count1 })
            }
            var array3 = tempObj.map((obj, index) => ({
              ...obj,
              ...tempObj1[index]
            }));
          // var array3Alternative = tempObj.map((obj, index) => Object.assign({}, obj, tempObj1[index]));
          
          // console.log(array3);

          //   console.log(tempObj,tempObj1)
            // return;


              if (featureRes?.properties['Description'] !== 'nan') {
                for (const property in featureRes.properties) {
                  const [month, year] = property.split("'").map(item => item.trim());

                  if (year &&  featureRes.properties[property] !== "nan") {
                    totalCount = totalCount + 1;
                    const findTillProperty = this.projectsTargetData.find((res) => res === property)

                    if (findTillProperty) {
                      tillCurrentCount = tillCurrentCount + 1
                    }
                  }
                }
                const tillDatePercentage = (tillCurrentCount/ totalCount) * 100;
                const perWorkPercentage = 100/perWork;
                projectPlannedTotal = projectPlannedTotal + Number(((tillDatePercentage*perWorkPercentage)/100))
              this.totalCount = totalCount;
            } 
            else {
                // console.log("featureRes?.properties['Description'] ====================>>>",featureRes?.properties['Description'])
                // for (const property in featureRes.properties) {
                //   const [month, year] = property.split("'").map(item => item.trim());

                //   if (year &&  featureRes.properties[property] !== "nan") {
                //     // totalCount = totalCount + 1;
                //     const findTillProperty = this.projectsTargetData.find((res) => res === property)

                //     if (findTillProperty) {
                //       tillCurrentCount = tillCurrentCount + 1
                //     }
                //   }
                // }

                // const tillDatePercentage = (tillCurrentCount/ this.totalCount) * 100;
                // const perWorkPercentage = 100/perWork;
                // projectAchivedTotal = projectAchivedTotal + Number(((tillDatePercentage*perWorkPercentage)/100).toFixed(2))
              }
          })
          
          if(this.projects[index]?.status === "Running"){
          this.projectsPercentageArray.push({name:this.projects[index].item, plannedValue:projectPlannedTotal.toFixed(2), id:this.projects[index].id})
          }
        
      }
        }
        // console.log(this.mileStoneTotalResult ,'sddsad', this.achivedTotalResult)
          const milestoneLastMonth = this.mileStoneTotalResult - milestoneOnTime;
          const achivedLastMonth = this.achivedTotalResult - achivedOnTime;
          // console.log(milestoneLastMonth , achivedLastMonth )
          if(milestoneLastMonth === achivedLastMonth){
            this.onTimeProject.push({color:"#008000"});
          } else {
            this.onTimeProject.push({color:"#e74c3c"});
          }
          if(totalPlannedResult){
            this.projectPlannedResult.push(totalPlannedResult);  
            this.projectAchivedResult.push(totalAchivedResult);  
          }
          if(projectAchived?.length){
            this.achivedData.push(projectAchived);
          } 
          if(projectPlanned?.length){
            this.plannedData.push(projectPlanned);
          }
      })
      // }
    //    else {
    //     this.resultHierarchy = []
    //     this.onTimeProject = []
    //   responses.forEach((resp) => {
    //     if(resp?.features){
    //       this.resultHierarchy.push(this.buildHierarchy(resp))
    //     }
    //   })
    //   this.resultHierarchy.forEach((resp) => {
    //     if(resp?.length){
    //     this.achivedTotalResult = 0;
    //     this.mileStoneTotalResult = 0;
    //     let milestoneOnTime = 0;
    //     let achivedOnTime = 0;
    //     const result = resp.find((res) => res.part === 'nan')
    //     const keys = [];
    //     for (let key in result.data.properties) {
    //       if (key !== 'id' && result.data.properties[key] !== 'nan' && new Date(result.data.properties[key]) < new Date()) {
    //         keys.push(key)
    //       }

    //     }
    //     resp.forEach((res) => {
    //       let milestoneTotal = 0;
    //       let achivedTotal = 0;

    //       if (res.children?.length) {
    //         res.children?.forEach((outerRes) => {

    //           if (outerRes.children?.length) {
    //             let outerResultCompleted = 0;
    //             outerRes.children.forEach((innerResp) => {
    //               let totalCompleted = 0;
    //               let milestonesCount = 0;
    //               let achivedCount = 0;
    //               for (let data of keys) {
    //                 if (milestonesCount !== 3) {
    //                   const foundPair = Object.entries(innerResp.data.properties).find(([key, value]) => key === data);
    //                   if (foundPair && foundPair[1] !== 'nan') {
    //                     totalCompleted += Number(foundPair[1])
    //                   }
    //                   milestonesCount++;
    //                 } else {
    //                   achivedCount++;
    //                   if (achivedCount === 3) {
    //                     achivedCount = 0;
    //                     milestonesCount = 0;
    //                   }
    //                 }
    //               }
    //               outerResultCompleted += ((((totalCompleted * innerResp.data.properties['Level 2']) * 100) / 100) * 100)
    //             })
    //             milestoneTotal += outerResultCompleted * outerRes.data.properties['Level 1']
    //           }
    //           if (outerRes.children?.length) {
    //             let outerResultCompleted = 0;
    //             outerRes.children.forEach((innerResp) => {
    //               let totalCompleted = 0;
    //               let milestonesCount = 0;
    //               let achivedCount = 0;
    //               for (let data of keys) {
    //                 if (milestonesCount !== 3) {
    //                   milestonesCount++;
    //                 } else {
    //                   const foundPair = Object.entries(innerResp.data.properties).find(([key, value]) => key === data);
    //                   if (foundPair && foundPair[1] !== 'nan' && foundPair[1] !== 'NaT') {
    //                     totalCompleted += Number(foundPair[1])
    //                   }
    //                   achivedCount++;
    //                   if (achivedCount === 3) {
    //                     achivedCount = 0;
    //                     milestonesCount = 0;
    //                   }
    //                 }
    //               }
    //               outerResultCompleted += ((((totalCompleted * innerResp.data.properties['Level 2']) * 100) / 100) * 100)
    //             })
    //             achivedTotal += outerResultCompleted * outerRes.data.properties['Level 1']
    //           }
    //         })
    //         this.achivedTotalResult += achivedTotal;
    //         this.mileStoneTotalResult += milestoneTotal;
    //       }
    //       achivedOnTime = achivedTotal;
    //       milestoneOnTime = milestoneTotal;
    //     })
    //       const milestoneLastMonth = this.mileStoneTotalResult - milestoneOnTime;
    //       const achivedLastMonth = this.achivedTotalResult - achivedOnTime;
    //       if(milestoneLastMonth === achivedLastMonth){
    //         this.onTimeProject.push({color:"#008000"});
    //       } else {
    //         this.onTimeProject.push({color:"#e74c3c"});
    //       }

    //     this.mileStoneArray.push(this.mileStoneTotalResult);
    //     this.achivedArray.push(this.achivedTotalResult);
    //   }
    //   })
    // }
      this.dropdownProjects = this.projectsPercentageArray.filter((resp) => parseFloat(resp.plannedValue) !== 0);
      this.projects.forEach((project, index) => {
        let projectTotalAchived = 0;
          const perWorkPercentage =  100 / (responses[index].features?.length/2)
          console.log(perWorkPercentage,'dsadsadsa')
          this.kmlData = project?.tempKmlData
          project?.kmlData.forEach(element => {
            if (element.name.includes('Planned')) {
              this.plannedRoad = element.measurement
            }
          });
          const data = this.dropdownProjects.some((res) => res.name === project.item)
          // console.log(this.projects,"data=====>data")
          if (data) {
            const filteredKMLData = this.getLastEntries(this.kmlData);
            filteredKMLData?.forEach((element, index) => {
              if (element.name.includes('Achieved')) {
                const value = this.plannedRoad != 0 ? ((element.measurement / this.plannedRoad)*perWorkPercentage).toFixed(2) : 0
                projectTotalAchived = projectTotalAchived + Number(value);
              }
            })
          }
        this.projectsPercentageArray = this.projectsPercentageArray.map((res) => {
          if (res.id === project.id) {
              return {
                  ...res,
                  achivedValue: projectTotalAchived
              };
          }
          return res;
      });
      
      

      })
      return of(percentage);
    
    })
  );
}


getLastEntries(data: any[]): any[] {
  const uniqueEntries = new Map<string, any>();

  data.forEach(item => {
    // Normalize the name by trimming spaces, removing line breaks, and converting to lower case
    const baseName = item.name.split('_').slice(0, 2).join('_').trim().replace(/\s+/g, ' ').toLowerCase();
    uniqueEntries.set(baseName, item);
  });

  return Array.from(uniqueEntries.values());
}

monthGreaterThanCurrentMonth(month, currentMonth) {
  const monthsInOrder = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const providedMonthIndex = monthsInOrder.indexOf(month);
  
  return providedMonthIndex > currentMonth - 1;
}

 openCommentForm() {
  // Add logic here to open the form when the comment icon is clicked
  console.log('Comment icon clicked! Open the form.');
  // You can implement your form opening logic here, for example, showing a modal or another component.
}

filterUsers(value: string): string[] {
  if (typeof value !== 'string') {
    return [];
  }
  
  const filterValue = value.toLowerCase();
  
  if (filterValue.includes('@')) {
    const searchTerm = filterValue.substring(filterValue.lastIndexOf('@') + 1);
    const data = this.users.filter(user => 
    user.username && typeof user.username === 'string' && user.username.toLowerCase().includes(searchTerm)
    );
    return data
  } else {
    return [];
  }
  }

  callProjectCompletionDays(name , data, projects){
    console.log("=====================>>>",data);
    console.log("=========================??pro",projects);
    let chartData = [];

    var chartDom = document.getElementById('chart-container-summary');
    var myChart = echarts.init(chartDom);
    let obj = this

    let option = {
      xAxis: {
        type: 'category',
        name:'Projects',
        data: name
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      yAxis: {
        type: 'value',
        name: 'Percentage (%)'
      },
      series: [
        {
          data: data,
          type: 'bar'
        }
      ]
    };


    myChart.setOption(option);
    // console.log(option, 'option');
    myChart.on('click', 'series', (params) => {
    // console.log(params.name, "paramsssssss");

      const clickedProject = this.projects.find(element => element.item === params.name);
      // console.log(clickedProject,"click");
      this.selectProject= clickedProject
      this.getProjectChart()
      if (clickedProject) {
        console.log("Project ID:", clickedProject.id);
      } else {
        console.log("Project not found.");
      }
    });
  }

  getUserComments() {
    // this.mapService.getComments().subscribe((resp: any) => {
    //   this.userComments = resp.data
    // })
  }

  selectUserFn(user): void {
    this.selectedUser = user;
    this.commentForm.get('message').setValue(`@${user.username}`+' ');
  }

  openDialog(templateRef, comment?: any) {
    let temp = '';
    if (localStorage.getItem('email') === 'osnagarnigam@rediffmail.com') {
      temp = 'Pmis';
    } else if (localStorage.getItem('email') === 'vishvaraj@heliware.co.in') {
      temp = 'Vishvaraj';
    }
    else {
      temp = " "
    }
    this.mapService.getMcgUsers({type:temp}).subscribe(res => this.users = res)
    if (this.editComment) {
      this.commentForm.get('name').disable();
      this.commentForm.get('name').setValue(comment.name);
      this.commentForm.get('message').setValue(comment.message);
    }
    const dialogRef = this.dialog.open(templateRef, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.editComment) {
          const updateComment = {
            name: result.value.name,
            id: comment.id,
            message: result.value.message,
          }
          this.editComment = false
          this.mapService.updateComments(updateComment).subscribe((resp: any) => {
            let upadteUserComment = this.userComments.find((res) => res.id === resp.comment._id);
            upadteUserComment.message = resp.comment.message
            this.commentForm.reset();
          })
        } else if (this.reply) {
          const updateComment = {
            name: result.value.name,
            id: comment.id,
            message: result.value.message,
          }
          console.log("comment =================>>>", comment);
          console.log(result.value)
          this.reply = false
          this.mapService.updateComments(updateComment).subscribe((resp: any) => {
            let upadteUserComment = this.userComments.find((res) => res.id === resp.comment._id);
            console.log(upadteUserComment)
            console.log(resp)
            upadteUserComment.reply = resp.comment.reply

          })
        } else {
          const data = result;
          if (this.selectedUser && this.selectedUser.id) {
            data.value.to = this.selectedUser.id;
          }

          const findProject = this.dropdownProjects.find((resp)=> resp.id === data.value.projectId)
          
          if(findProject) {
            data.value.projectName = findProject.name
          }

          if (this.creator && this.creator.id) {
            data.value.from = this.creator.id;
          }
          this.mapService.addComments(data.value).subscribe((res) => { })
        }
      } else {
        this.reply = false;
        this.editComment = false;
      }
      this.commentForm.get('name').enable();
      this.commentForm.reset();
      // this.getUserComments();
    });
  }

  replyComment(template, comment) {
    this.reply = true
    this.openDialog(template, comment)
  }

  startComment(templateRef) {
    this.getUserComments();
    this.dialog.open(templateRef, {
      width: '500px',
      height: '400px'
    }).afterClosed()
    console.log("start")
  }

  editCommentFn(template, comment) {
    console.log(comment)
    this.editComment = true;
    console.log("edit ==========>>>>", this.editComment);
    this.openDialog(template, comment)
  }
  deleteComment(comment){
    this.mapService.deleteComment(comment.id).subscribe((res)=>{
      this.getUserComments();
    })

  }
    onSubmit(data){
    console.log(data.value)
    this.creator.email
    this.mapService.addComments({name:data.value,email:this.creator.email}) 
}

buildHierarchy(data): HierarchyNode[] {
  const hierarchy: HierarchyNode[] = [];

  data.features?.forEach(item => {
    console.log(item.properties)
    const parts = item.properties["SI. No."]?.split('.');
    let currentLevel: HierarchyNode[] = hierarchy;

    parts.forEach((part, index) => {
      const existingNode = currentLevel.find(node => node.part === part);

      if (existingNode) {
        // Node already exists, move to the next level
        currentLevel = existingNode.children;
      } else {
        // Create a new node
        const newNode: HierarchyNode = { part, children: [] };

        // If it's the last part, store the additional properties
        if (index === parts.length - 1) {
          newNode.data = { ...item };
        }

        // Add the new node to the current level
        currentLevel.push(newNode);

        // Move to the next level
        currentLevel = newNode.children;
      }
    });
  });

  return hierarchy;
}
  

  // callProjectCompletionDays(){
  //     // console.log(this.achivedTotalResult)
  //   var chartDom = document.getElementById('chart-container-summary');
  //   var myChart = echarts.init(chartDom);
  //   let obj = this
  //   let option = {
  //     xAxis: {
  //       type: 'category',
  //       name:'Projects',
  //       data: name
  //     },
  //     tooltip: {
  //       trigger: 'axis',
  //       axisPointer: {
  //         type: 'shadow'
  //       }
  //     },
  //     yAxis: {
  //       type: 'value',
  //       name: 'Percentage (%)',
  //     },
  //     series: [
  //       {
  //         data: [this.achivedTotalResult],
  //         type: 'bar'
  //       }
  //     ]
  //   };

  //   myChart.setOption(option);

  //   myChart.on('click', 'series', (params) => {
  //     // this.handleChartSeriesClick(params)
  //     console.log(params)
  //     obj.showChart =false
  //     obj.parentData = {
  //     projectId: obj.form.controls.selectedProject.value.id,
  //     siteId: obj.form.controls.selectedSite.value.id
  //   }
  //   console.log(obj.ShapeLayerData)
  //   obj.sitesService.fetchSitesData(obj.creatorid)
  // .pipe(
  //   tap((res)=> { obj.siteId = res[0].id}),
  //   switchMap(()=>{
  //     // console.log(this.sites)
  //     return obj.mapService.getProjects(obj.siteId) 
  //   }),
  // )
  // .subscribe((res)=> console.log(res));
  // this.addGeojsonToMap();
  //   })
  // }

  getSelectedProcess(event){
    this.chartData(event.value)
  }
  handleChartSeriesClick() {

    let name = [];
    this.projects.forEach((res)=>{
      if(res.maps?.excelData?.excelName){
        name.push(res.item);
      }
    })
    console.log(this.onTimeProject,name)
    for(let i = 0; i <name?.length;i++){
      if(this.onTimeProject.length){
      if(this.onTimeProject[i] == undefined){

      }else{
        this.onTimeProject[i].name = name[i] 
      }

      }
  }
  // console.log(this.achivedArray , this.projectAchivedResult)
    const formatNumberWithCommas = (number) => {
      return parseFloat(number).toLocaleString('en-US'); 
    };

    var app: any = {};
    
    var chartDom = document.getElementById('chart-container-summary');
    
    var myChart = echarts.init(chartDom);
    const posList = [
      'left',
      'right',
      'top',
      'bottom',
      'inside',
      'insideTop',
      'insideLeft',
      'insideRight',
      'insideBottom',
      'insideTopLeft',
      'insideTopRight',
      'insideBottomLeft',
      'insideBottomRight'
    ];
    app.configParameters = {
      rotate: {
        min: -90,
        max: 90
      },
      
      align: {
        options: {
          left: 'left',
          center: 'center',
          right: 'right'
        }
      },
      verticalAlign: {
        options: {
          top: 'top',
          middle: 'middle',
          bottom: 'bottom'
        }
      },
      position: {
        options: posList.reduce(function (map, pos) {
          map[pos] = pos;
          return map;
        }, {})
      },
      distance: {
        min: 0,
        max: 100
      }
    };
    app.config = {
      rotate: 90,
      align: 'left',
      verticalAlign: 'middle',
      position: 'insideBottom',
      distance: 15,
      onChange: function () {
        const labelOption = {
          rotate: app.config.rotate,
          align: app.config.align,
          verticalAlign: app.config.verticalAlign,
          position: app.config.position,
          distance: app.config.distance
        };
        myChart.setOption({
          series: [
            {
              label: labelOption
            },
            {
              label: labelOption
            },
          ]
        });
      }
    };

    const labelOption = {
      
      show: true,
      position: app.config.position,
      distance: app.config.distance,
      align: app.config.align,
      verticalAlign: app.config.verticalAlign,
      rotate: app.config.rotate,
      formatter: '{c}  {name|{a}}',
      fontSize: 16,
      rich: {
        name: {}
      }
    };

    const option = {
      tooltip: {
        trigger: 'item',
        formatter: (params ) => {
          const data = `${params.name}: ${params.value}%<br/>`
          return data;
        }
      },
      xAxis: [
        {
          // axisLabel: {
          //   formatter: function (value) {
          //     return value.split(' ')[0];
          //   }
          // },
          axisLabel: {
            interval: 0,
            margin: 20,
            width: "50",
            // rotate: 30 ,//If the label names are too long you can manage this by rotating the label.,
            overflow: 'truncate',
          },
          name: 'Description of Items',
          nameLocation: 'middle',
          nameGap: 50,
          type: 'category',
          axisTick: { show: false },
          data: name
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Percent (%)' ,
          nameLocation: 'middle',
          nameGap: 100,
        }
      ],
      series: [
        {
          name: 'Planned',
          type: 'bar',
          barGap: 0,
          label: {
            ...labelOption,
            formatter: (params) => {
              return formatNumberWithCommas(params.value) + " " + "Planned";
            },
          },
          emphasis: {
            focus: 'series'
          },
          data: this.mileStoneArray?.length ? this.mileStoneArray : this.projectPlannedResult
        },
        {
          name: 'Achived',
          type: 'bar',
          label: {
            ...labelOption,
            formatter: (params) => {
              return formatNumberWithCommas(params.value) + " " + "Achived";
            },
          },
          emphasis: {
            focus: 'series'
          },
          // itemStyle: {
          //   color: (params)=>{
          //     const result = this.onTimeProject.find((res)=>res.name === params.name)
          //     return result?.color
          //   },
          // },
          data: this.achivedArray?.length ? this.achivedArray : this.projectAchivedResult
        },
      ]
    };
    
    myChart.setOption(option);
        myChart.on('click', 'series', (params) => {
          // console.log(params)
          const clickedProject = this.projects.find(element => element.item === params.name);
          // console.log(clickedProject,"click");
          const  userId = this.projects.find((project)=> project?.maps.excelData.userId === this.creator.email) 
          if(userId){
            // this.projectAchivedResult = [];
            // this.projectPlannedResult = [];
            // this.handleChartSeriesClick() 
          } else {
            this.selectProject= clickedProject
            // console.log(this.form)
            this.getProjectChart()
          } 

      // this.handleChartSeriesClick(params)
    //   obj.showChart =false
    //   obj.parentData = {
    //   projectId: obj.form.controls.selectedProject.value.id,
    //   siteId: obj.form.controls.selectedSite.value.id
    // }
  })
  }


  callChart(data, name){
    let chartData = []
    for(let i=0;i<data.length;i++){
      chartData.push({ value: data[i], name: name[i]})
    }
    var chartDom = document.getElementById('chart-no-of-project');
    var myChart = echarts.init(chartDom);
    console.log(myChart,'=========call')
    let obj = this
    const option = {
  tooltip: {
    trigger: 'item'
  },
  title: {
    text: 'Total Number Of Projects',
    left: 'center',
    textStyle: {
      color: 'White'
    },
  },
  legend: {
    top: '10%',
    left: 'center',
    color:'red',
    textStyle: {
      color: 'White'
    },
  },
  series: [
    {
      type: 'pie',
      top:'20%',
      left: '60px',
      radius: ['40%', '80%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: chartData
    }
  ]
};


  
    myChart.setOption(option);
    console.log(myChart,'---------------charts');
    
      myChart.on('click', 'series', (params) => {
      console.log( myChart, '=================param')
      let selectedValue : any = this.sites.find(res => res.name == params.name)
      // console.log(selectedValue)
      obj.form.controls.selectedSite.value = selectedValue
      this.getProjectData('a')
    })
  }

  

  chartShowStatus(temp){  
    this.isNotVisible=true;  
    this.showDiv= false;
    this.categoryChart = true;
    const containerWidth = 732;
    const containerHeight = 374;
    const maxDataPoints = 10; 
    let data = []  
console.log(temp,'temp')
    for (const property in temp) {
      
    
      let obj = {}

      temp[property].forEach(e => {
        if (obj.hasOwnProperty(e['status'])){
          obj[e['status']] +=1
        }else{
          obj[e['status']] = 1
        }
      });
      console.log(obj)
      const dataEntry = { name: property, ...obj };
      data.push(dataEntry);
    }

    if (data.length > maxDataPoints) {
      data = data.slice(0, maxDataPoints);
    }

    let names = [];

    data.forEach((res) => {
      for (let key of Object.keys(res)) {
        const exists = names.find((name) => name === key);
        if (!exists && key !== "name") {
          names.push(key);
        }
      }
    });
    
    data.forEach((res) => {
      for (let key of names) {
        if (!res.hasOwnProperty(key) && key !== "name") {
          // Add the key with a default value of 0
          res[key] = 0;
        }
      }
    });
  
    setTimeout(() => {
      const chartDom = document.getElementById('chart-container-status-summary1');

      if (this.myChart) {
          this.myChart.dispose();
      }

      let myChart = echarts.init(chartDom);


      const labelSetting: echarts.PictorialBarSeriesOption['label'] = {
        show: true,
        position: 'right',
        offset: [10, 0],
        fontSize: 16
      };

      function makeOption(
        type: 'bar' | 'pictorialBar',
        symbol?: string
      ): echarts.EChartsOption {
        const yAxisData = data.map(item => item.name);
      
        const series = Object.keys(data[0]).slice(1).map(key => ({
          name: key,
          id: `bar_${key}`,
          type: type as 'pictorialBar',
          barGap: '10%',
          barWidth: '0%',
          label: labelSetting,
          symbolRepeat: true,
          symbolSize: ['80%', '60%'],
          universalTransition: {
            enabled: true,
            delay: function (idx, total) {
              return (idx / total) * 1000;
            }
          },
          data: data.map(item => ({
            value: item[key],
            symbol: symbol
          }))
        }));
        console.log(series)
        return {
          title: {
            text: 'Project Running Status',
            left: 'center',
            textStyle: {
              color: '#ffffff' 
            }
          },
          legend: {
            data: series.map(serie => serie.name),
            left: '10%',
            top:'8%',
            textStyle: {
              color: '#ffffff' 
            }
          },
          name: names,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
          },
          grid: {
            containLabel: true,
            left: 20,
            bottom: 15
          },
          yAxis: {
            data: yAxisData,
            inverse: true,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              margin: 30,
              fontSize: 14,
              color: '#ffffff' 
            },
            axisPointer: {
              label: {
                show: true,
                margin: 30
              }
            }
          },
          xAxis: {
            splitLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false },
            axisLine: { show: false }
          },
          animationDurationUpdate: 500,
          series: series
        };
      }
      
      const options = [
        makeOption('bar'),
        makeOption('pictorialBar', 'diamond')
      ];
      
      
      let optionIndex = 0;
      const option = options[optionIndex];

      if (this.interval) {
          clearInterval(this.interval);
      }

      const interval = setInterval(() => {
          optionIndex = (optionIndex + 1) % options.length;
          myChart.setOption(options[optionIndex]);
      }, 2500);

      myChart.setOption(option);

      this.myChart = myChart;
      this.interval = interval;
  }, 500);
   
  }

  tempChart(){
    // alert()
    // this.onTimeProject = ['ds']
    console.log(this.projectsPercentageArray,'projectsPercentageArray')
    this.projectsPercentageArray.forEach((project) => {
      console.log(project.plannedValue,'project');
       
      let plannedValue = parseFloat(project.plannedValue);
      console.log(plannedValue,'planned')
      let achievedValue = parseFloat(project.achivedValue);
  
      if (achievedValue > plannedValue) {
        project.achivedValue = plannedValue;
      } else {
      }
    });
    let name = [];
  //   this.projects.forEach((res)=>{
  //     if(res.maps?.excelData?.excelName){
  //       name.push(res.item);
  //     }
  //   })
  //   console.log(this.onTimeProject,name)
  //   for(let i = 0; i <name?.length;i++){
  //     if(this.onTimeProject.length){
  //     if(this.onTimeProject[i] == undefined){

  //     }else{
  //       this.onTimeProject[i].name = name[i] 
  //     }

  //     }
  // }
  // console.log(this.achivedArray , this.projectAchivedResult)
    const formatNumberWithCommas = (number) => {
      return parseFloat(number).toLocaleString('en-US'); 
    };
    var app: any = {};
    
    var chartDom = document.getElementById('chart-container-summary');

    var myChart = echarts.init(chartDom);
    const posList = [
      'left',
      'right',
      'top',
      'bottom',
      'inside',
      'insideTop',
      'insideLeft',
      'insideRight',
      'insideBottom',
      'insideTopLeft',
      'insideTopRight',
      'insideBottomLeft',
      'insideBottomRight'
    ];
    app.configParameters = {
      rotate: {
        min: -90,
        max: 90
      },
      align: {
        options: {
          left: 'left',
          center: 'center',
          right: 'right'
        }
      },
      verticalAlign: {
        options: {
          top: 'top',
          middle: 'middle',
          bottom: 'bottom'
        }
      },
      position: {
        options: posList.reduce(function (map, pos) {
          map[pos] = pos;
          return map;
        }, {})
      },
      distance: {
        min: 0,
        max: 100
      }
    };
    app.config = {
      rotate: 90,
      align: 'left',
      verticalAlign: 'middle',
      position: 'insideBottom',
      distance: 15,
      onChange: function () {
        const labelOption = {
          rotate: app.config.rotate,
          align: app.config.align,
          verticalAlign: app.config.verticalAlign,
          position: app.config.position,
          distance: app.config.distance
        };
        myChart.setOption({
          series: [
            {
              label: labelOption
            },
            {
              label: labelOption
            },
          ]
        });
      }
    };

    const labelOption = {
      show: true,
      position: app.config.position,
      distance: app.config.distance,
      align: app.config.align,
      verticalAlign: app.config.verticalAlign,
      rotate: app.config.rotate,
      formatter: '{c}  {name|{a}}',
      fontSize: 16,
      rich: {
        name: {}
      }
    };

    const option = {
      title: {
        text: 'Project Completion Chart' ,
        left: 'center',
        right: 'center',
        textStyle: {
          color: 'White'
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          const formattedValue = parseFloat(params.value).toFixed(2);
          const data = `${params.name}: ${formattedValue}%<br/>`;
          return data;
        }
      },
      xAxis: [
        {
          axisLabel: {
            formatter: function (value) {
              return value.split(',')[0];
            },
            interval: 0,
            margin: 20,
            width: "50",
            // rotate: 30, // Rotate the label if needed
            overflow: 'truncate',
            textStyle: {
              color: 'White'
            }
          },
          name: 'Description of Items',
          nameLocation: 'middle',
          nameGap: 50,
          nameTextStyle: {
            color: 'White'
          },
          type: 'category',
          axisTick: { show: false },
          data: this.projectsPercentageArray.filter((resp)=> parseFloat(resp.plannedValue) !== 0).map((resp)=> resp.name)
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Percent (%)' ,
          nameLocation: 'middle',
          nameGap: 100,
          nameTextStyle: {
            color: 'White'
          },
          axisLabel: {
            textStyle: {
                color: 'White'
            }
        },
        }
      ],
      series: [
        {
          name: 'Planned',
          type: 'bar',
          barGap: 0,
          label: {
            ...labelOption,
            formatter: (params) => {
              return formatNumberWithCommas(params.value) + "%  " + "Planned";
            },
          },
          emphasis: {
            focus: 'series'
          },
          // data: this.projectsPercentageArray.filter((resp)=> parseFloat(resp.plannedValue) !== 0).map((resp)=> resp.plannedValue)
          data: this.projectsPercentageArray
          .filter((resp) => parseFloat(resp.plannedValue) !== 0)
          .map((resp) => isNaN(parseFloat(resp.plannedValue)) ? 0 : parseFloat(resp.plannedValue))
          },
        {
          name: 'Achived',
          type: 'bar',
          label: {
            ...labelOption,
            formatter: (params) => {
              return formatNumberWithCommas(params.value) + "%  " + "Achived";
            },
          },
          emphasis: {
            focus: 'series'
          },
          itemStyle:{
            color:'red'
          },
          // itemStyle: {
          //   color: (params)=>{
          //     const result = this.onTimeProject.find((res)=>res.name === params.name)
          //     return result?.color
          //   },
          // },
          data: this.projectsPercentageArray.filter((resp) => parseFloat(resp.plannedValue) !== 0).map((resp) => Math.min(parseFloat(resp.achivedValue), 100))
            // data:this.projectsPercentageArray.filter((resp)=> parseFloat(resp.plannedValue) !== 0).map((resp)=> resp.achivedValue)
        },
      ]
    };
    myChart.setOption(option);

    // myChart.on('click', 'series', (params) => {
    //   this.showProjectTable= false;
    //   this.showNoProject =false;

    //   // this.projectSummary= false;
    //   this.categoryChart=false;
    //   // console.log(params.name ,this.projects)
    //   const clickedProject = this.projects.find(element => element.item === params.name);
    //   // console.log(clickedProject,"click");
    //   this.selectProject = clickedProject
    //   this.getProjectChart()
    //   // console.log(params.data['name'] , this.sites)
    //   //obj.categoryChart = true
    //   // obj.chartShowStatus(temp ,params.data['name'])
    // })
  }
  


  // New Changes FOr PMIS

  chartShowStatusPie(temp){
    
    this.projectSummary =true;
    this.categoryChart = false
    let data = []
    for (const property in temp) {
      data.push({value: temp[property].length, name: property} )
    }
    // console.log(data)
    setTimeout(() => {
      var chartDom = document.getElementById('chart-container-status-summary');
    var myChart = echarts.init(chartDom);
    let obj = this
    let option = {
        title: {
          text: 'Project Category',
          subtext: '',
          left: 'center',
          textStyle: {
            color: '#ffffff' 
          }

        },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'horizontal',
        left: 'center',
        top: '10%',
        textStyle: {
          color: '#ffffff'
        }
      },
      series: [
        {
          name: 'Category',
          type: 'pie',
          radius: '40%',
          top:'4%',
          data:data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    myChart.setOption(option);
    let GeoJSONData:any;


    myChart.on('legendselectchanged', function (params) {
      console.log('Legend:', params, obj.projects);
      let filterSiteData = obj.filterSiteData
      // filterSiteData

  

      for (const category in params['selected']) {
        // if (params['selected'].hasOwnProperty(category)) {
          if (params['selected'][category] === false) {
            filterSiteData = filterSiteData.filter(e => e.category !== category);
          } 
          // else if (params['selected'][category] === true) {
          //   filterSiteData = filterSiteData.filter(e => e.category === params['name']);
          // }
        // }
      }
      
      // if (params['selected'][params['name']] === false) {
      //   console.log('snsdn')
      // filterSiteData = obj.filterSiteData.filter(e => e.category != params['name'])
      // }
      // else{
      //   filterSiteData = obj.filterSiteData.filter(e => e.category == params['name'])
      // }

      let this2 =obj
      obj.map.data.forEach(function(feature) {
      this2.map.data.remove(feature);
      console.log(feature,'efature')
      });
      var bounds = new google.maps.LatLngBounds(); //for extend/fit slected projects on map default
    console.log(obj.filterSiteData,'sitesdata')
    filterSiteData.forEach(ele => {
      let latitude = Number(ele.maps.metaData?.lat);
      let longitude = Number(ele.maps.metaData?.lng);

      
      const currentDate = new Date();
      const updatedAtDate = new Date(ele.updatedAt);

      const diffInDays = obj.dateDiffInDays(currentDate, updatedAtDate);
      let t 
      if(diffInDays == 0){
        t = `${ele.item}<br>last-updated: Just Today`
      }else{
        t = `${ele.item}<br>last-updated: ${diffInDays} days ago`
      }
        GeoJSONData = {
        "type": "FeatureCollection",
        "features": [
          {
            "type": "Feature",
            "properties": {
              title: t,
              data: ele
            },
            "geometry": {
              "coordinates": [
                    longitude,
                    latitude,
              ],
              "type": "Point"
            }
          }
        ]
      };
      // console.log(GeoJSONData)
   obj.map.data.addGeoJson(GeoJSONData);

   obj.map.panTo(new google.maps.LatLng(latitude,longitude));
   bounds.extend(new google.maps.LatLng(latitude,longitude));
    })

    obj.map.fitBounds(bounds);
    });
    
    // console.log(obj.map.data,'mapdata')
    
    myChart.on('click', 'series', (params) => {
      obj.projectSummary=false;
      // console.log(params.data['name'] , this.sites)
      obj.categoryChart = false 
      obj.chartShowStatus(temp)
    })
    }, 1000);

  }

  getUserData() {
  
    let id = localStorage.getItem('id')
    this.mapService.getUser(id).subscribe((res:any)=>{
     const user = res.user;
     this.typeFinance = user.typeFinance
      this.userInfoArray = [user.tableName];
      this.userFinanceExcel =[user.financeTableName]
      console.log("this.userFinanceExcel",this.userInfoArray, this.userFinanceExcel) 
    })
  
  }
  getdataa(){
    let id = localStorage.getItem('id')
    this.mapService.UserTable_name(id).subscribe((res:any)=>{
      console.log(res.tableName)
      this.userInfoArray = res.tableName
      console.log(this.userInfoArray,"this.userInfoArray===")
    })
  }
  getAdminData(){
    let id = localStorage.getItem('id')
    this.mapService.getAdmin(id).subscribe((res:any)=>{
      let geoJsonURL = res.data;

      if (geoJsonURL) {
        this.http.get(geoJsonURL).subscribe((res: any) => {
          console.log(res, "res====>");
  
          let placeId;
          this.mapService.searchOnMapData.next(res.features[0].geometry);
          console.log(placeId);
        });
      }
      
    })
  }

  async getProjectOverviewData(){
    
    console.log("this.userInfoArray",this.userInfoArray) 
      let data = await this.mapService.getXlsxGeoJsonFromPostgres(this.userInfoArray);
      let totalBudgetSanctioned: number[] = [];

      data['features'].forEach(res => {
        res.properties[ 'completed length' ] = "nan"
        delete res.properties[ 'Total Completed  length of roads' ];
        this.temp.forEach((e:any) => {
          if(e.item.split(', ')[1] === res.properties['Name of Project'].replace(/,/g, "")){
            this.tableData.push(res.properties)    
          }
        })
        
      })  
      // uploaded

      if(this.typeFinance == 'uploaded'){
      let financeData = await this.mapService.getXlsxGeoJsonFromPostgres(this.userFinanceExcel);
      // console.log(data,financeData,"data====>")

   

      // let totalBudgetSanctioned: number[] = [];
      this.mapService.getAdminSeting(data).subscribe((res) => {
        // console.log(res,'res')
        let props = res[0].categories;
        // console.log('Property Key:', props);

        props.forEach((element: any) => {
          let budgetSum = 0; 
          // console.log(financeData,'financeData')
         financeData['features'].forEach((feature) => {
            console.log(feature,'feature')
            let budget = feature.properties[element];
            // console.log('Budget (Raw):', budget);
      
            let budgetValue = parseFloat(budget);
            if (!isNaN(budgetValue)) {
              budgetSum += budgetValue;
              console.log(budgetSum,'budget')
            } else {
            }
          });
          totalBudgetSanctioned.push(parseFloat(budgetSum.toFixed(2)));
        });
    

      
        // console.log('Total Budget Sanctioned:', totalBudgetSanctioned);
      });

      // created
    }else if(this.typeFinance == 'created'){

      let formdata = new FormData();
      formdata.append('tablename', this.userFinanceExcel[0])
      let financeData = [];
      this.mapService.getExcelColumnData(formdata).subscribe((response) => {
        console.log(response,"data====>")
      financeData = response.final_data
      })
      
      // let totalBudgetSanctioned: number[] = [];
      this.mapService.getAdminSeting(data).subscribe((res) => {
        console.log(res,'res')
        let props = res[0].categories;
        // console.log('Property Key:', props);

        props.forEach((element: any) => {
          let budgetSum = 0; 
          // console.log(financeData,'financeData')

          financeData[element].forEach((feature) =>{
            let budgetValue = parseFloat(feature);
              if (!isNaN(budgetValue)) {
                budgetSum += budgetValue;
                console.log(budgetSum,'budget')
              } else {
              }
          })
        
          // financeData.forEach((feature) => {
          //   console.log(feature,'feature')
          //   let budget = feature.properties[element];
          //   // console.log('Budget (Raw):', budget);
      
          //   let budgetValue = parseFloat(budget);
          //   if (!isNaN(budgetValue)) {
          //     budgetSum += budgetValue;
          //     console.log(budgetSum,'budget')
          //   } else {
          //   }
          // });
        
      
          totalBudgetSanctioned.push(parseFloat(budgetSum.toFixed(2)));
        });
    
      
      
        // console.log('Total Budget Sanctioned:', totalBudgetSanctioned);
      });
      
    
    }
   
    
    // let data = await this.mapService.getXlsxGeoJsonFromPostgres('overall_summary_r2_lp7upujl34frtuso37vsvz');
    // data['features'].forEach(res => {
      
    //   res.properties[ 'completed length' ] = "nan"
    //   delete res.properties[ 'Total Completed  length of roads' ];
    //   console.log(this.temp)
    //   this.temp.forEach((e:any) => {
    //     // console.log(e)
    //     if(e.item.split(', ')[1] === res.properties['Name of Project'].replace(/,/g, "")){
    //       this.tableData.push(res.properties)    
    //     }
    //   })
      
    // })
    this.overAllTabledata = this.tableData
    this.tableData.map((item, index, res) => {
      console.log(item,"itemitem")
      if (item.Department === 'PWD' && item.Status === "Running") {
        this.TotalRunningProjectsPwd++
      }
      if (item.Department === 'PWD') {
        this.pwdCount++;
      }
      if (item.Department === 'Light'){
        this.Light++
      }
      if (item.Department === 'Light/Bridge Corporation'){
        this.lightBridgeCount++
      }
      if (item.Department === 'Light/Bridge Corporation' && item.Status === "Running" ){
        this.TotalRunningProjectsLightBridge++
      }
      if (item.Department === 'Light' && item.Status === "Running" ){
        this.TotalRunningProjectsLight++
      }
 
    }); 
    
    this.mapService.getAdminSeting(data).subscribe((res) => {
      this.resChart = res;
      // console.log(this.resChart,'hgjhghghgh')
      // console.log(this.resChart[0],'11hgjhghghgh')
      this.generateBudgetChart(this.resChart[0].categories, this.resChart[0].chartName, totalBudgetSanctioned);
      })

    this.totalProjectStarted = this.tableData.length
    console.log(this.tableData,'---------tablee')
    this.tableData.forEach(res => {
      console.log(res)
      if(res['Budget Sanctioned (in Lakhs)'] !== 'nan'){
       this.budgetSanctioned = this.budgetSanctioned + Number(res['Budget Sanctioned (in Lakhs)']) 
      }
      if(res['Budget Utilised (in Lakh)'] !== 'nan'){
        this.budgetUtilised = this.budgetUtilised + Number(res['Budget Utilised (in Lakh)'])  
      }
      if(res['Budget Transferred (in Lakhs) For 25 %'] !== 'nan'){
        this.budgetTransferred = this.budgetTransferred + Number(res['Budget Transferred (in Lakhs) For 25 %']) 
      }
      if(res['Status'] === "Running"){
        this.projectStarted = this.projectStarted + 1
      }
      if(res['Status'] === "Re-Tendered"){
        this.projectRetendered = this.projectRetendered+ 1
      }
      if(res['Total Planned length of roads (in m)'] !== 'nan'){
        this.totalPlannedRoad = this.totalPlannedRoad + Number(res['Total Planned length of roads (in m)'])
      }
      if(res['completed length'] !== 'nan'){
        this.totalCompleteRoad = this.totalCompleteRoad + Number(res['completed length'])
      }
      console.log(this.totalCompleteRoad,'kength')
      if(res['Total No. of Poles'] !== 'nan'){
        this.totalPoles = this.totalPoles + Number(res['Total No. of Poles'])
      }
      if(res['Total No. of Bulbs'] !== 'nan'){
        this.totalBulbs = this.totalBulbs + Number(res['Total No. of Bulbs'])
      }
    })

    this.tableHeader = Object.keys(this.tableData[0])
    this.tableHeader.pop()
    let budgetData = this.calculateBudget(this.tableData);
    console.log(budgetData)
    let budgetCategories = Object.keys(budgetData);
    this.showNoProject=true;
    // if(this.currentUser !== 'vishvaraj@heliware.co.in'){
      // this.generateBudgetChart([this.budgetSanctioned.toFixed(2),this.budgetTransferred.toFixed(2),this.budgetUtilised.toFixed(2)], ['Fund Sanctioned', 'Fund Transfered' ,'Fund Utilised'], 'chartExcel', 'Project Expense (In Lakhs)');
     
    // }
    // this.getCompletedLength()

  }
  calculateBudget(tableData: any[]): { [key: string]: number } {
    let budgetData: { [key: string]: number } = {};
   
    tableData.forEach(row => {
      Object.keys(row).forEach(key => {
        if (key.toLowerCase().includes('budget')) {
          const value = parseFloat(row[key]);
          if (!isNaN(value)) {
            budgetData[key] = (budgetData[key] || 0) + value;
          }
        }
      });
    });
  
    return budgetData;
  }


  onSearch(value: string) {
    if(value.length){
      this.searchProject = true;
    }
    else if(value.length == 0) {
      this.searchProject = false;
    }
    console.log(this.tableData,value,'objjj')
    this.filteredItems = this.tableData.filter(objData =>
      (objData['Name of Project']?.toLowerCase().includes(value.toLowerCase())) ||
      (objData['Apex Committee']?.toLowerCase().includes(value.toLowerCase())) ||
      (objData['Department']?.toLowerCase().includes(value.toLowerCase())) ||
      (objData['Sl. No.']?.toString().includes(value)) || 
      (objData['Responsibility/Name of Clerk']?.toLowerCase().includes(value.toLowerCase())) ||
      (objData['Status']?.toLowerCase().includes(value.toLowerCase()))
  );
  }

   generateBudgetChart(categories: string[], chartTitle: string,totalBudgetSanctioned:any) {
    // console.log('values---->', categories, chartTitle);
    
    this.cdr.detectChanges();
    var chartDom = document.getElementById('chartExcel');
    var myChart = echarts.init(chartDom);
    const option = {
      title: {
        text: chartTitle,
        left: 'center',
        right: 'center',
        textStyle: {
          color: 'White'
        },
      },
      label: {
        show: true,
        // position: 'top',
        color: "black",
        // fontSize:12,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '5%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        axisLabel: {
          textStyle: {
              color: 'White'
          }
        }
      },
      yAxis: {
        type: 'category',
        data: categories,
        axisLabel: {
          textStyle: {
              color: 'White'
          }
      },
      },
      series: [
        {
          name: 'Budget',
          type: 'bar',
          data: totalBudgetSanctioned,
          label: {
            color: 'White' 
          },
        }
      ]
    };

    // const option = {
    //   title: {
    //     text: chartTitle,
    //     left: 'center',
    //     textStyle: {
    //       color: 'White',
    //     },
    //   },
    //   tooltip: {
    //     trigger: 'axis',
    //     axisPointer: {
    //       type: 'shadow',
    //     },
    //   },
    //   grid: {
    //     left: '3%',
    //     right: '4%',
    //     bottom: '10%', // Add more space at the bottom for x-axis labels
    //     containLabel: true,
    //   },
    //   xAxis: {
    //     type: 'value',
    //     boundaryGap: [0, 0.01],
    //     interval: 1000, // Set the interval on the x-axis
    //     axisLabel: {
    //       textStyle: {
    //         color: 'White',
    //       },
    //     },
    //   },
    //   yAxis: {
    //     type: 'category',
    //     data: categories, // Dynamic categories data
    //     axisLabel: {
    //       textStyle: {
    //         color: 'White',
    //       },
    //     },
    //   },
    //   series: [
    //     {
    //       name: 'Budget',
    //       type: 'bar',
    //       data: [120, 180, 150], // Static series data
    //       label: {
    //         show: true, // Show the labels on bars
    //         position: 'right', // Position the labels on the right side
    //         color: 'White',
    //       },
    //     },
    //   ],
    // };
  
    myChart.setOption(option);
    

  }

  getPlannedLength(){
    this.obj = {}
    let re = this
    // this.projects = [];
    console.log(this.sites)
// Create an array of observables for each getProjects call
let observables
if(this.organization == "ADMIN"){
   observables = this.sites.map(res => this.mapService.getProjects(res.id));
}
else{
  observables = this.sites.map(res => this.mapService.fetchAssignedProjects({userid:localStorage.getItem('id'),siteid:res.id}));
}
    let tempObj = {}
    let temp1Obj = {}
    let temp2Obj = {}
    this.featureObject = {};
    let tempObject ={};

  forkJoin(observables)
    .pipe(takeUntil(this.unsubscribeSubject))
    .subscribe((responses: any[]) => {
  
    responses.map((projects, index )=> {
        const updatedProjects = projects.map((project) => {
          return { ...project, category: this.sites[index].category };
        });
        this.projects = [...this.projects, ...updatedProjects];
        this.showProjectsOnMap(this.projects) 
        projects.forEach(async (project: any) => {
          this.Category = project.category;
          // console.log(this.Category,"========>Category")
          if(project.maps.excelData){
          this.mapService.getXLSXData(project.maps.excelData.excelName).subscribe(responses => {

            if(responses?.features){
              const order = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      
              const currentDate = new Date();
              const currentMonth = currentDate.toLocaleString('en-US', { month: 'short' });
              const currentYear = currentDate.getFullYear();
              const monthProperties = Object.keys(responses.features[0]?.properties).filter(key => key.includes("'"));
              // this.weeksChart = true;
              const [weekPart, _, month, yearPart] = monthProperties[0].split(/['.\s]/);
              const yearly = yearPart.length === 4 ? yearPart : `20${yearPart}`;
              const firstMonth = month;
              const firstYear = yearly;
              const firstWeek = parseInt(weekPart);
              const firstDayOfMonth = new Date(`${firstMonth} 01, ${firstYear}`);
              const dayOfWeek = firstDayOfMonth.getDay();
              const firstDayOfTargetWeek = new Date(firstDayOfMonth);
              firstDayOfTargetWeek.setDate(firstDayOfMonth.getDate() - dayOfWeek + firstWeek * 7);
          
              // console.log(firstDayOfTargetWeek)
              temp2Obj[project.item.split(', ')[1]] = firstDayOfTargetWeek
              
              

              // let tempArray = [];
              
              let temp =[];
              responses.features.forEach((featureRes) => {
                if(featureRes?.properties['Description'] !== 'nan'){
                  temp.push(featureRes?.properties['Description'])
                }
              })
              // console.log(temp)
              // featureObject[project.item] = temp
              if (project?.tempKmlData.length) {
                let tempArray = [];
                let tempObj = {};
                
                project.tempKmlData.forEach((e: any) => {
                    let nameParts: any = e.name.split("_");
                    if (nameParts[0] === "Achieved") {
                        let workType = nameParts[1];
                        let dateStr = nameParts[2];
                        let date = new Date(dateStr);
        
                        if (!tempObj[workType] || new Date(tempObj[workType].date) < date) {
                            tempObj[workType] = { work: workType, measurement: e.measurement, date: dateStr };
                        }
                    }
                });
        
                tempArray = Object.values(tempObj);
                // console.log(tempArray,'temparray')
                let totalMeasurement = 0;
                tempArray.forEach(item => {
                    totalMeasurement += item.measurement;
                });
                // console.log(temp.length)
                let averageMeasurement = totalMeasurement / temp.length;
                this.featureObject[project.item.split(', ')[1]] = averageMeasurement.toFixed(2)

                this.tableData.forEach(element => {
                  // element['Start Date']  = temp2Obj[element['Name of Project'].replace(/,/g, "")]
                  element['completed length1'] = this.featureObject[element['Name of Project'].replace(/,/g, "")]
                })
            
              }
              console.log(this.tableData,"aaaaaaaaa")
            }
            // console.log(featureObject,'featureObject')
          })    
          }
         
          
          temp1Obj[project.item.split(', ')[1]] = project.status;
          tempObj[project.item.split(', ')[1]] = project.completionDate;
          if (project?.kmlData.length) {
            const name = project.kmlData[0].name.split("_");

            if (name[0] === "Planned") {
              console.log(project.length,"project.siteid")
              if( project.siteid == '65769735d0c2613eea0714f1'){

                this.totalLength = this.totalLength + Number(project.kmlData[0].measurement);
                this.projectRetendered = this.projectRetendered + 1;

                console.log(this.projectRetendered,'this.totalLength')
           
              }
              else if( project.siteid == '65827f42b52fd60ebf0a8d12'){
                this.totalLength = this.totalLength + Number(project.kmlData[0].measurement);
                // this.projectRetendered = this.projectRetendered + 1;
                console.log(this.totalLength,'this.totalLength')
              }
              re.obj[project.item.split(', ')[1]] = project.kmlData[0].measurement;
              
            }
          }
          console.log(temp1Obj,'dsadsadsa')
        });
      // });
    
      console.log(temp1Obj,'===============')
      this.tableData.forEach(element => {
        // console.log(his.featureObject[element['Name of Project'].replace(/,/g, "")])
        element['Status'] = temp1Obj[element['Name of Project'].replace(/,/g, "")]
        element['End Date'] = tempObj[element['Name of Project'].replace(/,/g, "")]
        element['Total Planned length of roads (in m)'] = this.obj[element['Name of Project'].replace(/,/g, "")];
        // element['completed length1'] = this.featureObject[element['Name of Project'].replace(/,/g, "")]
      });  
      console.log(temp1Obj,'temp1obj') 
      console.log(this.tableData,'sdsadsadsa')
      });



    })
  }

  getCompletedLength(){
    this.obj = {}
    let re = this
    this.sites.forEach((res) => {
      this.mapService.getProjects(res.id)
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(async(projects)=>{
        await projects.forEach(async (project: any) => {
          if (project?.tempKmlData.length) {
              let tempArray = [];
              let tempObj = {};
      
              project.tempKmlData.forEach((e: any) => {
                  let nameParts: any = e.name.split("_");
                  if (nameParts[0] === "Achieved") {
                      let workType = nameParts[1];
                      let dateStr = nameParts[2];
                      let date = new Date(dateStr);
      
                      if (!tempObj[workType] || new Date(tempObj[workType].date) < date) {
                          tempObj[workType] = { work: workType, measurement: e.measurement, date: dateStr };
                      }                   
                  }
              });         
             
              tempArray = Object.values(tempObj);
      
              this.tableData.forEach(element => {
                  if (element['Name of Project'].replace(/,/g, "") === project.item.split(', ')[1]) {
                      element['completed length'] = tempArray;
                  }
                  // console.log(tempArray,'tempArray')
                  // element['completed length'] = this.obj[element['Name of Project'].replace(/,/g,"")]

              });
                // re.obj[project.item.split(', ')[1]] = tempArray
          }
      });
      
        // console.log(re.obj)
      })
    })


  }
  


  filterTableData(a){
    console.log(a,'sdadsad')
    return;
    let temp = []
    this.sites.forEach((res) => {

      if(localStorage.getItem('email') === 'osnagarnigam@rediffmail.com' || localStorage.getItem('email') === 'vishvaraj@heliware.co.in'){
        this.mapService.getProjects(res.id)
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((res1) => {
          console.log(res1)
          res1.forEach(ele => {
            this.tableData.map((e)=>{
              
              console.log(e['Name of Project'].replace(/,/g, "") ,",====",ele.item.split(', ')[1])
              if(e['Name of Project'].replace(/,/g, "") === ele.item.split(', ')[1])
              {
                console.log(e,"in")
                temp.push(e)
              }
            })
          })    
      })
        

      }else{
        this.mapService.fetchAssignedProjects({userid:localStorage.getItem('id'),siteid:res.id}).subscribe((resp:any)=>{
          resp.forEach(ele => {
            this.tableData.map((e)=>{
              console.log(e['Name of Project'].replace(/,/g, "") ,",====",ele.item.split(', ')[1])
              if(e['Name of Project'].replace(/,/g, "") === ele.item.split(', ')[1])
              {
                console.log(e,"in")
                temp.push(e)
              }
            })
          })
        })
      }
      
      // this.mapService.getProjects(res.id)
      //   .pipe(takeUntil(this.unsubscribeSubject))
      //   .subscribe((res1) => {
      //     console.log(res1)
      //     res1.forEach(ele => {
      //       this.tableData.map((e)=>{
      //         console.log(e['Name of Project'].replace(/,/g, "") ,",====",ele.item.split(', ')[1])
      //         if(e['Name of Project'].replace(/,/g, "") === ele.item.split(', ')[1])
      //         {
      //           console.log(e,"in")
      //           temp.push(e)
      //         }
      //       })
      //     })
          
      // })
    })
    console.log(temp)
    this.tableData = temp;
    // this.overAllTabledata = this.tableData
    // console.log(a,"eeeeeee" ,this.assignedProjects)  
    // this.tableData.map((e)=>{
    //   a.forEach(ele => {
    //     console.log(ele ,',====',e)
    //   //   if (e.id === ele.id) {
    //   //     console.log(ele, e, "Common element found!");
    //   // }
    //   });
    // })
  }

  callChart2(chartArray, id, total?: number) {
    this.myChart = echarts.init((document.getElementById(id)) as any);
    console.log(chartArray)
    const option = {
      title: {
        text: total ? total + "%" + "\n" + "Completed" : '',
        left: 'center',
        top: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'horizontal', // 'horizontal' or 'vertical'
        top: '330',
          textStyle: {
            color: '#ffffff'
          }
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          emphasis: {
            label: {
              show: (id === 'chart-container-project' ) ?true:false,
              fontSize: 17,
              fontWeight: 'bold'
            }
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: chartArray
        }
      ]
    };
    this.myChart.setOption(option);
  }

}



