import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./auth/auth-interceptor";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



// import * as PlotlyJS from 'plotly.js/dist/plotly.js';
// import { PlotlyModule } from 'angular-plotly.js';
import { SocketIoModule, SocketIoConfig, Socket } from "ngx-socket-io";
import { SocketGis } from "./socket-services/socket-gis";
import { SocketChat } from "./socket-services/socket-chat"
import { SharedModule } from "./shared/shared.module";
import { LoadingComponent } from "./loading/loading.component";
import { SocketModelConfig } from "./socket-services/socket-model-config";
import { SocketAiMap } from "./socket-services/socket-aimap";
import { SocketGeoSpatial } from "./socket-services/socket-geospatial";
import { ColorPickerModule } from "ngx-color-picker";
import { WindowRef } from "./heli-ai/nav/WindowRef";
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { ConfirmDialogComponent } from "./main-nav/confirm-dialog/confirm-dialog.component";

import { NgxLoaderModule } from '@tusharghoshbd/ngx-loader';
// import { GridsterModule } from 'angular2gridster';

// import { MapAiModule } from './map-ai/map-ai.module';
// import { AddCountryComponent } from './map-ai/add-country/add-country.component';
// import { AddStateComponent } from './map-ai/ai-nav/add-state/add-state.component';

// PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    ConfirmDialogComponent,
    // AddCountryComponent,
    // AddStateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SocketIoModule,
    ColorPickerModule,
    SharedModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    NgxLoaderModule
    // GridsterModule.forRoot(),
    // NgxEchartsModule.forRoot({
    //   echarts: () => import('../../node_modules/echarts'), 
    // }),
    // MapAiModule
    // NgModalModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    SocketGis,
    SocketChat,
    SocketModelConfig,
    SocketAiMap,
    SocketGeoSpatial,
    WindowRef,
  ],
  // exports:[
  //   AddCountryComponent,
  //   AddStateComponent
  // ],
  // providers: [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}, SocketGis, SocketModelConfig, SocketAiMap],
  bootstrap: [AppComponent],
})
export class AppModule {}
