import { Component, OnInit } from '@angular/core';
// import * as Potree from '../../../../build/potree/heli.js'
import * as Potree from '../../../assets/potre/build/potree/heli.js'
import { WindowRef } from 'src/app/heli-ai/nav/WindowRef.js';
// import * as THREE from "../../../../libs/three.js/build/three.module.js";
// import * as proj4 from '../../../../libs/proj4/proj4.js';

import * as $ from 'jquery';
import * as THREE from 'three';
import { MapService } from '../map.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CutFillEstimationComponent } from '../cut-fill-estimation/cut-fill-estimation.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';  // Import HttpClient
import { ChartCutFillEstimationComponent } from '../chart-cut-fill-estimation/chart-cut-fill-estimation.component';
@Component({
  selector: 'app-point-cloud',
  templateUrl: './point-cloud.component.html',
  styleUrls: ['./point-cloud.component.scss']
})
export class PointCloudComponent implements OnInit {
//   window:WindowRef
  viewer
  response1:any;
  response2:any;
  pointCloudCounter = 1; 
  spinner:boolean= false
  constructor(public mapService : MapService,private snackbar: MatSnackBar,	public dialog: MatDialog,private http: HttpClient) { }

  ngOnInit(): void {

	setTimeout(() => {
		this.loadPointCloud();
		// this.test();	
	}, 1000);
    
  }

  loadPointCloud(){
	console.log("sm1");
	
    this.viewer= new Potree.Viewer(
		document.getElementById("potree_render_area")
	);
    this.viewer.setEDLEnabled(false);
	this.viewer.setFOV(60);
	this.viewer.setPointBudget(1000000);
	this.viewer.loadSettingsFromURL();
    this.viewer.loadGUI(() => {
	$("#menu_tools").next().show();
	});

	this.test();
		// const elToolbar = $("#potree_toolbar");
		// elToolbar.html(`	
		// 	<div class="potree_toolbar_label" style="color:#000000";>
		// 		 <input type="file" id="myIng" />
		// 	</div>
		// 	`);
		// {
		// 	elToolbar.find("input[type=file]").click((e) => {
		// 		let fileName = e.target.files[0];
		// 		console.log(fileName);
		// 		$.getJSON(fileName.name, function (importedJson) {
		// 			let measure = new Potree.Measure();
		// 			console.log(importedJson);

		// 			for (var i = 0; i < importedJson.features.length; i++) {
		// 				switch (importedJson.features[i].geometry.type) {
		// 					case "Point":
		// 						// SINGLE POINT MEASURE
		// 						measure = new Potree.Measure();
		// 						measure.name = importedJson.features[i].properties.name;
		// 						measure.showDistances = false;
		// 						measure.showCoordinates = true;
		// 						measure.maxMarkers = 1;
		// 						measure.lengthUnitDisplay = this.viewer.lengthUnitDisplay;

		// 						measure.addMarker(
		// 							new THREE.Vector3(
		// 								importedJson.features[i].geometry.coordinates[0],
		// 								importedJson.features[i].geometry.coordinates[1],
		// 								importedJson.features[i].geometry.coordinates[2]
		// 							)
		// 						);

		// 						this.viewer.scene.addMeasurement(measure);
		// 						break;
		// 					case "LineString":
		// 						// DISTANCE MEASURE
		// 						measure = new Potree.Measure();
		// 						measure.name = importedJson.features[i].properties.name;
		// 						measure.closed = false;
		// 						measure.lengthUnitDisplay = this.viewer.lengthUnitDisplay;

		// 						for (
		// 							var j = 0;
		// 							j < importedJson.features[i].geometry.coordinates.length;
		// 							j++
		// 						) {
		// 							measure.addMarker(
		// 								new THREE.Vector3(
		// 									importedJson.features[i].geometry.coordinates[j][0],
		// 									importedJson.features[i].geometry.coordinates[j][1],
		// 									importedJson.features[i].geometry.coordinates[j][2]
		// 								)
		// 							);
		// 						}
		// 						this.viewer.scene.addMeasurement(measure);
		// 						break;
		// 					case "Polygon":
		// 						// ANGLE and AREA MEASURE
		// 						measure = new Potree.Measure();
		// 						measure.name = importedJson.features[i].properties.name;
		// 						if (measure.name == "Angle") {
		// 							measure.showDistances = false;
		// 							measure.showAngles = true;
		// 							measure.showArea = false;
		// 						} else if (measure.name == "Area") {
		// 							measure.showDistances = true;
		// 							measure.showArea = true;
		// 							measure.showAngles = false;
		// 						}
		// 						measure.closed = true;
		// 						measure.lengthUnitDisplay = this.viewer.lengthUnitDisplay;

		// 						for (
		// 							var j = 0;
		// 							j <
		// 							importedJson.features[i].geometry.coordinates[0].length - 1;
		// 							j++
		// 						) {
		// 							measure.addMarker(
		// 								new THREE.Vector3(
		// 									parseFloat(
		// 										importedJson.features[i].geometry.coordinates[0][j][0]
		// 									),
		// 									parseFloat(
		// 										importedJson.features[i].geometry.coordinates[0][j][1]
		// 									),
		// 									parseFloat(
		// 										importedJson.features[i].geometry.coordinates[0][j][2]
		// 									)
		// 								)
		// 							);
		// 						}

		// 						this.viewer.scene.addMeasurement(measure);
		// 						break;
		// 					default:
		// 						console.info("There aren't any measurements to be uploaded");
		// 						return false;
		// 				}
		// 			}
		// 		});

		// 		//   viewer.scene.pointclouds.forEach(
		// 		//     (pc) => (pc.material.activeAttributeName = "elevation")
		// 		//   );
		// 	});

		// 	elToolbar.find("img[name=action_rgb]").click(() => {
		// 		this.viewer.scene.pointclouds.forEach(
		// 			(pc) => (pc.material.activeAttributeName = "rgba")
		// 		);
		// 	});
		// }

		// {
		// 	const schemes = Object.keys(Potree.Gradients).map((name) => ({
		// 		name: name,
		// 		values: Potree.Gradients[name],
		// 	}));
		// 	const elGradientSchemes = elToolbar.find("span[name=gradient_schemes]");

		// 	for (const scheme of schemes) {
		// 		const elButton = $(`
		// 		<span style=""></span>
		// 	`);

		// 		const svg = Potree.Utils.createSvgGradient(scheme.values);
		// 		svg.setAttributeNS(null, "class", `button-icon`);
		// 		svg.style.height = "2em";
		// 		svg.style.width = "1.3em";

		// 		elButton.append($(svg));

		// 		elButton.click(() => {
		// 			for (const pointcloud of this.viewer.scene.pointclouds) {
		// 				pointcloud.material.activeAttributeName = "elevation";
		// 				pointcloud.material.gradient = Potree.Gradients[scheme.name];
		// 			}
		// 		});

		// 		elGradientSchemes.append(elButton);
		// 	}
		// }

		// {
		// 	elToolbar.find("img[name=action_measure_point]").click(() => {
		// 		const measurement = this.viewer.measuringTool.startInsertion({
		// 			showDistances: false,
		// 			showAngles: false,
		// 			showCoordinates: true,
		// 			showArea: false,
		// 			closed: true,
		// 			maxMarkers: 1,
		// 			name: "Point",
		// 		});
		// 	});

		// 	elToolbar.find("img[name=action_measure_distance]").click(() => {
		// 		const measurement = this.viewer.measuringTool.startInsertion({
		// 			showDistances: true,
		// 			showArea: false,
		// 			closed: false,
		// 			name: "Distance",
		// 		});
		// 	});

		// 	elToolbar.find("img[name=action_measure_circle]").click(() => {
		// 		const measurement = this.viewer.measuringTool.startInsertion({
		// 			showDistances: true,
		// 			showArea: true,
		// 			closed: true,
		// 			name: "Area",
		// 		});
		// 	});
		// }

		// {
		// 	let options = ["rgba", "elevation"];

		// 	let attributeSelection = elToolbar.find("#optMaterial");
		// 	for (let option of options) {
		// 		let elOption = $(`<option>${option}</option>`);
		// 		attributeSelection.append(elOption);
		// 	}

		// 	const updateMaterialSelection = (event, ui) => {
		// 		let selectedValue = attributeSelection.selectmenu().val();

		// 		for (const pointcloud of this.viewer.scene.pointclouds) {
		// 			pointcloud.material.activeAttributeName = selectedValue;
		// 		}
		// 	};
		// 	console.log(attributeSelection)
		// 	attributeSelection.selectmenu({ change: updateMaterialSelection });
		// }

		// {
		// 	elToolbar.find("#sldPointBudget").slider({
		// 		value: this.viewer.getPointBudget(),
		// 		min: 100000,
		// 		max: 10000000,
		// 		step: 100000,
		// 		slide: (event, ui) => {
		// 			this.viewer.setPointBudget(ui.value);
		// 		},
		// 	});

		// 	const onBudgetChange = () => {
		// 		let budget = (this.viewer.getPointBudget() / 1000000).toFixed(1) + "M";
		// 		elToolbar.find("span[name=lblPointBudget]").html(budget);
		// 	};

		// 	onBudgetChange();
		// 	this.viewer.addEventListener("point_budget_changed", onBudgetChange);
		// }
  }

  test(){
    console.log(this.viewer)
	console.log("sm2");
    let str = $("#myInput").val();
		let		str1 = $("#myIn").val();
		let		url = this.mapService.pointCloudUrl;
		let		name = str1;
		console.log(this.mapService.pointCloudUrl,'pointcloudurl')
		if(this.mapService.isType == 'Point Cloud'){
			// function
			this.loadOldData()
		}
		Potree.loadPointCloud(url + `.json`, name, (e) => {
					console.log(e, "---------------------------------->PointCloud");
					const scene = this.viewer.scene;
					const pointcloud = e.pointcloud;

					const material = pointcloud.material;
					material.size = 1;
					material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
					material.shape = Potree.PointShape.SQUARE;
					scene.addPointCloud(pointcloud);
					scene.view.setView(
						[590144.208, 231895.191, 1077.02],
						[589800.0, 231432.852, 756.242]
					);
					//  e.pointcloud.position.z -= 45;
					e.pointcloud.castShadow = true;
					this.viewer.fitToScreen();

					var awsurl;
					$('input[type="file"]').change(function (e) {
						let fileName = e.target.files[0];
						// alert("A file has been selected.");
						const formData = new FormData();
						formData.append("file", fileName);
						//   console.log(formData)
						$.ajax({
							url: "http://143.110.180.66/geo_upload/",
							type: "POST",
							data: formData,
							processData: false,
							contentType: false,
							success: function (da) {
								awsurl = da;
								// console.log(awsurl)

								$.getJSON(awsurl, function (importedJson) {
									let measure = new Potree.Measure();
									console.log(importedJson);

									for (var i = 0; i < importedJson.features.length; i++) {
										switch (importedJson.features[i].geometry.type) {
											case "Point":
												// SINGLE POINT MEASURE
												measure = new Potree.Measure();
												measure.name =
													importedJson.features[i].properties.name;
												measure.showDistances = false;
												measure.showCoordinates = true;
												measure.maxMarkers = 1;
												measure.lengthUnitDisplay =this.viewer.lengthUnitDisplay;

												measure.addMarker(
													new THREE.Vector3(
														importedJson.features[i].geometry.coordinates[0],
														importedJson.features[i].geometry.coordinates[1],
														importedJson.features[i].geometry.coordinates[2]
													)
												);

												this.viewer.scene.addMeasurement(measure);
												break;
											case "LineString":
												// DISTANCE MEASURE
												measure = new Potree.Measure();
												measure.name =
													importedJson.features[i].properties.name;
												measure.closed = false;
												measure.lengthUnitDisplay = this.viewer.lengthUnitDisplay;

												for (
													var j = 0;
													j <
													importedJson.features[i].geometry.coordinates
														.length;
													j++
												) {
													measure.addMarker(
														new THREE.Vector3(
															importedJson.features[i].geometry.coordinates[
															j
															][0],
															importedJson.features[i].geometry.coordinates[
															j
															][1],
															importedJson.features[i].geometry.coordinates[
															j
															][2]
														)
													);
												}
												this.viewer.scene.addMeasurement(measure);
												break;
											case "Polygon":
												// ANGLE and AREA MEASURE
												measure = new Potree.Measure();
												measure.name =
													importedJson.features[i].properties.name;
												if (measure.name == "Angle") {
													measure.showDistances = false;
													measure.showAngles = false;
													measure.showArea = false;
												} else if (measure.name == "Area") {
													measure.color.r = 0;
													measure.color.b = 1;
													measure.showDistances = false;
													measure.showArea = true;
													measure.showAngles = false;
													measure.showCoordinates = false;
													// console.log(measure)
												}
												measure.closed = true;
												measure.lengthUnitDisplay = this.viewer.lengthUnitDisplay;

												for (
													var j = 0;
													j <
													importedJson.features[i].geometry.coordinates[0]
														.length -
													1;
													j++
												) {
													measure.addMarker(
														new THREE.Vector3(
															parseFloat(
																importedJson.features[i].geometry
																	.coordinates[0][j][0]
															),
															parseFloat(
																importedJson.features[i].geometry
																	.coordinates[0][j][1]
															),
															parseFloat(
																importedJson.features[i].geometry
																	.coordinates[0][j][2]
															)
														)
													);
												}

												this.viewer.scene.addMeasurement(measure);
												break;
											default:
												console.info(
													"There aren't any measurements to be uploaded"
												);
												return false;
										}
									}
								});
							},
						});
					});
					// console.log(awsurl,"-------------------AWSURL")

					// let Geourl = "resources/measure.json";
					// var fileName=[];
					//     $('input[type="file"]').change(function (e) {
					//   fileName = e.target.files[0];
					//   console.log(fileName)
					//  console.log(fileName)
					// 	const formData = new FormData();
					//   formData.append("zip_file", fileName);
					//   console.log(formData)
					// setTimeout(function(){

					// },5000)
				});
  }

  loadOldData(){
	console.log("this.viewer,this.mapService.oldData",this.viewer,this.mapService.oldData);
	
    Potree.loadProject(this.viewer,this.mapService.oldData)     
	}

  toggle2Dswitch(){
	this.mapService.toggle3Dswitch = false
  }

  savePointCloudData(){
	console.log("this.mapService.oldData",this.mapService.selectedData);
	
	let data = Potree.saveProject(this.viewer)
	let pointTemp = data['pointclouds'][0];
	data['pointclouds'] = [];
	data['pointclouds'].push(pointTemp);
	let data2 = {
	  data : data,
	  projectId : this.mapService.selectedData.projectId,
	  layereId : this.mapService.selectedData.item._id,
	  layereName : this.mapService.selectedData.item.layerName,
	}
  
	console.log("data2",data2);
	
	this.mapService.updatePointCloudFinalData(data2).subscribe((res)=>{
	  console.log(res)
	  // if(res['message'] === "Updated"){
		this.snackbar.open(res['message'], 'ok')
	  // }
	  })
  }

  
  cutFillEstimation() {
    const dialogRef = this.dialog.open(CutFillEstimationComponent, {
        width: '300px',
        data: { message: 'data from pointcloud' }
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log("result==>", result);
        if (result) {    
            console.log("Starting polygon drawing...");

            // Start polygon measurement
            const measurement = this.viewer.measuringTool.startInsertion({
                showDistances: true,
                showAngles: false,
                showCoordinates: true,
                showArea: true,
                closed: true,
                name: "Polygon"
            });

            const coordinates = [];  // Store polygon coordinates

            console.log("Listening for marker_added event...");

            // Capture markers as they are added
			measurement.addEventListener('marker_added', () => {
				const lastMarker = measurement.points[measurement.points.length - 1];  
				if (lastMarker) {
					const { x, y, z } = lastMarker.position;
					coordinates.push([x, y, z]);
					console.log("Marker added:", { x, y, z });
				}
			});
			
			// ✅ Add Right Click Event to Finalize the Polygon
			window.addEventListener("contextmenu", (event) => {
				event.preventDefault(); // Prevent default browser right-click menu
				console.log("Right-click detected. Finalizing polygon...");
			
				if (coordinates.length < 3) {
					console.warn("A polygon requires at least 3 points!");
					return;
				}
			
				// ✅ Close the polygon by repeating the first point
				coordinates.push(coordinates[0]);
			
				// ✅ Manually finalize insertion
				measurement.dispatchEvent({ type: 'insertion_finished' });
			
				// ✅ Disable measurement tool & re-enable input
				this.viewer.measuringTool.activeMeasurement = null;
				this.viewer.inputHandler.enabled = true;
			
				console.log("Polygon finalized on right-click.");
			}, { once: true }); // ✅ Ensure this only runs once
			

            // ✅ Ensure `insertion_finished` event fires
			measurement.addEventListener('insertion_finished', () => {
				console.log("Polygon drawing completed.");
			
				if (coordinates.length > 0) {
					coordinates.push(coordinates[0]); // ✅ Close the polygon
				}
			
				const featureCollection = {
					type: "FeatureCollection",
					features: [{
						type: "Feature",
						geometry: {
							type: "Polygon",
							coordinates: [coordinates]  
						},
						properties: {
							name: "Polygon Feature",
							area: measurement.getArea()  
						}
					}]
				};
			
				console.log("FeatureCollection:", JSON.stringify(featureCollection, null, 2));
			
				// ✅ API Calls (Now only triggered after right-click)
				let formData = new FormData();
				formData.append("bounding_box", JSON.stringify(featureCollection));
				formData.append("server_type", "PointCloud");

				this.spinner = true;
			
				const apiUrl = "https://helibhumi.ai/bhumi/dem_elevation_max_min_percentage/";
				this.http.post(apiUrl, formData).subscribe(
					(response1) => {
						console.log("API Response 1:", response1);
						this.response1 = response1;
			
						let formData = new FormData();
						formData.append("bounding_box", JSON.stringify(featureCollection));
						formData.append("server_type", "PointCloud");
						formData.append("reference_elevation", result.referenceElevation);
						formData.append("crop_dem_file", response1['crop_dem_file']);
			
						const apiUrl = "https://helibhumi.ai/bhumi/dem_cut_fill_volume/";
						this.http.post(apiUrl, formData).subscribe(
							(response2) => {
								console.log("API Response 2:", response2);
								this.response2 = response2;
			
								let formData = new FormData();
								formData.append("bounding_box", JSON.stringify(featureCollection));
								formData.append("server_type", "PointCloud");
								formData.append("reference_elevation", result.referenceElevation);
								formData.append("crop_dem_file", response2['crop_dem_file']);
								formData.append("colorGeoTIFF", response2['cut_fill_file']);
								formData.append("elevationGeoTIFF", response2['crop_dem_file']);
			
								const apiUrl = "https://helibhumi.ai/bhumi/las_generate/";
								this.http.post(apiUrl, formData).subscribe(
									(response3) => {
										console.log("API Response 3:", response3);
			
										let formData = new FormData();
										formData.append("bounding_box", JSON.stringify(featureCollection));
										formData.append("server_type", "PointCloud");
										formData.append("reference_elevation", result.referenceElevation);
										formData.append("crop_dem_file", response2['crop_dem_file']);
										formData.append("colorGeoTIFF", response2['cut_fill_file']);
										formData.append("elevationGeoTIFF", response2['crop_dem_file']);
										formData.append("las_file", response3['las_file_path']);
			
										const apiUrl = "https://helibhumi.ai/bhumi/las_tiling/";
										this.http.post(apiUrl, formData).subscribe(
											(response) => {
												console.log("API Response 4:", response);
												let las_tile_path = response['las_tile_path'];
												const parts = las_tile_path.split("/");
												const lastTwoNames = parts.slice(-2);
												let url = 'https://helibhumi.ai:4001/static/';
												let las_url = url + lastTwoNames[0] + '/' + lastTwoNames[1] + '/' + 'heliware.json';
			
												this.http.get(las_url).subscribe(
													(response) => {
														console.log("Checking JSON before loading:", response);
														if (!response || typeof response !== "object") {
															console.error("Error: API did not return a valid JSON object.", response);
															return;
														}
			
														let modifiedResponse = JSON.parse(JSON.stringify(response));
														if (!modifiedResponse.hasOwnProperty('wkt')) {
															console.warn("Warning: 'wkt' field is missing. Adding default WKT.");
															modifiedResponse['wkt'] = "GEOGCS['WGS 84',DATUM['WGS_1984',SPHEROID['WGS 84',6378137,298.257223563]],PRIMEM['Greenwich',0],UNIT['degree',0.0174532925199433]]";
														}
			
														console.log("✅ Modified JSON (with wkt):", modifiedResponse);
														let pointCloudName = `Cut-Fill Value Estimation ${this.pointCloudCounter}`;
														this.pointCloudCounter++;
			
														Potree.loadPointCloud(las_url, pointCloudName, (e) => {
															console.log(`Point Cloud Loaded: ${pointCloudName}`, e);
															this.viewer.scene.addPointCloud(e.pointcloud);
														});
														this.spinner = false;
													},
													(error) => {
														console.error("Error fetching JSON:", error);
													}
												);
			
												// ------ Open Cut & Fill Dialog Box ------
												const dialogRef = this.dialog.open(ChartCutFillEstimationComponent, {
													width: '450px',
													height: 'auto',
													panelClass: 'custom-dialog-container',
													disableClose: true,
													backdropClass: 'dialog-backdrop-transparent',
													data: { 
														message: 'data from pointcloud',
														response1: this.response1,
														response2: this.response2,
													}
												});
			
												dialogRef.afterClosed().subscribe(result => {
													console.log("result==>", result);
												});
											},
											(error) => {
												console.error("API Error:", error);
												setTimeout(() => {
													this.snackbar.open(error, 'OK', {
													  duration: 5000
													})
												  }, 100);
											}
										);
									},
									(error) => {
										console.error("API Error:", error);
										setTimeout(() => {
											this.snackbar.open(error, 'OK', {
											  duration: 5000
											})
										  }, 100);
									}
								);
							},
							(error) => {
								console.error("API Error:", error);
								setTimeout(() => {
									this.snackbar.open(error, 'OK', {
									  duration: 5000
									})
								  }, 100);
							}
						);
					},
					(error) => {
						console.error("API Error:", error);
						setTimeout(() => {
							this.snackbar.open(error, 'OK', {
							  duration: 5000
							})
						  }, 100);
					}
				);
			});
			
        }
    });
}
 

}
