import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Chart, ChartConfiguration, ChartItem, registerables } from 'chart.js';

Chart.register(...registerables);

@Component({
  selector: 'app-chart-cut-fill-estimation',
  templateUrl: './chart-cut-fill-estimation.component.html',
  styleUrls: ['./chart-cut-fill-estimation.component.scss']
})
export class ChartCutFillEstimationComponent implements OnInit, AfterViewInit {
  
  @ViewChild('barChart') barChartRef!: ElementRef;

  maxElevation;
  minElevation;
  cutVolume;
  fillVolume;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ChartCutFillEstimationComponent> // ✅ DialogRef for closing
  ) {}

  ngOnInit(): void {
    console.log("data", this.data);
    this.maxElevation = this.data?.response1['MAX_ELEVATION'];
    this.minElevation = this.data?.response1['MIN_ELEVATION'];
    this.cutVolume = this.data?.response2['Cut_Volume'];
    this.fillVolume = this.data?.response2['Fill_Volume'];
  }

  ngAfterViewInit(): void {
    this.createBarChart();
  }

  createBarChart(): void {
    const chartConfig: ChartConfiguration = {
      type: 'bar',
      data: {
        labels: this.data?.response1['Elevation_Arr'],
        datasets: [{
          label: 'Elevation Percentage (%)',
          data: this.data?.response1['Elevation_Arr_Percentage'],
          backgroundColor: ['rgba(54, 162, 235, 0.6)', 'rgba(255, 99, 132, 0.6)'],
          borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
          borderWidth: 1
        }]
      },
      options: {
        indexAxis: 'x',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'DEM Elevation Max-Min Percentage',
            font: { size: 18 }
          },
          legend: { display: true }
        },
        scales: {
          x: { title: { display: true, text: 'Elevation Value (m)' }},
          y: { title: { display: true, text: 'Elevation Percentage (%)' }, beginAtZero: true }
        }
      }
    };

    const chartItem = this.barChartRef.nativeElement as ChartItem;
    new Chart(chartItem, chartConfig);
  }

  // ✅ Function to close the dialog when clicking the close button
  closeDialog(): void {
    this.dialogRef.close();
  }
}
