<mat-nav-list class="sidenav-list">
    <a mat-list-item routerLink="/new-dashboard" (click)="onToggleClose()">
        <!-- <mat-icon>account_circle</mat-icon> -->
        <mat-icon>bar_chart</mat-icon>
        <span>Admin Dashboard</span>
    </a>
    <!-- <a mat-list-item routerLink="/gis/detection-dashboard" (click)="onToggleClose()">
        <mat-icon>account_circle</mat-icon>
        <span>Admin Dashboard</span>
    </a> -->
    <!-- <a mat-list-item routerLink="/dashboard/admin-dashboard/project-dashboard" (click)="onToggleClose()">
        <mat-icon>account_circle</mat-icon>
        <span>Project Dashboard</span>
    </a> -->
    <a mat-list-item routerLink="/dashboard/admin-dashboard/admin-panel" (click)="onToggleClose()" *ngIf="isUser">
        <mat-icon>input</mat-icon>
        <span>User Managment</span>
    </a>
    <a mat-list-item routerLink="/dashboard/admin-dashboard/user-dashboard" (click)="onToggleClose()" *ngIf="isUser">
        <mat-icon>account_circle</mat-icon>
        <span>Contracts & Vendors</span> 
        <!-- <span>User Dashboard</span>  -->
    </a>

    <a mat-list-item routerLink="/dashboard/sites" (click)="onToggleClose()">
        <mat-icon>assignment</mat-icon>
        <span>Project Managment</span>
    </a>
    <a mat-list-item routerLink="/dashboard/add-items" (click)="onToggleClose()">
        <mat-icon>description</mat-icon>
        <span>Add Inventory</span>
    </a>
    <a mat-list-item routerLink="/dashboard/notification" (click)="onToggleClose()">
        <mat-icon>notifications</mat-icon>
        <span>Notifications</span>
    </a>
    <a mat-list-item routerLink="/dashboard/finance-allocation" (click)="onToggleClose()">
        <mat-icon>attach_money</mat-icon>
        <span>Finance & Budget Allocation</span>
    </a>
    <a mat-list-item routerLink="/new-dashboard" (click)="onToggleClose()">
        <!-- <mat-icon>attach_money</mat-icon> -->
        <!-- <span>Finance & Budget Allocation</span> -->
    </a>
    <!-- <a mat-list-item routerLink="/dashboard/admin-dashboard/add-inventory-excel" (click)="onToggleClose()">
        <mat-icon>input</mat-icon>
        <span>Add Inventory Excel</span>
    </a> -->
    <!-- <a mat-list-item routerLink="/dashboard/pmis"(click)="onDashboard()">
        <mat-icon>description</mat-icon>
        <span>PMIS Dashboard</span>
    </a> -->
</mat-nav-list>