import { Component, OnInit, Output , EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class NewSidenavListComponent implements OnInit {
  @Output() closeSideNav = new EventEmitter();
  userId: string;   
  isUser :boolean =true;
  constructor() { }

  ngOnInit(): void {
    this.userId = localStorage.getItem('email')
    
    let temp = '';
    if (localStorage.getItem('organization') === 'osnagarnigam@rediffmail.com') {
      temp = 'Pmis';
    } 
    else if (localStorage.getItem('email') === 'vishvaraj@heliware.co.in') {
      temp = 'Vishvaraj';
    }
    else if(localStorage.getItem('organization') === 'ADMIN'){
      this.isUser = true;
    }
    else {
      temp = " ";
      this.isUser = false;
    }

  }
  

  onToggleClose() {
    this.closeSideNav.emit();
  }
  onDashboard(){
  
  }
}