import { stringify } from 'querystring';
import { ElementRef, Injectable, ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver, ComponentFactory } from '@angular/core';
import { projectData } from '../models/projectData.interface';
import { BehaviorSubject, Observable, Subject, from } from 'rxjs';
import { ServerService } from '../server.service';
import { AuthService } from '../auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { addlayersinfo } from '../models/add-layers.interface';
import { SocketGis } from '../socket-services/socket-gis';
import { LayerTree } from '../models/layer-tree.interface';
import { attribute } from 'src/app/models/attribute.interface';
import { tap } from 'rxjs/operators';
import { featureIcon, matIcon } from '../models/dataGeoJSON.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import Axios from 'axios';
import { GeoJsonLayer, IconLayer, TextLayer } from "@deck.gl/layers";
import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { bbox, center, feature, Feature } from '@turf/turf';
import * as ColorConverter from 'color-convert';
import * as THREE from 'three';
// import {MathUtils} from 'three';
import * as PANOLENS from 'panolens';
import { TagComponent } from '../shared/tag/tag.component';
import { MatDialog } from '@angular/material/dialog';
import { features } from 'process';
import { EventEmitter } from 'events';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { Raycaster, Intersection } from 'three';
import { SubscriptionLike, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as Tiny from 'tinygradient';
import { PanTaggingComponent } from './gis-nav/pan-tagging/pan-tagging.component';
import { PanhoverTaggingComponent } from './gis-nav/panhover-tagging/panhover-tagging.component';
import * as turf  from '@turf/turf'
import { SocketChat } from '../socket-services/socket-chat';
// import length from '@turf/length'


// import { SocketGis } from '../app.module';

export type MODE = 'deleteMode' | 'dataMode' | 'bulkDataMode' | 'elevationMode' | 'listenMode' | 'kmlMode' | 'shpMode' | 'heatMode';

export interface CrossSection {
  name: string,
  description: string,
  profiles: Array<Array<{
    y: number,
    coords: {
      lat: number,
      lng: number
    }
  }>>
}

// KMLGEOJSON INTERFACE
export interface kmlGeoJSON {
  features: Array<{
    geometry: {
      type: string,
      coordinates: Array<number>
    },
    id?: string,
    properties: {
      layer: string,
      colorClass?: string,
      name: string,
      description?: string,
      category: string,
      area?: number,
      length?: number,
      perimeter?: number,
      center?: Array<number>,
      image?: string,
      id?: string,
      icon?: string,
      stroke?: string,
      styleUrl?: string,
      styleHash?: string,
      styleMapHash?: {
        normal: string,
        highlight: string
      },
      iconUrl?: string,
      'stroke-opacity'?: string,
      'stroke-width'?: string,
      fill?: string,
      'fill-opacity'?: string
    }
  }>,
  type: string
}

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private geoJsonData: any;
  private checkedValueSubject = new BehaviorSubject<boolean>(false);
  checkedValue$ = this.checkedValueSubject.asObservable();
  
  setCheckedValue(value: boolean) {
    this.checkedValueSubject.next(value);
  }

  private checkedStateSubject = new BehaviorSubject<boolean>(false);
  checkedState$ = this.checkedStateSubject.asObservable();

  private imageArraySubject = new BehaviorSubject<string[]>([]); 
  public imageArray$: Observable<string[]> = this.imageArraySubject.asObservable();

  private checkedStateSource = new BehaviorSubject<boolean>(false); // Default value
  checkedStates$ = this.checkedStateSource.asObservable(); // Observable to subscribe to

  // Method to update the checked state
  updateCheckedState(checked: boolean) {
    this.checkedStateSource.next(checked);
  }
  


  setCheckedState(state: boolean) {
    this.checkedStateSubject.next(state);
  }

  // Method to get the current checked state
  getCheckedState() {
    return this.checkedStateSubject.getValue();
  }



  // addComments(arg0: { name: any; email: string; }) {
  //   throw new Error('Method not implemented.');
  // }
  // scene: THREE.Scene
  // camera: THREE.PerspectiveCamera
  // tdrenderer: THREE.WebGLRenderer
  // mesh: THREE.Mesh
  // controls: OrbitControls
  // spriteMaterial: THREE.SpriteMaterial;
  // raycaster: Raycaster
  // endAnimation: boolean = false
  // spline: THREE.CatmullRomCurve3;
  // mouse: THREE.Vector2;
 
 public imagess: any[] = [];
  private projectData: projectData;
  mouseenterFn: any;
  createInfofn: any;
  panImageIndex: number;
  initExec = 0
  imageArray1 = [];
  imageArray2 = [];
  imageArray3 = [];
  imageArray4 = [];
  private dataSubject = new BehaviorSubject<any>(null);
  private currentPolygon: google.maps.Polygon | null = null;
  get project() {
    return this.projectData;
  }
  private i: 0;
  proData: projectData
  // public proData : projectData ;
  // {
  //   id: "1",
  //   data: null,
  //   maps: {
  //     mapData: [
  //       {zoomlevel:9,nyl:221,nyz:221,nxl:557,nxz:557},
  //       {zoomlevel:10,nyl:442,nyz:442,nxl:754,nxz:754},
  //       {zoomlevel:12,nyl:1770,nyz:1771,nxl:3018,nxz:3018},
  //       {zoomlevel:13,nyl:3541,nyz:3542,nxl:6036,nxz:6037},
  //       {zoomlevel:14,nyl:7083,nyz:7084,nxl:12072,nxz:12074},
  //       {zoomlevel:15,nyl:14167,nyz:14169,nxl:24145,nxz:24148},
  //       {zoomlevel:16,nyl:28334,nyz:28339,nxl:48291,nxz:48297},
  //       {zoomlevel:17,nyl:56668,nyz:56678,nxl:96583,nxz:96594},
  //       {zoomlevel:18,nyl:113337,nyz:113357,nxl:193166,nxz:193188},
  //       {zoomlevel:19,nyl:226674,nyz:226715,nxl:386333,nxz:386376},
  //       {zoomlevel:20,nyl:453349,nyz:453430,nxl:772666,nxz:772752},
  //       {zoomlevel:21,nyl:906699,nyz:906860,nxl:1545332,nxz:1545505},
  //       {zoomlevel:22,nyl:1813399,nyz:1813720,nxl:3090664,nxz:3091011}
  //     ],
  //     metaData: {
  //       lng:85.288317977455,
  //       lat:23.64316934919861,
  //       name:["ntpc","Patratu_NTPC_Web"],
  //       site:"Ranchi",
  //       jsonname:"ranchirs",
  //       maxzoom:25,
  //       minzoom:13
  //     }
  //   },
  //   videos: [
  //     "https://s3-us-west-2.amazonaws.com/heliwarestorage/phpserver/files/ntpcAugust.mp4",
  //     "https://s3-us-west-2.amazonaws.com/heliwarestorage/phpserver/files/ntpcDecember.mp4"
  //   ]
  // }
  // currentSelectedLayer: Subject<string> = new Subject();
  createLayer: Subject<addlayersinfo> = new Subject();


  ///location
  // Search on map
  searchOnMapData: Subject<any> = new Subject();


  // atrributeAdded: Subject<string[]> = new Subject();

  layerChanged: Subject<addlayersinfo> = new Subject();

  deleteLayer: Subject<addlayersinfo> = new Subject();

  zoomToLayer: Subject<string> = new Subject();

  toggleLayerVisibility: Subject<{ name: string, visible: boolean }> = new Subject();

  globalReportCreate: Subject<string> = new Subject();

  globalAdvanceReportCreate: Subject<string> = new Subject();

  projectDataChanged: Subject<projectData> = new Subject();

  pseudoprojectDataChanged: Subject<projectData> = new Subject();

  gisProjectsChanged: Subject<boolean> = new Subject();

  onceProjectChanged: Subject<projectData> = new Subject();

  gotProject: Subject<{ layerTree: Array<LayerTree>, id: string }> = new Subject();

  triggerMap: Subject<string> = new Subject();

  triggerMap2: Subject<string> = new Subject();

  triggerChangeWindowAnalysis: Subject<any> = new Subject();

  triggerChangeWindowAnalysisPointCloud: Subject<any> = new Subject();

  deletionMode: Subject<boolean> = new Subject();

  compareVideo: Subject<boolean> = new Subject();

  openWithMap: Subject<boolean> = new Subject();

  videoPaused: Subject<Boolean> = new Subject();

  videoSeeked: Subject<Boolean> = new Subject();

  videoSeekedBuffer: Subject<Boolean> = new Subject();

  videoEnded: Subject<Boolean> = new Subject();

  kmlTrigger: Subject<string> = new Subject();

  kmlLayersChanged: Subject<any> = new Subject();

  kmlIndexChange: Subject<Array<number>> = new Subject();

  triggerLoader: Subject<boolean> = new Subject();

  // video GPS Coordinates
  settimeOfVideo: Subject<number> = new Subject();
  toogleGpsCoordinates: Subject<boolean> = new Subject();

  // Bulk Data
  bDLayersChanged: Subject<{ name: string }[]> = new Subject();

  bDIndexChange: Subject<Array<number>> = new Subject();

  deleteBDIndex: Subject<number> = new Subject();

  // Shape File
  shapeFileRecieved: Subject<ArrayBuffer> = new Subject();

  // Data Layer
  dataLayerVisibility: Subject<boolean> = new Subject();

  // Breif Mode
  detailDataMode: Subject<boolean> = new Subject();

  // Elevation Layer
  elevationMode: Subject<boolean> = new Subject();
  csvDataChanged: Subject<any> = new Subject();
  triggerFetchElevation: Subject<boolean> = new Subject();
  hasElevationFetchedSubject: Subject<boolean> = new Subject();
  elevationProfileTrigger: Subject<boolean> = new Subject();
  chartVisible: Subject<boolean> = new Subject();
  crossSectionSave: Subject<CrossSection> = new Subject();
  crossSectionDelete: Subject<number> = new Subject();
  crossSectionSelect: Subject<CrossSection> = new Subject();

  // Bulk Data Layer
  bulkDataMode: Subject<boolean> = new Subject();
  imgArray: Subject<any> = new Subject();

  bulkDataCsvChanged: Subject<any> = new Subject();
  triggerFetchBulkData: Subject<boolean> = new Subject();
  hasBulkDataFetchedSubject: Subject<boolean> = new Subject();
  allLayerDataForAdmin: any

  activeAttributesChanged: Subject<Array<attribute>> = new Subject();
  addAttributeListner: Subject<attribute> = new Subject();
  toggleLayerChartVisibility: Subject<boolean> = new Subject();
  zoomFeature: Subject<{ id: string, type: string, properties: any, geometry: any }> = new Subject();
  toggleInLayerChartVisibility: Subject<number> = new Subject();
  userLiveLocation: Map<string, google.maps.Marker> = new Map()
  userLocationSub: SubscriptionLike;
  adminUserLiveLocation: Map<any, any> = new Map()
  adminUserLocationSub: Subscription = new Subscription();
  paranomaCameraValue: Subject<any> = new Subject();
  triggerWMSLayerMap: Subject<string> = new Subject();
  triggerTMSLayerMap: Subject<string> = new Subject();
  triggerWMSLayerMap2: Subject<string> = new Subject();
  triggerChangeWindow: Subject<any> = new Subject();
  GeoTifLayerMap: Subject<string> = new Subject();

  pointCloudData: Subject<any> = new Subject();
  clearPointCloudData : Subject<any> = new Subject();

  exportClicked : Subject<any> = new Subject();
  panaromaUpdate
  ///////inspection images/////////////////////
  imageArray = [];
  image360Coordinates = [];
  images = [];
  directionsRenderer:any;
   coordinates:any=[];

  nwDatalat: Array<any> = [];
  nwDatalog: Array<any> = [];

  panImg: boolean = false
  removePanormaOnUncheck:Subject<any> = new Subject();

  // chartDataSubject: Subject<any> = new Subject();
  // map
  @ViewChild('gmap', { static: true }) gMapElement: ElementRef;
 

  @ViewChild('gMapCompare', { static: true }) gMapCompareElement: ElementRef;
  @ViewChild('mapCont', { static: true }) mapContElement: ElementRef;

  @ViewChild('gmapwindow1', { static: true }) gMapElement2: ElementRef;
  @ViewChild('gmapwindow2', { static: true }) gMapCompareElement2: ElementRef;
  @ViewChild('gmapwindow3', { static: true }) gMapCompareElement3: ElementRef;
  @ViewChild('gmapwindow4', { static: true }) gMapCompareElement4: ElementRef;
  @ViewChild('gmapwindow5', { static: true }) gMapCompareElement5: ElementRef;


  map: google.maps.Map;

  map1: any;

  mappanel1: google.maps.Map;
  mappanel2:  google.maps.Map;
  mappanel3: google.maps.Map;
  mappanel4:  google.maps.Map;
  mappanel5:  google.maps.Map;
  
  deckOverlay: GoogleMapsOverlay;
  deckOverlayV2: Map<number, GoogleMapsOverlay> = new Map()
  routeDeckOverlay: Map<number, GoogleMapsOverlay> = new Map()
  labeldeckOverlay: GoogleMapsOverlay;
  labeldeckOverlayIconV2: Map<number, GoogleMapsOverlay> = new Map()
  //Loading Spinner
  loading: boolean = false;
  spinner: EventEmitter = new EventEmitter();
  clickEventFromDataLayer: EventEmitter = new EventEmitter();
  panaroma360SelectedFeatureCollection = null;

  gridMap: boolean;
  nongridMap: boolean;
  singleMap: boolean = true;

  toggle3Dswitch : boolean = false
  pointCloudUrl : string ;
  // matIcons: string[] = ['place', 'hotel', 'local_hospital', 'home', 'restaurant', 'school', 'subway', 'phone', 'local_post_office', 'location_city', 'local_parking', 'flight', 'public']
  matIcons: matIcon[] = [
    {
      name: 'Place',
      mat: 'place',
      color: 'rgb(48, 48, 247)'
    },
    {
      name: 'Hotel',
      mat: 'hotel',
      color: 'rgb(9, 255, 0)'
    },
    {
      name: 'Local Hospital',
      mat: 'local_hospital',
      color: 'rgb(138, 138, 231)'
    },
    {
      name: 'Home',
      mat: 'home',
      color: 'rgb(224, 113, 9)'
    },
    {
      name: 'Restaurant',
      mat: 'restaurant',
      color: 'rgb(233, 117, 223)'
    },
    {
      name: 'School',
      mat: 'school',
      color: 'rgb(241, 34, 34)'
    },
    {
      name: 'Subway',
      mat: 'subway',
      color: 'rgb(228, 225, 55)'
    },
    {
      name: 'Phone',
      mat: 'phone',
      color: 'rgb(241, 198, 6)'
    },
    {
      name: 'Local Post Office',
      mat: 'local_post_office',
      color: 'rgb(233, 16, 179)'
    },
    {
      name: 'Location City',
      mat: 'location_city',
      color: 'rgb(28, 167, 231)'
    },
    {
      name: 'Local Parking',
      mat: 'local_parking',
      color: 'rgb(5, 228, 209)'
    },
    {
      name: 'Flight',
      mat: 'flight',
      color: 'rgb(158, 108, 223)'
    },
    {
      name: 'Public',
      mat: 'public',
      color: 'rgb(30, 70, 61)'
    },
    {
      name: 'Temple',
      mat: 'account_balance',
      color: 'rgb(215, 75, 237)'
    },
    {
      name: 'Tower',
      mat: 'sensors',
      color: 'rgb(39, 167, 247)'
    },
    {
      name: 'ATM',
      mat: 'credit_card',
      color: 'rgb(39, 167, 247)'
    },
    {
      name: 'OAB',
      mat: 'card_membership',
      color: 'rgb(215, 75, 237)'
    },
    {
      name: 'House',
      mat: 'house',
      color: 'rgb(226, 20, 216)'
    }
  ]

  featureIcons: featureIcon[] = [
    {
      featureDesc: 'place',
      icon: 'https://maps.google.com/mapfiles/kml/paddle/P.png'
    },
    {
      featureDesc: 'hotel',
      icon: 'https://maps.google.com/mapfiles/kml/shapes/lodging.png'
    },
    {
      featureDesc: 'local_hospital',
      icon: 'https://maps.google.com/mapfiles/kml/shapes/hospitals.png'
    },
    {
      featureDesc: 'home',
      icon: 'https://maps.google.com/mapfiles/kml/shapes/homegardenbusiness.png'
    },
    {
      featureDesc: 'restaurant',
      icon: 'https://maps.google.com/mapfiles/kml/shapes/dining.png'
    },
    {
      featureDesc: 'school',
      icon: 'https://maps.google.com/mapfiles/kml/paddle/S.png'
    },
    {
      featureDesc: 'subway',
      icon: 'https://maps.google.com/mapfiles/kml/shapes/subway.png'
    },
    {
      featureDesc: 'phone',
      icon: 'https://maps.google.com/mapfiles/kml/shapes/phone.png'
    },
    {
      featureDesc: 'local_post_office',
      icon: 'https://maps.google.com/mapfiles/kml/shapes/post_office.png'
    },
    {
      featureDesc: 'location_city',
      icon: 'https://maps.google.com/mapfiles/kml/shapes/ranger_station.png'
    },
    {
      featureDesc: 'local_parking',
      icon: 'https://maps.google.com/mapfiles/kml/shapes/parking_lot.png'
    },
    {
      featureDesc: 'flight',
      icon: 'https://maps.google.com/mapfiles/kml/shapes/airports.png'
    },
    {
      featureDesc: 'public',
      icon: 'https://maps.google.com/mapfiles/kml/paddle/blu-blank.png'
    },
    {
      featureDesc: 'account_balance',
      icon: 'https://heliwarestorage.s3.us-west-2.amazonaws.com/mapper_Data/layer_Icons/temple_1.png'
    },
    {
      featureDesc: 'sensors',
      icon: 'https://heliwarestorage.s3.us-west-2.amazonaws.com/mapper_Data/layer_Icons/tower_1.png'
    },
    {
      featureDesc: 'credit_card',
      icon: 'https://heliwarestorage.s3.us-west-2.amazonaws.com/mapper_Data/layer_Icons/atm.png'
    },
    {
      featureDesc: 'card_membership',
      icon: 'https://heliwarestorage.s3.us-west-2.amazonaws.com/mapper_Data/layer_Icons/billboard.png'
    },
    {
      featureDesc: 'house',
      icon: 'https://heliwarestorage.s3.us-west-2.amazonaws.com/mapper_Data/layer_Icons/house.png'
    }

  ]

  ///////for graph data///////////
  selectedUploadData: any[] = [];


  modeUpdated: Subject<'deleteMode' | 'dataMode' | 'bulkDataMode' | 'elevationMode' | 'listenMode' | 'kmlMode' | 'shpMode' | 'heatMode'> = new Subject();
  exportCKMLLayer: Subject<string> = new Subject();

  exportAllLayer: Subject<string> = new Subject();
  droneMarker: google.maps.Marker;
  updateGroundVideos: Subject<any> = new Subject()
  // currentSelectedLayer: string;
  panhovergisContainerRef: ViewContainerRef
  spritesData: Array<{
    name?: string,
    content: string,
    pos: THREE.Vector3,
    sprite: THREE.Sprite
  }> = [];
  panGisExpPanelWithPanIndex: any = { panGisExpPanel: false, panIndex: 0, panImageIndex: 0 };
  panGisExpPanelWithPanIndexOnDataLayer: any = { panGisExpPanel: false, panImageIndex: 0 };
  panhoverComponents: Array<ComponentRef<PanhoverTaggingComponent>> = []
  image360polyLineCurrentSet: Array<any> = [];
  image360polygonCurrentSet: Array<any> = [];
  image360pointCurrentSet:Array<any> = [];

  constructor(public dialog: MatDialog, private server: ServerService, private auth: AuthService, private snackbar: MatSnackBar, private socket: SocketGis, private chatSocket: SocketChat, private http: HttpClient, private route: Router, private componentFactoryResolver: ComponentFactoryResolver) { }

  // init(gMapElement: any, loaded) {
  //   this.map = gMapElement;
  //   this.loading = loaded;
  //   console.log(this.loading);

  //   ///////////////////////////////////location///////////////////////////////////
  //   this.currentPolygon = null;
  //   this.searchOnMapData.subscribe(data => {
  //     console.log(data, `=========service`);
  //     const boundingbox = bbox(data);
  //     const valid = boundingbox.every(val => Number.isFinite(val));
  //     if (valid) {
  //       const sw = {
  //         lat: boundingbox[1],
  //         lng: boundingbox[0]
  //       }
  //       const ne = {
  //         lat: boundingbox[3],
  //         lng: boundingbox[2]
  //       }
  //       console.log(sw, ne);
  //       // console.log(this.map);
  //       this.map.setZoom(14);
  //       this.map.fitBounds(new google.maps.LatLngBounds(sw, ne));
  //       // console.log(43534);

  //     }
     
  //     if (valid && data.type === 'Polygon') {
  //       // Flatten the coordinates array and extract all points
        
  //        const coordinates = data.coordinates[0].map(coord => ({
  //         lat: coord[1],
  //         lng: coord[0]
  //       }));
       
  //       const sw = {
  //         lat: Math.min(...coordinates.map(coord => coord.lat)),
  //         lng: Math.min(...coordinates.map(coord => coord.lng))
  //       };
  //       const ne = {
  //         lat: Math.max(...coordinates.map(coord => coord.lat)),
  //         lng: Math.max(...coordinates.map(coord => coord.lng))
  //       };
  //       console.log(sw, ne);
  //       this.map.setZoom(14);
  //       this.map.fitBounds(new google.maps.LatLngBounds(sw, ne));
        
  //       if (this.currentPolygon) {
  //         this.currentPolygon.setMap(null);
  //       }

  //       const boundaryPolygon = new google.maps.Polygon({
  //         paths: coordinates,
  //         strokeColor: 'rgb(229 200 0)',
  //         strokeOpacity: 0.8,
  //         strokeWeight: 2,
  //         fillColor: '#e6f0ff',
  //         fillOpacity: 0.35
  //       });
  //       boundaryPolygon.setMap(this.map);
  //       this.currentPolygon = boundaryPolygon;
  //     }
  //   })

  // }

  setImageArray(images: string[]) {
    this.imageArraySubject.next(images);
  }

  fetchData(data: any) {
    const imageArray: string[] = [];
  }

  updateData(data: any) {
    this.dataSubject.next(data);
  }
  getData() {
    return this.dataSubject.asObservable();
  }
  setGeoJsonData(data: any): void {
    this.geoJsonData = data;
  }

  // Method to get geoJsonData
  getGeoJsonData(): any {
    return this.geoJsonData;
  }
  init(gMapElement: any,gMapElement2:any, gMapCompareElement,gMapCompareElement2,gMapCompareElement3,gMapCompareElement4,gMapCompareElement5, loaded) {
    this.map = gMapElement;

    this.map1 = gMapCompareElement

    this.mappanel1 = gMapElement2;
    console.log(this.mappanel1,this.map)
    this.mappanel2 = gMapCompareElement2;
    this.mappanel3 = gMapCompareElement3;
    this.mappanel4 = gMapCompareElement4
    this.mappanel5 = gMapCompareElement5;

    this.loading = loaded;
    console.log(this.loading);
  
    ///////////////////////////////////location///////////////////////////////////
    this.currentPolygon = null;
    this.searchOnMapData.subscribe(data => {
      console.log(data, `=========service`);
      const boundingbox = bbox(data);
      const valid = boundingbox.every(val => Number.isFinite(val));
      const infoWindow = new google.maps.InfoWindow();
  
      if (valid) {
        if (data.type === 'Polygon') {
          const coordinates = data.coordinates[0].map(coord => ({
            lat: coord[1],
            lng: coord[0]
          }));
  
          const sw = {
            lat: Math.min(...coordinates.map(coord => coord.lat)),
            lng: Math.min(...coordinates.map(coord => coord.lng))
          };
          const ne = {
            lat: Math.max(...coordinates.map(coord => coord.lat)),
            lng: Math.max(...coordinates.map(coord => coord.lng))
          };
  
          console.log(sw, ne);
          this.map.setZoom(14);
          this.map.fitBounds(new google.maps.LatLngBounds(sw, ne));
  
          if (this.currentPolygon) {
            this.currentPolygon.setMap(null);
            
          }
  
          const boundaryPolygon = new google.maps.Polygon({
            paths: coordinates,
            strokeColor: 'rgb(229 200 0)',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#e6f0ff',
            fillOpacity: 0.35
            
          });
          boundaryPolygon.setMap(this.map);
          this.currentPolygon = boundaryPolygon;
          google.maps.event.addListener(boundaryPolygon, 'click', (event) => {
            console.log(event ,data ,"data")
            // if(data.names === undefined){
            //   infoWindow.setContent(`<div><strong>Prayagraj</strong></div>`);
            // }
            // else{
            //   infoWindow.setContent(`<div><strong>${data.names}</strong></div>`);
            // }
            infoWindow.setContent(`<div><strong>${data.names}</strong></div>`);
            infoWindow.setPosition(event.latLng);
            infoWindow.open(this.map);
        }
      );
  
        } else if (data.type === 'Point') {
          const point = {
            lat: data.coordinates[1],
            lng: data.coordinates[0]
          };
  
          console.log('Point:', point);
          this.map.setCenter(point);
          this.map.setZoom(14);
  
          // Add a red flag marker
          const marker = new google.maps.Marker({
            position: point,
            map: this.map,
            icon: {
              // path: 'M 0,0 L 0,-20 L 10,-15 L 0,-10 Z',
              url: 'https://heliwarestorage.s3.us-west-2.amazonaws.com/construction/1.gif',
              scaledSize: new google.maps.Size(20, 20)
              // scale: 1, // Adjust size of the point
              // fillColor: 'red',
              // fillOpacity: 1,
              // strokeColor: 'red',
              // strokeWeight: 2
            }
          });
        //   google.maps.event.addListener(marker, 'click', () => {
        //     console.log("cxfghjkhgcghvh456")
        //     infoWindow.setContent(`<div><strong>${data.names}</strong></div>`);
        //     console.log(data,"data===>")
        //     infoWindow.open(this.map, marker);
        // });
        }
      }
    });
  }
  
  

  fetchPlaceDetails(placeId: string): Observable<any> {
    const url = `https://nominatim.openstreetmap.org/details.php`;
    const params = {
      place_id: placeId,
      addressdetails: '1',
      hierarchy: '0',
      group_hierarchy: '1',
      polygon_geojson: '1',
      format: 'json'
    };

    return this.http.get(url, { params });
  }
  
  
  categoryIcon(data): Observable<any> {
    return this.server.categoryUrl(data)
  }
  


  getMcgProjects(id){
    return this.server.getMcgProjects(id)
  }

  getMcgAllProject(){
    return this.server.getMcgAllProjects()
  }

  getProject(id: string) {
    this.server.getGISDatafromServer(id).subscribe(data => {
      this.projectData = data.projectData
      this.proData = data.projectData
      this.projectDataChanged.next(this.projectData)
      this.onceProjectChanged.next(this.projectData)
    })
  }

  getProjectNew(id: string) {
    this.server.getGISDatafromServer(id).subscribe(data => {
      this.projectData = data.projectData
      this.proData = data.projectData
      this.projectDataChanged.next(this.projectData)
      // this.onceProjectChanged.next(this.projectData)
    })
  }

  async getProjectAsync(id: string) {
    let data = await this.server.getGISDatafromServer(id).toPromise();
    this.projectData = data.projectData
      this.proData = data.projectData
      this.projectDataChanged.next(this.projectData)
      this.onceProjectChanged.next(this.projectData)
  }

  pseudogetProject(id: string) {
    this.server.getGISDatafromServer(id).subscribe(data => {
      this.projectData = data.projectData
      this.pseudoprojectDataChanged.next(this.projectData)
    })
  }

  getProjects(siteid: string) {
    console.log(siteid,'sites')
    return this.server.getGISProjects(siteid)

  }
   
  createProject(projectData: projectData) {
    console.log(projectData,'projectData')
    projectData.creatorid = this.auth.getUser().id
    this.server.saveGISDatatoServer(projectData).subscribe(res => {
      this.snackbar.open(`Site created successfully!`, 'OK', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: ['green-snackbar', 'custom-snackbar']
      });
      projectData.id = res.id;
      window.location.reload();
      if (this.auth.getUser().organization.includes('MCG')) {
        this.assignMcgProjectToAdmin(projectData.id);
      }
      this.projectData = projectData;
      this.gisProjectsChanged.next(true);
    }, err => {
    })
  }
  addPanTag(data: string) {
    this.server.addPanTag(this.projectData.id, data).subscribe((response) => {
      this.pseudogetProject(this.projectData.id);
    }, (err) => {
      console.log(err);
    })
  }

  deletePanTag(tagDataId: string) {
    this.server.deletePanTag(this.projectData.id, tagDataId).subscribe((response) => {
      this.pseudogetProject(this.projectData.id);
    }, (err) => {
      console.log(err);
    })
  }

  assignMcgProjectToAdmin(projectId) {
    this.server.assignMcgProjectToAdmin(projectId).subscribe(res => {
    }, err => {
    })
  }



  // updateProject(Site: Site, id: string){
  //   console.log(Site, id);
  //   this.server.updateSiteDataToServer(Site, id).subscribe(data=>{
  //     this.Site =Site;
  //     this.sitesChanged.next(this.Site);
  //   },err=>{
  //   })
  // }

  updateProject(projectData: projectData, id: string) {
    console.log("projectData,id===>",projectData,id)
    this.server.updateGISDataToServer(projectData, id).subscribe(data => {
      this.snackbar.open(`Project updated successfully!`, 'OK', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: ['green-snackbar', 'custom-snackbar']
      });
      this.projectData = projectData;
      this.projectDataChanged.next(this.projectData);
      this.gisProjectsChanged.next(true);
    }, err => {
    })
  }

  async getAllUserLayers() {
    return await this.server.getAllUserLayers();
  }

  /// get ALL mcg projects ///
  async getAllMcgProjects() {
    return await this.server.getAllMcgProjects()
  }

  uploadKML(data: FormData) {
    this.server.uploadKML(data).subscribe(res => {
      if (!this.projectData.kmls) {
        this.projectData.kmls = []
      }
      this.updateProject(this.projectData, this.projectData.id)
      this.kmlTrigger.next(res.kmlurl)
    }, err => {
    })
  }
  uploadTif(data: FormData) {
    return this.server.uploadKML(data)
  }

  // uploadSHP(data: FormData){
  //   this.server.uploadSHP(data).subscribe(res=>{
  //   },err=>{
  //   })
  // }

  uploadIMG(data: FormData) {
    return this.server.uploadIMG(data)
  }

  // Elevataion Layer
  uploadElevationJSON(file: File) {
    return this.server.uploadElevationJSON(file).pipe(tap(res => {
      this.projectData.elevationJSONurl = res.jsonurl;
      this.updateProject(this.projectData, this.projectData.id)
    }))
    // .subscribe((res)=>{
    //   this.projectData.elevationJSONurl = res.jsonurl;
    //   this.updateProject(this.projectData, this.projectData.id)
    // }, err=>console.log(err));
  }

  // Bulk Data Layer
  uploadBulkDataJSON(file: File) {
    return this.server.uploadBulkDataJSON(file).pipe(tap((res) => {
      this.projectData.bulkDataJSONurl = res.jsonurl;
      this.updateProject(this.projectData, this.projectData.id)
    }));
  }

  onTriggerMap(option: string) {
    this.triggerMap.next(option)
  }

  onTriggerMap2(option: string) {
    this.triggerMap2.next(option)
  }

  toggleDeletion(deleteMode: boolean) {
    this.deletionMode.next(deleteMode)
  }

  toggleVideo(isPaused: Boolean) {
    this.videoPaused.next(isPaused)
  }

  onEndVideo() {
    this.videoEnded.next(true);
  }

  sendToConvertKML(data: { json: any, name: string }) {
    this.server.KMLGISExport(data).subscribe(res => {
      const link = document.createElement('a')
      link.href = res.kml;
      link.download = data.name;
      link.click()
    }, err => {
    })
  }

  shareProject(data: { id: string, userid: string, accessControl: 'canEdit' | 'canView', message: string, phoneno?: string }) {
    this.server.shareGISProject(data).subscribe(res => {
      this.snackbar.open('Successfully Shared', 'ok', {
        duration: 3000
      });
      this.pseudogetProject(this.projectData.id);
    }, error => {
      this.snackbar.open('Something went wrong', 'ok', {
        duration: 3000
      });
    })
  }

  unShareProject(data: { id: string, userid: string, accessControl: 'canEdit' | 'canView' }) {
    this.server.unShareGISProject(data).subscribe(res => {
      this.snackbar.open('Successfully UnShared', 'ok', {
        duration: 3000
      });
      this.pseudogetProject(this.projectData.id);
    }, error => {
      this.snackbar.open('Something went wrong', 'ok', {
        duration: 3000
      });
    })
  }

  addFeature2D(feature, id) {
    this.server.uploadFeatureDSM({ feature: feature, id: id }).subscribe(d => {
      console.log(d, 'add')
    })
  }

  joinRoom(id: string) {
    console.log("Hello ser er")
    this.socket = new SocketChat();
    this.socket.emit('join', {
      token: this.auth.getToken(),
      id
    });
  }

  joinChatRoom(id: string) {
    console.log("Hello ser er")
    this.socket = new SocketGis();
    this.socket.emit('join', {
      token: this.auth.getToken(),
      id
    });
  }

  roomDisconnect() {
    this.socket.emit('end');
  }

  addChatMessage(data:any) {
    // console.log("message =====================>>>>",message)
    this.chatSocket.emit("message-outer",data)
    // this.getChatMessage(data.to)
  }

  // getChatMessage(id) {
  //   this.chatSocket.on(id,(res)=>{
  //     console.log("res =========================>>>>>>", res)
  //   })
  // }

  addFeature(feature: any) {
    console.log(feature)
    this.socket.emit('add feature', feature);
  }

  deleteFeature(feature: any) {
    this.socket.emit('delete feature', feature);
  }

  listenAddFeature() {
    return this.socket.fromEvent('add feature')
  }

  listenDeleteFeature() {
    return this.socket.fromEvent('delete feature')
  }

  addKML(kml: any) {
    this.socket.emit('add kml', kml);
  }

  deleteKML(kml: any) {
    this.socket.emit('delete kml', kml);
  }

  listenAddKML() {
    return this.socket.fromEvent('add kml')
  }

  listenDeleteKML() {
    return this.socket.fromEvent('delete kml')
  }

  updateElev(url: any) {
    this.socket.emit('update elev', url);
  }

  listenUpdateElev() {
    return this.socket.fromEvent('update elev')
  }

  updateBulk(url: any) {
    this.socket.emit('update bulk', url);
  }

  listenUpdateBulk() {
    return this.socket.fromEvent('update bulk')
  }

  // Locations of users
  listenLocations() {
    return this.socket.fromEvent<{ userId: string, locationData: { latitude: number, longitude: number } }>('location')
  }


  ///////////////////////////Android///////////////////////////////////////
  // location from android
  emitUserIdForLocation() {
    // console.log(this.route.url.slice(0).split('/')[2])
    this.socket.emit('locationReciever', this.route.url.slice(0).split('/')[2])
  }

  // get Loction from android
  getLocationFromAndroid() {
    return this.socket.fromEvent('getLocation' + this.route.url.slice(0).split('/')[2])
  }


  // get Multiple Loction from android
  getLocationFromAndroidByProject(project) {
    return this.socket.fromEvent('getLocation' + project)
  }

  ///////////////////////////Android///////////////////////////////////////


  addAttribute(id: string, attribute: attribute) {
    this.server.addGisAttribute({ id: id, attribute: attribute }).subscribe(data => {
      console.log(data.message)
    })
  }

  //////////////////////////////insp image//////////////////////////////////////////////
  async showImagesDom(g, i) {
    console.log(g);
    const result = await Axios.get(g);
    const data = result.data;
    console.log(data);

    for (let i = 0; i < data.features.length; i++) {
      var image = data.features[i].properties.file_path;
      this.imageArray.push(image);
    }
    console.log(this.imageArray, "==============>)aa gya ")
    this.setImageArray(this.imageArray);


    let cont = document.getElementById('InspDomImg');
    cont.innerHTML = '';

    if (this.imageArray.length > 0) {
      this.imageArray.map((i, k) => {
        let img = document.createElement('img');
        img.setAttribute('width', "100%");
        img.setAttribute('src', i);
        cont.appendChild(img);
        img.style.cursor = 'pointer';
        img.style.margin = "2px";
        img.addEventListener("mouseover", onHover);
        img.addEventListener("mouseleave", onHide);
        function onHover() {
          img.style.border = '2px solid #03A9F4';
        }
        function onHide() {
          img.style.border = 'none';
        }
        img.addEventListener("mouseover", mouseenter);
      })



      var img, img2, img3, img4, img5, lens, res, cx, cy, context, canvas, eventSrc;


      async function mouseenter(e) {
        // console.log(e);

        img2 = document.createElement('img');
        img2.setAttribute("id", "image")
        img2.style.width = "50%";
        img2.style.height = "78%";
        img2.style.right = "2px";
        img2.style.bottom = "12%";
        // img2.style.width = "75%";
        // img2.style.height = "97%";
        // img2.style.right = "7px";
        // img2.style.bottom = "17px";
        img2.style.position = "absolute";
        img2.style.zIndex = "10000";
        img2.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
        img2.style.border = "2px solid #b7abed";
        img2.style.color = "white";
        img2.setAttribute('src', e.target.currentSrc);
        const main = document.getElementById("mainI");
        // console.log(main);
        main.innerHTML = "";
        main.appendChild(img2);
        img2.style.cursor = 'crosshair';
        img2.addEventListener("mouseenter", imageZoom);
        img2.addEventListener("mouseout", mouseOut);
      }
    }



    function imageZoom(e) {
      console.log(e);
      img = document.getElementById("mainI");
      console.log("imgin imagezoom", img);
      img3 = img.querySelector("img");
      res = document.getElementById("zoomOutputImg");
      res.style.visibility = "visible";
      res.style.bottom = "95px";
      res.style.width = "300px";
      res.style.height = "250px";
      // res.style.right= "400px";
      res.style.position = "absolute";
      res.style.zIndex = "10000";
      lens = document.createElement("DIV");
      lens.setAttribute("class", "img-zoom-lens");
      /*insert lens:*/
      //  img3.parentElement.insertBefore(lens, img3);
      lens.style.position = "absolute";
      // lens.style.border = "4px solid red";
      lens.style.width = "30px";
      lens.style.height = "30px";
      // lens.style.zIndex="100000";
      /*insert lens:*/
      img3.parentElement.insertBefore(lens, img3);
      /*calculate the ratio between res DIV and lens:*/
      cx = res.offsetWidth / lens.offsetWidth;
      cy = res.offsetHeight / lens.offsetHeight;
      /*set background properties for the res DIV:*/
      res.style.backgroundImage = "url('" + e.target.currentSrc + "')";
      res.style.backgroundSize = (img3.width * cx) + "px " + (img3.height * cy) + "px";
      lens.removeEventListener("mousemove", moveLens);
      img3.removeEventListener("mousemove", moveLens);
      /*execute a function when someone moves the cursor over the image, or the lens:*/
      lens.addEventListener("mousemove", moveLens);
      img3.addEventListener("mousemove", moveLens);
      /*and also for touch screens:*/
      lens.addEventListener("touchmove", moveLens);
      img3.addEventListener("touchmove", moveLens);
      // }
      function moveLens(e) {
        var pos, x, y;
        /*prevent any other actions that may occur when moving over the image:*/
        e.preventDefault();
        /*get the cursor's x and y positions:*/
        pos = getCursorPos(e);
        /*calculate the position of the lens:*/
        x = pos.x - (lens.offsetWidth / 2);
        y = pos.y - (lens.offsetHeight / 2);

        /*prevent the lens from being positioned outside the image:*/
        if (x > img2.width - lens.offsetWidth) { x = img2.width - lens.offsetWidth; }
        if (x < 0) { x = 0; }
        if (y > img2.height - lens.offsetHeight) { y = img2.height - lens.offsetHeight; }
        if (y < 0) { y = 0; }
        /*set the position of the lens:*/
        lens.style.left = x + "px";
        lens.style.top = y + "px";

        /*display what the lens "sees":*/
        res.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
      }

      function getCursorPos(e) {
        var a, x = 0, y = 0;
        e = e || window.event;
        /*get the x and y positions of the image:*/
        a = img3.getBoundingClientRect();
        /*calculate the cursor's x and y coordinates, relative to the image:*/
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        // console.log(x, y);

        /*consider any page scrolling:*/
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return { x: x, y: y };
        // }
      }

    }

    function mouseOut() {
      let result2 = document.getElementById("zoomOutputImg");
      result2.style.visibility = "hidden";
    }

  }
  
  clearData(){
    let mainImage = document.getElementById("image");
    if (mainImage) {
      mainImage.style.visibility = "hidden";
    }
    let mainImage1 = document.getElementById("image2");
    if (mainImage1) {
      mainImage1.style.visibility = "hidden";
    }
    this.imageArray=[];
    this.imageArray2=[];
    let cont = document.getElementById('InspDomImg');
    console.log("cont", cont);
    cont.innerHTML = "";
    this.imageArray = [];
    let cont1 = document.getElementById('InspDomImg2');
    console.log("cont", cont1);
    cont1.innerHTML = "";
    this.imageArray2 = [];
  }

  async showImagesDomMapAnalysis(g, i,screen) {
    console.log(g);
    const result = await Axios.get(g);
    const data = result.data;
    console.log(data,screen);
    // let cont
// if (screen == 'two_window1') {  
//       this.imageArray1=[]
//       for (let i = 0; i < data.features.length; i++) {
//         var image = data.features[i].properties.file_path;
//         this.imageArray1.push(image);
//         this.setImageArray(this.imageArray1);
//       }
//         cont = document.getElementById('InspDomImg1');
//         cont.innerHTML = '';

//     } else if ( screen == 'two_window2') {
//       this.imageArray2=[]
//       for (let i = 0; i < data.features.length; i++) {
//         var image = data.features[i].properties.file_path;
//         this.imageArray2.push(image);
//       }
//       this.setImageArray(this.imageArray2);

//         cont = document.getElementById('InspDomImg2');
//         cont.innerHTML = '';
//     } 


   
    var img, img2, img3, img4, img5, lens, res, cx, cy, context, canvas, eventSrc;
    if (screen == 'two_window1') {
      this.imageArray=[]
      for (let i = 0; i < data.features.length; i++) {
        var image = data.features[i].properties.file_path;
        this.imageArray.push(image);
      }
      console.log(this.imageArray, "==============>)aa gya ")
      this.setImageArray(this.imageArray);
  
  
      let cont = document.getElementById('InspDomImg');
      cont.innerHTML = '';
      this.imageArray.map((i, k) => {
        let img = document.createElement('img');
        img.setAttribute('width', "100%");
        img.setAttribute('src', i);
        cont.appendChild(img);
        img.style.cursor = 'pointer';
        img.style.margin = "2px";
        img.addEventListener("mouseover", onHover);
        img.addEventListener("mouseleave", onHide);
        function onHover() {
          img.style.border = '2px solid #03A9F4';
        }
        function onHide() {
          img.style.border = 'none';
        }
        img.addEventListener("mouseover", mouseenter);
      })



      


      async function mouseenter(e) {
        // console.log(e);

        img2 = document.createElement('img');
        img2.setAttribute("id", "image")
        img2.style.width = "50%";
        img2.style.height = "78%";
        img2.style.right = "50%";
        img2.style.bottom = "12%";
        // img2.style.width = "75%";
        // img2.style.height = "97%";
        // img2.style.right = "7px";
        // img2.style.bottom = "17px";
        img2.style.position = "absolute";
        img2.style.zIndex = "10000";
        img2.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
        img2.style.border = "2px solid #b7abed";
        img2.style.color = "white";
        img2.setAttribute('src', e.target.currentSrc);
        const main = document.getElementById("mainI1");
        // console.log(main);
        main.innerHTML = "";
        main.appendChild(img2);
        img2.style.cursor = 'crosshair';
        // img2.addEventListener("mouseenter", imageZoom);
        // img2.addEventListener("mouseout", mouseOut);
      }
    }else if(screen == 'two_window2'){
      this.imageArray2=[]
      for (let i = 0; i < data.features.length; i++) {
        var image = data.features[i].properties.file_path;
        this.imageArray2.push(image);
      }
      console.log(this.imageArray2, "==============>)aa gya ")
      this.setImageArray(this.imageArray2);
  
  
      let cont = document.getElementById('InspDomImg2');
      cont.innerHTML = '';
      this.imageArray2.map((i, k) => {
        let img = document.createElement('img');
        img.setAttribute('width', "100%");
        img.setAttribute('src', i);
        cont.appendChild(img);
        img.style.cursor = 'pointer';
        img.style.margin = "2px";
        img.addEventListener("mouseover", onHover);
        img.addEventListener("mouseleave", onHide);
        function onHover() {
          img.style.border = '2px solid #03A9F4';
        }
        function onHide() {
          img.style.border = 'none';
        }
        img.addEventListener("mouseover", mouseenter);
      })



      // var img, img2, img3, img4, img5, lens, res, cx, cy, context, canvas, eventSrc;


      async function mouseenter(e) {
        // console.log(e);

        img2 = document.createElement('img');
        img2.setAttribute("id", "image2")
        img2.style.width = "50%";
        img2.style.height = "78%";
        img2.style.right = "2px";
        img2.style.bottom = "12%";
        // img2.style.width = "75%";
        // img2.style.height = "97%";
        // img2.style.right = "7px";
        // img2.style.bottom = "17px";
        img2.style.position = "absolute";
        img2.style.zIndex = "10000";
        img2.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
        img2.style.border = "2px solid #b7abed";
        img2.style.color = "white";
        img2.setAttribute('src', e.target.currentSrc);
        const main = document.getElementById("mainI2");
        // console.log(main);
        main.innerHTML = "";
        main.appendChild(img2);
        img2.style.cursor = 'crosshair';
        // img2.addEventListener("mouseenter", imageZoom);
        // img2.addEventListener("mouseout", mouseOut);
      }
    }

    if (this.imageArray.length > 0) {
      // // this.imageArray.map((i, k) => {
      // //   let img = document.createElement('img');
      // //   img.setAttribute('width', "100%");
      // //   img.setAttribute('src', i);
      // //   cont.appendChild(img);
      // //   img.style.cursor = 'pointer';
      // //   img.style.margin = "2px";
      // //   img.addEventListener("mouseover", onHover);
      // //   img.addEventListener("mouseleave", onHide);
      // //   function onHover() {
      // //     img.style.border = '2px solid #03A9F4';
      // //   }
      // //   function onHide() {
      // //     img.style.border = 'none';
      // //   }
      // //   img.addEventListener("mouseover", mouseenter);
      // // })



      // var img, img2, img3, img4, img5, lens, res, cx, cy, context, canvas, eventSrc;


      // async function mouseenter(e) {
      //   // console.log(e);

      //   img2 = document.createElement('img');
      //   img2.setAttribute("id", "image")
      //   img2.style.width = "50%";
      //   img2.style.height = "78%";
      //   img2.style.right = "2px";
      //   img2.style.bottom = "12%";
      //   // img2.style.width = "75%";
      //   // img2.style.height = "97%";
      //   // img2.style.right = "7px";
      //   // img2.style.bottom = "17px";
      //   img2.style.position = "absolute";
      //   img2.style.zIndex = "10000";
      //   img2.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
      //   img2.style.border = "2px solid #b7abed";
      //   img2.style.color = "white";
      //   img2.setAttribute('src', e.target.currentSrc);
      //   const main = document.getElementById("mainI");
      //   // console.log(main);
      //   main.innerHTML = "";
      //   main.appendChild(img2);
      //   img2.style.cursor = 'crosshair';
      //   img2.addEventListener("mouseenter", imageZoom);
      //   img2.addEventListener("mouseout", mouseOut);
      // }
    }



    function imageZoom(e) {
      console.log(e);
      img = document.getElementById("mainI");
      console.log("imgin imagezoom", img);
      img3 = img.querySelector("img");
      res = document.getElementById("zoomOutputImg");
      res.style.visibility = "visible";
      res.style.bottom = "95px";
      res.style.width = "300px";
      res.style.height = "250px";
      // res.style.right= "400px";
      res.style.position = "absolute";
      res.style.zIndex = "10000";
      lens = document.createElement("DIV");
      lens.setAttribute("class", "img-zoom-lens");
      /*insert lens:*/
      //  img3.parentElement.insertBefore(lens, img3);
      lens.style.position = "absolute";
      // lens.style.border = "4px solid red";
      lens.style.width = "30px";
      lens.style.height = "30px";
      // lens.style.zIndex="100000";
      /*insert lens:*/
      img3.parentElement.insertBefore(lens, img3);
      /*calculate the ratio between res DIV and lens:*/
      cx = res.offsetWidth / lens.offsetWidth;
      cy = res.offsetHeight / lens.offsetHeight;
      /*set background properties for the res DIV:*/
      res.style.backgroundImage = "url('" + e.target.currentSrc + "')";
      res.style.backgroundSize = (img3.width * cx) + "px " + (img3.height * cy) + "px";
      lens.removeEventListener("mousemove", moveLens);
      img3.removeEventListener("mousemove", moveLens);
      /*execute a function when someone moves the cursor over the image, or the lens:*/
      lens.addEventListener("mousemove", moveLens);
      img3.addEventListener("mousemove", moveLens);
      /*and also for touch screens:*/
      lens.addEventListener("touchmove", moveLens);
      img3.addEventListener("touchmove", moveLens);
      // }
      function moveLens(e) {
        var pos, x, y;
        /*prevent any other actions that may occur when moving over the image:*/
        e.preventDefault();
        /*get the cursor's x and y positions:*/
        pos = getCursorPos(e);
        /*calculate the position of the lens:*/
        x = pos.x - (lens.offsetWidth / 2);
        y = pos.y - (lens.offsetHeight / 2);

        /*prevent the lens from being positioned outside the image:*/
        if (x > img2.width - lens.offsetWidth) { x = img2.width - lens.offsetWidth; }
        if (x < 0) { x = 0; }
        if (y > img2.height - lens.offsetHeight) { y = img2.height - lens.offsetHeight; }
        if (y < 0) { y = 0; }
        /*set the position of the lens:*/
        lens.style.left = x + "px";
        lens.style.top = y + "px";

        /*display what the lens "sees":*/
        res.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
      }

      function getCursorPos(e) {
        var a, x = 0, y = 0;
        e = e || window.event;
        /*get the x and y positions of the image:*/
        a = img3.getBoundingClientRect();
        /*calculate the cursor's x and y coordinates, relative to the image:*/
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        // console.log(x, y);

        /*consider any page scrolling:*/
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return { x: x, y: y };
        // }
      }

    }

    function mouseOut() {
      let result2 = document.getElementById("zoomOutputImg");
      result2.style.visibility = "hidden";
    }

  }

  async showImagesDomKml(g, i) {
   
    // console.log(g);
    // const result = await Axios.get(g);
    const data = g;
    console.log(data);
    for (let i = 0; i < data.features.length; i++) {
      var image = data.features[i].properties.file_path;
      this.imageArray.push(image);
    }
    console.log(this.imageArray, "==============>)aa gya ")


    let cont = document.getElementById('InspDomImg');
    cont.innerHTML = '';

    if (this.imageArray.length > 0) {
      this.imageArray.map((i, k) => {
        let img = document.createElement('img');
        img.setAttribute('width', "100%");
        img.setAttribute('src', i);
        cont.appendChild(img);
        img.style.cursor = 'pointer';
        img.style.margin = "2px";
        img.addEventListener("mouseover", onHover);
        img.addEventListener("mouseleave", onHide);
        function onHover() {
          img.style.border = '2px solid #03A9F4';
        }
        function onHide() {
          img.style.border = 'none';
        }
        img.addEventListener("mouseover", mouseenter);
      })



      var img, img2, img3, img4, img5, lens, res, cx, cy, context, canvas, eventSrc;


      async function mouseenter(e) {
        console.log(e);

        img2 = document.createElement('img');
        img2.setAttribute("id", "image")
        img2.style.width = "50%";
        img2.style.height = "78%";
        img2.style.right = "2px";
        img2.style.bottom = "12%";
        // img2.style.width = "75%";
        // img2.style.height = "97%";
        // img2.style.right = "7px";
        // img2.style.bottom = "17px";
        img2.style.position = "absolute";
        img2.style.zIndex = "10000";
        img2.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
        img2.style.border = "2px solid #b7abed";
        img2.style.color = "white";
        img2.setAttribute('src', e.target.currentSrc);
        const main = document.getElementById("mainI");
        console.log(main);
        main.innerHTML = "";
        main.appendChild(img2);
        img2.style.cursor = 'crosshair';
        img2.addEventListener("mouseenter", imageZoom);
        img2.addEventListener("mouseout", mouseOut);
      }
    }



    function imageZoom(e) {
      console.log(e);
      img = document.getElementById("mainI");
      console.log("imgin imagezoom", img);
      img3 = img.querySelector("img");
      res = document.getElementById("zoomOutputImg");
      res.style.visibility = "visible";
      res.style.bottom = "95px";
      res.style.width = "300px";
      res.style.height = "250px";
      // res.style.right= "400px";
      res.style.position = "absolute";
      res.style.zIndex = "10000";
      lens = document.createElement("DIV");
      lens.setAttribute("class", "img-zoom-lens");
      /*insert lens:*/
      //  img3.parentElement.insertBefore(lens, img3);
      lens.style.position = "absolute";
      // lens.style.border = "4px solid red";
      lens.style.width = "30px";
      lens.style.height = "30px";
      // lens.style.zIndex="100000";
      /*insert lens:*/
      img3.parentElement.insertBefore(lens, img3);
      /*calculate the ratio between res DIV and lens:*/
      cx = res.offsetWidth / lens.offsetWidth;
      cy = res.offsetHeight / lens.offsetHeight;
      /*set background properties for the res DIV:*/
      res.style.backgroundImage = "url('" + e.target.currentSrc + "')";
      res.style.backgroundSize = (img3.width * cx) + "px " + (img3.height * cy) + "px";
      lens.removeEventListener("mousemove", moveLens);
      img3.removeEventListener("mousemove", moveLens);
      /*execute a function when someone moves the cursor over the image, or the lens:*/
      lens.addEventListener("mousemove", moveLens);
      img3.addEventListener("mousemove", moveLens);
      /*and also for touch screens:*/
      lens.addEventListener("touchmove", moveLens);
      img3.addEventListener("touchmove", moveLens);
      // }
      function moveLens(e) {
        var pos, x, y;
        /*prevent any other actions that may occur when moving over the image:*/
        e.preventDefault();
        /*get the cursor's x and y positions:*/
        pos = getCursorPos(e);
        /*calculate the position of the lens:*/
        x = pos.x - (lens.offsetWidth / 2);
        y = pos.y - (lens.offsetHeight / 2);

        /*prevent the lens from being positioned outside the image:*/
        if (x > img2.width - lens.offsetWidth) { x = img2.width - lens.offsetWidth; }
        if (x < 0) { x = 0; }
        if (y > img2.height - lens.offsetHeight) { y = img2.height - lens.offsetHeight; }
        if (y < 0) { y = 0; }
        /*set the position of the lens:*/
        lens.style.left = x + "px";
        lens.style.top = y + "px";

        /*display what the lens "sees":*/
        res.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
      }

      function getCursorPos(e) {
        var a, x = 0, y = 0;
        e = e || window.event;
        /*get the x and y positions of the image:*/
        a = img3.getBoundingClientRect();
        /*calculate the cursor's x and y coordinates, relative to the image:*/
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        // console.log(x, y);

        /*consider any page scrolling:*/
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return { x: x, y: y };
        // }
      }

    }

    function mouseOut() {
      let result2 = document.getElementById("zoomOutputImg");
      result2.style.visibility = "hidden";
    }

  }

  async removeImagesDom() {
    let cont = document.getElementById('InspDomImg');
    console.log("cont", cont);
    cont.innerHTML = "";
    this.imageArray = [];

  }


  /////////************************************Inspection feature****************************************** */

  async addInspData(geoUrl, i, popupDiv) {
    console.log(geoUrl);
    // const data: any = await this.getGeoJsonFromPostgres(geoUrl.table);
    // const data = geoUrl.data;
    // const data = geoUrl;
    // console.log(data);
    const result = await Axios.get(geoUrl.data);
    const data = result.data;
    console.log(data);

    //////for graph///////
    const filteredFeatures = data.features.map(({ properties }: any) => {
      for (const key in properties) {
        if (properties[key] === null) {
          delete properties[key]
        }
      }
      return properties
    })
    this.selectedUploadData.push({ features: filteredFeatures, i })
    //////for graph///////

    if (data) {
      let fillColor, strokeColor;

      if (geoUrl.fillColor && !geoUrl.fillColor.includes('#')) {
        const hex = geoUrl.fillColor;
        // convert to RGB
        let c = hex.split('(')[1].split(')')[0].split(',');
        c.map((e, i) => {
          c[i] = +e;
          if (i == 3) {
            c[i] = Math.round(e * 255);
          }
        })
        fillColor = c;
      } else {
        fillColor = [0, 228, 255, 255];
      }

      if (geoUrl.strokeColor && !geoUrl.strokeColor.includes('#')) {
        const hex2 = geoUrl.strokeColor;
        // convert to RGB
        let b = hex2.split('(')[1].split(')')[0].split(',');
        b.map((e, i) => {
          b[i] = +e;
          if (i == 3) {
            b[i] = Math.round(e * 255);
          }
        })
        strokeColor = b;
      } else {
        // strokeColor = [200, 0, 250, 255];
        strokeColor = [252, 0, 0];
      }
      // if (this.deckOverlay.setMap(this.map))this.deckOverlay.setMap(null);
      this.deckOverlay = new GoogleMapsOverlay({
        layers: [
          new GeoJsonLayer({
            id: "json",
            data: data,
            filled: true,
            pointRadiusMinPixels: 2,
            pointRadiusMaxPixels: 200,
            opacity: 1,
            pointRadiusScale: 0.3,
            getRadius: (f: Feature) => Math.pow(550, f.properties.mag),
            // getFillColor: [255, 70, 30, 180],
            getElevation: d => (geoUrl.scale ? geoUrl.scale : 1) * ((geoUrl.selectedAttribute) ? d.properties[geoUrl.selectedAttribute] : (d.properties && d.properties.height) ? d.properties.height : geoUrl.height ? geoUrl.height : 0),
            getFillColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : fillColor,
            getLineColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : strokeColor,
            getLineWidth: geoUrl.strokeWidth ? geoUrl.strokeWidth : 0.2,
            lineWidthMinPixels: geoUrl.strokeWidth ? geoUrl.strokeWidth : 0.2,
            // pointRadiusMinPixels:geoUrl.unit?geoUrl.unit:2,
            // getFillColor: [224, 8, 199, 255],
            autoHighlight: true,
            highlightColor: [0, 128, 255],
            // getLineColor: [200, 0, 250, 255],
            pickable: true,
            transitions: {
              getRadius: {
                type: "spring",
                stiffness: 0.1,
                damping: 0.15,
                enter: () => [8], // grow from size 0,
                duration: 10000,
              },
            },
            onHover: info => setTooltip2(info.object, info.x, info.y),
            onClick: info => showInfo(info)
          }),
        ],
      });
      console.log(this.map);

      this.deckOverlay.setMap(this.map);
      // this.map.data.loadGeoJson(`json`+ i); 


      let showInfo = (e) => {
        console.log("ein show info", e);
        console.log(e.object.properties.file_path);
        let img = JSON.stringify(e.object.properties.file_path);
        console.log(img);

        // var layericon
        // var layericon1
        // let immageee = "C-" + e.object.properties.file_name
        // let iconArr = []
        // let iconArr1 = []

        data.features.find((o, i) => {
          if (o.properties.file_path == e.object.properties.file_path) {
            const main = document.getElementById("mainI");
            main.innerHTML = "";
            let img2 = document.createElement('img');
            img2.setAttribute('src', o.properties.file_path);
            img2.setAttribute("id", "image")
            img2.style.width = "450px";
            img2.style.height = "415px";
            img2.style.right = "2px";
            img2.style.bottom = "81px";
            img2.style.position = "absolute";
            img2.style.zIndex = "10000";
            img2.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
            img2.style.border = "2px solid #b7abed";
            img2.style.color = "white";
            main.appendChild(img2);
            console.log("main", main);

          }


        })
      }

      let setTooltip2 = (object, x, y) => {
        console.log(455, 'hover');

        if (object) {
          popupDiv.innerHTML = '';
          let keys = Object.keys(object.properties);
          keys.map(e => {
            popupDiv.innerHTML += `${e} :  ${object.properties[e]} <br>`;
          })
          popupDiv.style.display = 'block';
          popupDiv.style.left = x + 'px';
          popupDiv.style.top = y + 'px';
          popupDiv.style.padding = "15px";
          popupDiv.style.backgroundColor = "#eeeeee";
          popupDiv.style.border = "1px solid #000"
        } else {
          popupDiv.style.display = 'none';
        }
      }

      console.log(data);
      const boundingbox = bbox(data);
      const valid = boundingbox.every(val => Number.isFinite(val));
      if (valid) {
        const sw = {
          lat: boundingbox[1],
          lng: boundingbox[0]
        }
        const ne = {
          lat: boundingbox[3],
          lng: boundingbox[2]
        }
        console.log(sw, ne);
        console.log(this.map);
        this.map.setZoom(12);
        this.map.fitBounds(new google.maps.LatLngBounds(sw, ne));
        console.log(43534);

      }
      this.spinner.emit('loader', false);

      // // this.loaded.next(101);
    }
  }

  async addInspDataMapAnalysis(geoUrl, i, popupDiv,screen) {
    console.log(geoUrl);
    // const data: any = await this.getGeoJsonFromPostgres(geoUrl.table);
    // const data = geoUrl.data;
    // const data = geoUrl;
    // console.log(data);
    const result = await Axios.get(geoUrl.data);
    const data = result.data;
    console.log(data);

    //////for graph///////
    const filteredFeatures = data.features.map(({ properties }: any) => {
      for (const key in properties) {
        if (properties[key] === null) {
          delete properties[key]
        }
      }
      return properties
    })
    this.selectedUploadData.push({ features: filteredFeatures, i })
    //////for graph///////

    if (data) {
      let fillColor, strokeColor;

      if (geoUrl.fillColor && !geoUrl.fillColor.includes('#')) {
        const hex = geoUrl.fillColor;
        // convert to RGB
        let c = hex.split('(')[1].split(')')[0].split(',');
        c.map((e, i) => {
          c[i] = +e;
          if (i == 3) {
            c[i] = Math.round(e * 255);
          }
        })
        fillColor = c;
      } else {
        fillColor = [0, 228, 255, 255];
      }

      if (geoUrl.strokeColor && !geoUrl.strokeColor.includes('#')) {
        const hex2 = geoUrl.strokeColor;
        // convert to RGB
        let b = hex2.split('(')[1].split(')')[0].split(',');
        b.map((e, i) => {
          b[i] = +e;
          if (i == 3) {
            b[i] = Math.round(e * 255);
          }
        })
        strokeColor = b;
      } else {
        // strokeColor = [200, 0, 250, 255];
        strokeColor = [252, 0, 0];
      }
      // if (this.deckOverlay.setMap(this.map))this.deckOverlay.setMap(null);
      this.deckOverlay = new GoogleMapsOverlay({
        layers: [
          new GeoJsonLayer({
            id: "json",
            data: data,
            filled: true,
            pointRadiusMinPixels: 2,
            pointRadiusMaxPixels: 200,
            opacity: 1,
            pointRadiusScale: 0.3,
            getRadius: (f: Feature) => Math.pow(550, f.properties.mag),
            // getFillColor: [255, 70, 30, 180],
            getElevation: d => (geoUrl.scale ? geoUrl.scale : 1) * ((geoUrl.selectedAttribute) ? d.properties[geoUrl.selectedAttribute] : (d.properties && d.properties.height) ? d.properties.height : geoUrl.height ? geoUrl.height : 0),
            getFillColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : fillColor,
            getLineColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : strokeColor,
            getLineWidth: geoUrl.strokeWidth ? geoUrl.strokeWidth : 0.2,
            lineWidthMinPixels: geoUrl.strokeWidth ? geoUrl.strokeWidth : 0.2,
            // pointRadiusMinPixels:geoUrl.unit?geoUrl.unit:2,
            // getFillColor: [224, 8, 199, 255],
            autoHighlight: true,
            highlightColor: [0, 128, 255],
            // getLineColor: [200, 0, 250, 255],
            pickable: true,
            transitions: {
              getRadius: {
                type: "spring",
                stiffness: 0.1,
                damping: 0.15,
                enter: () => [8], // grow from size 0,
                duration: 10000,
              },
            },
            onHover: info => setTooltip2(info.object, info.x, info.y),
            onClick: info => showInfo(info)
          }),
        ],
      });
      console.log(this.map);

      // this.deckOverlay.setMap(this.map);
      if(screen === 'two_window1'){
        this.deckOverlay.setMap(this.mappanel1)
      }else if(screen === 'two_window2'){
        this.deckOverlay.setMap(this.map1)
      }else if(screen === 'window1'){ 
        this.deckOverlay.setMap(this.mappanel2)
      }else if(screen === 'window2'){
        this.deckOverlay.setMap(this.mappanel3)
      }else if(screen === 'window3'){
        this.deckOverlay.setMap(this.mappanel4)
      }else if(screen === 'window4'){
        this.deckOverlay.setMap(this.mappanel5)
      }
      // this.map.data.loadGeoJson(`json`+ i); 


      let showInfo = (e) => {
        console.log("ein show info", e);
        console.log(e.object.properties.file_path);
        let img = JSON.stringify(e.object.properties.file_path);
        console.log(img);

        // var layericon
        // var layericon1
        // let immageee = "C-" + e.object.properties.file_name
        // let iconArr = []
        // let iconArr1 = []

        data.features.find((o, i) => {
          if (o.properties.file_path == e.object.properties.file_path) {
            const main = document.getElementById("mainI");
            main.innerHTML = "";
            let img2 = document.createElement('img');
            img2.setAttribute('src', o.properties.file_path);
            img2.setAttribute("id", "image")
            img2.style.width = "450px";
            img2.style.height = "415px";
            img2.style.right = "2px";
            img2.style.bottom = "81px";
            img2.style.position = "absolute";
            img2.style.zIndex = "10000";
            img2.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
            img2.style.border = "2px solid #b7abed";
            img2.style.color = "white";
            main.appendChild(img2);
            console.log("main", main);

          }


        })
      }

      let setTooltip2 = (object, x, y) => {
        console.log(455, 'hover');

        if (object) {
          popupDiv.innerHTML = '';
          let keys = Object.keys(object.properties);
          keys.map(e => {
            popupDiv.innerHTML += `${e} :  ${object.properties[e]} <br>`;
          })
          popupDiv.style.display = 'block';
          popupDiv.style.left = x + 'px';
          popupDiv.style.top = y + 'px';
          popupDiv.style.padding = "15px";
          popupDiv.style.backgroundColor = "#eeeeee";
          popupDiv.style.border = "1px solid #000"
        } else {
          popupDiv.style.display = 'none';
        }
      }

      console.log(data);
      const boundingbox = bbox(data);
      const valid = boundingbox.every(val => Number.isFinite(val));
      if (valid) {
        const sw = {
          lat: boundingbox[1],
          lng: boundingbox[0]
        }
        const ne = {
          lat: boundingbox[3],
          lng: boundingbox[2]
        }
        console.log(sw, ne);
        console.log(this.map);
        this.map.setZoom(12);
        this.map.fitBounds(new google.maps.LatLngBounds(sw, ne));
        console.log(43534);

      }
      this.spinner.emit('loader', false);

      // // this.loaded.next(101);
    }
  }


  async addInspDataKml(geoUrl, i, popupDiv) {
    console.log(geoUrl);
    // const data: any = await this.getGeoJsonFromPostgres(geoUrl.table);
    // const data = geoUrl.data;
    // const data = geoUrl;
    // console.log(data);
    // const result = await Axios.get(geoUrl.data);
    const data = geoUrl.data;
    console.log(data);

    //////for graph///////
    const filteredFeatures = data.features.map(({ properties }: any) => {
      for (const key in properties) {
        if (properties[key] === null) {
          delete properties[key]
        }
      }
      return properties
    })
    this.selectedUploadData.push({ features: filteredFeatures, i })
    //////for graph///////

    if (data) {
      let fillColor, strokeColor;

      if (geoUrl.fillColor && !geoUrl.fillColor.includes('#')) {
        const hex = geoUrl.fillColor;
        // convert to RGB
        let c = hex.split('(')[1].split(')')[0].split(',');
        c.map((e, i) => {
          c[i] = +e;
          if (i == 3) {
            c[i] = Math.round(e * 255);
          }
        })
        fillColor = c;
      } else {
        fillColor = [0, 228, 255, 255];
      }

      if (geoUrl.strokeColor && !geoUrl.strokeColor.includes('#')) {
        const hex2 = geoUrl.strokeColor;
        // convert to RGB
        let b = hex2.split('(')[1].split(')')[0].split(',');
        b.map((e, i) => {
          b[i] = +e;
          if (i == 3) {
            b[i] = Math.round(e * 255);
          }
        })
        strokeColor = b;
      } else {
        // strokeColor = [200, 0, 250, 255];
        strokeColor = [252, 0, 0];
      }
      // if (this.deckOverlay.setMap(this.map))this.deckOverlay.setMap(null);
      this.deckOverlay = new GoogleMapsOverlay({
        layers: [
          new GeoJsonLayer({
            id: "json",
            data: data,
            filled: true,
            pointRadiusMinPixels: 2,
            pointRadiusMaxPixels: 200,
            opacity: 1,
            pointRadiusScale: 0.3,
            getRadius: (f: Feature) => Math.pow(550, f.properties.mag),
            // getFillColor: [255, 70, 30, 180],
            getElevation: d => (geoUrl.scale ? geoUrl.scale : 1) * ((geoUrl.selectedAttribute) ? d.properties[geoUrl.selectedAttribute] : (d.properties && d.properties.height) ? d.properties.height : geoUrl.height ? geoUrl.height : 0),
            getFillColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : fillColor,
            getLineColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : strokeColor,
            getLineWidth: geoUrl.strokeWidth ? geoUrl.strokeWidth : 0.2,
            lineWidthMinPixels: geoUrl.strokeWidth ? geoUrl.strokeWidth : 0.2,
            // pointRadiusMinPixels:geoUrl.unit?geoUrl.unit:2,
            // getFillColor: [224, 8, 199, 255],
            autoHighlight: true,
            highlightColor: [0, 128, 255],
            // getLineColor: [200, 0, 250, 255],
            pickable: true,
            transitions: {
              getRadius: {
                type: "spring",
                stiffness: 0.1,
                damping: 0.15,
                enter: () => [8], // grow from size 0,
                duration: 10000,
              },
            },
            onHover: info => setTooltip2(info.object, info.x, info.y),
            onClick: info => showInfo(info)
          }),
        ],
      });
      console.log(this.map);

      this.deckOverlay.setMap(this.map);
      // this.map.data.loadGeoJson(`json`+ i); 


      let showInfo = (e) => {
        console.log("ein show info", e);
        console.log(e.object.properties.file_path);
        let img = JSON.stringify(e.object.properties.file_path);
        console.log(img);

        // var layericon
        // var layericon1
        // let immageee = "C-" + e.object.properties.file_name
        // let iconArr = []
        // let iconArr1 = []

        data.features.find((o, i) => {
          if (o.properties.file_path == e.object.properties.file_path) {
            const main = document.getElementById("mainI");
            main.innerHTML = "";
            let img2 = document.createElement('img');
            img2.setAttribute('src', o.properties.file_path);
            img2.setAttribute("id", "image")
            img2.style.width = "450px";
            img2.style.height = "415px";
            img2.style.right = "2px";
            img2.style.bottom = "81px";
            img2.style.position = "absolute";
            img2.style.zIndex = "10000";
            img2.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
            img2.style.border = "2px solid #b7abed";
            img2.style.color = "white";
            main.appendChild(img2);
            console.log("main", main);

          }


        })
      }

      let setTooltip2 = (object, x, y) => {
        console.log(455, 'hover');

        if (object) {
          popupDiv.innerHTML = '';
          let keys = Object.keys(object.properties);
          keys.map(e => {
            popupDiv.innerHTML += `${e} :  ${object.properties[e]} <br>`;
          })
          popupDiv.style.display = 'block';
          popupDiv.style.left = x + 'px';
          popupDiv.style.top = y + 'px';
          popupDiv.style.padding = "15px";
          popupDiv.style.backgroundColor = "#eeeeee";
          popupDiv.style.border = "1px solid #000"
        } else {
          popupDiv.style.display = 'none';
        }
      }

      console.log(data);
      const boundingbox = bbox(data);
      const valid = boundingbox.every(val => Number.isFinite(val));
      if (valid) {
        const sw = {
          lat: boundingbox[1],
          lng: boundingbox[0]
        }
        const ne = {
          lat: boundingbox[3],
          lng: boundingbox[2]
        }
        console.log(sw, ne);
        console.log(this.map);
        this.map.setZoom(12);
        this.map.fitBounds(new google.maps.LatLngBounds(sw, ne));
        console.log(43534);

      }
      this.spinner.emit('loader', false);

      // // this.loaded.next(101);
    }
  }

  removeInspLayer(i) {
    console.log(i);
    console.log(this.map.data);
    this.deckOverlay.setMap(null);
    const index = this.selectedUploadData.findIndex((data: any) => data.i === i);
    if (index > -1) {
      this.selectedUploadData.splice(index, 1);
    }

  }

  startMap(map){
    this.mappanel1 = map
    
    // @ViewChild('gmapwindow1', { static: true }) gMapElement2: ElementRef;
    // console.log(this.gMapElement2)
  }

  startMap2Window(map1,map2){
    // this.map1 = map1
    this.mappanel1 = map1
    this.map1 = map2
  }

  startMap4Window(map3,map4,map5, map6){
    this.mappanel2=map3;
    this.mappanel3= map4;
    this.mappanel4= map5;
    this.mappanel5= map6;

  }

  async addUploadedDataMapAnalysis(geoUrl, i, popupDiv,screen) {
    console.log(geoUrl);
    // this.init(this.gMapElement,this.gMapElement2, this.gMapCompareElement,this.gMapCompareElement2,this.gMapCompareElement3,this.gMapCompareElement4,this.gMapCompareElement5, this.loading)
    const data: any = await this.getGeoJsonFromPostgres(geoUrl.table);
    // const data = geoUrl.data;
    // const data = geoUrl;
    console.log(data);
    // const result = await Axios.get(geoUrl.data);
    // const data = result.data;
    // console.log(data);

    //////for graph///////
    const filteredFeatures = data.features.map(({ properties }: any) => {
      for (const key in properties) {
        if (properties[key] === null) {
          delete properties[key]
        }
      }
      return properties
    })
    // console.log(filteredFeatures,'filteredFeatures')
    this.selectedUploadData.push({ features: filteredFeatures, i })
    //////for graph///////

    if (data) {
      let fillColor, strokeColor;

      if (geoUrl.fillColor && !geoUrl.fillColor.includes('#')) {
        const hex = geoUrl.fillColor;
        // convert to RGB
        let c = hex.split('(')[1].split(')')[0].split(',');
        c.map((e, i) => {
          c[i] = +e;
          if (i == 3) {
            c[i] = Math.round(e * 255);
          }
        })
        fillColor = c;
      } else {
        // let arr =  [{ color: "#d7191c" }, { color: "#000000" }];
        // const tc = new Tiny(arr).rgb(10).map((c) => c.toHexString());
        // console.log(tc, `tccc 877890-0--0-00987674573498hfkdgjfoimk`);
        // fillColor=tc;
        fillColor = [0, 228, 255, 255];
      }

      if (geoUrl.strokeColor && !geoUrl.strokeColor.includes('#')) {
        const hex2 = geoUrl.strokeColor;
        // convert to RGB
        let b = hex2.split('(')[1].split(')')[0].split(',');
        b.map((e, i) => {
          b[i] = +e;
          if (i == 3) {
            b[i] = Math.round(e * 255);
          }
        })
        strokeColor = b;
      } else {
        // strokeColor = [200, 0, 250, 255];
        strokeColor = [252, 0, 0];
      }
      // console.log(d.properties.height);

      // if (this.deckOverlay.setMap(this.map))this.deckOverlay.setMap(null);
      console.log(fillColor);

      const hashmapData = new GoogleMapsOverlay({
        glOptions: {
          preserveDrawingBuffer: true
        },
        layers: [
          new GeoJsonLayer({
            id: "json",
            data: data,
            filled: true,
            pointRadiusMinPixels: 2,
            pointRadiusMaxPixels: 200,
            opacity: 0.9,
            pointRadiusScale: 0.3,
            getRadius: (f: Feature) => Math.pow(550, f.properties.mag),
            getElevation: d => d.properties.height ? d.properties.height : 10,
            // getFillColor: [255, 70, 30, 180],
            // getElevation: d=> (geoUrl.scale?geoUrl.scale:1)*((geoUrl.selectedAttribute)?d.properties[geoUrl.selectedAttribute]:(d.properties && d.properties.height)?d.properties.height: geoUrl.height?geoUrl.height:0),
            getFillColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : fillColor,
            getLineColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : strokeColor,
            // getElevation: geoUrl.height?geoUrl.height:0.2,
            getLineWidth: geoUrl.strokeWidth ? geoUrl.strokeWidth : 0.2,
            lineWidthMinPixels: geoUrl.strokeWidth ? geoUrl.strokeWidth : 0.2,
            // pointRadiusMinPixels:geoUrl.unit?geoUrl.unit:2,
            // getFillColor: [224, 8, 199, 255],
            autoHighlight: true,
            // highlightColor: #RRGGBB,
            highlightColor: [0, 128, 255, 120],
            // highlightColor: [0,0,255,1],
            pickable: true,
            preserveDrawingBuffer: true,
            transitions: {
              getRadius: {
                type: "spring",
                stiffness: 0.1,
                damping: 0.15,
                enter: () => [8], // grow from size 0,
                duration: 10000,
              },
            },
            onHover: info => setTooltip2(info.object, info.x, info.y),
            // onClick: info => showInfo(info)
          }),
        ],
      });
      // console.log(this.map);
      this.deckOverlayV2.set(i, hashmapData)
      // this.deckOverlayV2.get(i)?.setMap(this.map)
      // this.deckOverlayV2.get(i)?.setMap(this.map1)
      if(screen === 'two_window1'){
        this.deckOverlayV2.get(i)?.setMap(this.mappanel1)
      }else if(screen === 'two_window2'){
        this.deckOverlayV2.get(i)?.setMap(this.map1)
      }else if(screen === 'window1'){ 
        this.deckOverlayV2.get(i)?.setMap(this.mappanel2)
      }else if(screen === 'window2'){
        this.deckOverlayV2.get(i)?.setMap(this.mappanel3)
      }else if(screen === 'window3'){
        this.deckOverlayV2.get(i)?.setMap(this.mappanel4)
      }else if(screen === 'window4'){
        this.deckOverlayV2.get(i)?.setMap(this.mappanel5)
      }
      
      // this.deckOverlayV2.get(i)?.setMap(this.mappanel2)
      // this.deckOverlayV2.get(i)?.setMap(this.mappanel3)
      // this.deckOverlayV2.get(i)?.setMap(this.mappanel4)
      // this.deckOverlayV2.get(i)?.setMap(this.mappanel5)
      
      // this.deckOverlay.setMap(this.map);
      // this.map.data.loadGeoJson(`json`+ i); 


      // let showInfo = (e) => {
      //   console.log("ein show info", e);
      //   console.log(e.object.properties.file_path);
      //   let img = JSON.stringify(e.object.properties.file_path);
      //   console.log(img);

      //   // var layericon
      //   // var layericon1
      //   // let immageee = "C-" + e.object.properties.file_name
      //   // let iconArr = []
      //   // let iconArr1 = []

      //   data.features.find((o, i) => {
      //     if (o.properties.file_path == e.object.properties.file_path) {
      //       const main = document.getElementById("mainI");
      //       main.innerHTML = "";
      //       let img2 = document.createElement('img');
      //       img2.setAttribute('src', o.properties.file_path);
      //       img2.setAttribute("id", "image")
      //       img2.style.width = "450px";
      //       img2.style.height = "415px";
      //       img2.style.right = "2px";
      //       img2.style.bottom = "81px";
      //       img2.style.position = "absolute";
      //       img2.style.zIndex = "10000";
      //       img2.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
      //       img2.style.border = "2px solid #b7abed";
      //       img2.style.color = "white";
      //       main.appendChild(img2);
      //       console.log("main", main);

      //     }


      //   })
      // }

      let setTooltip2 = (object, x, y) => {
        console.log(455, 'hover');

        if (object) {
          popupDiv.innerHTML = '';
          let keys = Object.keys(object.properties);
          keys.map(e => {
            popupDiv.innerHTML += `${e} :  ${object.properties[e]} <br>`;
          })
          popupDiv.style.display = 'block';
          popupDiv.style.left = x + 'px';
          popupDiv.style.top = y + 'px';
          popupDiv.style.padding = "15px";
          popupDiv.style.backgroundColor = "#eeeeee";
          popupDiv.style.border = "1px solid #000"
        } else {
          popupDiv.style.display = 'none';
        }
      }

      console.log(data);
      const boundingbox = bbox(data);
      const valid = boundingbox.every(val => Number.isFinite(val));
      if (valid) {
        const sw = {
          lat: boundingbox[1],
          lng: boundingbox[0]
        }
        const ne = {
          lat: boundingbox[3],
          lng: boundingbox[2]
        }
        console.log(sw, ne);
        console.log(this.mappanel1);
        if(screen === 'two_window1'){
          this.mappanel1.setZoom(12);
        this.mappanel1.fitBounds(new google.maps.LatLngBounds(sw, ne));
        }else if(screen === 'two_window2'){
          this.map1.setZoom(12);
        this.map1.fitBounds(new google.maps.LatLngBounds(sw, ne));
        }else if(screen === 'window1'){ 
          this.mappanel2.setZoom(12);
        this.mappanel2.fitBounds(new google.maps.LatLngBounds(sw, ne));
        }else if(screen === 'window2'){
          this.mappanel3.setZoom(12);
          this.mappanel3.fitBounds(new google.maps.LatLngBounds(sw, ne));
        }else if(screen === 'window3'){
          this.mappanel4.setZoom(12);
          this.mappanel4.fitBounds(new google.maps.LatLngBounds(sw, ne));
        }else if(screen === 'window4'){
          this.mappanel5.setZoom(12);
          this.mappanel5.fitBounds(new google.maps.LatLngBounds(sw, ne));
        }
        
        console.log(43534);

      }
      this.spinner.emit('loader', false);

      // // this.loaded.next(101);
    }
  }

  /////////************************************uploaded data feature****************************************** */

  async addUploadedData(geoUrl, i, popupDiv) {
    console.log(geoUrl);
    const data: any = await this.getGeoJsonFromPostgres(geoUrl.table);
    // const data = geoUrl.data;
    // const data = geoUrl;
    console.log(data);
    // const result = await Axios.get(geoUrl.data);
    // const data = result.data;
    // console.log(data);

    //////for graph///////
    const filteredFeatures = data.features.map(({ properties }: any) => {
      for (const key in properties) {
        if (properties[key] === null) {
          delete properties[key]
        }
      }
      return properties
    })
    console.log(filteredFeatures,'filteredFeatures')
    this.selectedUploadData.push({ features: filteredFeatures, i })
    //////for graph///////

    if (data) {
      let fillColor, strokeColor;

      if (geoUrl.fillColor && !geoUrl.fillColor.includes('#')) {
        const hex = geoUrl.fillColor;
        // convert to RGB
        let c = hex.split('(')[1].split(')')[0].split(',');
        c.map((e, i) => {
          c[i] = +e;
          if (i == 3) {
            c[i] = Math.round(e * 255);
          }
        })
        fillColor = c;
      } else {
        // let arr =  [{ color: "#d7191c" }, { color: "#000000" }];
        // const tc = new Tiny(arr).rgb(10).map((c) => c.toHexString());
        // console.log(tc, `tccc 877890-0--0-00987674573498hfkdgjfoimk`);
        // fillColor=tc;
        fillColor = [0, 228, 255, 255];
      }

      if (geoUrl.strokeColor && !geoUrl.strokeColor.includes('#')) {
        const hex2 = geoUrl.strokeColor;
        // convert to RGB
        let b = hex2.split('(')[1].split(')')[0].split(',');
        b.map((e, i) => {
          b[i] = +e;
          if (i == 3) {
            b[i] = Math.round(e * 255);
          }
        })
        strokeColor = b;
      } else {
        // strokeColor = [200, 0, 250, 255];
        strokeColor = [252, 0, 0];
      }
      // console.log(d.properties.height);

      // if (this.deckOverlay.setMap(this.map))this.deckOverlay.setMap(null);
      console.log(fillColor);

      const hashmapData = new GoogleMapsOverlay({
        glOptions: {
          preserveDrawingBuffer: true
        },
        layers: [
          new GeoJsonLayer({
            id: "json",
            data: data,
            filled: true,
            pointRadiusMinPixels: 2,
            pointRadiusMaxPixels: 200,
            opacity: 0.9,
            pointRadiusScale: 0.3,
            getRadius: (f: Feature) => Math.pow(550, f.properties.mag),
            getElevation: d => d.properties.height ? d.properties.height : 10,
            // getFillColor: [255, 70, 30, 180],
            // getElevation: d=> (geoUrl.scale?geoUrl.scale:1)*((geoUrl.selectedAttribute)?d.properties[geoUrl.selectedAttribute]:(d.properties && d.properties.height)?d.properties.height: geoUrl.height?geoUrl.height:0),
            getFillColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : fillColor,
            getLineColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : strokeColor,
            // getElevation: geoUrl.height?geoUrl.height:0.2,
            getLineWidth: geoUrl.strokeWidth ? geoUrl.strokeWidth : 0.2,
            lineWidthMinPixels: geoUrl.strokeWidth ? geoUrl.strokeWidth : 0.2,
            // pointRadiusMinPixels:geoUrl.unit?geoUrl.unit:2,
            // getFillColor: [224, 8, 199, 255],
            autoHighlight: true,
            // highlightColor: #RRGGBB,
            highlightColor: [0, 128, 255, 120],
            // highlightColor: [0,0,255,1],
            pickable: true,
            preserveDrawingBuffer: true,
            transitions: {
              getRadius: {
                type: "spring",
                stiffness: 0.1,
                damping: 0.15,
                enter: () => [8], // grow from size 0,
                duration: 10000,
              },
            },
            onHover: info => setTooltip2(info.object, info.x, info.y),
            // onClick: info => showInfo(info)
          }),
        ],
      });
      console.log(this.map);
      this.deckOverlayV2.set(i, hashmapData)
      this.deckOverlayV2.get(i)?.setMap(this.map)

      // this.deckOverlay.setMap(this.map);
      // this.map.data.loadGeoJson(`json`+ i); 


      // let showInfo = (e) => {
      //   console.log("ein show info", e);
      //   console.log(e.object.properties.file_path);
      //   let img = JSON.stringify(e.object.properties.file_path);
      //   console.log(img);

      //   // var layericon
      //   // var layericon1
      //   // let immageee = "C-" + e.object.properties.file_name
      //   // let iconArr = []
      //   // let iconArr1 = []

      //   data.features.find((o, i) => {
      //     if (o.properties.file_path == e.object.properties.file_path) {
      //       const main = document.getElementById("mainI");
      //       main.innerHTML = "";
      //       let img2 = document.createElement('img');
      //       img2.setAttribute('src', o.properties.file_path);
      //       img2.setAttribute("id", "image")
      //       img2.style.width = "450px";
      //       img2.style.height = "415px";
      //       img2.style.right = "2px";
      //       img2.style.bottom = "81px";
      //       img2.style.position = "absolute";
      //       img2.style.zIndex = "10000";
      //       img2.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
      //       img2.style.border = "2px solid #b7abed";
      //       img2.style.color = "white";
      //       main.appendChild(img2);
      //       console.log("main", main);

      //     }


      //   })
      // }

      let setTooltip2 = (object, x, y) => {
        console.log(455, 'hover');

        if (object) {
          popupDiv.innerHTML = '';
          let keys = Object.keys(object.properties);
          keys.map(e => {
            popupDiv.innerHTML += `${e} :  ${object.properties[e]} <br>`;
          })
          popupDiv.style.display = 'block';
          popupDiv.style.left = x + 'px';
          popupDiv.style.top = y + 'px';
          popupDiv.style.padding = "15px";
          popupDiv.style.backgroundColor = "#eeeeee";
          popupDiv.style.border = "1px solid #000"
        } else {
          popupDiv.style.display = 'none';
        }
      }

      console.log(data);
      const boundingbox = bbox(data);
      const valid = boundingbox.every(val => Number.isFinite(val));
      if (valid) {
        const sw = {
          lat: boundingbox[1],
          lng: boundingbox[0]
        }
        const ne = {
          lat: boundingbox[3],
          lng: boundingbox[2]
        }
        console.log(sw, ne);
        console.log(this.map);
        this.map.setZoom(12);
        this.map.fitBounds(new google.maps.LatLngBounds(sw, ne));
        console.log(43534);

      }
      this.spinner.emit('loader', false);

      // // this.loaded.next(101);
    }
  }

  async addUploadedDataKml(geoUrl, i, popupDiv,name) {
    console.log(geoUrl.features[0].geometry.coordinates,'urls');
    // const data = geoUrl.data;
    var line = turf.lineString(geoUrl.features[0].geometry.coordinates);
    var measurement = turf.length(line, {units: 'kilometers'});
    console.log(measurement,'measurement')
    geoUrl.features[0].properties['Measurement (in KM)'] = measurement.toFixed(2)
    const data = geoUrl
    // console.log(data);
    // const result = await Axios.get(geoUrl.data);
    // const data = result.data;
    // console.log(data);

    //////for graph///////
    const filteredFeatures = data.features.map(({ properties }: any) => {
      for (const key in properties) {
        if (properties[key] === null) {
          delete properties[key]
        }
      }
      return properties
    })
    this.selectedUploadData.push({ features: filteredFeatures, i })
    //////for graph///////

    if (data) {
      let fillColor, strokeColor;

      if (geoUrl.fillColor && !geoUrl.fillColor.includes('#')) {
        const hex = geoUrl.fillColor;
        // convert to RGB
        let c = hex.split('(')[1].split(')')[0].split(',');
        c.map((e, i) => {
          c[i] = +e;
          if (i == 3) {
            c[i] = Math.round(e * 255);
          }
        })
        fillColor = c;
      } else {
        // let arr =  [{ color: "#d7191c" }, { color: "#000000" }];
        // const tc = new Tiny(arr).rgb(10).map((c) => c.toHexString());
        // console.log(tc, `tccc 877890-0--0-00987674573498hfkdgjfoimk`);
        // fillColor=tc;
        fillColor = [0, 228, 255, 255];
      }

      if (geoUrl.strokeColor && !geoUrl.strokeColor.includes('#')) {
        const hex2 = geoUrl.strokeColor;
        // convert to RGB
        let b = hex2.split('(')[1].split(')')[0].split(',');
        b.map((e, i) => {
          b[i] = +e;
          if (i == 3) {
            b[i] = Math.round(e * 255);
          }
        })
        strokeColor = b;
      } else {
        // strokeColor = [200, 0, 250, 255];
        strokeColor = [252, 0, 0];
      }
      // console.log(d.properties.height);

      // if (this.deckOverlay.setMap(this.map))this.deckOverlay.setMap(null);
      console.log(fillColor);

      const hashmapData = new GoogleMapsOverlay({
        glOptions: {
          preserveDrawingBuffer: true
        },
        layers: [
          new GeoJsonLayer({
            id: "json",
            data: data,
            filled: true,
            pointRadiusMinPixels: 2,
            pointRadiusMaxPixels: 200,
            opacity: 0.9,
            pointRadiusScale: 0.3,
            getRadius: (f: Feature) => Math.pow(550, f.properties.mag),
            getElevation: d => d.properties.height ? d.properties.height : 10,
            // getFillColor: [255, 70, 30, 180],
            // getElevation: d=> (geoUrl.scale?geoUrl.scale:1)*((geoUrl.selectedAttribute)?d.properties[geoUrl.selectedAttribute]:(d.properties && d.properties.height)?d.properties.height: geoUrl.height?geoUrl.height:0),
            getFillColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : fillColor,
            getLineColor: d => (name.split('_')[0] === 'Achieved') ? [0,0,252] : [252,0,0],
            // getElevation: geoUrl.height?geoUrl.height:0.2,
            getLineWidth: geoUrl.strokeWidth ? geoUrl.strokeWidth : 2,
            lineWidthMinPixels: geoUrl.strokeWidth ? geoUrl.strokeWidth : 2,
            // pointRadiusMinPixels:geoUrl.unit?geoUrl.unit:2,
            // getFillColor: [224, 8, 199, 255],
            autoHighlight: true,
            // highlightColor: #RRGGBB,
            highlightColor: [0, 128, 255, 120],
            // highlightColor: [0,0,255,1],
            pickable: true,
            preserveDrawingBuffer: true,
            transitions: {
              getRadius: {
                type: "spring",
                stiffness: 0.1,
                damping: 0.15,
                enter: () => [8], // grow from size 0,
                duration: 10000,
              },
            },
            onHover: info => setTooltip2(info.object, info.x, info.y),
            // onClick: info => showInfo(info)
          }),
        ],
      });
      console.log(this.map);
      this.deckOverlayV2.set(i, hashmapData)
      this.deckOverlayV2.get(i)?.setMap(this.map)
      // this.deckOverlay.setMap(this.map);
      // this.map.data.loadGeoJson(`json`+ i); 


      // let showInfo = (e) => {
      //   console.log("ein show info", e);
      //   console.log(e.object.properties.file_path);
      //   let img = JSON.stringify(e.object.properties.file_path);
      //   console.log(img);

      //   // var layericon
      //   // var layericon1
      //   // let immageee = "C-" + e.object.properties.file_name
      //   // let iconArr = []
      //   // let iconArr1 = []

      //   data.features.find((o, i) => {
      //     if (o.properties.file_path == e.object.properties.file_path) {
      //       const main = document.getElementById("mainI");
      //       main.innerHTML = "";
      //       let img2 = document.createElement('img');
      //       img2.setAttribute('src', o.properties.file_path);
      //       img2.setAttribute("id", "image")
      //       img2.style.width = "450px";
      //       img2.style.height = "415px";
      //       img2.style.right = "2px";
      //       img2.style.bottom = "81px";
      //       img2.style.position = "absolute";
      //       img2.style.zIndex = "10000";
      //       img2.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
      //       img2.style.border = "2px solid #b7abed";
      //       img2.style.color = "white";
      //       main.appendChild(img2);
      //       console.log("main", main);

      //     }


      //   })
      // }

      let setTooltip2 = (object, x, y) => {
        console.log(455, 'hover',object);
        if(object){
          var line = turf.lineString(object.geometry.coordinates);
          var measurement = turf.length(line, {units: 'kilometers'});
          console.log(measurement,'measurement')
          object.properties['Measurement (in KM)'] = measurement.toFixed(2)
        }
        
        if (object) {
          popupDiv.innerHTML = '';
          let keys = Object.keys(object.properties);
          keys.map(e => {
            popupDiv.innerHTML += `${e} :  ${object.properties[e]} <br>`;
          })
          popupDiv.style.display = 'block';
          popupDiv.style.left = x + 'px';
          popupDiv.style.top = y + 'px';
          popupDiv.style.padding = "15px";
          popupDiv.style.backgroundColor = "#eeeeee";
          popupDiv.style.border = "1px solid #000"
        } else {
          popupDiv.style.display = 'none';
        }
      }

      console.log(data);
      const boundingbox = bbox(data);
      const valid = boundingbox.every(val => Number.isFinite(val));
      if (valid) {
        const sw = {
          lat: boundingbox[1],
          lng: boundingbox[0]
        }
        const ne = {
          lat: boundingbox[3],
          lng: boundingbox[2]
        }
        console.log(sw, ne);
        console.log(this.map);
        this.map.setZoom(12);
        this.map.fitBounds(new google.maps.LatLngBounds(sw, ne));
        console.log(43534);

      }
      this.spinner.emit('loader', false);

      // // this.loaded.next(101);
    }
  }


  removeUploadedLayer(i) {
    console.log(i);
    console.log(this.map.data);
    // this.deckOverlay.setMap(null);
    this.deckOverlayV2.get(i)?.setMap(null)
    this.deckOverlayV2.delete(i)
    const index = this.selectedUploadData.findIndex((data: any) => data.i === i);
    if (index > -1) {
      this.selectedUploadData.splice(index, 1);
    }

  }

  //////////////////////////////////////////////show pan data//////////////////////////////////////////////////////
  rotateCamera([camera, renderer, scene, addPanPostionChange], sprite) {
    const { x, y, z } = sprite.position;

    addPanPostionChange.x = x
    addPanPostionChange.y = y
    addPanPostionChange.z = z
    console.log(x, y, z)
    addPanPostionChange.isUserMoving = false;

    this.panaromaUpdate();

    //renderer.render( scene, camera );
  }

  
setPolyLine(coordinates){
  const image360polyLineCurrentSet = new google.maps.Polyline({
    path: coordinates.map(([long, lat])=>new google.maps.LatLng(lat, long)),
    geodesic: true,
    strokeColor: "#03A9F4",
    strokeOpacity: 0.8,
    strokeWeight: 8,
  });
  this.image360polyLineCurrentSet.push(image360polyLineCurrentSet);
  image360polyLineCurrentSet.setMap(this.map);

  return image360polyLineCurrentSet;
}

highlightPoint({lat, lng}){
  const image360pointCurrentSet = new google.maps.Marker({
    position: new google.maps.LatLng(lat, lng),
    map: this.map,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "#03A9F4",
      fillOpacity: 0.8,
      strokeWeight: 0,
      scale: 10
    }
  });
  this.image360pointCurrentSet.push(image360pointCurrentSet);
  return image360pointCurrentSet;

}
removeHighlightPoint(){
  this.image360pointCurrentSet.forEach(
    (image360pointCurrentSet)=>image360pointCurrentSet.setMap(null));

}

removePolyLine(){
  this.image360polyLineCurrentSet.forEach(
    (image360polyLineCurrentSet)=>image360polyLineCurrentSet.setMap(null));

}

removePrevPanHighlighter(){
  const element = document.getElementById(`img${this.panImageIndex}`);
  element.style.removeProperty('filter');
}

openNextPanaroma(seekBy){
  this.removePrevPanHighlighter();
  const element = document.getElementById(`img${this.panImageIndex + (seekBy)}`);
  if(element){
    element.style['filter'] = 'blur(2px)';
    this.removePrevPanHighlighter();
    element.click();
  }
}

  async showPanDom(g, i, screen) {  
    // console.log(screen)
    // this.imageArray = []
    this.imagess = []
    console.log(g);
    const result = await Axios.get(g);
    const data = result.data;
    data.features.forEach((img)=>{
      this.image360Coordinates.push(img.geometry);
      this.imagess.push(img.properties.file_path)
    });
    
    
    
    
    // for (let i = 0; i < data.features.length; i++) {
    //   var image = data.features[i].properties.file_path;
    //   this.imageArray.push(image);
    // }
    // let cont
    let cont1, cont2, cont3, cont4, cont5, cont6
    if (screen == 'window') {
      this.imageArray1=[]
      for (let i = 0; i < data.features.length; i++) {
        var image = data.features[i].properties.file_path;
        this.imageArray1.push(image);
      }
      cont1 = document.getElementById('InspPanImg');
      cont1.innerHTML = '';
    }
     else if (screen == 'window1' || screen == 'two_window1') {  
      this.imageArray1=[]
      for (let i = 0; i < data.features.length; i++) {
        var image = data.features[i].properties.file_path;
        this.imageArray1.push(image);
      }
      if(screen == 'two_window1'){
        cont1 = document.getElementById('InspPanImg1');
        console.log(cont1,"cont1")
        cont1.innerHTML = '';
      }
      if(screen == 'window1'){
        cont1 = document.getElementById('InspPanImg11');
        console.log(cont1,"cont1")
        cont1.innerHTML = '';
      }

    } else if (screen == 'window2' || screen == 'two_window2') {
      this.imageArray2=[]
      for (let i = 0; i < data.features.length; i++) {
        var image = data.features[i].properties.file_path;
        this.imageArray2.push(image);
      }
      if(screen == 'two_window2'){
        cont2 = document.getElementById('InspPanImg2');
        console.log(cont2,"cont2")
        cont2.innerHTML = '';
      }
      if (screen == 'window2'){
        cont2 = document.getElementById('InspPanImg22');
        console.log(cont2,"cont2")
        cont2.innerHTML = '';
      }
    } 
    else if (screen == 'window3') {
    
      this.imageArray3 = []
      for (let i = 0; i < data.features.length; i++) {
        var image = data.features[i].properties.file_path;
        this.imageArray3.push(image);
      }
      cont3 = document.getElementById('InspPanImg3');
      cont3.innerHTML = '';
    } 
    else if (screen == 'window4') {
      this.imageArray4=[]
      for (let i = 0; i < data.features.length; i++) {
        var image = data.features[i].properties.file_path;
        this.imageArray4.push(image);
      }
      cont4 = document.getElementById('InspPanImg4');
      cont4.innerHTML = '';
    } 




    // let cont = document.getElementById('InspPanImg');


    if (this.imageArray1.length > 0) {
      if (screen == 'window') {
        this.imageArray1.map((i, k) => {
          console.log(k)
          let img = document.createElement('img');
          img.id = 'img' + k + '-' + screen
          img.setAttribute('width', "100%");
          img.setAttribute('src', i);
          cont1.appendChild(img);
          img.style.cursor = 'pointer';
          img.style.margin = "2px";
          img.style.display = 'none'
          img.addEventListener("mouseover", onHover);
          img.addEventListener("mouseleave", onHide);
          img.addEventListener("load", onload);
          function onload() {
            img.style.display = 'block';
          }
          function onHover() {
            img.style.border = '2px solid #03A9F4';
          }
          function onHide() {
            img.style.border = 'none';
          }
          // img.addEventListener("mouseover", mouseenter);
          img.addEventListener("click", mouseenter)


        })
      } 
     else if (screen == 'window1' || screen == 'two_window1') {
        this.imageArray1.map((i, k) => {
          console.log(k)
          let img = document.createElement('img');
          img.id = 'img' + k + '-' + screen
          img.setAttribute('width', "100%");
          img.setAttribute('src', i);
          cont1.appendChild(img);
          img.style.cursor = 'pointer';
          img.style.margin = "2px";
          img.style.display = 'none'
          img.addEventListener("mouseover", onHover);
          img.addEventListener("mouseleave", onHide);
          img.addEventListener("load", onload);
          function onload() {
            img.style.display = 'block';
          }
          function onHover() {
            img.style.border = '2px solid #03A9F4';
          }
          function onHide() {
            img.style.border = 'none';
          }
          // img.addEventListener("mouseover", mouseenter);
          img.addEventListener("click", mouseenter)


        })
      } else if (screen == 'window2' || screen == 'two_window2') {
        this.imageArray2.map((i, k) => {
          console.log(k)
          let img = document.createElement('img');
          img.id = 'img' + k + '-' + screen
          img.setAttribute('width', "100%");
          img.setAttribute('src', i);
          cont2.appendChild(img);
          img.style.cursor = 'pointer';
          img.style.margin = "2px";
          img.style.display = 'none'
          img.addEventListener("mouseover", onHover);
          img.addEventListener("mouseleave", onHide);
          img.addEventListener("load", onload);
          function onload() {
            img.style.display = 'block';
          }
          function onHover() {
            img.style.border = '2px solid #03A9F4';
          }
          function onHide() {
            img.style.border = 'none';
          }
          // img.addEventListener("mouseover", mouseenter);
          img.addEventListener("click", mouseenter)

        })
      } else if (screen == 'window3') {
        this.imageArray3.map((i, k) => {
          console.log(k)
          let img = document.createElement('img');
          img.id = 'img' + k + '-' + screen
          img.setAttribute('width', "100%");
          img.setAttribute('src', i);
          cont3.appendChild(img);
          img.style.cursor = 'pointer';
          img.style.margin = "2px";
          img.style.display = 'none'
          img.addEventListener("mouseover", onHover);
          img.addEventListener("mouseleave", onHide);
          img.addEventListener("load", onload);
          function onload() {
            img.style.display = 'block';
          }
          function onHover() {
            img.style.border = '2px solid #03A9F4';
          }
          function onHide() {
            img.style.border = 'none';
          }
          // img.addEventListener("mouseover", mouseenter);
          img.addEventListener("click", mouseenter)

        })
      }
      else if (screen == 'window4') {
        this.imageArray4.map((i, k) => {
          console.log(k)
          let img = document.createElement('img');
          img.id = 'img' + k + '-' + screen
          img.setAttribute('width', "100%");
          img.setAttribute('src', i);
          cont4.appendChild(img);
          img.style.cursor = 'pointer';
          img.style.margin = "2px";
          img.style.display = 'none'
          img.addEventListener("mouseover", onHover);
          img.addEventListener("mouseleave", onHide);
          img.addEventListener("load", onload);
          function onload() {
            img.style.display = 'block';
          }
          function onHover() {
            img.style.border = '2px solid #03A9F4';
          }
          function onHide() {
            img.style.border = 'none';
          }
          // img.addEventListener("mouseover", mouseenter);
          img.addEventListener("click", mouseenter)

        })
      }


      var img, img2, img3, img4, img5, lens, res, cx, cy, context, canvas, eventSrc;




      var camera, scene, renderer;
      var addPanPostionChange = { x: 0, y: 0, z: 0, isUserMoving: true }

      var isUserInteracting = false,
        onMouseDownMouseX = 0, onMouseDownMouseY = 0,
        lon = 0, onMouseDownLon = 0,
        lat = 0, onMouseDownLat = 0,
        phi = 0, theta = 0;
      var onPointerDownPointerX, onPointerDownPointerY, onPointerDownLon, onPointerDownLat;
      const dialog = this.dialog;
      let spritesData = (empty = false): Array<{
        name?: string,
        content: string,
        pos: THREE.Vector3,
        sprite: THREE.Sprite,
      }> => {
        if (empty) {
          this.spritesData = [];
        }
        return this.spritesData
      };
      let panhoverContainerRef: ViewContainerRef = this.panhovergisContainerRef;
      let factory: ComponentFactory<PanhoverTaggingComponent>;
      let componentFactoryResolver = this.componentFactoryResolver;
      let addPanTag = (data) => this.addPanTag(data);
      const panIndex = i;
      const imageArray = this.imageArray;
      const panTags = () => this.projectData.panTag;
      factory = componentFactoryResolver.resolveComponentFactory(PanhoverTaggingComponent);
      let panhoverComponents = () => this.panhoverComponents;
      let panHoverIndex = (x, y, z, content) => this.panhoverComponents.findIndex(
        ({ instance }) => instance.data.x == x && instance.data.y == y && instance.data.z == z && instance.data.content == content);
      const upadatepanGisExpPanel = ({ panGisExpPanel, panIndex, panImageIndex }) => {
        this.panGisExpPanelWithPanIndex = { panGisExpPanel, panIndex, panImageIndex };
        console.log(panGisExpPanel, panIndex, panImageIndex,'show')
      }
      
      let prevPanIndex = null;
      let panIndexEventAdded = []
      let panIndexEventIndex = new Set()
      const getpanGisExpPanel = () => this.panGisExpPanelWithPanIndex;
      const panaromaCameraValue = (val) => this.paranomaCameraValue.next(val)
      const panaromaUpdate = (fn) => this.panaromaUpdate = fn;
      const mouseenterFn = (fn) => this.mouseenterFn = fn;
      const getMouserEnterFn = () => this.mouseenterFn;
      const createInfoset = (fn) => this.createInfofn = fn;
      const getInfoset = () => this.createInfofn;
      const removeHighlightCoordinate = () => {
        if(this.image360polyLineCurrentSet.length > 0){
          this.removePolyLine();
        }
        if(this.image360pointCurrentSet.length > 0){
          this.removeHighlightPoint();
        }
      }
      const setPolyLine = (coordinates) => this.setPolyLine(coordinates);
      const highlightPoint = ({lat, lng}) => this.highlightPoint({lat, lng});
      const image360Coordinates = () => this.image360Coordinates;
      const setPanImageIndex = (index) => this.panImageIndex = index;
      let addAnimationInst = []
      let initExec = () => this.initExec;
      let setinitExec = (val) => this.initExec = val
      async function mouseenter(e) {
        console.log(e.srcElement.id)
        let currentScreen = e.srcElement.id.split('-')[1]
        console.log(currentScreen,"currentScreen")
        !getMouserEnterFn() && mouseenterFn(mouseenter);
        panaromaUpdate(animate)
        !getInfoset() && createInfoset(createInfo);
        const raycaster = new THREE.Raycaster();
        console.log(imageArray, '====>')
        let panImageIndex = imageArray.findIndex((value)=> encodeURI(value) == e.srcElement.currentSrc);
        setPanImageIndex(panImageIndex);
        console.log(panImageIndex,'panimage')
        removeHighlightCoordinate();
        const coordinates = image360Coordinates();
        console.log(coordinates,"coordinates")
        if(coordinates[panImageIndex].type == "LineString"){
          setPolyLine(coordinates[panImageIndex].coordinates);
        }
      //   if (panImageIndex >= 0 && panImageIndex < coordinates.length) {
      //     if (coordinates[panImageIndex].type == "LineString") {
      //         setPolyLine(coordinates[panImageIndex].coordinates);
      //     }
      // } else {
      //     console.error("panImageIndex is out of bounds:", panImageIndex);
      // }
      
        if(coordinates[panImageIndex].type == "Point"){
          const [lng, lat] = coordinates[panImageIndex].coordinates;
          highlightPoint({lat, lng});
        }

        upadatepanGisExpPanel({ panGisExpPanel: true, panIndex, panImageIndex });

        ///////////////////////three js panorama ///////////////////////////////////////////////////

        // var camera, scene, renderer;

        // var isUserInteracting = false,
        // onMouseDownMouseX = 0, onMouseDownMouseY = 0,
        // lon = 0, onMouseDownLon = 0,
        // lat = 0, onMouseDownLat = 0,
        // phi = 0, theta = 0;
        // var onPointerDownPointerX, onPointerDownPointerY, onPointerDownLon, onPointerDownLat;

        init(currentScreen);
        animate();
        spritesData(true)
        panTags().forEach((data) => {
          if (data.panImageIndex ===getpanGisExpPanel(). panImageIndex && data.panIndex === getpanGisExpPanel(). panIndex) {
            let vector = new THREE.Vector3(data.pos.x, data.pos.y, data.pos.z);
            let { x, y } = projectVector(vector);
            panTagOnPan({ name: data.name, content: data.content, ...data.eventClient }, data.pos, { x, y }, getpanGisExpPanel(). panImageIndex);
            return;
          }
        })

        function resize(container: ElementRef<HTMLDivElement>) {
          if (this.fetched) {
            camera.aspect = container.nativeElement.clientWidth / container.nativeElement.clientHeight
            camera.updateProjectionMatrix()
            renderer.setSize(container.nativeElement.clientWidth, container.nativeElement.clientHeight)
          }
        }

        // Create Info
        function getIntersections(event) {
          let vector = new THREE.Vector2();
          vector.set(
            ((event.clientX) / event.target.clientWidth) * 2 - 3,
            - ((event.clientY) / event.target.clientHeight) * 2 + 1.1);
          raycaster.setFromCamera(vector, camera);
          const intersects = raycaster.intersectObjects(scene.children);
          return intersects;
        }

        function selectPan(event) {
          console.log(event)
          const canvas = renderer.domElement;
          let mouse = new THREE.Vector2();
          mouse.set(
            ((event.clientX) / event.target.clientWidth) * 2 - 3,
            - ((event.clientY) / event.target.clientHeight) * 2 + 1.1);
          raycaster.setFromCamera(mouse, camera);
          const sprites = spritesData() ? spritesData().map(data => data.sprite) : [];
          const intersects = raycaster.intersectObjects(sprites);
          console.log(raycaster, intersects, sprites)
          if (intersects.length === 1) {
            spritesData().forEach((element, index) => {
              if (element.sprite.id === intersects[0].object.id) {
                var uuid = intersects[0].object.uuid;
                const index = sprites.findIndex(val => {
                  return val.uuid === uuid;
                });
                var point = intersects[0].point;
                point.project(camera);
                point.x = ((0.5 + point.x / 2) * (canvas.width / window.devicePixelRatio)) + 400;
                point.y = ((0.5 - point.y / 2) * (canvas.height / window.devicePixelRatio));
                panhoverComponents()[index].instance.data.display = 'block';
                panhoverComponents()[index].instance.data.event.top = point.y + "px";
                panhoverComponents()[index].instance.data.event.left = point.x + "px";
              } else {
                panhoverComponents()[index].instance.data.display = 'none';
              }
            })
          } else {
            panhoverComponents().forEach(element => {
              if (element.instance.data) element.instance.data.display = 'none';
            });
          }
        }

        function roundRect(ctx, x, y, w, h, r) {
          ctx.beginPath();
          ctx.moveTo(x + r, y);
          ctx.lineTo(x + w - r, y);
          ctx.quadraticCurveTo(x + w, y, x + w, y + r);
          ctx.lineTo(x + w, y + h - r);
          ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
          ctx.lineTo(x + r, y + h);
          ctx.quadraticCurveTo(x, y + h, x, y + h - r);
          ctx.lineTo(x, y + r);
          ctx.quadraticCurveTo(x, y, x + r, y);
          ctx.closePath();
          ctx.fill();
          ctx.stroke();
        }

        function makeTextSprite(message, parameters) {
          if (parameters === undefined) parameters = {};

          const fontface = parameters.hasOwnProperty("fontface") ?
            parameters["fontface"] : "Arial";

          const fontsize = parameters.hasOwnProperty("fontsize") ?
            parameters["fontsize"] : 24;

          const borderThickness = parameters.hasOwnProperty("borderThickness") ?
            parameters["borderThickness"] : 4;

          const borderColor = parameters.hasOwnProperty("borderColor") ?
            parameters["borderColor"] : { r: 0, g: 0, b: 0, a: 1.0 };

          const backgroundColor = parameters.hasOwnProperty("backgroundColor") ?
            parameters["backgroundColor"] : { r: 255, g: 255, b: 255, a: 1.0 };

          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          context.font = "Bold " + fontsize + "px " + fontface;

          // get size data (height depends only on font size)
          const metrics = context.measureText(message);
          const textWidth = metrics.width;

          // background color
          context.fillStyle = "rgba(" + backgroundColor.r + "," + backgroundColor.g + ","
            + backgroundColor.b + "," + backgroundColor.a + ")";
          // border color
          context.strokeStyle = "rgba(" + borderColor.r + "," + borderColor.g + ","
            + borderColor.b + "," + borderColor.a + ")";

          context.lineWidth = borderThickness;
          roundRect(context, borderThickness / 2, borderThickness / 2, textWidth + borderThickness, fontsize * 1.4 + borderThickness, 6);
          // 1.4 is extra height factor for text below baseline: g,j,p,q.

          // text color
          context.fillStyle = "rgba(0, 0, 0, 1.0)";

          context.fillText(message, borderThickness, fontsize + borderThickness);

          // canvas contents will be used for a texture
          //0x080808
          const texture = new THREE.Texture(canvas);
          texture.needsUpdate = true;

          const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
          const sprite = new THREE.Sprite(spriteMaterial);
          sprite.renderOrder = 1;
          sprite.scale.set(70, 35, 1);
          return sprite;
        }

        function projectVector(vector) {
          vector.project(camera)
          let x = Math.round((0.5 + vector.x / 2) * (renderer.domElement.width / window.devicePixelRatio));
          let y = Math.round((0.5 - vector.y / 2) * (renderer.domElement.height / window.devicePixelRatio));
          return { x, y }
        }

        function panTagOnPan(val, point, { x, y }, panImageIndex, populatePanTag = true) {
          console.log(val)
          const sprite = makeTextSprite(` ${val.name} `, { fontsize: 65, borderColor: { r: 0, g: 0, b: 255, a: 1.0 }, backgroundColor: { r: 255, g: 255, b: 255, a: 1.0 } });
          scene.add(sprite);
          sprite.position.set(point.x, point.y, point.z);
          const data = {
            name: val.name,
            content: val.content,
            cameraPostion: val.cameraPostion,
            pos: point,
            sprite,
            panIndex: getpanGisExpPanel().panIndex,
            panImageIndex,
          };
          const spritePresent = spritesData()
            .findIndex((data) => data.pos.x == point.x && data.pos.y == point.y && data.pos.z == point.z && data.name == val.name
            );
          if (spritePresent == -1) {
            spritesData().push(data);
          }
          !populatePanTag && addPanTag({ tagData: data });
          let panhover = panhoverContainerRef.createComponent(factory);
          panhover.instance.data = {
            event: {
              top: y + 'px',
              left: x + 'px'
            },
            display: 'none',
            content: val.content,
            x: point.x,
            y: point.y,
            z: point.z
          };
          panHoverIndex(point.x, point.y, point.z, val.content) == -1 && panhoverComponents().push(panhover);
        }

        async function createInfo(event) {
          let intersects = getIntersections(event);
          let found: any
          intersects.forEach((element) => {
            Object.keys(element).forEach(function (key) {
              if (key == 'object') {
                Object.keys(element[key]).forEach(function (ele) {
                  if (element[key][ele] == 'Mesh') {
                    found = element;
                  }
                });
              }
            })
          })
          let vector = new THREE.Vector3(found.point.x, found.point.y, found.point.z);
          let { x, y } = projectVector(vector);
          let data = {};

          data['panImageIndex'] = getpanGisExpPanel().panImageIndex
          data['cameraPostion'] = camera.position;

          if (panIndexEventIndex.has(data['panImageIndex'])) {
            dialog.open(PanTaggingComponent, {
              data: data
            }).afterClosed().subscribe(val => {
              if (val) {
                panTagOnPan(val, found.point, { x, y }, val.panImageIndex, false);
              }
            })
          }
        }

        function init(screen) {
          console.log(screen)
          var container, mesh;
          container = document.getElementById("mainIPan" + screen);
          container.innerHTML = '';
          // container.style.display = "block";
          // container.style.width = "50%";
          // container.style.height = "80%";
          // container.style.right = "2px";
          // container.style.top = "64px";
          // container.style.position = "fixed";
          // container.style.zIndex = "10000";
          camera = new THREE.PerspectiveCamera(75, (window.innerWidth / 2) / window.innerHeight, 1, 1100);
          camera.target = new THREE.Vector3(0, 0, 0);

          scene = new THREE.Scene();

          var geometry = new THREE.SphereGeometry(500, 60, 40);
          geometry.scale(- 1, 1, 1);

          var material = new THREE.MeshBasicMaterial({
            map: new THREE.TextureLoader().load(e.target.currentSrc),
            depthWrite: false
          });

          mesh = new THREE.Mesh(geometry, material);

          scene.add(mesh);
          console.log(scene);
          // const controls = new OrbitControls(camera, container.nativeElement);
          // controls.target.set(42.03438149010981,438.899393535862,-234.7748943720854);
          // controls.enableZoom = false;
          // controls.update();

          //setControls(new OrbitControls(camera, container.nativeElement))
          ///getControls().target.set(0,0,0);
          //getControls().enableZoom = false;
          //console.log(getControls(),'iinital value')

          renderer = new THREE.WebGLRenderer({ antialias: true });
          renderer.setPixelRatio(window.devicePixelRatio);
          //renderer.setSize( (window.innerWidth), window.innerHeight );
          renderer.setSize((window.innerWidth / 2), window.innerHeight);
          console.log(renderer.domElement);
          container.appendChild(renderer.domElement);

          //controls.target = new THREE.Vector3( 100, 100, 200 )
          //controls.update()

          panaromaCameraValue([camera, renderer, scene, addPanPostionChange])

          container.addEventListener('mousedown', onDocumentMouseDown, false);
          container.addEventListener('mousemove', onDocumentMouseMove, false);
          container.addEventListener('mouseup', onDocumentMouseUp, false);
          container.addEventListener('wheel', onDocumentMouseWheel, false);
          if (!panIndexEventIndex.has(panImageIndex) && initExec() == 0) {
            !panIndexEventAdded.includes(panImageIndex) && container.addEventListener("dblclick", createInfo);
            panIndexEventIndex.delete(prevPanIndex);
            prevPanIndex = panImageIndex;
            panIndexEventIndex.add(panImageIndex);
            panIndexEventAdded.push(panImageIndex);
          }
          setinitExec(initExec() + 1)
          //

          // document.addEventListener( 'dragover', function ( event ) {

          //   event.preventDefault();
          //   event.dataTransfer.dropEffect = 'copy';

          // }, false );

          // document.addEventListener( 'dragenter', function ( event ) {

          //   document.body.style.opacity = 0.5;

          // }, false );

          // document.addEventListener( 'dragleave', function ( event ) {

          //   document.body.style.opacity = 1;

          // }, false );

          document.addEventListener('drop', function (event) {

            event.preventDefault();

            var reader = new FileReader();
            reader.addEventListener('load', function (event) {

              material.map.image.src = event.target.result;
              material.map.needsUpdate = true;

            }, false);
            reader.readAsDataURL(event.dataTransfer.files[0]);

            // document.body.style.opacity = 1;

          }, false);

          //

          window.addEventListener('resize', onWindowResize, false);

        }

        function onWindowResize() {

          camera.aspect = window.innerWidth / window.innerHeight;
          camera.updateProjectionMatrix();

          // renderer.setSize( window.innerWidth, window.innerHeight );
          renderer.setSize((window.innerWidth / 2), window.innerHeight);

        }

        function onDocumentMouseDown(event) {

          event.preventDefault();

          isUserInteracting = true;

          onPointerDownPointerX = event.clientX;
          onPointerDownPointerY = event.clientY;

          onPointerDownLon = lon;
          onPointerDownLat = lat;

        }

        function onDocumentMouseMove(event) {

          if (isUserInteracting === true) {

            lon = (onPointerDownPointerX - event.clientX) * 0.1 + onPointerDownLon;
            lat = (event.clientY - onPointerDownPointerY) * 0.1 + onPointerDownLat;

          }
          selectPan(event)
        }

        function onDocumentMouseUp(event) {

          isUserInteracting = false;
          addPanPostionChange.isUserMoving = true;

        }

        function onDocumentMouseWheel(event) {

          const fov = camera.fov + event.deltaY * 0.05;
          camera.fov = THREE.Math.clamp(fov, 5, 75);
          camera.updateProjectionMatrix();

        }

        function animate() {

          addAnimationInst.push(requestAnimationFrame(animate));
          update();

        }

        function update() {

          if (isUserInteracting === false) {

            lon += 0.001;

          }

          lat = Math.max(- 85, Math.min(85, lat));
          phi = THREE.Math.degToRad(90 - lat);
          theta = THREE.Math.degToRad(lon);

          if (addPanPostionChange.isUserMoving) {
            camera.target.x = 500 * Math.sin(phi) * Math.cos(theta);
            camera.target.y = 500 * Math.cos(phi);
            camera.target.z = 500 * Math.sin(phi) * Math.sin(theta);
          } else {
            camera.target.x = addPanPostionChange.x;
            camera.target.y = addPanPostionChange.y;
            camera.target.z = addPanPostionChange.z;
          }

          camera.lookAt(camera.target);

          /*
          // distortion
          camera.position.copy( camera.target ).negate();
          */

          renderer.render(scene, camera);

          if (addAnimationInst.length > 1) {
            cancelAnimationFrame(addAnimationInst[0]);
            addAnimationInst.shift();
          }

        }
      }

    }

  }

  async removePanDom(i) {
    let cont = document.getElementById('InspPanImg');
    console.log("cont", cont);
    cont.innerHTML = "";
    let btn1R = document.getElementById('mainIPan');
    btn1R.innerHTML = "";
    btn1R.style.display = "none";
    let btn2 = document.getElementById('panComare');
    btn2.innerHTML = "";
    btn2.style.display = "none";
    this.imageArray = [];

  }
  

  clearAllUserLocation() {
    this.userLiveLocation.forEach((val) => val.setMap(null));
    this.userLiveLocation.clear();
  }



  ///////////////////////////Android///////////////////////////////////////
  //  liveLocationSocket(checked:boolean){
  //   if(checked){
  //     this.joinRoom(this.auth.getUser().id)
  //   //  this.emitUserIdForLocation()
  //     this.userLocationSub = this.getLocationFromAndroid().subscribe((data: any)=>{
  //       console.log(this.route.url.slice(0).split('/')[2] == data.projectId)
  //       console.log(data)
  //       this.userLiveLocation.get(data.deviceId)?.setMap(null);
  //       if(data.sharing == 'true'){
  //         console.log(data.sharing, 'trueeeeeee');       
  //         this.userLiveLocation.set(
  //           data.deviceId, 
  //           new google.maps.Marker({
  //           icon: `https://maps.google.com/mapfiles/kml/shapes/man.png`,
  //           map: this.map,
  //           position: new google.maps.LatLng(data.locationModel.latitude, data.locationModel.longitude)
  //         }));
  //       }else{
  //         console.log(data.sharing, 'falseeee');
  //         this.userLiveLocation.delete(data.deviceId)
  //       }
  //     })
  //   }else{
  //     this.userLocationSub.unsubscribe();
  //     this.clearAllUserLocation()
  //   }

  //   }


  //////////////user's name on web////////////////
  setInformation(marker, data) {
    const infowindow = new google.maps.InfoWindow({
      // maxWidth: 200,
    });
    const contentString =
      // '<div id="content">' +
      // '<div id="siteNotice">' +
      // "</div>" +
      '<div id="bodyContent">' +
      // "<p><b>User Info</b>" +
      `<p><b>Name : ${data.username}</b></p>` +
      // "</div>" +
      "</div>";
    marker.addListener('click', () => {
      console.log(contentString, 'kjkjk');
      infowindow.setContent(contentString)
      infowindow.setPosition({
        lat: parseFloat(data.locationModel.latitude),
        lng: parseFloat(data.locationModel.longitude)
      })
      infowindow.open(this.map);
      // console.log(infowindow, `indooo`);

    })
  }
  ///////////////////////////Android///////////////////////////////////////
  liveLocationSocket(checked: boolean) {
    if (checked) {
      this.joinRoom(this.auth.getUser().id)
      //  this.emitUserIdForLocation()
      this.userLocationSub = this.getLocationFromAndroid().subscribe((data: any) => {
        console.log(this.route.url.slice(0).split('/')[2] == data.projectId)
        console.log(data)
        const mapInstanceKey = `${data.username} (${data.deviceId} )`
        this.userLiveLocation.get(mapInstanceKey)?.setMap(null);
        if (data.sharing == 'true') {
          console.log(data.sharing, 'trueeeeeee');
          const marker = new google.maps.Marker({
            icon: `https://maps.google.com/mapfiles/kml/shapes/man.png`,
            map: this.map,
            position: new google.maps.LatLng(data.locationModel.latitude, data.locationModel.longitude)
          })
          this.setInformation(marker, data)
          this.userLiveLocation.set(
            mapInstanceKey,
            marker
          );
        } else {
          console.log(data.sharing, 'falseeee');
          this.userLiveLocation.delete(mapInstanceKey)
        }
      })
    } else {
      this.userLocationSub.unsubscribe();
      this.clearAllUserLocation()
    }
  }
  clearAllAdminUserLocation() {
    // console.log(email)
    this.adminUserLiveLocation.forEach((val, key) => {
      val.mapMarker.setMap(null);
      //if(email == val.email){
      //  console.log(key,'keyadmin userlocation')
      //  val.mapMarker.setMap(null);
      //  this.adminUserLiveLocation.delete(key);
      // }
    });
    this.adminUserLiveLocation.clear()
  }
  liveLocationSocketByProject(checked: boolean, projects: any) {
    if (checked) {
      this.joinRoom(this.auth.getUser().id)
      // this.emitUserIdForLocation()
      // console.log(checked, project._id,'projecriss')
      projects.forEach((project) => {
        var adminUserLocationSub = this.getLocationFromAndroidByProject(project._id).subscribe((data: any) => {
          // console.log(data)
          const mapInstanceKey = `${data.username} (${data.deviceId} )`
          this.adminUserLiveLocation.get(mapInstanceKey)?.mapMarker.setMap(null);
          if (data.sharing == 'true') {
            const marker = new google.maps.Marker({
              icon: `https://maps.google.com/mapfiles/kml/shapes/man.png`,
              map: this.map,
              position: new google.maps.LatLng(data.locationModel.latitude, data.locationModel.longitude)
            })
            this.setInformation(marker, data);
            this.adminUserLiveLocation.set(
              mapInstanceKey,
              {
                email: project.userdata.email,
                mapMarker: marker
              });
          } else {
            this.adminUserLiveLocation.delete(mapInstanceKey)
          }
          // console.log(this.adminUserLiveLocation,'sdfsdfdsf')
        })
        this.adminUserLocationSub.add(adminUserLocationSub)
      })
    } else {
      console.log(checked, 'checked flag')
      this.adminUserLocationSub.unsubscribe();
      this.adminUserLocationSub = new Subscription();
      this.clearAllAdminUserLocation()
    }
  }

  ///changing center of the left map 
  moveToLatLong(lat: number, long: number) {
    this.map.setCenter(new google.maps.LatLng(lat, long))

  }

  ///////////////////////////Android///////////////////////////////////////

  /////////************************************Inspection feature****************************************** */

  async addInspPanData(geoUrl, i, popupDiv) {
    this.loading = true;
    const result = await Axios.get(geoUrl.data);
    const data = result.data;
    console.log(data);

    if (data) {
      let fillColor, strokeColor;

      if (geoUrl.fillColor && !geoUrl.fillColor.includes('#')) {
        const hex = geoUrl.fillColor;
        // convert to RGB
        let c = hex.split('(')[1].split(')')[0].split(',');
        c.map((e, i) => {
          c[i] = +e;
          if (i == 3) {
            c[i] = Math.round(e * 255);
          }
        })
        fillColor = c;
      } else {
        fillColor = [0, 228, 255, 255];
      }

      if (geoUrl.strokeColor && !geoUrl.strokeColor.includes('#')) {
        const hex2 = geoUrl.strokeColor;
        // convert to RGB
        let b = hex2.split('(')[1].split(')')[0].split(',');
        b.map((e, i) => {
          b[i] = +e;
          if (i == 3) {
            b[i] = Math.round(e * 255);
          }
        })
        strokeColor = b;
      } else {
        strokeColor = [200, 0, 250, 255];
      }
      // if (this.deckOverlay.setMap(this.map))this.deckOverlay.setMap(null);
      this.deckOverlay = new GoogleMapsOverlay({
        layers: [
          new GeoJsonLayer({
            id: "json",
            data: data,
            filled: true,
            pointRadiusMinPixels: 2,
            pointRadiusMaxPixels: 200,
            opacity: 0.4,
            pointRadiusScale: 0.3,
            getRadius: (f: Feature) => Math.pow(550, f.properties.mag),
            getElevation: d => (geoUrl.scale ? geoUrl.scale : 1) * ((geoUrl.selectedAttribute) ? d.properties[geoUrl.selectedAttribute] : (d.properties && d.properties.height) ? d.properties.height : geoUrl.height ? geoUrl.height : 0),
            getFillColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : fillColor,
            getLineColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : strokeColor,
            getLineWidth: geoUrl.strokeWidth ? geoUrl.strokeWidth : 2,
            lineWidthMinPixels: geoUrl.strokeWidth ? geoUrl.strokeWidth : 2,
            autoHighlight: true,
            highlightColor: [0, 128, 255],
            pickable: true,
            transitions: {
              getRadius: {
                type: "spring",
                stiffness: 0.1,
                damping: 0.15,
                enter: () => [8], // grow from size 0,
                duration: 10000,
              },
            },
            onHover: info => setTooltip2(info.object, info.x, info.y),
            onClick: info => showInfo(info)
          }),
        ],
      });
      this.deckOverlay.setMap(this.map);


      let showInfo = (e) => {
        // console.log("ein show info", e);
        // console.log(e.object.properties.file_path);
        // let img = JSON.stringify(e.object.properties.file_path);
        // console.log(img);        
        // data.features.find((o, i) => {
        //   if (o.properties.file_path == e.object.properties.file_path) {
        //     const main = document.getElementById("mainIPan");
        //     main.innerHTML = "";
        //     let img2 = document.createElement('img');
        //     img2.setAttribute('src', o.properties.file_path);
        //     img2.setAttribute("id", "image")
        //     img2.style.width = "450px";
        //     img2.style.height = "415px";
        //     img2.style.right = "2px";
        //     img2.style.bottom = "81px";
        //     img2.style.position = "absolute";
        //     img2.style.zIndex = "10000";
        //     img2.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
        //     img2.style.border = "2px solid #b7abed";
        //     img2.style.color = "white";
        //     main.appendChild(img2);
        //     console.log("main", main);
        //   }
        // })


        //////////////////////////////////////////////// panorama on point data//////////////////////////////////////////////////////////

        let img = JSON.stringify(e.object.properties.file_path);
        console.log(img);
        data.features.find((o, i) => {
          if (o.properties.file_path == e.object.properties.file_path) {
            var camera, scene, renderer;
            var isUserInteracting = false,
              onMouseDownMouseX = 0, onMouseDownMouseY = 0,
              lon = 0, onMouseDownLon = 0,
              lat = 0, onMouseDownLat = 0,
              phi = 0, theta = 0;
            var onPointerDownPointerX, onPointerDownPointerY, onPointerDownLon, onPointerDownLat;

            init();
            animate();

            function init() {

              var container, mesh;
              container = document.getElementById("mainIPan");
              container.innerHTML = '';
              container.style.display = "block";
              container.style.width = "50%";
              container.style.height = "80%";
              container.style.right = "2px";
              container.style.top = "64px";
              container.style.position = "fixed";
              container.style.zIndex = "10000";
              camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1100);
              camera.target = new THREE.Vector3(0, 0, 0);
              console.log(o.properties.file_path);

              scene = new THREE.Scene();

              var geometry = new THREE.SphereGeometry(500, 60, 40);
              geometry.scale(- 1, 1, 1);

              var material = new THREE.MeshBasicMaterial({
                map: new THREE.TextureLoader().load(o.properties.file_path)
              });

              mesh = new THREE.Mesh(geometry, material);

              scene.add(mesh);
              // console.log(scene);

              renderer = new THREE.WebGLRenderer();
              renderer.setPixelRatio(window.devicePixelRatio);
              renderer.setSize(window.innerWidth, window.innerHeight);
              console.log(renderer.domElement);
              container.appendChild(renderer.domElement);

              container.addEventListener('mousedown', onDocumentMouseDown, false);
              container.addEventListener('mousemove', onDocumentMouseMove, false);
              container.addEventListener('mouseup', onDocumentMouseUp, false);
              container.addEventListener('wheel', onDocumentMouseWheel, false);



              document.addEventListener('drop', function (event) {

                event.preventDefault();

                var reader = new FileReader();
                reader.addEventListener('load', function (event) {

                  material.map.image.src = event.target.result;
                  material.map.needsUpdate = true;

                }, false);
                reader.readAsDataURL(event.dataTransfer.files[0]);


              }, false);


              window.addEventListener('resize', onWindowResize, false);

            }

            function onWindowResize() {

              camera.aspect = window.innerWidth / window.innerHeight;
              camera.updateProjectionMatrix();

              renderer.setSize(window.innerWidth, window.innerHeight);

            }

            function onDocumentMouseDown(event) {

              event.preventDefault();

              isUserInteracting = true;

              onPointerDownPointerX = event.clientX;
              onPointerDownPointerY = event.clientY;

              onPointerDownLon = lon;
              onPointerDownLat = lat;

            }

            function onDocumentMouseMove(event) {

              if (isUserInteracting === true) {

                lon = (onPointerDownPointerX - event.clientX) * 0.1 + onPointerDownLon;
                lat = (event.clientY - onPointerDownPointerY) * 0.1 + onPointerDownLat;

              }

            }

            function onDocumentMouseUp(event) {

              isUserInteracting = false;

            }

            function onDocumentMouseWheel(event) {

              const fov = camera.fov + event.deltaY * 0.05;
              camera.fov = THREE.Math.clamp(fov, 5, 75);
              camera.updateProjectionMatrix();

            }

            function animate() {

              requestAnimationFrame(animate);
              update();

            }

            function update() {

              if (isUserInteracting === false) {

                lon += 0.001;

              }

              lat = Math.max(- 85, Math.min(85, lat));
              phi = THREE.Math.degToRad(90 - lat);
              theta = THREE.Math.degToRad(lon);

              camera.target.x = 500 * Math.sin(phi) * Math.cos(theta);
              camera.target.y = 500 * Math.cos(phi);
              camera.target.z = 500 * Math.sin(phi) * Math.sin(theta);

              camera.lookAt(camera.target);

              /*
              // distortion
              camera.position.copy( camera.target ).negate();
              */

              renderer.render(scene, camera);

            }
          }
        })

      }

      let setTooltip2 = (object, x, y) => {
        console.log(455, 'hover');

        if (object) {
          popupDiv.innerHTML = '';
          let keys = Object.keys(object.properties);
          keys.map(e => {
            popupDiv.innerHTML += `${e} :  ${object.properties[e]} <br>`;
          })
          popupDiv.style.display = 'block';
          popupDiv.style.left = x + 'px';
          popupDiv.style.top = y + 'px';
          popupDiv.style.padding = "15px";
          popupDiv.style.backgroundColor = "#eeeeee";
          popupDiv.style.border = "1px solid #000"
        } else {
          popupDiv.style.display = 'none';
        }
      }

      console.log(data);
      const boundingbox = bbox(data);
      const valid = boundingbox.every(val => Number.isFinite(val));
      if (valid) {
        const sw = {
          lat: boundingbox[1],
          lng: boundingbox[0]
        }
        const ne = {
          lat: boundingbox[3],
          lng: boundingbox[2]
        }
        console.log(sw, ne);
        console.log(this.map);
        this.map.setZoom(12);
        this.map.fitBounds(new google.maps.LatLngBounds(sw, ne));
        console.log(43534);

      }
      this.loading = false;
      // // this.loaded.next(101);
    }
  }


  removeInspPanLayer(i) {
    console.log(i);

    this.deckOverlay.setMap(null);
  }



  ///////////////////////////////////////////////////Pan Compare///////////////////////////////////////////


  async addInspPanComareLayer(geoUrl, i, popupDiv) {
    // let removeBtn = document.createElement('button');
    // removeBtn.innerText = "Remove";
    // removeBtn.style.width = "auto";
    // removeBtn.style.height = "auto";
    // removeBtn.style.right = "2px";
    // removeBtn.style.bottom = "81px";
    // removeBtn.style.position = "absolute";
    // removeBtn.style.zIndex = "10000";
    // // removeBtn.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
    // removeBtn.style.border = "2px solid #b7abed";
    // removeBtn.style.color = "white";
    this.loading = true;
    const result = await Axios.get(geoUrl.data);
    const data = result.data;
    console.log(data);
    this.updateData(data);

    if (data) {
      let fillColor, strokeColor;

      if (geoUrl.fillColor && !geoUrl.fillColor.includes('#')) {
        const hex = geoUrl.fillColor;
        // convert to RGB
        let c = hex.split('(')[1].split(')')[0].split(',');
        c.map((e, i) => {
          c[i] = +e;
          if (i == 3) {
            c[i] = Math.round(e * 255);
          }
        })
        fillColor = c;
      } else {
        fillColor = [255, 0, 0, 255];
      }

      if (geoUrl.strokeColor && !geoUrl.strokeColor.includes('#')) {
        const hex2 = geoUrl.strokeColor;
        // convert to RGB
        let b = hex2.split('(')[1].split(')')[0].split(',');
        b.map((e, i) => {
          b[i] = +e;
          if (i == 3) {
            b[i] = Math.round(e * 255);
          }
        })
        strokeColor = b;
      } else {
        strokeColor = [255, 0, 0, 255];
      }
      this.panaroma360SelectedFeatureCollection = data;
      this.deckOverlay = new GoogleMapsOverlay({


        layers: [
          new GeoJsonLayer({
            id: "json",
            data: data,
            filled: true,
            pointRadiusMinPixels: 2,
            pointRadiusMaxPixels: 200,
            opacity: 0.4,
            pointRadiusScale: 0.3,
            getRadius: (f: Feature) => Math.pow(550, f.properties.mag),
            getElevation: d => (geoUrl.scale ? geoUrl.scale : 1) * ((geoUrl.selectedAttribute) ? d.properties[geoUrl.selectedAttribute] : (d.properties && d.properties.height) ? d.properties.height : geoUrl.height ? geoUrl.height : 0),
            getFillColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : fillColor,
            getLineColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : strokeColor,
            getLineWidth: geoUrl.strokeWidth ? geoUrl.strokeWidth : 2,
            lineWidthMinPixels: geoUrl.strokeWidth ? geoUrl.strokeWidth : 2,
            autoHighlight: true,
            highlightColor: [0, 128, 255],
            pickable: true,
            transitions: {
              getRadius: {
                type: "spring",
                stiffness: 0.1,
                damping: 0.15,
                enter: () => [8], // grow from size 0,
                duration: 10000,
              },
            },
            onHover: info => setTooltip2(info.object, info.x, info.y),
            onClick: info => showInfo(info)
          }),
        ],
      });
      this.deckOverlay.setMap(this.map);
      this.clickEventFromDataLayer.on('clickedEvent', (event)=>{
        showInfo({object: this.panaroma360SelectedFeatureCollection.features[event]});
      });
      var mesh, renderer;

      let showInfo = (e) => {
        console.log(e.object)
        if(this.image360polyLineCurrentSet){
          this.removePolyLine();
        }
        if(this.image360pointCurrentSet){
          this.removeHighlightPoint();
        }
        if(e.object.geometry.type == "LineString"){
          this.setPolyLine(e.object.geometry.coordinates);
        }
        if(e.object.geometry.type == "Point"){
          const [lng, lat] = e.object.geometry.coordinates;
          this.highlightPoint({lat, lng});
        }
        let panfeatureIndex =
        this.panaroma360SelectedFeatureCollection.features.findIndex((feature)=>feature.properties.file_path == e.object.properties.file_path);

        let elements = document.querySelectorAll('[src="' + e.object.properties.file_path + '"]');

        if(elements.length > 0){
          const element = document.getElementById(elements[0].id);
          element.style['filter'] = 'blur(2px)';
        }
        this.panGisExpPanelWithPanIndexOnDataLayer['panImageIndex'] = panfeatureIndex;

        let img = JSON.stringify(e.object.properties.file_path);
        console.log(img);
        data.features.find((o, i) => {
          if (o.properties.file_path == e.object.properties.file_path) {
            var camera, scene, renderer;
            var isUserInteracting = false,
              onMouseDownMouseX = 0, onMouseDownMouseY = 0,
              lon = 0, onMouseDownLon = 0,
              lat = 0, onMouseDownLat = 0,
              phi = 0, theta = 0;
            var onPointerDownPointerX, onPointerDownPointerY, onPointerDownLon, onPointerDownLat;
            function removePanaromaWebGLContext(mesh, renderer) {
              if(scene){
                scene.remove(mesh);
              }
              document.getElementById('panComare').removeChild(renderer.domElement);
              renderer.dispose();
              renderer.forceContextLoss(); // Force context loss
              renderer.domElement = null; // Remove reference to the DOM element
           }
           mesh && renderer && removePanaromaWebGLContext(mesh, renderer);

            init();
            animate();
            this.panGisExpPanelWithPanIndexOnDataLayer['panGisExpPanel'] = true;

            function init() {

              var container, mesh;
              container = document.getElementById("panComare");
              container.innerHTML = '';
              container.style.display = "block";
              container.style.width = "50%";
              container.style.height = "80%";
              container.style.left = "-40%";
              container.style.top = "64px";
              container.style.position = "fixed";
              container.style.zIndex = "10000";
              camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1100);
              camera.target = new THREE.Vector3(0, 0, 0);
              console.log(o.properties.file_path);

              scene = new THREE.Scene();

              var geometry = new THREE.SphereGeometry(500, 60, 40);
              geometry.scale(- 1, 1, 1);

              var material = new THREE.MeshBasicMaterial({
                map: new THREE.TextureLoader().load(o.properties.file_path)
              });

              mesh = new THREE.Mesh(geometry, material);

              scene.add(mesh);
              // console.log(scene);

              renderer = new THREE.WebGLRenderer();
              renderer.setPixelRatio(window.devicePixelRatio);
              renderer.setSize(window.innerWidth, window.innerHeight);
              console.log(renderer.domElement);
              container.appendChild(renderer.domElement);

              container.addEventListener('mousedown', onDocumentMouseDown, false);
              container.addEventListener('mousemove', onDocumentMouseMove, false);
              container.addEventListener('mouseup', onDocumentMouseUp, false);
              container.addEventListener('wheel', onDocumentMouseWheel, false);



              document.addEventListener('drop', function (event) {

                event.preventDefault();

                var reader = new FileReader();
                reader.addEventListener('load', function (event) {

                  material.map.image.src = event.target.result;
                  material.map.needsUpdate = true;

                }, false);
                reader.readAsDataURL(event.dataTransfer.files[0]);


              }, false);


              window.addEventListener('resize', onWindowResize, false);

            }

            function onWindowResize() {

              camera.aspect = window.innerWidth / window.innerHeight;
              camera.updateProjectionMatrix();

              renderer.setSize(window.innerWidth, window.innerHeight);

            }

            function onDocumentMouseDown(event) {

              event.preventDefault();

              isUserInteracting = true;

              onPointerDownPointerX = event.clientX;
              onPointerDownPointerY = event.clientY;

              onPointerDownLon = lon;
              onPointerDownLat = lat;

            }

            function onDocumentMouseMove(event) {

              if (isUserInteracting === true) {

                lon = (onPointerDownPointerX - event.clientX) * 0.1 + onPointerDownLon;
                lat = (event.clientY - onPointerDownPointerY) * 0.1 + onPointerDownLat;

              }

            }

            function onDocumentMouseUp(event) {

              isUserInteracting = false;

            }

            function onDocumentMouseWheel(event) {

              const fov = camera.fov + event.deltaY * 0.05;
              camera.fov = THREE.Math.clamp(fov, 5, 75);
              camera.updateProjectionMatrix();

            }

            function animate() {

              requestAnimationFrame(animate);
              update();

            }

            function update() {

              if (isUserInteracting === false) {

                lon += 0.001;

              }

              lat = Math.max(- 85, Math.min(85, lat));
              phi = THREE.Math.degToRad(90 - lat);
              theta = THREE.Math.degToRad(lon);

              camera.target.x = 500 * Math.sin(phi) * Math.cos(theta);
              camera.target.y = 500 * Math.cos(phi);
              camera.target.z = 500 * Math.sin(phi) * Math.sin(theta);

              camera.lookAt(camera.target);
              renderer.render(scene, camera);

            }
          }
        })

      }

      let setTooltip2 = (object, x, y) => {
        console.log(455, 'hover');

        if (object) {
          popupDiv.innerHTML = '';
          let keys = Object.keys(object.properties);
          keys.map(e => {
            popupDiv.innerHTML += `${e} :  ${object.properties[e]} <br>`;
          })
          popupDiv.style.display = 'block';
          popupDiv.style.left = x + 'px';
          popupDiv.style.top = y + 'px';
          popupDiv.style.padding = "15px";
          popupDiv.style.backgroundColor = "#eeeeee";
          popupDiv.style.border = "1px solid #000"
        } else {
          popupDiv.style.display = 'none';
        }
      }

      console.log(data);
      const boundingbox = bbox(data);
      const valid = boundingbox.every(val => Number.isFinite(val));
      if (valid) {
        const sw = {
          lat: boundingbox[1],
          lng: boundingbox[0]
        }
        const ne = {
          lat: boundingbox[3],
          lng: boundingbox[2]
        }
        console.log(sw, ne);
        console.log(this.map);
        this.map.setZoom(12);
        this.map.fitBounds(new google.maps.LatLngBounds(sw, ne));
        console.log(43534);

      }
      this.loading = false;
      // // this.loaded.next(101);
    }
  }
  async addInspPanComareLayerMapAnalysis(geoUrl, i, popupDiv ,screen) {
    // let removeBtn = document.createElement('button');
    // removeBtn.innerText = "Remove";
    // removeBtn.style.width = "auto";
    // removeBtn.style.height = "auto";
    // removeBtn.style.right = "2px";
    // removeBtn.style.bottom = "81px";
    // removeBtn.style.position = "absolute";
    // removeBtn.style.zIndex = "10000";
    // // removeBtn.style.backgroundImage = "url('assets/Spin-1s-165px (1).gif')";
    // removeBtn.style.border = "2px solid #b7abed";
    // removeBtn.style.color = "white";
    this.loading = true;
    const result = await Axios.get(geoUrl.data);
    const data = result.data;
    console.log(data);

    if (data) {
      let fillColor, strokeColor;

      if (geoUrl.fillColor && !geoUrl.fillColor.includes('#')) {
        const hex = geoUrl.fillColor;
        // convert to RGB
        let c = hex.split('(')[1].split(')')[0].split(',');
        c.map((e, i) => {
          c[i] = +e;
          if (i == 3) {
            c[i] = Math.round(e * 255);
          }
        })
        fillColor = c;
      } else {
        fillColor = [255, 0, 0, 255];
      }

      if (geoUrl.strokeColor && !geoUrl.strokeColor.includes('#')) {
        const hex2 = geoUrl.strokeColor;
        // convert to RGB
        let b = hex2.split('(')[1].split(')')[0].split(',');
        b.map((e, i) => {
          b[i] = +e;
          if (i == 3) {
            b[i] = Math.round(e * 255);
          }
        })
        strokeColor = b;
      } else {
        strokeColor = [255, 0, 0, 255];
      }
      this.deckOverlay = new GoogleMapsOverlay({


        layers: [
          new GeoJsonLayer({
            id: "json",
            data: data,
            filled: true,
            pointRadiusMinPixels: 2,
            pointRadiusMaxPixels: 200,
            opacity: 0.4,
            pointRadiusScale: 0.3,
            getRadius: (f: Feature) => Math.pow(550, f.properties.mag),
            getElevation: d => (geoUrl.scale ? geoUrl.scale : 1) * ((geoUrl.selectedAttribute) ? d.properties[geoUrl.selectedAttribute] : (d.properties && d.properties.height) ? d.properties.height : geoUrl.height ? geoUrl.height : 0),
            getFillColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : fillColor,
            getLineColor: d => d.properties.color ? ColorConverter.hex.rgb(d.properties.color) : strokeColor,
            getLineWidth: geoUrl.strokeWidth ? geoUrl.strokeWidth : 2,
            lineWidthMinPixels: geoUrl.strokeWidth ? geoUrl.strokeWidth : 2,
            autoHighlight: true,
            highlightColor: [0, 128, 255],
            pickable: true,
            transitions: {
              getRadius: {
                type: "spring",
                stiffness: 0.1,
                damping: 0.15,
                enter: () => [8], // grow from size 0,
                duration: 10000,
              },
            },
            onHover: info => setTooltip2(info.object, info.x, info.y),
            onClick: info => showInfo(info)
          }),
        ],
      });
   

      //  this.deckOverlay.setMap(this.map);
      if(screen === 'two_window1'){
         this.deckOverlay.setMap(this.mappanel1);
      } else if(screen === 'two_window2'){
        this.deckOverlay.setMap(this.map1);
      }
      if(screen === 'window1'){
        this.deckOverlay.setMap(this.mappanel2);
      }
      else if(screen === 'window2'){
        this.deckOverlay.setMap(this.mappanel3);
      }
      else if(screen === 'window3'){
        this.deckOverlay.setMap(this.mappanel4);
      }
      else if(screen === 'window4'){
        this.deckOverlay.setMap(this.mappanel5);
      }
 
 
      let showInfo = (e) => {
        let img = JSON.stringify(e.object.properties.file_path);
        console.log(img);
        data.features.find((o, i) => {
          if (o.properties.file_path == e.object.properties.file_path) {
            var camera, scene, renderer;
            var isUserInteracting = false,
              onMouseDownMouseX = 0, onMouseDownMouseY = 0,
              lon = 0, onMouseDownLon = 0,
              lat = 0, onMouseDownLat = 0,
              phi = 0, theta = 0;
            var onPointerDownPointerX, onPointerDownPointerY, onPointerDownLon, onPointerDownLat;

            init();
            animate();

            function init() {

              var container, mesh;
              container = document.getElementById("panComare");
              container.innerHTML = '';
              container.style.display = "block";
              container.style.width = "50%";
              container.style.height = "80%";
              container.style.left = "-40%";
              container.style.top = "64px";
              container.style.position = "fixed";
              container.style.zIndex = "10000";
              camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1100);
              camera.target = new THREE.Vector3(0, 0, 0);
              console.log(o.properties.file_path);

              scene = new THREE.Scene();

              var geometry = new THREE.SphereGeometry(500, 60, 40);
              geometry.scale(- 1, 1, 1);

              var material = new THREE.MeshBasicMaterial({
                map: new THREE.TextureLoader().load(o.properties.file_path)
              });

              mesh = new THREE.Mesh(geometry, material);

              scene.add(mesh);
              // console.log(scene);

              renderer = new THREE.WebGLRenderer();
              renderer.setPixelRatio(window.devicePixelRatio);
              renderer.setSize(window.innerWidth, window.innerHeight);
              console.log(renderer.domElement);
              container.appendChild(renderer.domElement);

              container.addEventListener('mousedown', onDocumentMouseDown, false);
              container.addEventListener('mousemove', onDocumentMouseMove, false);
              container.addEventListener('mouseup', onDocumentMouseUp, false);
              container.addEventListener('wheel', onDocumentMouseWheel, false);



              document.addEventListener('drop', function (event) {

                event.preventDefault();

                var reader = new FileReader();
                reader.addEventListener('load', function (event) {

                  material.map.image.src = event.target.result;
                  material.map.needsUpdate = true;

                }, false);
                reader.readAsDataURL(event.dataTransfer.files[0]);


              }, false);


              window.addEventListener('resize', onWindowResize, false);

            }

            function onWindowResize() {

              camera.aspect = window.innerWidth / window.innerHeight;
              camera.updateProjectionMatrix();

              renderer.setSize(window.innerWidth, window.innerHeight);

            }

            function onDocumentMouseDown(event) {

              event.preventDefault();

              isUserInteracting = true;

              onPointerDownPointerX = event.clientX;
              onPointerDownPointerY = event.clientY;

              onPointerDownLon = lon;
              onPointerDownLat = lat;

            }

            function onDocumentMouseMove(event) {

              if (isUserInteracting === true) {

                lon = (onPointerDownPointerX - event.clientX) * 0.1 + onPointerDownLon;
                lat = (event.clientY - onPointerDownPointerY) * 0.1 + onPointerDownLat;

              }

            }

            function onDocumentMouseUp(event) {

              isUserInteracting = false;

            }

            function onDocumentMouseWheel(event) {

              const fov = camera.fov + event.deltaY * 0.05;
              camera.fov = THREE.Math.clamp(fov, 5, 75);
              camera.updateProjectionMatrix();

            }

            function animate() {

              requestAnimationFrame(animate);
              update();

            }

            function update() {

              if (isUserInteracting === false) {

                lon += 0.001;

              }

              lat = Math.max(- 85, Math.min(85, lat));
              phi = THREE.Math.degToRad(90 - lat);
              theta = THREE.Math.degToRad(lon);

              camera.target.x = 500 * Math.sin(phi) * Math.cos(theta);
              camera.target.y = 500 * Math.cos(phi);
              camera.target.z = 500 * Math.sin(phi) * Math.sin(theta);

              camera.lookAt(camera.target);
              renderer.render(scene, camera);

            }
          }
        })

      }

      let setTooltip2 = (object, x, y) => {
        console.log(455, 'hover');

        if (object) {
          popupDiv.innerHTML = '';
          let keys = Object.keys(object.properties);
          keys.map(e => {
            popupDiv.innerHTML += `${e} :  ${object.properties[e]} <br>`;
          })
          popupDiv.style.display = 'block';
          popupDiv.style.left = x + 'px';
          popupDiv.style.top = y + 'px';
          popupDiv.style.padding = "15px";
          popupDiv.style.backgroundColor = "#eeeeee";
          popupDiv.style.border = "1px solid #000"
        } else {
          popupDiv.style.display = 'none';
        }
      }

      console.log(data);
      const boundingbox = bbox(data);
      const valid = boundingbox.every(val => Number.isFinite(val));
      if (valid) {
        const sw = {
          lat: boundingbox[1],
          lng: boundingbox[0]
        }
        const ne = {
          lat: boundingbox[3],
          lng: boundingbox[2]
        }
        console.log(sw, ne);
        console.log(this.map);
        if(screen === 'two_window1'){
          // this.deckOverlay.setMap(this.mappanel1);
          this.mappanel1.setZoom(12);
        this.mappanel1.fitBounds(new google.maps.LatLngBounds(sw, ne));
       } else if(screen === 'two_window2'){
        //  this.deckOverlay.setMap(this.map1);
         this.map1.setZoom(12);
        this.map1.fitBounds(new google.maps.LatLngBounds(sw, ne));
       }
       
        
        console.log(43534);

      }
      this.loading = false;
      // // this.loaded.next(101);
    }
  }


  removeInspPanCompareLayer(i) {
    console.log(i);
    this.imagess = []
    this.deckOverlay.setMap(null);
  }

  async removePanComDom(i) {
    // let cont = document.getElementById('InspPanImg');
    // console.log("cont", cont);
    // cont.innerHTML= "";
    // let btn1R = document.getElementById('mainIPan');
    // btn1R.innerHTML= "";
    // btn1R.style.display ="none";
    let btn2 = document.getElementById('panComare');
    btn2.innerHTML = "";
    btn2.style.display = "none";
    this.imageArray = [];


  }

  ////////////////////////////////////////////tagggggggg/////////////////////////////////////////////////////////
  // Create Info
  //  createInfo(event){
  //   let intersects = this.getIntersections( event );
  //   let found: Intersection
  //   intersects.forEach((element)=>{
  //     Object.keys(element).forEach(function(key){
  //       if(key == 'object'){
  //         Object.keys(element[key]).forEach(function(ele){
  //           if(element[key][ele] == 'Mesh'){
  //             found = element;
  //           }
  //         });
  //       }
  //     })
  //   })
  //   let vector = new THREE.Vector3(found.point.x, found.point.y, found.point.z)
  //   let {x,y} = this.projectVector(vector)
  //   let data = {}
  //   this.dialog.open(TagComponent, {
  //     data: data
  //   }).afterClosed().subscribe(val=>{
  //     console.log(val);

  //     if(val){
  //       const sprite = this.makeTextSprite(` ${val.name} ` ,{ fontsize: 65, borderColor: {r:0, g:0, b:255, a:1.0}, backgroundColor: {r:255, g:255, b:255, a:1.0}  } );
  //       this.scene.add(sprite);
  //       sprite.position.set(found.point.x, found.point.y, found.point.z);
  //       console.log(sprite);

  //       //   this.spritesData.push({
  //       //     name: val.name,
  //       //     content: val.content,
  //       //     pos: found.point,
  //       //     sprite
  //       //   });


  //       // this.spritesDataChanged.next(this.spritesData)
  //       // let panhover = this.panhoverContainerRef.createComponent(this.factory);
  //       // panhover.instance.data = {
  //       //   event:{
  //       //     top: y+'px',
  //       //     left: x+'px'
  //       //   },
  //       //     display: 'none',
  //       //     content: val.content
  //       // }
  //       // this.panhoverComponents.push(panhover)
  //     }
  //   })
  // }

  // getIntersections( event ) {
  //   let vector = new THREE.Vector2();
  //   vector.set(
  //     ( (event.clientX) / event.target.clientWidth ) * 2 - 1,
  //     - ( (event.clientY) / event.target.clientHeight ) * 2 + 1 );
  //     console.log(vector , `vector`);
  //     console.log(this.camera , `this.camera`);

  //     this.raycaster.setFromCamera( vector, this.camera );
  //   const intersects = this.raycaster.intersectObjects( this.scene.children );
  //   return intersects;
  // }


  // projectVector(vector){
  //   vector.project(this.camera)
  //   let x = Math.round((0.5 + vector.x / 2) * (this.tdrenderer.domElement.width / window.devicePixelRatio));
  //   let y = Math.round((0.5 - vector.y / 2) * (this.tdrenderer.domElement.height / window.devicePixelRatio));
  //   return {x,y}
  // }

  // makeTextSprite( message, parameters ){
  //   if ( parameters === undefined ) parameters = {};

  //   const fontface = parameters.hasOwnProperty("fontface") ?
  //     parameters["fontface"] : "Arial";

  //   const fontsize = parameters.hasOwnProperty("fontsize") ?
  //     parameters["fontsize"] : 24;

  //   const borderThickness = parameters.hasOwnProperty("borderThickness") ?
  //     parameters["borderThickness"] : 4;

  //   const borderColor = parameters.hasOwnProperty("borderColor") ?
  //     parameters["borderColor"] : { r:0, g:0, b:0, a:1.0 };

  //   const backgroundColor = parameters.hasOwnProperty("backgroundColor") ?
  //     parameters["backgroundColor"] : { r:255, g:255, b:255, a:1.0 };

  //   const canvas = document.createElement('canvas');
  //   const context = canvas.getContext('2d');
  //   context.font = "Bold " + fontsize + "px " + fontface;

  //   // get size data (height depends only on font size)
  //   const metrics = context.measureText( message );
  //   const textWidth = metrics.width;

  //   // background color
  //   context.fillStyle   = "rgba(" + backgroundColor.r + "," + backgroundColor.g + ","
  //                   + backgroundColor.b + "," + backgroundColor.a + ")";
  //   // border color
  //   context.strokeStyle = "rgba(" + borderColor.r + "," + borderColor.g + ","
  //                   + borderColor.b + "," + borderColor.a + ")";

  //   context.lineWidth = borderThickness;
  //   this.roundRect(context, borderThickness/2, borderThickness/2, textWidth + borderThickness, fontsize * 1.4 + borderThickness, 6);
  //   // 1.4 is extra height factor for text below baseline: g,j,p,q.

  //   // text color
  //   context.fillStyle = "rgba(0, 0, 0, 1.0)";

  //   context.fillText( message, borderThickness, fontsize + borderThickness);

  //   // canvas contents will be used for a texture
  //   //0x080808
  //   const texture = new THREE.Texture(canvas);
  //   texture.needsUpdate = true;

  //   const spriteMaterial = new THREE.SpriteMaterial({ map: texture} );
  //   const sprite = new THREE.Sprite( spriteMaterial );
  //   sprite.renderOrder = 1;
  //   //  sprite.scale.set( 120, 70, 1);
  //   // sprite.position.set( 6, 5, 5 );
  //   sprite.scale.set( 70, 35, 1);
  //   return sprite;
  // }


  // roundRect(ctx, x, y, w, h, r) {
  //   ctx.beginPath();
  //   ctx.moveTo(x+r, y);
  //   ctx.lineTo(x+w-r, y);
  //   ctx.quadraticCurveTo(x+w, y, x+w, y+r);
  //   ctx.lineTo(x+w, y+h-r);
  //   ctx.quadraticCurveTo(x+w, y+h, x+w-r, y+h);
  //   ctx.lineTo(x+r, y+h);
  //   ctx.quadraticCurveTo(x, y+h, x, y+h-r);
  //   ctx.lineTo(x, y+r);
  //   ctx.quadraticCurveTo(x, y, x+r, y);
  //   ctx.closePath();
  //   ctx.fill();
  //   ctx.stroke();
  // }




  //////////labelShapefileData////////////////////////////



  async AddLabeltoGeojson(data, i, labelproperty?: string) {
    console.log(labelproperty, data, i);
    // const zoom = Math.min();
    // const fontSize = 10;
    // const scale = 2 ** zoom;
    // const sizeMaxPixels = (scale / 3) * fontSize;
    // const sizeMinPixels = (scale / 1000) * fontSize;

    // console.log(textSize , `textsize`);

    // if (this.map.getLayer("poi-labels" + i)) {
    //   this.map.removeLayer("poi-labels" + i);
    //   this.map.removeSource("places" + i);
    // }

    // this.map.addSource("places" + i, {
    //   type: "geojson",
    //   data: data,
    // });

    // this.map.addLayer({
    //   id: "poi-labels" + i,
    //   type: "symbol",
    //   source: "places" + i,
    //   layout: {
    //     "text-field": ["get", labelproperty],
    //     "text-variable-anchor": ["top", "bottom", "left", "right"],
    //     "text-radial-offset": 0.5,
    //     "text-justify": "auto",
    //     "text-size": [
    //       "interpolate",
    //       ["exponential", 2],
    //       ["zoom"],
    //       0,
    //       8,
    //     ],
    //   },
    // });

    // const result = await Axios.get(data);
    //   const dataa = result.data;
    //   console.log(dataa);

    this.labeldeckOverlay = new GoogleMapsOverlay({
      layers: [
        new TextLayer({
          id: 'text-layer-geojson-' + i,
          type: TextLayer,
          data: data.features,
          // pickable: true,
          sizeUnits: "meters",
          sizeMinPixels: 5,
          // billboard : false,
          background: true,
          backgroundPadding: [5, 5],
          lineHeight: 5,
          getPosition: d => [...center(d).geometry.coordinates, 5],
          getText: d => d.properties[labelproperty].toString() ? d.properties[labelproperty].toString() : "",
          // getSize: 32,
          getColor: d => [255, 0, 0, 255],
          outlineWidth: 1,
          outlineColor: [0, 0, 255, 255],
          // getSize:d=> textSize,
          // sizeMaxPixels,
          // sizeMinPixels,
          getSize: 5,
          getTextAnchor: 'middle',
          getAlignmentBaseline: 'center'
        }),
      ],


    })
    // console.log(this.deckOverlay, 123123);

    this.labeldeckOverlay.setMap(this.map);
    // this.map.addL
    // this.AiMapService.isSpinner.next(false);
  }
  removelabeltogeojson(i) {
    console.log(i);
    this.labeldeckOverlayIconV2.get(i)?.setMap(null)
    this.labeldeckOverlayIconV2.delete(i)
    // this.labeldeckOverlay.setMap(null);
  }

  removeicontoUploadedgeojson(i) {
    this.labeldeckOverlayIconV2.get(i)?.setMap(null)
    this.labeldeckOverlayIconV2.delete(i)
  }


  ////////////////////////////////postgres///////////////////////////////////

  uploadShapeToPostgres(data) {
    // this.isSpinner.next(true);
    console.log(data);
    return this.http.post<any>(`https://ai.heliware.co.in/postgre/shp_postgre/`, data);
  }

  uploadKmlData(data) {
    console.log(data);
    return this.http.post<any>(
      `https://ai.heliware.co.in/postgre/kml_postgre_sgurr/`, data);
  }

  uploadDxfData(data) {
    this.spinner.emit('loader', true);
    console.log(data);
    return this.http.post<any>(
      `https://ai.heliware.co.in/postgre/dxf_postgre/`, data);
  }

  async deleteTablePostgres(table) {
    const form = new FormData();
    form.append("input_tablename", table);
    this.http
      .post("https://ai.heliware.co.in/postgre/delete_postgres_table/", form)
      .subscribe((res) => {
        this.snackbar.open((res as any).message, "ok");
      });
  }

  async getGeoJsonFromPostgres(table) {
    console.log(table);
    this.spinner.emit('loader', true);
    const form = new FormData();
    form.append("input_tablename", table);
    return await this.http
      .post("https://ai.heliware.co.in/postgre/postgre_to_geo/", form)
      .toPromise();
  }

  async getRouteDataResponse(data,check) {
    console.log(check,"latlogData",data);
      const formData = new FormData();
        formData.append("source", JSON.stringify(data[0]));
        formData.append("destination", JSON.stringify(data[1]));
        return await this.http
          .post("https://ai.heliware.co.in/network/india_route/", formData).toPromise();
  }

  async downloadXlsxData(table) {
    this.spinner.emit('loader', true);
    const form = new FormData();
    form.append("input_tablename", table);
    return await this.http
      .post("https://ai.heliware.co.in/postgre/geo_postgis_xlsx/", form)
      .toPromise();
  }


  async updateGeoJsonFromPostgres(table, condition, idObj = null) {
    console.log(table);
    this.spinner.emit('loader', true);
    const form = new FormData();
    let query = `UPDATE ${table} SET ` + condition
    if (idObj) {
      query = query + ` WHERE "${idObj.col}"=${idObj.id}`
    }
    form.append("query", query);
    form.append("tablename", table);
    return await this.http
      .post("https://ai.heliware.co.in/postgre/update_table/", form)
      .toPromise();
  }

  // https://ai.heliware.co.in/postgre/dwg_postgres/
  // https://ai.heliware.co.in/postgre/shape_geo_postgres/
  // https://ai.heliware.co.in/postgre/kml_postgres/
  // place////////////////////////////////'

  uploadVideoSnapshot(data: FormData) {
    this.server.uploadVideoSnapShot(data).subscribe((response) => {
      this.getProject(this.projectData.id)
      this.snackbar.open('File uploaded', 'ok', {
        duration: 500
      })
    }, (err) => {
      console.log(err)
    })
  }

  uploadExcel(data: FormData) {
    return this.server.uploadExcel(data);
  }

  deleteVideoSnapshot(gisId: string, snapType: string, imageId: string) {
    this.server.deleteVideoSnapShot(gisId, snapType, imageId).subscribe((response) => {
      this.pseudogetProject(this.projectData.id)
    }, (err) => {
      console.log(err)
    })
  }

  ///Ground video ///
  getGroundVideo(projectId: string, layer: string) {
    this.server.getGroundVideos(projectId, layer).subscribe(data => {
      this.updateGroundVideos.next(data)
    }, error => {
      this.snackbar.open('Something went wrong', 'ok', {
        duration: 3000
      });
    })
  }

  videoUpload(data: any) {
    this.server.videoUploadNotification(data).subscribe(res => {
      this.snackbar.open('Notified to users', 'ok', {
        duration: 3000
      });
      this.pseudogetProject(this.projectData.id);
    }, error => {
      this.snackbar.open('Something went wrong', 'ok', {
        duration: 3000
      });
    })
  }
  ////////////////////////////////nwRoute//////////////////////////////////////
  async showRouteData(geoUrl, i, popupDiv,checked) {
    const data: any = geoUrl.pointsData;
    console.log(data);
    const routeHashData = new GoogleMapsOverlay({
      glOptions: {
        preserveDrawingBuffer: true
      },
      layers: [
        new GeoJsonLayer({
          id: "route",
          data: data,
          filled: true,
          pointRadiusMinPixels: 2,
          pointRadiusMaxPixels: 200,
          opacity: 1,
          pointRadiusScale: 0.3,
          // getRadius: (f: Feature) => Math.pow(550, f.properties.mag),
          // getFillColor: [255, 70, 30, 180],
          getElevation: d => (geoUrl.scale ? geoUrl.scale : 1) * ((geoUrl.selectedAttribute) ? d.properties[geoUrl.selectedAttribute] : (d.properties && d.properties.height) ? d.properties.height : geoUrl.height ? geoUrl.height : 0),
          // getFillColor: d=> d.properties.color?ColorConverter.hex.rgb(d.properties.color): fillColor,
          // getLineColor: d=> d.properties.color?ColorConverter.hex.rgb(d.properties.color):strokeColor,
          getLineColor: d => geoUrl.color,
          getLineWidth: geoUrl.strokeWidth ? geoUrl.strokeWidth : 2,
          lineWidthMinPixels: geoUrl.strokeWidth ? geoUrl.strokeWidth : 1,
          // pointRadiusMinPixels:geoUrl.unit?geoUrl.unit:2,
          getFillColor: d => geoUrl.color,
          autoHighlight: true,
          highlightColor: [0, 128, 255],
          pickable: true,
          transitions: {
            getRadius: {
              type: "spring",
              stiffness: 0.1,
              damping: 0.15,
              enter: () => [8], // grow from size 0,
              duration: 10000,
            },
          },
          // onHover: info => setTooltip2(info.object, info.x, info.y),
          // onClick: info => showInfo(info)
        }),
      ],
    });
    console.log(this.map);
    // console.log(data);
    // this.map.data.loadGeoJson(data)
    // this.deckOverlay.setMap(this.map);
    this.routeDeckOverlay.set(i, routeHashData)
    this.routeDeckOverlay.get(i)?.setMap(this.map)

    // let setTooltip2 = (object, x, y) => {
    //   console.log(455, 'hover');

    //   if (object) {
    //     popupDiv.innerHTML = '';
    //     let keys = Object.keys(object.properties);
    //     // console.log(keys, 'keeyyssss');
    //     keys.map(e => {
    //       console.log(object.properties[e]);
    //       popupDiv.innerHTML += `${e} :  ${object.properties[e]} <br>`;
    //     })
    //     popupDiv.style.display = 'block';
    //     popupDiv.style.flexWrap = 'wrap';
    //     popupDiv.style.textAlign = 'justify';
    //     popupDiv.style.textJustify = 'inter-word';
    //     // popupDiv.style.textAlign = 'center';
    //     // popupDiv.style.width = '40%';
    //     popupDiv.style.left = x + 'px';
    //     popupDiv.style.top = y + 'px';
    //     popupDiv.style.padding = "15px";
    //     popupDiv.style.backgroundColor = "#eeeeee";
    //     popupDiv.style.border = "1px solid #000"
    //   } else {
    //     popupDiv.style.display = 'none';
    //   }
    // }

    // console.log(data,"data11");
    // const boundingbox = bbox(data);
    // const valid = boundingbox.every(val => Number.isFinite(val));
    // if (valid) {
    //   const sw = {
    //     lat: boundingbox[1],
    //     lng: boundingbox[0]
    //   }
    //   const ne = {
    //     lat: boundingbox[3],
    //     lng: boundingbox[2]
    //   }
    //   console.log(sw, ne);
    //   console.log(this.map);
    //   this.map.setZoom(12);
    //   this.map.fitBounds(new google.maps.LatLngBounds(sw, ne));
    // }
    // this.spinner.emit('loader', false);
    // // this.loaded.next(101);
    
if (checked === true) {
  console.log(checked, "checked=>");
   this.coordinates = geoUrl.pointsData;
  console.log(this.coordinates, "this.RouteLayerData[i]");

  const origin = { lat: this.coordinates[0][1], lng: this.coordinates[0][0] };
  const destination = { lat: this.coordinates[1][1], lng: this.coordinates[1][0] };

  const directionsService = new google.maps.DirectionsService();
  this.directionsRenderer = new google.maps.DirectionsRenderer();
  this.directionsRenderer.setMap(this.map);

  directionsService.route(
    {
      origin: origin,
      destination: destination,
      travelMode: google.maps.TravelMode.WALKING, // Can also use WALKING, BICYCLING, TRANSIT
    },
    (response, status) => {
      if (status === "OK") {
        this.directionsRenderer.setDirections(response);
      } else {
        window.alert("Directions request failed due to " + status);
      }
    }
  );
} 
  
  }
  // }


  removeRouteData(i,e) {
    console.log(i);
    console.log(this.map.data);
    this.routeDeckOverlay.get(i)?.setMap(null)
    this.routeDeckOverlay.delete(i)
    // this.deckOverlay.setMap(null);
    if (e === false) {
      if (this.directionsRenderer) {
        this.directionsRenderer.setMap(null);
        this.directionsRenderer = null;
      }
      this.coordinates.setMap(null)
    }
  }
  ///////////////////////////////////////////////tarck video save data////////////////////////////////////////////////
  addLayerVideo(data: any) {
    this.server.addLayerVideoData(this.projectData.id, data).subscribe((response) => {
      this.pseudogetProject(this.projectData.id)
    }, (err) => {
      console.log(err)
    })
  }

  removeLayerVideo(trackId: string) {
    this.server.removeLayerVideoData(this.projectData.id, trackId).subscribe((response) => {
      this.pseudogetProject(this.projectData.id)
    }, (err) => {
      console.log(err)
    })
  }

  updateLayerVideo(trackId: string, data: any) {
    this.server.updateLayerVideoData(trackId, data).subscribe((response) => {
      this.pseudogetProject(this.projectData.id)
    }, (err) => {
      console.log(err)
    })
  }
  /////////////////////////////////////////////// End tarck video save data////////////////////////////////////////////////


  // MCG ADMIN 
  mcgCreateUser(formData: any) {
    return this.server.createMCGUser(formData).subscribe((res) => {
      // console.log(res)
    });
  }

  getMcgUsers(data) {
    return this.server.getMCGUser(data)
  }

  updateRole(data){
    console.log(data,"====>data")
    return this.server.updateRole(data)
  }

  getDataUserdashboard() {
    return this.server.getUserDashboardData()
  }

  getProjectsDashboard(data: any) {
    return this.server.getProjectDashboardData(data)
  }

  deleteMcgUser(data) {
    return this.server.deleteMCGUser(data)
  }

  getMcgUsersById(id) {
    return this.server.getMCGUserById(id)
  }

  getAssignFetchSites(data:any){
    return this.server.getAssignedFetchSites(data)
  }

  mcgUpdateUser(formData: any) {
    return this.server.updateMCGUser(formData)
  }

  assignUserInProject(data) {
    return this.server.assignUser(data)
  }
  
 
 //////////////////// upload data icon/////////////////////////////
  addIcon(data, i, iconUrl, iconColor) {
    console.log('asdasdsad', data.data.features)
    const iconLayer = new IconLayer({
      id: 'icon-star-layer-' + i,
      data: data.data.features,
      getIcon: (d) => ({
        url: iconUrl,
        width: 65,
        height: 60,
        anchorY: 30,
      }),
      getPosition: d => [...center(d).geometry.coordinates, 5],
      getSize: d => 5,
      getColor: d => Array.isArray(iconColor) ? iconColor : iconColor.match(/\d+/g).map(Number),
      // iconAtlas:iconUrl,
      // iconMapping: {
      //   marker: {
      //     x: 0,
      //     y: 0,
      //     width: 65,
      //     height: 60,
      //     anchorY: 128,
      //     // mask: true
      //   }
      // },
      sizeScale: 6,
      pickable: true
    })

    const iconLayerInst = new GoogleMapsOverlay({
      layers: [iconLayer]
    });

    this.labeldeckOverlayIconV2.set(i, iconLayerInst);
    this.labeldeckOverlayIconV2.get(i)?.setMap(this.map);
  }

  addWMSLayer(data: any) {
    return this.server.saveWMSLayerServer(data)
  }


  addGeoTiff(data:any){
    return this.server.saveGeoTiff(data)
  }

  addPortalDetection(data){
    return this.server.savePortalDetection(data)
  }

  addStreetLightDetection(data){
    return this.server.saveStreetLightDetection(data)
  }

  addChangeDetectionData(data:any){
    return this.server.addChangeDetection(data)
  }
  
  addPortalGeoTiff(data:any){
    return this.server.savePortalGeoTiff(data)
  }

  onGeoTiffMap(option: string){
    this.GeoTifLayerMap.next(option)
  }
  
  addPointCloudLayer(data: any) {
    return this.server.savePointCloudLayerServer(data)
  }

  addPotHoleDetectionData(data):Observable<any>{
    return this.server.addPotHoleDetectionData(data);
  }

  addStreetLightDetectionData(data):Observable<any>{
    return this.server.addStreetLightDetectionData(data);
  }

  onTriggerWMSMap(event , option) {
    // console.log(option , event.checked)
    option.checked = event.checked
    this.triggerWMSLayerMap.next(option)
    
  }
  
  onTriggerWMSMap2(option: string) {
    this.triggerWMSLayerMap2.next(option)
  }


  changeWindowScreen(event, screen, windowType1) {
    let obj = {
      dataType: event.value,
      screen: screen,
      type: windowType1
    }
    if(screen == 'window1' || screen == 'two_window1'){
      this.imageArray1 = []
      let cont1 = document.getElementById('InspPanImg1');
      cont1.innerHTML = '';
    }
    else if(screen == 'window2' || screen == 'two_window2'){
      this.imageArray2 = []
      let cont1 = document.getElementById('InspPanImg2');
      cont1.innerHTML = '';
    }
    else if(screen == 'window3'){
      this.imageArray3 = []
      let cont1 = document.getElementById('InspPanImg3');
      cont1.innerHTML = '';
    }
    else if(screen == 'window4'){
      this.imageArray4 = []
      let cont1 = document.getElementById('InspPanImg4');
      cont1.innerHTML = '';
    }
    console.log(event, screen, windowType1)
    this.triggerChangeWindow.next(obj);
    // this.mapService.triggerChangeWindow = new Subject()
  }

  triggerMapAnalysis(url, window) {
    let obj: any = {
      url: url,
      window: window
    }
    this.triggerChangeWindowAnalysis.next(obj);

  }

  triggerMapAnalysisPointCloud(url, window) {
    let obj: any = {
      url: url,
      window: window
    }
    this.triggerChangeWindowAnalysisPointCloud.next(obj)
  }

    //////////// get all projects id's//////////////
    // getAllDetectionProjects(reqObj){
    //   return this.server.getAllProjects(reqObj)
    // }

    async getAllDetectionProjects(reqObj){
      return await this.server.getAllProjects(reqObj)
    }
    ///////////////////////////HeatMap////////////////////////////////////////////

    // removeHeatMapgeojson(i){
    //   const heatMapdata = this.heatMapOverlay.get(i);
    //   if(heatMapdata){
    //     heatMapdata.event.target.style.color = '';
    //     heatMapdata.data.setMap(null);
    //   }
    //   // this.heatMapOverlay.delete(i);
    // }


    /// add heatMap on map
    // heatMapPopulate(data){
    //   return new google.maps.visualization.HeatmapLayer({
    //     data,
    //     map: this.map,
    //   });
    // }     
    
    async getXlsxGeoJsonFromPostgres(table) {
      console.log(table);
      // this.spinner.emit('loader', true);
      const form = new FormData();
      form.append("input_tablename", table);
      return await this.http
        .post("https://ai.heliware.co.in/postgre/postgres_2_geo_wo_geom_const/", form)
        // .post("https://ai.heliware.co.in/postgre/postgre_to_geo/", form)
        .toPromise();
    }

    uploadXlsxData(data) {
      this.spinner.emit('loader', true);
      console.log(data);  
      return this.http.post<any>(
          `https://ai.heliware.co.in/postgre/xlsx_csv_2_postgres/`,data);
    }

    addItemExcel(data){
      return this.server.addItemExcelData(data)
    }

    getItem(){
      return this.server.getAllItem()
    }

    getExcelData(){
      return this.server.getExcelItem()
    }

    getUser(creater_id){
      return this.server.getUserData(creater_id)
    }

    UserTable_name(id){
      return this.server.getUserTable_name(id)
    }
    getAdmin(id){
      return this.server.getadminData(id)
    }
    
    deleteItem(i:any){
      console.log(i,'=================================ii')
      return this.server.deleteAllItem(i)
    }
    deleteItems(i:any){
      console.log(i,'=================================ii')
      return this.server.deleteAllItems(i)
    }
  getXLSXData(data) {
    const formData = new FormData();
    formData.append("input_tablename", data)
    return this.http.post<any>(
      `https://ai.heliware.co.in/postgre/postgres_2_geo_wo_geom_const/`, formData);
  }

  


  getSiteData(id){
    return this.server.getSingleSite(id);
  }

  uploadfileSite(data){
    console.log(data,'data1');
    return this.server.getKmlUploadedUrl(data)
  }
  
  addComments(data){
    return this.server.userComments(data)
  }

  getComments(id){
    return this.server.getUserComments(id)
  }

  updateComments(data){
    return this.server.updateUserComments(data)
  }

  deleteComment(id){
    return this.server.deleteUserComments(id)
  }
  
  editPanName(data){
    return this.server.getPanItem(data)
  }

  deletePanImage(data){
    return this.server.getPanName(data)
  }

  editMap(data){
    return this.server.getWMSLayer(data)
  }

  deleteWms(data){
    return this.server.getDeleteWms(data)
  }

  editRes(data){
    return this.server.getEditItem(data)
  }
  deletePointCloud(data){
    return this.server.getdeleteItem(data)
  }

  editGround(data){
    return this.server.getEditedImg(data)
  }

  deleteGround(data){
    return this.server.getGroundName(data)
  }

  editDroneName(data){
    return this.server.getDroneName(data)
    
  }

  contractorUser(data){
     return this.server.contractorUser(data)
  }

  // deleteFileSave(data){
  //   return this.server.getFile(data)
  // }

  // editItem(data){
  //   return this.server.getEditFile(data)
  // }

  addExcelTable(data){
    return this.server.addExcelData(data)
  }

  addExcelColumnData(data){
    return this.server.addExcelColumnData(data)
  }

  addExcelRowData(data){
    return this.server.addExcelRowData(data)
  }

  getExcelColumnData(formData){
    return this.server.getExcelColumnData(formData)
  }

  onTriggerChangeDetection(option){
    this.triggerTMSLayerMap.next(option)
  }

  fetchAssignedProjects(data) {
    return this.server.getAssignedProject(data)
  }


  addNewRow(table, formValue) {
    console.log(table);
    console.log(formValue)
    const form = new FormData();
    form.append('tablename',table);
    form.append('add_data', JSON.stringify( formValue));
    return  this.http
      .post("https://ai.heliware.co.in/postgre/postgis_construction_add_data_without_geom/",form)
  }

  // updateCount(data){
  //   console.log(data,'dat==================>>>>>>>>>')
  //   return this.server.updatePdfCount(data);
  // }

  convertUrlToFile(url: string) {
    return this.http.get(url, { responseType: 'blob' })
  }

  getChangeDetectionPolygons(data){
    return this.http.post('https://ai.heliware.co.in/file_conversion/color_percentage_based_on_polygon_from_a_geotif/',data)
  }
}