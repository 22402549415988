import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// import { ColorPickerModule } from 'primeng/colorpicker';

@Component({
  selector: 'app-cut-fill-estimation',
  templateUrl: './cut-fill-estimation.component.html',
  styleUrls: ['./cut-fill-estimation.component.scss']
})
export class CutFillEstimationComponent implements OnInit {
  polygonHeight: number = 0;
  referenceElevation: number = 0;
  selectedColor: string = '#ff0000';  // Default color

  constructor(private dialogRef: MatDialogRef<CutFillEstimationComponent>) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  submitForm() {
    const formData = {
      // polygonHeight: this.polygonHeight,
      referenceElevation: this.referenceElevation,
      selectedColor: this.selectedColor
    };
    console.log('Form Data:', formData);
    this.dialogRef.close(formData);
  }

}
