import { Injectable } from "@angular/core";
import { aiprojectData } from "../models/aimap.interface";
import { Observable, Subject } from "rxjs";
import { ServerService } from "../server.service";
import { AuthService } from "../auth/auth.service";
import { LayerTree } from "../models/layer-tree.interface";
import { addlayersinfo } from "../models/add-layers.interface";
import { SocketAiMap } from "../socket-services/socket-aimap";
import { attribute } from "../models/attribute.interface";
import { LeafletIcons } from "../models/leafletIcons.interface";
import { StandardData } from "../models/standard-data.interface";
// import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CreateDataScienceComponent } from "./nav/create-data-science/create-data-science.component";
import {
  ScatterplotLayer,
  GeoJsonLayer,
  ArcLayer,
  TextLayer,
} from "@deck.gl/layers";
import { MapboxLayer } from "@deck.gl/mapbox";
import { bbox } from "@turf/turf";
import Axios from "axios";
// import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
const BACKENDURL = environment.apiURL;

@Injectable({
  providedIn: "root",
})
export class AiMapService {
  private projectData: aiprojectData;

  get project() {
    return this.projectData;
  }

  private projectList: Array<{ id: string; item: string; image: string }>;

  // Site page : Project List for the site
  projectsListChanged: Subject<
    Array<{ id: string; item: string; image: string }>
  > = new Subject();

  // Routing Features
  // routeWayPoint: Subject<{color:string,waypoints :Array<any>}> = new Subject();
  routingChanged: Subject<Array<{ lat: number; lng: number }>> = new Subject();
  routeModechanged: Subject<boolean> = new Subject();
  saveReportChanged: Subject<{ name: String; id: String; active: boolean }> =
    new Subject();
  gotKeysSubject: Subject<any> = new Subject();
  gotLayersData: Subject<any> = new Subject();

  zoomFeature: Subject<{
    id: string;
    type: string;
    properties: any;
    geometry: any;
  }> = new Subject();

  // Project data for Leaflet Module
  projectdataChanged: Subject<aiprojectData> = new Subject();
  pseudoAiProjectUpdated: Subject<aiprojectData> = new Subject();
  standarddataChanged: Subject<StandardData> = new Subject();
  userDataUpdated: Subject<any> = new Subject();
  bulkDataChanged: Subject<any> = new Subject();
  visualizeGeoprocessedDataSubject: Subject<any> = new Subject();
  visualizeDataScienceSubject: Subject<any> = new Subject();
  newGeojsonLayerfromsheet: Subject<{
    name: string;
    url?: string;
    table?: string;
  }> = new Subject();
  // MapStyleChanged: Subject< "ColorCoded" | "ColorWithDot" | "ColoredCircle" | "SizedCircle" | "HeatMap" | "Clustering"> = new Subject();

  gotProject: Subject<{ layerTree: Array<LayerTree>; id: string }> =
    new Subject();

  // Layer Group and Layer Manger
  createLayer: Subject<addlayersinfo> = new Subject();
  zoomToLayer: Subject<string> = new Subject();
  layerChanged: Subject<addlayersinfo> = new Subject();
  // deletionMode: Subject<boolean> = new Subject();
  exportCKMLLayer: Subject<string> = new Subject();
  deleteLayer: Subject<addlayersinfo> = new Subject();
  toggleLayerVisibility: Subject<{ name: string; visible: boolean }> =
    new Subject();
  dataLayerVisibility: Subject<boolean> = new Subject();
  exportAllLayer: Subject<string> = new Subject();
  toggleLayerChartVisibility: Subject<boolean> = new Subject();
  toggleInLayerChartVisibility: Subject<number> = new Subject();
  // Properties Attribute
  activeAttributesChanged: Subject<Array<attribute>> = new Subject();
  addAttributeListner: Subject<attribute> = new Subject();

  // Analysis Fetures
  // chartVisibiltyChanged: Subject<boolean> = new Subject();
  radiusChanged: Subject<number> = new Subject();
  analysisMode: Subject<boolean> = new Subject();

  isochroneMode: Subject<boolean> = new Subject();
  isochroneListner: Subject<any> = new Subject();
  createIsochrone: Subject<any> = new Subject();
  getIsochrone: Subject<any> = new Subject();
  isSpinner: Subject<boolean> = new Subject();
  // gotProcessingData: Subject<any> = new Subject();

  recivedRouteData: Subject<{
    route: any;
    data: {
      name: string;
      "transport-mode": string;
      color: string;
      points: any;
    };
  }> = new Subject();

  // Search on map
  searchOnMapData: Subject<any> = new Subject();

  recivedIsochroneData: Subject<any> = new Subject();
  gotScriptingResult: Subject<any> = new Subject();
  gotScriptingCountResult: Subject<any> = new Subject();
  constructor(
    private server: ServerService,
    private socket: SocketAiMap,
    private auth: AuthService,
    private snackbar: MatSnackBar,
    private http: HttpClient
  ) {}

  // Site page : Project List for the site
  getProjectsList(siteid: string) {
    this.server.getAiMapProjects(siteid).subscribe((data) => {
      this.projectList = data;
      this.projectsListChanged.next(this.projectList);
    });
  }
  createProject(projectData: aiprojectData) {
    this.server.createAiMapProject(projectData).subscribe((data) => {
      this.projectList.push({
        id: data.id,
        item: projectData.item,
        image: projectData.image,
      });
      this.projectsListChanged.next(this.projectList);
    });
  }

  pseudofetchAIProject(id) {
    this.server.getAiMapProject(id).subscribe(
      (res) => {
        this.pseudoAiProjectUpdated.next(res.projectData);
      },
      (err) => {
        this.snackbar.open("Cannot fetch", "OK", {
          duration: 3000,
        });
      }
    );
  }

  // Get Project Data for Leaflet Module in leaflet-map component
  getProject(id: string) {
    this.server.getAiMapProject(id).subscribe((data) => {
      this.projectData = data.projectData;
      this.projectdataChanged.next(this.projectData);
    });
  }

  getProjectReport(id: string) {
    this.server.getAiMapProjectReport(id).subscribe((data) => {
      this.projectData = data.projectData;
      this.projectdataChanged.next(this.projectData);
    });
  }

  updateProject(projectData: aiprojectData, id: string, isupdate?: boolean) {
    if (isupdate) this.projectdataChanged.next(projectData);
    this.projectData = projectData;
    this.server.updateAiMapProject(projectData, id).subscribe((data) => {
      // console.log(data)
    });
  }

  updateProjectwithGeoJson(projectData: aiprojectData, id: string) {
    // this.projectdataChanged.next(projectData);
    this.projectData = projectData;
    this.server.updateAiMapProject(projectData, id).subscribe((data) => {
      console.log(data);
    });
  }

  async writeGeoJsonIntoPostgres(url) {
    const form = new FormData();
    form.append("input_file", url);
    return await this.http
      .post("https://ai.heliware.co.in/postgre/data_upload/", form)
      .toPromise();
  }

  async getGeoJsonFromPostgres(table) {
    const form = new FormData();
    form.append("input_tablename", table);
    return await this.http
      .post("https://ai.heliware.co.in/postgre/postgre_to_geo/", form)
      .toPromise();
  }

  async layerQuery(query) {
    console.log(query);

    const form = new FormData();
    form.append("input_query", query);
    return await this.http
      .post("https://ai.heliware.co.in/postgre/postgres_script_geo_data/", form)
      .toPromise();
  }

  async jsonToXls(json) {
    return await this.http
      .post(`${BACKENDURL}/aimap/jsonxls`, json)
      .toPromise();
  }

  async saveGeojsonDataToPostgres(data) {
    const form = new FormData();
    form.append("input_data", JSON.stringify(data));
    return await this.http
      .post("https://ai.heliware.co.in/postgre/postgres_geodata_upload/", form)
      .toPromise();
  }

  async deleteTablePostgres(table) {
    const form = new FormData();
    form.append("input_tablename", table);
    this.http
      .post("https://ai.heliware.co.in/postgre/delete_postgres_table/", form)
      .subscribe((res) => {
        this.snackbar.open((res as any).message, "ok");
      });
  }

  // getStandardData(){
  //   this.server.getStandardData().subscribe((data)=>{
  //     this.standarddataChanged.next(data.standardData);
  //   })
  // }

  routing(data) {
    this.routingChanged.next(data);
  }

  uploadIMG(data: FormData) {
    return this.server.uploadIMG(data);
  } // Upload Image on server

  addAttribute(id: string, attribute: attribute) {
    this.server
      .addAttribute({ id: id, attribute: attribute })
      .subscribe((data) => {
        // console.log(data.message)
      });
  }

  shareProject(data: {
    id: string;
    userid: string;
    accessControl: "canEdit" | "canView";
    message: string;
  }) {
    this.server.shareAIProject(data).subscribe(
      (res) => {
        this.snackbar.open("Successfully Shared", "ok", {
          duration: 3000,
        });
        this.pseudofetchAIProject(data.id);
      },
      (error) => {
        this.snackbar.open("Something went wrong", "ok", {
          duration: 3000,
        });
      }
    );
  }

  unShareProject(data: {
    id: string;
    userid: string;
    accessControl: "canEdit" | "canView";
  }) {
    this.server.unShareAIProject(data).subscribe(
      (res) => {
        this.snackbar.open("Successfully UnShared", "ok", {
          duration: 3000,
        });
        this.pseudofetchAIProject(data.id);
      },
      (error) => {
        this.snackbar.open("Something went wrong", "ok", {
          duration: 3000,
        });
      }
    );
  }

  saveReport(report, iscreateLayer?: boolean, active?: boolean) {
    this.server.saveReport(report).subscribe((res) => {
      console.log(res);

      if (!iscreateLayer) this.isSpinner.next(false);
      this.snackbar.open(" Report Saved sucessfully", "OK");
      this.saveReportChanged.next(res);
    });
  }

  updateReport(report, isreportsame?: boolean, active?: boolean) {
    console.log(report);
    this.server.updateReport(report).subscribe((res) => {
      this.isSpinner.next(false);
      if (!isreportsame) this.saveReportChanged.next(res);
    });
  }

  sendPublicReport(sendingData) {
    console.log(sendingData);

    this.server.sendPublicReport(sendingData).subscribe((res) => {
      // console.log(res);
    });
  }

  fetchLayersData(id) {
    this.isSpinner.next(true);

    this.http.get<any>(`${BACKENDURL}/aimap/keys/` + id).subscribe((keys) => {
      this.gotLayersData.next(keys);
    });
  }

  uploadBulkData(data, name, url) {
    this.isSpinner.next(true);
    this.server.uploadbulkData(data).subscribe((res) => {
      this.gotKeysSubject.next({ keys: res.columns, name: name, url: url });
      this.isSpinner.next(false);
    });
  }

  // deleteBulkData(name){
  //   this.http.delete<any>(`${BACKENDURL}/aimap/bulkdata/`+name).subscribe(r=>{
  //     console.log(r)
  //   })
  // }

  // deleteFile(u){
  //   this.http.post<any>(`${BACKENDURL}/aimap/deletefiles`,{'url':u.toString()}).subscribe(r=>{
  //     console.log(r)
  //   })
  // }

  //   updateBulkData(data,name){
  //     this.isSpinner.next(true);

  //     this.server.updatebulkData(data).subscribe(res=>{
  //       this.newGeojsonLayerfromsheet.next({name:name, url: res});
  //       this.isSpinner.next(false);

  //     })
  // }

  updateBulkData(data, name) {
    this.isSpinner.next(true);

    this.server.updatebulkData(data).subscribe((res) => {
      this.newGeojsonLayerfromsheet.next({ name: name, table: res.table_name });
      this.isSpinner.next(false);
    });
  }

  getIsochroneData(req) {
    this.isSpinner.next(true);
    this.server.getIsochrone(req).subscribe((data) => {
      this.recivedIsochroneData.next(data);
      this.isSpinner.next(false);
    });
  }
  ///////////data Science////////////////////
  datasciencesubject: Subject<any> = new Subject();
  datacleaning: Array<any> = [];
  datacleaningUpdated: Subject<Array<any>> = new Subject();
  map: mapboxgl.Map;

  async getdeldatascience(data, type, name) {
    this.isSpinner.next(true);
    console.log(data);
    const res = await this.http
      .post(
        "https://ai.heliware.co.in/api/data_science/attribute_delete/",
        data
      )
      .toPromise();
    this.datacleaning.push({ name: name, data: res, visibility: true });
    console.log(this.datacleaning[0].data);
    let k = this.datacleaning;
    this.datacleaningUpdated.next(this.datacleaning);
    this.isSpinner.next(false);
  }

  async getdatascienceJsondataa(key, value, type, name) {
    this.datacleaning.push({
      name: name,
      att_value: value,
      p_name: key,
      type: type,
    });
    console.log(this.datacleaning);
    let k = this.datacleaning;
    this.datacleaningUpdated.next(k);
    console.log(k);
  }

  async getdatascienceJsondata(url, data, type, name) {
    console.log(url);
    console.log(data);

    this.datacleaning.push({
      name: name,
      data: data,
      urldata: url,
      type: type,
    });
    console.log(this.datacleaning);
    let k = this.datacleaning;
    this.datacleaningUpdated.next(k);
    console.log(k);
  }

  onCallDataScienceee(name, data, mode) {
    //  console.log(data)
    //  console.log(mode)
    this.isSpinner.next(true);
    this.http
      .post<any>(
        `https://ai.heliware.co.in/api/data_science/attribute_delete/`,
        data
      )
      .subscribe((result) => {
        //  let ress= this.http.post<any>(`https://ai.heliware.co.in/api/data_science/attribute_delete/`,data).toPromise()
        this.isSpinner.next(false);
        console.log(result);
        let datasci = {
          name: name,
          mode: mode,
          data: result,
        };
        this.visualizeDataScienceSubject.next(datasci);
        console.log(datasci);
      });
  }
  onDataDetails(name, data, mode) {
    console.log(data);
    console.log(mode);
    this.isSpinner.next(true);
    this.http
      .post<any>(
        `https://ai.heliware.co.in/api/data_science/data_details/`,
        data
      )
      .subscribe((result) => {
        //  let ress= this.http.post<any>(`https://ai.heliware.co.in/api/data_science/attribute_delete/`,data).toPromise()
        console.log(result);
        console.log(Object.keys(result));
        console.log(Object.values(result));
        console.table(result);
        let datasci = {
          name: name,
          mode: mode,
          data: result,
        };
        this.visualizeDataScienceSubject.next(datasci);
        //  console.log(datasci)
        this.isSpinner.next(false);
        //  this.snackbar.open(`Key ${Object.keys(result)} Values ${Object.values(result)} `, 'Close', {
        //   horizontalPosition: 'center',
        //   verticalPosition: 'top',

        // });
      });
  }
  onSeperateAttribute(name, data, mode) {
    // console.log(data)
    // console.log(mode)
    this.isSpinner.next(true);
    this.http
      .post<any>(
        `https://ai.heliware.co.in/api/data_science/attribute_separate/`,
        data
      )
      .subscribe((result) => {
        //  let ress= this.http.post<any>(`https://ai.heliware.co.in/api/data_science/attribute_delete/`,data).toPromise()
        console.log(result);
        let datasci = {
          name: name,
          mode: mode,
          data: result,
        };
        this.visualizeDataScienceSubject.next(datasci);
        //  console.log(datasci)
        this.isSpinner.next(false);
      });
  }

  onAttributeCase(name, data, mode) {
    console.log(data);
    console.log(mode);
    this.http
      .post<any>(
        `https://ai.heliware.co.in/api/data_science/change_attribute_case/`,
        data
      )
      .subscribe((result) => {
        //  let ress= this.http.post<any>(`https://ai.heliware.co.in/api/data_science/attribute_delete/`,data).toPromise()
        console.log(result);
        let datasci = {
          name: name,
          mode: mode,
          data: result,
        };
        this.visualizeDataScienceSubject.next(datasci);
        console.log(datasci);
      });
  }

  onAddUpdate(name, data, mode) {
    console.log(data);
    console.log(mode);
    this.http
      .post<any>(
        `https://ai.heliware.co.in/api/data_science/attribute_update/`,
        data
      )
      .subscribe((result) => {
        //  let ress= this.http.post<any>(`https://ai.heliware.co.in/api/data_science/attribute_delete/`,data).toPromise()
        console.log(result);
        let datasci = {
          name: name,
          mode: mode,
          data: result,
        };
        this.visualizeDataScienceSubject.next(datasci);
        console.log(datasci);
      });
  }

  onFillValue(name, data, mode) {
    console.log(data);
    console.log(mode);
    this.http
      .post<any>(
        `https://ai.heliware.co.in/api/data_science/fillna_values/`,
        data
      )
      .subscribe((result) => {
        //  let ress= this.http.post<any>(`https://ai.heliware.co.in/api/data_science/attribute_delete/`,data).toPromise()
        console.log(result);
        let datasci = {
          name: name,
          mode: mode,
          data: result,
        };
        this.visualizeDataScienceSubject.next(datasci);
        console.log(datasci);
      });
  }
  async delDataCleaning(data) {
    console.log(data);
    this.http
      .post<any>(
        `https://ai.heliware.co.in/api/data_science/attribute_delete/`,
        data
      )
      .subscribe(async (result) => {
        //  let ress= this.http.post<any>(`https://ai.heliware.co.in/api/data_science/attribute_delete/`,data).toPromise()
        console.log(result);
        const res = await Axios.get(result);
        console.log(res.data);
        console.log(res);
        // let dataaa = JSON.stringify(res)
        // this.adddatacleaning(res);
      });
  }

  tableDataClean(data) {
    console.log(data);
    this.http
      .post<any>(
        `https://ai.heliware.co.in/api/data_science/data_details/`,
        data
      )
      .subscribe((result) => {
        console.log(result);
      });
  }

  getRouteData(data, mode?: string) {
    this.isSpinner.next(true);
    let form = new FormData();
    form.append("apikey", environment.heligeo_key);
    form.append("list", JSON.stringify(data.points));
    form.append("transport-mode", mode ? mode : "drive");
    this.http
      .post<any>(`https://nav.heliware.co.in/api/route/`, form)
      .subscribe((result) => {
        console.log(result);
        this.recivedRouteData.next({
          route: result,
          data: {
            name: data.name,
            "transport-mode": mode ? mode : "drive",
            color: data.color,
            points: data.points,
          },
        });
        this.isSpinner.next(false);
      });
  }

  async getRouteDataDirect(data, mode?: string) {
    this.isSpinner.next(true);
    let form = new FormData();
    form.append("apikey", environment.heligeo_key);
    form.append("list", JSON.stringify(data.points));
    form.append("transport-mode", mode ? mode : "drive");
    return await this.http
      .post<any>(`https://nav.heliware.co.in/api/route/`, form)
      .toPromise();
  }

  async getprocessingData(id) {
    return this.http
      .get<any>(`${BACKENDURL}/aimap/geoprocessing/` + id)
      .toPromise();
  }

  async deleteGeoprocessedData(id) {
    return this.http
      .get<any>(`${BACKENDURL}/aimap/geoprocessing/delete/` + id)
      .toPromise();
  }
  async deleteDataSci(id) {
    return this.http
      .get<any>(`${BACKENDURL}/aimap/datascience/delete/` + id)
      .toPromise();
  }

  async getDataSci(id) {
    return this.http
      .get<any>(`${BACKENDURL}/aimap/datascience/` + id)
      .toPromise();
  }

  getUnion(data, name, type) {
    this.isSpinner.next(true);
    this.http
      .post<any>(`https://ai.heliware.co.in/api/geoprocessing/union/`, data)
      .subscribe((result) => {
        this.isSpinner.next(false);
        let visudata = {
          name: name,
          type: type,
          data: result,
          visibility: true,
        };
        this.visualizeGeoprocessedDataSubject.next(visudata);
      });
  }

  getIntersection(data, name, type) {
    this.isSpinner.next(true);
    this.http
      .post<any>(
        `https://ai.heliware.co.in/api/geoprocessing/intersection/`,
        data
      )
      .subscribe((result) => {
        this.isSpinner.next(false);
        let visudata = {
          name: name,
          type: type,
          data: result,
          visibility: true,
        };
        this.visualizeGeoprocessedDataSubject.next(visudata);
      });
  }

  getPointWithInPolygon(data, name, type) {
    this.isSpinner.next(true);
    this.http
      .post<any>(
        `https://ai.heliware.co.in/api/geoprocessing/pointcheck/`,
        data
      )
      .subscribe((result) => {
        this.isSpinner.next(false);
        let visudata = {
          name: name,
          type: type,
          data: result,
          visibility: true,
        };
        this.visualizeGeoprocessedDataSubject.next(visudata);
      });
  }

  getShortDistance(data, name, type) {
    this.isSpinner.next(true);
    this.http
      .post<any>(`https://ai.heliware.co.in/api/geoprocessing/knn/`, data)
      .subscribe((result) => {
        this.isSpinner.next(false);
        let visudata = {
          name: name,
          type: type,
          data: result,
          visibility: true,
        };
        this.visualizeGeoprocessedDataSubject.next(visudata);
      });
  }

  getPointBuffer(data, name, type) {
    console.log(data);
    this.isSpinner.next(true);
    this.http
      .post<any>(
        `https://ai.heliware.co.in/api/geoprocessing/bufferpolygon/`,
        data
      )
      .subscribe((result) => {
        this.isSpinner.next(false);
        console.log(result);
        let visudata = {
          name: name,
          type: type,
          data: result,
          visibility: true,
        };
        console.log(type);
        console.log(data);
        console.log(name);
        this.visualizeGeoprocessedDataSubject.next(visudata);
        console.log(visudata);
      });
  }

  getLineBuffer(data, name, type) {
    this.isSpinner.next(true);
    this.http
      .post<any>(
        `https://ai.heliware.co.in/api/geoprocessing/bufferline/`,
        data
      )
      .subscribe((result) => {
        this.isSpinner.next(false);
        let visudata = {
          name: name,
          type: type,
          data: result,
          visibility: true,
        };
        this.visualizeGeoprocessedDataSubject.next(visudata);
      });
  }

  saveGeoprocessedData(data, id) {
    console.log(data);
    console.log(id);
    return this.http
      .post<any>(`${BACKENDURL}/aimap/geoprocessing`, { id: id, geoData: data })
      .toPromise();
  }

  saveDataScience(data, id) {
    // console.log(data)
    // console.log(id)
    return this.http
      .post<any>(`${BACKENDURL}/aimap/datascience`, { id: id, sciData: data })
      .toPromise();
  }

  saveScriptingdata(id, data) {
    return this.http
      .post<any>(`${BACKENDURL}/aimap/scripting`, { id: id, data: data })
      .toPromise();
  }

  getScriptingData(id) {
    return this.http
      .get<any>(`${BACKENDURL}/aimap/scripting/` + id)
      .toPromise();
  }

  deleteScriptingData(id) {
    return this.http
      .get<any>(`${BACKENDURL}/aimap/scripting/delete/` + id)
      .toPromise();
  }

  // Elevation of Cut and Fill
  getElevationCutFill(data) {
    return this.http
      .post<any>('https://ai.heliware.co.in/api/dem_elevation_max_min_percentage/', data)
  }

  // Elevation of Cut and Fill
  getDemCutFillVolume(data) {
    return this.http
      .post<any>('https://ai.heliware.co.in/api/dem_cut_fill_volume/', data)
  }

  getTiling(data) {
    return this.http
      .post<any>('https://ai.heliware.co.in/file_conversion/user_upload_dem_rgb_tiling/', data)
  }


  getUploadingRGBDemTiling(data) {
    return this.http
      .post<any>('https://ai.heliware.co.in/file_conversion/user_upload_dem_rgb_tiling_s3/', data)
  }

  findQuery(q) {
    this.http
      .post<any>(`${environment.aiApiUrl}/scripting/`, q)
      .subscribe((result) => {
        this.gotScriptingResult.next({
          data: result,
          type: q.get("type"),
          query: q.get("query"),
        });
      });
  }

  findQueryC(q) {
    // this.http.post<any>(`${environment.aiApiUrl}/scripting/`,q).subscribe(result=>{
    //   console.log(result);
    //   // let data = JSON.stringify(result);
    //   let data = Object.keys(result);
    //   let dataV = Object.values(result);
    //   console.log(data);
    //   alert(data + ' = ' +  dataV );
    // })
    this.http
      .post<any>(`${environment.aiApiUrl}/scripting/`, q)
      .subscribe((result) => {
        console.log(result);
        this.gotScriptingCountResult.next(result);
      });
  }

  findQueryInstant(q) {
    return this.http
      .post<any>(`${environment.aiApiUrl}/scripting/`, q)
      .toPromise();
  }

  // Socket Request
  joinRoom(id: string) {
    this.socket.emit("join", {
      token: this.auth.getToken(),
      id,
    });
  } // Join Socket

  addFeature(feature: any) {
    this.socket.emit("add feature", feature);
  } // Add new layer(Feature) to memory

  deleteFeature(feature: any) {
    this.socket.emit("delete feature", feature);
  }

  showScriptingUiData(data: any) {
    console.log(data);
  }
}
