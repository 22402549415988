<div style="height: 100%">
    <div class="potree_container" style="
      position: relative;
      width: 100%;
      height: 92%;
      right: 0px;
      top: -5px;
      background-image: linear-gradient(180deg, #e2dcdc, #67a6e7);">
        <div id="potree_render_area" style="background-image: linear-gradient(180deg, #e2dcdc, #67a6e7)">
            <!-- <div id="potree_toolbar"></div> -->
        </div>
        <div>
            <div id="potree_sidebar_container" class="">
                <div>
                    <div id="potree_menu" class="accordion">
                        <h3 id="menu_appearance">
                            <span data-i18n="tb.rendering_opt"></span>
                        </h3>
                        <div>
                            <ul class="pv-menu-list">
                                <li>
                                    <span data-i18n="appearance.nb_max_pts"></span>:
                                    <span id="lblPointBudget"></span>
                                    <div id="sldPointBudget"></div>
                                </li>
                                <li>
                                    <span data-i18n="appearance.field_view"></span>:
                                    <span id="lblFOV"></span>
                                    <div id="sldFOV"></div>
                                </li>

                                <div class="divider"><span>Resolution</span></div>

                                <li>
                                    <label><input type="checkbox" id="chkEDLEnabled" /><span
                                            data-i18n="appearance.edl_enable"></span></label>
                                </li>
                                <li>
                                    <span data-i18n="appearance.edl_radius"></span>:
                                    <span id="lblEDLRadius"></span>
                                    <div id="sldEDLRadius"></div>
                                </li>
                                <li>
                                    <span data-i18n="appearance.edl_strength"></span>:
                                    <span id="lblEDLStrength"></span>
                                    <div id="sldEDLStrength"></div>
                                </li>
                                <li>
                                    <span data-i18n="appearance.edl_opacity"></span>:
                                    <span id="lblEDLOpacity"></span>
                                    <div id="sldEDLOpacity"></div>
                                </li>

                                <li>
                                    <select id="background_options">
                                        <option id="background_options_skybox" value="skybox">
                                            Skybox
                                        </option>
                                        <option id="background_options_black" value="black">
                                            Black
                                        </option>
                                        <option id="background_options_white" value="white">
                                            White
                                        </option>
                                        <option id="background_options_none" value="null">
                                            None
                                        </option>
                                    </select>
                                </li>

                                <div class="divider"><span>Other</span></div>

                                <li>
                                    <select id="splat_quality_options">
                                        <option id="splat_quality_options_standard" value="standard">
                                            Standard
                                        </option>
                                        <option id="splat_quality_options_hq" value="hq">
                                            High Quality
                                        </option>
                                    </select>
                                </li>
                            </ul>
                        </div>

                        <h3 id="menu_tools"><span data-i18n="tb.tools_opt"></span></h3>
                        <div class="pv-menu-list">
                            <div class="divider"><span>GIS Tools</span></div>

                            <li id="tools"></li>
                        </div>
                        <h3 id="menu_scene"><span data-i18n="tb.scene_opt"></span></h3>
                        <div class="pv-menu-list">
                            <div id="scene_objects"></div>

                            <div class="divider"><span>Properties</span></div>

                            <div id="scene_object_properties"></div>
                        </div>
                        <h3 id="menu_filters">
                            <span data-i18n="tb.filters_opt"></span>
                        </h3>
                        <div>
                            <div class="divider"><span>Classification</span></div>

                            <ul id="classificationList" class="pv-menu-list"></ul>

                            <div class="divider"><span>Returns</span></div>

                            <div id="return_filter_panel">
                                <ul class="pv-menu-list">
                                    <li>
                                        <span data-i18n="filters.return_number"></span>:
                                        <span id="lblReturnNumber"></span>
                                        <div id="sldReturnNumber"></div>
                                    </li>
                                    <li>
                                        <span data-i18n="filters.number_of_returns"></span>:
                                        <span id="lblNumberOfReturns"></span>
                                        <div id="sldNumberOfReturns"></div>
                                    </li>
                                </ul>
                            </div>

                            <div class="divider"><span>Point Source ID</span></div>

                            <div>
                                <ul class="pv-menu-list" id="pointsourceid_filter_panel"></ul>
                            </div>
                            <div id="gpstime_filter_panel">
                                <ul class="pv-menu-list">
                                    <div id="gpstime_multilevel_range_container">
                                        <li>
                                            <span style="display: flex"> </span>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div></div>
                </div>
            </div>
        </div>
        <div style=" position: absolute; z-index: 10000; right: 70px; bottom: 10px; display: flex;">
            <!-- <p>Powered by</p> -->
            <!-- <img src="logo.png" alt="HeliwareLogo" width="150" height="40" style="margin-top: 4px" /> -->
        </div>
        <!-- <div style=" position: absolute; z-index: 10000; left: 10px; top: 10px; padding: 0 10px 0 0px;">
            <input type="text" id="myInput" class="myInputClass" placeholder="Enter URL" />
            <br />
            <input type="text" id="myIn" class="myInputClass" placeholder="Enter Name" />
            <br />
            <button type="button" id="myBtn" (click)="test()">
                Show Heli-PointCloud
            </button>
        </div> -->
        <!-- <input type="file" id="myIng" style="
        position: absolute;
        z-index: 10000;
        left: 10px;
        bottom: 24px;
        padding: 0 10px 0 0px;
      " /> -->
      <div style="position: relative;
      z-index: 99999999999;
      color: azure;
      top: 20px;
      left: 40px;
      width: 41px;
      padding: 10px;" (click)="toggle2Dswitch()"><span>
         <span><button mat-mini-fab aria-label="2D">2D</button></span>
      </span></div>
    </div>
</div>