<div cdkDrag cdkDragRootElement=".cdk-overlay-container" class="dialog-container">
  <!-- ✅ Close Button -->
  <button class="close-button" (click)="closeDialog()">✖</button>

  <div class="chart-container">
    <canvas #barChart></canvas>
  </div>

  <div class="info">
    <p><strong>1. Max Elevation:</strong> {{ maxElevation }} meters</p>
    <p><strong>2. Min Elevation:</strong> {{ minElevation }} meters</p>
    <p style="color:green"><strong>3. Cut Volume:</strong> {{ cutVolume }}</p>
    <p style="color:blue"><strong>4. Fill Volume:</strong> {{ fillVolume }}</p>
  </div>
</div>