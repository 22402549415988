
<div>
  <div class="header">
    <div>
      <img src="assets/Navbar.png" style="height: 60px; width: 100vw" />
    </div>

    <app-toolbar
      (SideNavToggle)="sidenav.toggle()"
      style="
        position: fixed;
        /* background-color: #4b8dce; */
        margin-top: -50px;
      "
    ></app-toolbar>
    <div>
      <img src="assets/logo_1.png" class="imgs" />
    </div>

    <div class="time">
      {{ currentDate | date }} | {{ currentDate | date : "hh:mm:ss a" }}
    </div>
    <div class="search-box">
      <div
        class="box"
        style="
          height: 22.5px;
          display: flex;
          position: absolute;
          z-index: 9999;
          top: 28px;
          left: 1200px;
        "
      >
        <input
          class="search"
          style="
            color: black;
            font-size: 15px;
            padding: 6px;
            border-radius: 12px;
          "
          type="text"
          matInput
          [(ngModel)]="placeHint"
          (keyup)="placesearch()"
          autocomplete="off"
        />
        <mat-icon color="primary">search</mat-icon>
      </div>

      <mat-list
        *ngIf="resultPlace.length > 0"
        style="
          position: fixed;
          right: 5px;
          top: 100px;
          z-index: 1200;
          border: 1px solid grey;
          width: 400px;
          height: 250px;
          overflow-y: scroll;
          background-color: white;
        "
      >
        <mat-list-item
          *ngFor="let res of resultPlace; let i = index"
          (click)="selectedPlace(i)"
          style="margin: 10px; font-size: 13px; width: 380px; cursor: pointer"
        >
          {{ res.label }}
        </mat-list-item>
      </mat-list>
    </div>
  </div>
  <!-- <mat-sidenav #sidenav>
      <app-sidenav-list (closeSideNav)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav> -->
  <mat-sidenav-container>
    <mat-sidenav #sidenav style="position: fixed; top: 64px">
      <app-sidenav-list (closeSideNav)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <!-- <app-toolbar (SideNavToggle)="sidenav.toggle()"></app-toolbar> -->
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <!-- <div style="height: 22.5px;
    display: flex;
    position: absolute;
    z-index: 9999;
    top: 15px;
    left: 5px;
    cursor: pointer;">
      <button mat-icon-button (click)="openSidenav()">
          <mat-icon>menu</mat-icon>
      </button>
    </div> -->
    <!-- <app-toolbar (SideNavToggle)="sidenav.toggle()"></app-toolbar> -->
     <!-- <app-toolbar></app-toolbar> -->

  <!-- Refresh Btn  -->
  <div
    style="
      height: 22.5px;
      display: flex;
      position: absolute;
      z-index: 9999;
      top: 26px;
      left: 1450px;
      cursor: pointer;
    "
  >
    <a class="nav-item" (click)="onLogout()" matTooltip="Logout">
      <mat-icon style="color: white">input</mat-icon></a
    >
  </div>
  <div
    style="
      height: 22.5px;
      display: flex;
      position: absolute;
      z-index: 9999;
      top: 26px;
      left: 1400px;
      cursor: pointer;
    "
  >
    <a class="nav-item" (click)="navigateToHome()" matTooltip="Home">
      <mat-icon style="color: white">home</mat-icon></a
    >
  </div>
  <!-- End Refresh Btn  -->

  <div class="backgroud-map">
    <div #gmap class="background-image"></div>
  </div>
  <button
    *ngIf="currentUser !== 'vishvaraj@heliware.co.in' && showButton"
    (click)="toggleTable('divAA')"
    style="
      position: relative;
      z-index: 999;
      left: 1415px;
      bottom: 147px;
      background: linear-gradient(
        180deg,
        rgba(57, 51, 14, 0.608) 0%,
        black 100%
      );
      border: none;
      cursor: pointer;
      box-shadow: 5px 5px 15px 5px;
      border: 2px solid #e5c800;
      border-radius: 10px;
      width: 57px;
      height: 52px;
    "
    matTooltip="Overall Project Progress"
  >
    <mat-icon style="font-size: 38px; margin-right: 15px; color: orange">
      table_chart</mat-icon
    >
  </button>

  <button
    *ngIf="currentUser !== 'vishvaraj@heliware.co.in' && hideButten"
    (click)="toggleProjectTable('divA')"
    style="
      position: relative;
      z-index: 999;
      left: 1415px;
      bottom: 173px;
      background: linear-gradient(
        180deg,
        rgba(57, 51, 14, 0.608) 0%,
        black 100%
      );
      border: none;
      cursor: pointer;
      box-shadow: 5px 5px 15px 5px;
      border: 2px solid #e5c800;
      border-radius: 10px;
      width: 57px;
      height: 52px;
    "
    matTooltip="Overall Project Progress"
  >
    <mat-icon style="font-size: 38px; margin-right: 15px; color: orange">
      table_chart</mat-icon
    >
  </button>

<button *ngIf="showDiv && !chart1" (click)="toggleShowDiv('divA1')"  [ngStyle]="{ 'left': currentUser === 'vishvaraj@heliware.co.in' ? '0px' : '-56px' }" style="position: relative; bottom: 204px; background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;" matTooltip="Total Number of Projects" ><mat-icon style="font-size: 38px;margin-right: 15px;color: orange;"> donut_small</mat-icon></button>
<button *ngIf="currentUser !== 'vishvaraj@heliware.co.in' && showDiv && !chart2"(click)="toggleShowDiv2('divA2')"  style="position: absolute;  left: 0%; bottom: 173px; background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;" matTooltip="Project Expense" ><mat-icon style="font-size: 38px;margin-right: 15px;color: orange;"> attach_money</mat-icon></button>
<button class="maxDiv3" *ngIf="isNotVisible && !chart3" (click)="toggleShowDiv3('divA3')"  style="position: absolute;  left: 0%;bottom: 320px; background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;"matTooltip="Overall Project Progress" ><mat-icon style="font-size: 38px;margin-right: 15px;color: orange;">pie_chart</mat-icon></button>
<button class="maxDiv4" *ngIf="isNotVisible && !chart4" (click)="toggleShowDiv4('divA4')"  style="position: absolute;  left: 0%; bottom: 173px; background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;"matTooltip="Overall Project Progress" ><mat-icon style="font-size: 38px;margin-right: 15px;color: orange;">show_chart</mat-icon></button>
<button  class="maxDiv7" *ngIf="completion && !chart5" (click)="toggleShowDiv5('divA5')"  style="position: absolute;  left: 85%; top: 85%; background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;"matTooltip="Overall Project Progress" ><mat-icon style="font-size: 38px;margin-right: 15px;color: orange;">bar_chart</mat-icon></button>
<button  class="maxDiv8" *ngIf="currentUser !== 'vishvaraj@heliware.co.in' && showProjectChart && completion1 && !chart6" (click)="toggleShowDiv6('divA6')" style="position: absolute;  left: 82%;top: 88%; background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;" matTooltip="Project Budget"><mat-icon style="margin:9px -2px 0 -14px;font-size: 35px;color: orange;">attach_money</mat-icon></button>
<button  class="maxDiv11" *ngIf="currentUser !== 'vishvaraj@heliware.co.in' && showProjectChart && completion2 && !chart7" (click)="toggleShowDiv7('divA7')" style="position: absolute;  left: 77%;top: 88%;background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;" matTooltip="Project Timeline"><mat-icon style="margin: 0 0 0 -30px;font-size: 50px;color: orange;">multiline_chart</mat-icon></button>
<button  class="maxDiv10" *ngIf="currentUser !== 'vishvaraj@heliware.co.in' && showProjectChart && completion3 && !chart8" (click)="toggleShowDiv8('divA8')" style="position: absolute;  left: 87%;top: 88%;background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;" matTooltip="Progress"><mat-icon style="margin: 0 0 0 -30px;font-size: 50px;color: orange;">show_chart</mat-icon></button>
<button  class="maxDiv5" *ngIf="currentUser !== 'vishvaraj@heliware.co.in' && isVisible && !chart9" (click)="toggleShowDiv9('divA9')" style="position: absolute;  left: 96%;top: 65%;background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;" matTooltip="Progress"><mat-icon style="margin: 0 0 0 -30px;font-size: 50px;color: orange;">show_chart</mat-icon></button>
<button  class="maxDiv6" *ngIf="currentUser !== 'vishvaraj@heliware.co.in' && !chart10 && completion4" (click)="toggleShowDiv10('divs1')" style="position: absolute;  left: 96%;top: 50%;background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;" matTooltip="Progress"><mat-icon style="margin: 0 0 0 -30px;font-size: 50px;color: orange;">bubble_chart</mat-icon></button>
<button  class="maxDiv9" *ngIf="currentUser !== 'vishvaraj@heliware.co.in' &&  showProjectChart && !chart11 && completion5" (click)="toggleShowDiv11('divs2')" style="position: absolute;  left: 96%;top: 50%;background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;"  matTooltip="Events Date"><mat-icon style="margin: 0 0 0 -30px;font-size: 50px;color: orange;">calendar_today</mat-icon></button>

<!-- vishvaraj -->
<button  class="pro1" *ngIf="currentUser === 'vishvaraj@heliware.co.in' && chart12 && completion6" (click)="toggleShowDiv12('divs3')" style="position: absolute;  left: 96%;top: 50%;background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;"  matTooltip="Expense"><mat-icon style="margin: 0 0 0 -30px;font-size: 50px;color: orange;">attach_money</mat-icon></button>
<button  class="pro2" *ngIf="currentUser === 'vishvaraj@heliware.co.in' &&  chart13 && completion7" (click)="toggleShowDiv13('divs4')" style="position: absolute;  left: 96%;top: 60%;background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;"  matTooltip="progress"><mat-icon style="margin: 0 0 0 -30px;font-size: 50px;color: orange;">trending_up</mat-icon></button>
<button  class="pro3" *ngIf="currentUser === 'vishvaraj@heliware.co.in' && chart14 && completion8" (click)="toggleShowDiv14('divs5')" style="position: absolute;  left: 82%;top: 88%;background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;"  matTooltip="Milestone Progress"><mat-icon style="margin: 0 0 0 -30px;font-size: 50px;color: orange;">track_changes</mat-icon></button>
<button  class="pro4" *ngIf="currentUser === 'vishvaraj@heliware.co.in' && chart15 && completion9" (click)="toggleShowDiv15('divs6')" style="position: absolute;  left: 77%;top: 88%;background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;"  matTooltip="Project Timeline"><mat-icon style="margin: 0 0 0 -30px;font-size: 50px;color: orange;">multiline_chart</mat-icon></button>
<button  class="pro5" *ngIf="currentUser === 'vishvaraj@heliware.co.in' && chart16 && completion10" (click)="toggleShowDiv16('divs7')" style="position: absolute;  left: 72%;top: 88%;background: linear-gradient(180deg, rgba(57, 51, 14, 0.608) 0%, black 100%) ;border: none ; cursor: pointer; box-shadow:5px 5px 15px 5px; border: 2px solid #E5C800; border-radius: 10px;width: 57px;height: 52px;"  matTooltip="Milestone"><mat-icon style="margin: 0 0 0 -30px;font-size: 50px;color: orange;">flag</mat-icon></button>

  <form [formGroup]="form" style="position: absolute; top: 11%">
    <mat-select
      class="custom-dropdown"
      formControlName="selectedSite"
      (selectionChange)="getProjectData($event)"
      placeholder="Select site"
    >
      <mat-option *ngFor="let i of sites" [value]="i">{{ i.name }}</mat-option>
    </mat-select>
    <input
      class="custom-dropdown placeholder"
      style="margin-left: 20px; cursor: pointer"
      list="ice-cream-flavors"
      placeholder="Select project"
      (input)="getProjectChart()"
      formControlName="selectedProject"
      id="ice-cream-choice"
      name="ice-cream-choice"
    />
    <datalist id="ice-cream-flavors">
      <option *ngFor="let project of projects" [value]="project.item"></option>
    </datalist>
    
  </form>

  <!-- Excel Data -->

  <div class="table_box" [@slideInOut]="animationState" class="divA">
    <button
      (click)="toggleProjectTable('divA')"
      style="
        position: relative;
        z-index: 999;
        background-color: black;
        color: white;
        left: 1480px;
        top: 10px;
        border: none;
        cursor: pointer;
      "
    >
      <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
    </button>
    <div
      *ngIf="
        (currentUser === 'osnagarnigam@rediffmail.com' && showProjectTable) ||
        (currentUser !== 'vishvaraj@heliware.co.in' && showProjectTable)
      "
      class="dataProjectStatus"
      style="width: 98%; padding: 39px 15px"
    >
      <h4 class="titlele">Overall Project Progress</h4>
      <div
        id="dataProjectStatusTable_wrapper"
        class="dataTables_wrapper no-footer"
      >
        <div class="dataTables_length" id="dataProjectStatusTable_length">
          <!-- <label>Show <select name="dataProjectStatusTable_length" aria-controls="dataProjectStatusTable" class=""><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option></select> entries</label> -->
        </div>
        <div id="dataProjectStatusTable_filter" class="dataTables_filter">
          <label style="color: white"
            >Search:<input
              type="search"
              (input)="onSearch($event.target.value)"
              class=""
              placeholder=""
              aria-controls="dataProjectStatusTable"
          /></label>
        </div>

        <table
          *ngIf="!searchProject"
          id="dataProjectStatusTable"
          class="display dataTable no-footer"
          style="width: 100%"
          aria-describedby="dataProjectStatusTable_info"
        >
          <thead>
            <tr>
              <!-- <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" *ngFor="let i of tableHeader">{{i}}</th> -->
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Sl. No.
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Apex Committee
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Department
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Name of Project
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Responsibility/ Name of Clerk
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Status
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Start Date
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                End Date
              </th>
              <!-- <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of issue of MoM</th>
              <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of issue of GO</th>
              <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of DPR submission</th>
              <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of Publishing of RFP</th>
              <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of Technical Bid Opening</th>
              <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of Financial Bid Opening</th>
              <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of issue of work Order</th>-->
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Budget Sanctioned (in Lakhs)
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Budget Transferred (in Lakhs)
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Budget Utilised (in Lakh)
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Total Planned length of roads (in KM)
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Total Completed length of roads (in KM)
              </th>
              <!-- <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Total Poles</th> -->
              <!-- <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Total Bulbs</th> -->
            </tr>
          </thead>

          <tbody id="html_overAllProgressTable">
            <tr class="odd" *ngFor="let i of tableData">
              <td class="sorting_1">{{ i["Sl. No."] }}</td>
              <td class="sorting_1">{{ i["Apex Committee"] }}</td>
              <td class="sorting_1">{{ i["Department"] }}</td>
              <td class="sorting_1">{{ i["Name of Project"] }}</td>
              <td class="sorting_1">{{ i["Responsibility/Name of Clerk"] }}</td>
              <td class="sorting_1">{{ i["Status"] }}</td>
              <td class="sorting_1">
                {{
                  i["Start Date"] === "NaT"
                    ? ""
                    : (i["Start Date"] | date : mediumDate)
                }}
              </td>
              <td class="sorting_1">
                {{
                  i["End Date"] === "NaT"
                    ? ""
                    : (i["End Date"] | date : mediumDate)
                }}
              </td>
              <!-- <td class="sorting_1" >{{(i['Date of issue of MoM'] === 'NaT') ? '': i['Date of issue of MoM'] | date :mediumDate}}</td>
              <td class="sorting_1" >{{(i['Date of issue of GO'] === 'NaT') ? '': i['Date of issue of GO']  | date :mediumDate}}</td>
              <td class="sorting_1" >{{i['Date of DPR submission']| date :mediumDate}}</td>
              <td class="sorting_1" >{{(i['Date of Publishing of RFP' === 'NaT']) ? '' : i['Date of Publishing of RFP'] | date :mediumDate}}</td>
              <td class="sorting_1" >{{(i['Date of Technical Bid Opening']=== 'NaT') ? '' : i['Date of Technical Bid Opening'] | date :mediumDate}}</td>
              <td class="sorting_1" >{{(i['Date of Financial Bid Opening'] === 'NaT') ? '' : i['Date of Financial Bid Opening'] | date :mediumDate}}</td>
              <td class="sorting_1" >{{(i['Date of issue of work Order'] === 'NaT')? '' :i['Date of issue of work Order'] | date :mediumDate}}</td>-->
              <td class="sorting_1">
                {{
                  i["Budget Sanctioned (in Lakhs)"] === "nan"
                    ? ""
                    : i["Budget Sanctioned (in Lakhs)"]
                }}
              </td>
              <td class="sorting_1">
                {{
                  i["Budget Transferred (in Lakhs) For 25 %"] === "nan"
                    ? ""
                    : i["Budget Transferred (in Lakhs) For 25 %"]
                }}
              </td>
              <td class="sorting_1">
                {{
                  i["Budget Utilised (in Lakh)"] === "nan"
                    ? ""
                    : i["Budget Utilised (in Lakh)"]
                }}
              </td>
              <td class="sorting_1">
                {{
                  i["Total Planned length of roads (in m)"] === "nan"
                    ? ""
                    : (i["Total Planned length of roads (in m)"]
                      | number : ".2-2")
                }}
              </td>
              <td
                class="sorting_1"
                *ngIf="
                  i['completed length1'] >
                  i['Total Planned length of roads (in m)']
                "
              >
                {{
                  i["Total Planned length of roads (in m)"] | number : ".2-2"
                }}
              </td>
              <td
                class="sorting_1"
                *ngIf="
                  i['completed length1'] <=
                    i['Total Planned length of roads (in m)'] ||
                  i['completed length1'] == undefined
                "
              >
                {{ i["completed length1"] }}
              </td>
              <!-- <td class="sorting_1" >
    
                <ng-container *ngIf="i['completed length'] !== 'undefined' && i['completed length'] !== 'nan' && i['completed length'].length">
                    <li *ngFor="let n of i['completed length']">{{n.work}} : {{n.measurement}}</li>
                </ng-container>

            </td> -->
            <!-- <td class="sorting_1" >{{(i['Total Poles'] === 'nan') ? '': i['Total Poles'] |  number: '1.2-2'}}</td> -->
            <!-- <td class="sorting_1">{{(i['Total Bulbs'] === 'nan') ? '': i['Total Bulbs'] |  number: '1.2-2'}}</td> -->
            </tr>


          </tbody>
        </table>
        <table
          *ngIf="searchProject"
          id="dataProjectStatusTable"
          class="display dataTable no-footer"
          style="width: 100%"
          aria-describedby="dataProjectStatusTable_info"
        >
          <thead>
            <tr>
              <!-- <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" *ngFor="let i of tableHeader">{{i}}</th> -->
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Sl. No.
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Apex Committee
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Department
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Name of Project
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Responsibility/ Name of Clerk
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Status
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Start Date
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                End Date
              </th>
              <!-- <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of issue of MoM</th>
            <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of issue of GO</th>
            <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of DPR submission</th>
            <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of Publishing of RFP</th>
            <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of Technical Bid Opening</th>
            <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of Financial Bid Opening</th> 
            <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Date of issue of work Order</th>-->
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Budget Sanctioned (in Lakhs)
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Budget Transferred (in Lakhs)
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Budget Utilised (in Lakh)
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Total Planned length of roads (in KM)
              </th>
              <th
                class="sorting"
                tabindex="0"
                aria-controls="dataProjectStatusTable"
                rowspan="1"
                colspan="1"
                aria-label="Department Name: activate to sort column ascending"
              >
                Total Completed length of roads (in KM)
              </th>
              <!-- <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Total Poles</th> -->
              <!-- <th class="sorting" tabindex="0" aria-controls="dataProjectStatusTable" rowspan="1" colspan="1" aria-label="Department Name: activate to sort column ascending" >Total Bulbs</th> -->
            </tr>
          </thead>

          <tbody id="html_overAllProgressTable">
            <tr class="odd" *ngFor="let i of filteredItems">
              <td class="sorting_1">{{ i["Sl. No."] }}</td>
              <td class="sorting_1">{{ i["Apex Committee"] }}</td>
              <td class="sorting_1">{{ i["Department"] }}</td>
              <td class="sorting_1">{{ i["Name of Project"] }}</td>
              <td class="sorting_1">{{ i["Responsibility/Name of Clerk"] }}</td>
              <td class="sorting_1">{{ i["Status"] }}</td>
              <td class="sorting_1">
                {{
                  i["Start Date"] === "NaT"
                    ? ""
                    : (i["Start Date"] | date : mediumDate)
                }}
              </td>
              <td class="sorting_1">
                {{
                  i["End Date"] === "NaT"
                    ? ""
                    : (i["End Date"] | date : mediumDate)
                }}
              </td>
              <!-- <td class="sorting_1" >{{(i['Date of issue of MoM'] === 'NaT') ? '': i['Date of issue of MoM'] | date :mediumDate}}</td>
            <td class="sorting_1" >{{(i['Date of issue of GO'] === 'NaT') ? '': i['Date of issue of GO']  | date :mediumDate}}</td>
            <td class="sorting_1" >{{i['Date of DPR submission']| date :mediumDate}}</td>
            <td class="sorting_1" >{{(i['Date of Publishing of RFP' === 'NaT']) ? '' : i['Date of Publishing of RFP'] | date :mediumDate}}</td>
            <td class="sorting_1" >{{(i['Date of Technical Bid Opening']=== 'NaT') ? '' : i['Date of Technical Bid Opening'] | date :mediumDate}}</td>
            <td class="sorting_1" >{{(i['Date of Financial Bid Opening'] === 'NaT') ? '' : i['Date of Financial Bid Opening'] | date :mediumDate}}</td>
            <td class="sorting_1" >{{(i['Date of issue of work Order'] === 'NaT')? '' :i['Date of issue of work Order'] | date :mediumDate}}</td>-->
              <td class="sorting_1">
                {{
                  i["Budget Sanctioned (in Lakhs)"] === "nan"
                    ? ""
                    : i["Budget Sanctioned (in Lakhs)"]
                }}
              </td>
              <td class="sorting_1">
                {{
                  i["Budget Transferred (in Lakhs) For 25 %"] === "nan"
                    ? ""
                    : i["Budget Transferred (in Lakhs) For 25 %"]
                }}
              </td>
              <td class="sorting_1">
                {{
                  i["Budget Utilised (in Lakh)"] === "nan"
                    ? ""
                    : i["Budget Utilised (in Lakh)"]
                }}
              </td>
              <td class="sorting_1">
                {{
                  i["Total Planned length of roads (in m)"] === "nan"
                    ? ""
                    : (i["Total Planned length of roads (in m)"]
                      | number : "1.2-2")
                }}
              </td>
              <!-- <td class="sorting_1" >{{(i['completed length'] === 'nan') ? '' : i['completed length']}}</td> -->
              <!-- <td class="sorting_1" >{{(i['Total Poles'] === 'nan') ? '': i['Total Poles'] |  number: '1.2-2'}}</td> -->
              <!-- <td class="sorting_1">{{(i['Total Bulbs'] === 'nan') ? '': i['Total Bulbs'] |  number: '1.2-2'}}</td> -->
              <!-- <td class="sorting_1" ><span *ngIf="i['completed length'] !== undefined">
              <ul *ngIf="i['completed length'].length">
                <li *ngFor="let n of i['completed length']"> <strong> {{n.work}} : </strong>{{n.measurement}}</li>
              </ul>
            </span></td> -->

              <td class="sorting_1">{{ i["completed length1"] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- 
<div style="font-size:small ;right: 28px;
bottom: -8px;
z-index: 99999;
position: fixed;">
  <p class="heliwareicon"><a style="color: rgb(1, 1, 1);  font-style: italic;" href="https://heliware.co.in/"
  target="_blank">Powered By Heliware</a></p>
</div> -->

<div>
  <img
    src="assets/Footer2.png"
    style="
      height: 60px;
      width: 100vw;
      display: flex;
      position: absolute;
      z-index: 9999;
      bottom: 1px;
    "
  />
</div>

<div [@slideInOut]="animationStates" class="divAA">
  <button
    (click)="toggleTable('divAA')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 1480px;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div
    id="dataProjectStatusTable_wrapper"
    style="margin-top: 80px; margin-bottom: 100px"
    *ngIf="inputData"
    class="dataProjectStatus dataTables_wrapper no-footer"
  >
    <h4 class="titlele">Project History</h4>
    <table
      *ngIf="inputData.selectedTableData != undefined"
      id="dataProjectStatusTable"
      class="display dataTable no-footer"
      style="width: 100%"
      aria-describedby="dataProjectStatusTable_info"
    >
      <thead>
        <tr>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Sl. No.
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Apex Committee
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Department
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Name of Project
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Responsibility/ Name of Clerk
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Status
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Start Date
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            End Date
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Date of issue of MoM
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Date of issue of GO
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Date of DPR submission
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Date of Publishing of RFP
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Date of Technical Bid Opening
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Date of Financial Bid Opening
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Date of issue of work Order
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Budget Sanctioned (in Lakhs)
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Budget Transferred (in Lakhs) For 25 %
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Budget Utilised (in Lakh)
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Total Planned length of roads (in KM)
          </th>
          <th
            class="sorting"
            tabindex="0"
            aria-controls="dataProjectStatusTable"
            rowspan="1"
            colspan="1"
            aria-label="Department Name: activate to sort column ascending"
          >
            Total Completed length of roads
          </th>
        </tr>
      </thead>

      <tbody id="html_overAllProgressTable">
        <tr class="odd">
          <td class="sorting_1">
            {{ inputData.selectedTableData["Sl. No."] }}
          </td>
          <td class="sorting_1">
            {{ inputData.selectedTableData["Apex Committee"] }}
          </td>
          <td class="sorting_1">
            {{ inputData.selectedTableData["Department"] }}
          </td>
          <td class="sorting_1">
            {{ inputData.selectedTableData["Name of Project"] }}
          </td>
          <td class="sorting_1">
            {{ inputData.selectedTableData["Responsibility/Name of Clerk"] }}
          </td>
          <td class="sorting_1">{{ inputData.selectedTableData["Status"] }}</td>
          <td class="sorting_1">
            {{
              inputData.selectedTableData["Start Date"] === "NaT"
                ? ""
                : (inputData.selectedTableData["Start Date"]
                  | date : "mediumDate")
            }}
          </td>
          <td class="sorting_1">
            {{
              inputData.selectedTableData["End Date"] === "NaT"
                ? ""
                : (inputData.selectedTableData["End Date"]
                  | date : "mediumDate")
            }}
          </td>
          <td class="sorting_1">
            {{
              inputData.selectedTableData["Date of issue of MoM"] === "NaT"
                ? ""
                : (inputData.selectedTableData["Date of issue of MoM"]
                  | date : "mediumDate")
            }}
          </td>
          <td class="sorting_1">
            {{
              inputData.selectedTableData["Date of issue of GO"] === "NaT"
                ? ""
                : (inputData.selectedTableData["Date of issue of GO"]
                  | date : "mediumDate")
            }}
          </td>
          <td class="sorting_1">
            {{
              inputData.selectedTableData["Date of DPR submission"]
                | date : "mediumDate"
            }}
          </td>
          <td class="sorting_1">
            {{
              inputData.selectedTableData["Date of Publishing of RFP"] === "NaT"
                ? ""
                : (inputData.selectedTableData["Date of Publishing of RFP"]
                  | date : "mediumDate")
            }}
          </td>
          <td class="sorting_1">
            {{
              inputData.selectedTableData["Date of Technical Bid Opening"] ===
              "NaT"
                ? ""
                : (inputData.selectedTableData["Date of Technical Bid Opening"]
                  | date : "mediumDate")
            }}
          </td>
          <td class="sorting_1">
            {{
              inputData.selectedTableData["Date of Financial Bid Opening"] ===
              "NaT"
                ? ""
                : (inputData.selectedTableData["Date of Financial Bid Opening"]
                  | date : "mediumDate")
            }}
          </td>
          <td class="sorting_1">
            {{
              inputData.selectedTableData["Date of issue of work Order"] ===
              "NaT"
                ? ""
                : (inputData.selectedTableData["Date of issue of work Order"]
                  | date : "mediumDate")
            }}
          </td>
          <td class="sorting_1">
            {{
              inputData.selectedTableData["Budget Sanctioned (in Lakhs)"] ===
              "nan"
                ? ""
                : inputData.selectedTableData["Budget Sanctioned (in Lakhs)"]
            }}
          </td>
          <td class="sorting_1">
            {{
              inputData.selectedTableData[
                "Budget Transferred (in Lakhs) For 25 %"
              ] === "nan"
                ? ""
                : inputData.selectedTableData[
                    "Budget Transferred (in Lakhs) For 25 %"
                  ]
            }}
          </td>
          <td class="sorting_1">
            {{
              inputData.selectedTableData["Budget Utilised (in Lakh)"] === "nan"
                ? ""
                : inputData.selectedTableData["Budget Utilised (in Lakh)"]
            }}
          </td>
          <td class="sorting_1">
            {{
              inputData.selectedTableData[
                "Total Planned length of roads (in m)"
              ] === "nan"
                ? ""
                : (inputData.selectedTableData[
                    "Total Planned length of roads (in m)"
                  ] | number : "1.2-2")
            }}
          </td>
          <td class="sorting_1" style="width: 100px">
            <ng-container
              *ngIf="
                inputData.selectedTableData['completed length'] !==
                  'undefined' &&
                inputData.selectedTableData['completed length'] !== 'nan' &&
                inputData.selectedTableData['completed length'].length
              "
            >
              <li
                *ngFor="
                  let n of inputData.selectedTableData['completed length']
                "
              >
                {{ n.work }} : {{ n.measurement }}
              </li>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="isVisible" [@slideInOut]="animationStat" class="divA1">
  <button
    (click)="toggleShowDiv('divA1')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div class="projct" id="chart-no-of-project"></div>
</div>

<div *ngIf="isVisible" [@slideInOut]="animationState1" class="divA2">
  <button
    (click)="toggleShowDiv2('divA2')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div class="excel" id="chartExcel"></div>
</div>

<div *ngIf="isNotVisible" [@slideInOut]="animationState2" class="divA3">
  <button
    (click)="toggleShowDiv3('divA3')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div class="char1" id="chart-container-status-summary"></div>
</div>

<div *ngIf="isNotVisible" [@slideInOut]="animationState3" class="divA4">
  <button
    (click)="toggleShowDiv4('divA4')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div class="char1" id="chart-container-status-summary1"></div>
</div>

<div *ngIf="showCompletionChart" [@slideInOut]="animationState4" class="divA5">
  <button
    (click)="toggleShowDiv5('divA5')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div class="char3" id="chart-container-summary"></div>
</div>

<div
  *ngIf="
    (currentUser === 'osnagarnigam@rediffmail.com' && showProjectTable) ||
    (currentUser !== 'vishvaraj@heliware.co.in' && showProjectChart)
  "
  [@slideInOut]="animationState5"
  class="divA6"
>
  <button
    (click)="toggleShowDiv6('divA6')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div class="char1" id="project-budget-chart"></div>
</div>

<div
  *ngIf="
    (currentUser === 'osnagarnigam@rediffmail.com' && showProjectTable) ||
    currentUser !== 'vishvaraj@heliware.co.in'
  "
  [@slideInOut]="animationState6"
  class="divA7"
>
  <button
    (click)="toggleShowDiv7('divA7')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div id="project_timeline_drag">
    <div>
      <span style="position: absolute; margin-top: -22px; right: 167px; color: orange;font-size:31px">
        {{ showingFirstChart ? 'Project Timeline' : 'Project Task Timeline' }}
      </span><br />
  
      <span *ngIf="showingFirstChart" style="position: absolute; margin-top: 0px; right: 115px; color: white; font-size: 19px;">
        Start Date : {{ taskData.startDate | date : "EEEE, MMMM d, y" }}
      </span><br />
      <span *ngIf="showingFirstChart" style="position: absolute; margin-top: 3px; right: 133px; color: white; font-size: 19px;">
        End Date : {{ taskData.endDate | date : "EEEE, MMMM d, y" }}
      </span>
  
      <!-- Chart container -->
      <div class="char1" id="weeks-chart-container" style="width: 130%; height: 500px;left:-80px;bottom:10px"></div>
    </div>
  </div>
  
  
  
</div>

<div [@slideInOut]="animationState7" class="divA8">
  <button
    (click)="toggleShowDiv8('divA8')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div *ngIf="showProjectChart" id="progress_drag_chart">
    <div id="common-container">
      <div style="width: 80%; height: 50%; text-align: center">
        <mat-select
          class="select-item"
          style="position: relative; top: 30%; left: 10%"
          id="dropdown"
          placeholder="Select Progress"
          [(ngModel)]="selectedProcess"
          (selectionChange)="getSelectedProcess($event)"
        >
          <mat-option value="twentyFive">Progress @ 25%</mat-option>
          <mat-option value="fifty">Progress @ 50%</mat-option>
          <mat-option value="seventyFive">Progress @ 75%</mat-option>
          <mat-option value="hundred">Progress @ 100%</mat-option>
        </mat-select>
      </div>
      <div class="char4" id="chart-container-summary3"></div>

      <div
        style="
          width: 100%;
          text-align: center;
          font-weight: bold;
          color: white;
          margin-top: 10px;
          position: absolute;
          right: 33%;
          top: 12%;
        "
      >
        <p>Note: Progress is shown based on Payment Stage</p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isVisible" [@slideInOut]="animationState8" class="divA9">
  <button
    (click)="toggleShowDiv9('divA9')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div
    *ngIf="isVisible"
    class="maxDiv5"
    matTooltip="Overall Project Running"
    style="display: none"
  >
    <span
      ><mat-icon
        style="margin: 9px -2px 0 -14px; font-size: 35px; color: orange"
        >show_chart</mat-icon
      ></span
    >
  </div>
  <div
    class="char1"
    id="project-running"
    style="
      width: 88%;
      font-size: small;
      position: absolute;
      top: 0%;
      padding: 0 32px 19px 10px;
      border-radius: 12px;
      left: 2%;
    "
    *ngIf="showProject"
  >
    <h5 style="color: white; text-align: center">Overall Project Running</h5>
    <div
      *ngIf="isVisible"
      style="display: flex; flex-direction: row; margin-top: 20px"
    >
      <div class="box2" style="margin-left: 30px">
        <p style="color: white">
          <span id="total_project">{{ totalProjectStarted }}</span> Total
          Projects Sanctioned
        </p>
      </div>
      <div class="box2" style="margin-left: 20px">
        <p style="color: white">
          <span id="total_project">{{ projectStarted }}</span> Total Projects
          Started
        </p>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; margin-top: 10px">
      <div class="box2" style="margin-left: 29px">
        <p style="color: white">
          <span id="total_project">{{
            budgetSanctioned | number : ".1-4"
          }}</span>
          Total Funds Sanctioned (in Lakhs)
        </p>
      </div>
      <div *ngIf="isVisible" class="box2" style="margin-left: 20px">
        <p style="color: white">
          <span id="total_project">{{ Light }}</span> Total projects
        </p>
      </div>
    </div>
  </div>
</div>

<div
  [@slideInOut]="animationState9"
  class="divs1"
  [ngStyle]="{
    width: showPWD && showLight ? '30%' : '18%',
    left: showPWD && showLight ? '68%' : '80%'
  }"
>
  <button
    (click)="toggleShowDiv10('divs1')"
    style="
      position: relative;
      z-index: 999;
      background-color: #000000;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div
    *ngIf="pwdCharts"
    id="pwd-light"
    [ngStyle]="{ width: showPWD && showLight ? '30%' : '15%' }"
    style="
      position: absolute;
      right: 0px;
      top: 70px;
      text-align: center;
      font-size: small;
      padding: 0 30px 22px 0px;
      border-radius: 12px;
      margin-right: 12px;
      margin-left: 2s;
    "
  >
    <div
      class="char1"
      [ngStyle]="{ right: showPWD && showLight ? '-18%' : '-260%' }"
      style="
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: -345%;
        right: 10%;
      "
    >
      <div style="width: 45%; flex-direction: column" *ngIf="showPWD">
        <h5
          style="color: white; margin-left: 80px"
          [ngStyle]="showStyle ? { 'margin-left': '70px' } : {}"
        >
          PWD
        </h5>
        <div style="display: flex; flex-direction: column">
          <div
            class="box-clr-2"
            style="margin-left: 40px"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{ pwdCount }}</span> Total projects
            </p>
          </div>
          <div
            class="box-clr-2"
            style="margin-left: 40px; margin-top: 10px"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{ TotalRunningProjectsPwd }}</span>
              Total running projects
            </p>
          </div>
          <div
            class="box-clr-2"
            style="margin-left: 40px; margin-top: 10px"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{ projectRetendered }}</span> Total
              completed length
            </p>
          </div>
          <div
            class="box-clr-2"
            style="margin-left: 40px; margin-top: 10px"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{
                totalLength | number : ".1-4"
              }}</span>
              Total length of the road(in km)
            </p>
          </div>
          <div
            class="box-clr-2"
            style="margin-top: 10px; margin-left: 41px"
            *ngIf="showBudget"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{
                budgetSanctioned | number : ".1-4"
              }}</span>
              Total Funds Sanctioned (in Lakhs)
            </p>
          </div>
        </div>
      </div>
      <div style="width: 45%; flex-direction: column" *ngIf="showLight">
        <div style="display: flex; flex-direction: column">
          <h5
            style="color: white"
            [ngStyle]="showStyle ? { 'margin-left': '70px' } : {}"
          >
            Light
          </h5>
          <div
            class="box-clr-2"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{ Light }}</span> Total projects
            </p>
          </div>
          <div
            class="box-clr-2"
            style="margin-top: 10px"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{ TotalRunningProjectsLight }}</span>
              Total running projects
            </p>
          </div>
          <div
            class="box-clr-2"
            style="margin-top: 10px"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{ totalPoles }}</span> Total poles
            </p>
          </div>
          <div
            class="box-clr-2"
            style="margin-top: 10px"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{ totalBulbs }}</span
              >Total bulbs
            </p>
          </div>
          <div
            class="box-clr-2"
            style="margin-top: 10px; margin-left: 3px"
            *ngIf="showBudget"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{
                budgetSanctioned | number : ".1-4"
              }}</span>
              Total Funds Sanctioned (in Lakhs)
            </p>
          </div>
        </div>
      </div>
      <div style="width: 45%; flex-direction: column" *ngIf="showBridge">
        <h3
          style="color: white; margin-left: 80px"
          [ngStyle]="showStyle ? { 'margin-left': '50px' } : {}"
        >
          Light/Bridge Corporation
        </h3>
        <div style="display: flex; flex-direction: column">
          <div
            class="box-clr-2"
            style="margin-left: 40px"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{ lightBridgeCount }}</span> Total
              projects
            </p>
          </div>
          <div
            class="box-clr-2"
            style="margin-left: 40px; margin-top: 10px"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{
                TotalRunningProjectsLightBridge
              }}</span>
              Total running projects
            </p>
          </div>
          <div
            class="box-clr-2"
            style="margin-left: 40px; margin-top: 10px"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{ projectChange }}</span> Total
              completed length
            </p>
          </div>
          <div
            class="box-clr-2"
            style="margin-left: 40px; margin-top: 10px"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{
                totalLength | number : ".1-4"
              }}</span>
              Total length of the road(in km)
            </p>
          </div>
          <div
            class="box-clr-2"
            style="margin-top: 10px; margin-left: 41px"
            *ngIf="showBudget"
            [ngStyle]="showStyle ? { 'margin-left': '30px' } : {}"
          >
            <p style="color: white">
              <span id="total_project">{{
                budgetSanctioned | number : ".1-4"
              }}</span>
              Total Funds Sanctioned (in Lakhs)
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="tables" [@slideInOut]="animationState10" class="divs2">
  <button
    (click)="toggleShowDiv11('divs2')"
    style="
      position: relative;
      z-index: 999;
      background-color: #000000;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div
    *ngIf="selectedTableData && !excelNotFound"
    id="project-detail"
    class="charts"
  >
    <p class="project-name">
      {{ projectName }}
    </p>

    <table class="box-clr-1" style="text-align: center; text-align: center">
      <tr>
        <th class="color">Events</th>
        <th class="color">Date</th>
      </tr>
      <tr></tr>
      <tr>
        <td class="color">MoM issued</td>
        <td class="color">
          <ng-container
            *ngIf="
              selectedTableData['Date of issue of MoM'] !== 'NaT';
              else notAvailable
            "
          >
            {{
              selectedTableData["Date of issue of MoM"] | date : "dd-MMM-yyyy"
            }}
          </ng-container>
          <ng-template #notAvailable>Not available</ng-template>
        </td>
      </tr>
      <tr>
        <td>DPR issued</td>
        <td>
          <ng-container
            *ngIf="
              selectedTableData['Date of DPR submission'] !== 'NaT';
              else notAvailable
            "
          >
            {{
              selectedTableData["Date of DPR submission"] | date : "dd-MMM-yyyy"
            }}
          </ng-container>
          <ng-template #notAvailable>Not available</ng-template>
        </td>
      </tr>
      <tr>
        <td class="color">GO submission</td>
        <td class="color">
          <ng-container
            *ngIf="
              selectedTableData['Date of issue of GO'] !== 'NaT';
              else notAvailable
            "
          >
            {{
              selectedTableData["Date of issue of GO"] | date : "dd-MMM-yyyy"
            }}
          </ng-container>
          <ng-template #notAvailable>Not available</ng-template>
        </td>
      </tr>

      <tr>
        <td>RFP published</td>
        <td>
          <ng-container
            *ngIf="
              selectedTableData['Date of Publishing of RFP'] !== 'NaT';
              else notAvailable
            "
          >
            {{
              selectedTableData["Date of Publishing of RFP"]
                | date : "dd-MMM-yyyy"
            }}
          </ng-container>
          <ng-template #notAvailable>Not available</ng-template>
        </td>
      </tr>
      <tr>
        <td class="color">Bid Opening Tech</td>
        <td class="color">
          <ng-container
            *ngIf="
              selectedTableData['Date of Technical Bid Opening'] !== 'NaT';
              else notAvailable
            "
          >
            {{
              selectedTableData["Date of Technical Bid Opening"]
                | date : "dd-MMM-yyyy"
            }}
          </ng-container>
          <ng-template #notAvailable>Not available</ng-template>
        </td>
      </tr>
      <tr>
        <td>Bid Opening: Fin</td>
        <td>
          <ng-container
            *ngIf="
              selectedTableData['Date of Financial Bid Opening'] !== 'NaT';
              else notAvailable
            "
          >
            {{
              selectedTableData["Date of Financial Bid Opening"]
                | date : "dd-MMM-yyyy"
            }}
          </ng-container>
          <ng-template #notAvailable>Not available</ng-template>
        </td>
      </tr>

      <tr>
        <td class="color">Work Order issued</td>
        <td class="color">
          <ng-container
            *ngIf="
              selectedTableData['Date of issue of work Order'] !== 'NaT';
              else notAvailable
            "
          >
            {{
              selectedTableData["Date of issue of work Order"]
                | date : "dd-MMM-yyyy"
            }}
          </ng-container>
          <ng-template #notAvailable>Not available</ng-template>
        </td>
      </tr>
      <tr>
        <td>Start Date</td>
        <td>
          <ng-container
            *ngIf="selectedTableData['Start Date'] !== 'NaT'; else notAvailable"
          >
            {{ projectStartDate | date : "dd-MMM-yyyy" }}
          </ng-container>
          <ng-template #notAvailable>Not available</ng-template>
        </td>
      </tr>
      <tr>
        <td class="color">Total project days</td>
        <td class="color">
          <ng-container>
            {{ totalProjectDays }}
          </ng-container>
          <ng-template #notAvailable>Not available</ng-template>
        </td>
      </tr>
      <tr></tr>
    </table>
  </div>
</div>

<div [@slideInOut]="animationState11" class="divs3">
  <button
    (click)="toggleShowDiv12('divs3')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <span style="display: flex; color: white; position: absolute; left: 35%"
    >Project Expenses (%)</span
  >
  <div
    id="chart-container-project"
    style="width: 378px; margin-top: -35px; height: 400px"
    id="chart-container-project"
    class="char4"
  ></div>
</div>

<div [@slideInOut]="animationState12" class="divs4">
  <button
    (click)="toggleShowDiv13('divs4')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <span style="display: flex; color: white; position: absolute; left: 35%"
    >Project Progress</span
  >
  <div
    id="chart-container-progress"
    style="width: 378px; margin-top: -35px; height: 400px"
    class="char4"
  ></div>
</div>

<div [@slideInOut]="animationState13" class="divs5">
  <button
    (click)="toggleShowDiv14('divs5')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <span
    style="position: absolute; display: flex; left: 40%; color: white; top: 3%"
    >Progress Based On Milestone (%)</span
  >
  <div id="chart-container-milestone-progress" class="char4"></div>
</div>

<div [@slideInOut]="animationState14" class="divs6">
  <button
    (click)="toggleShowDiv15('divs6')"
    style="
      position: relative;
      z-index: 999;
      background-color: black;
      color: white;
      left: 90%;
      top: 10px;
      border: none;
      cursor: pointer;
    "
  >
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div
    style="
      text-align: center;
      font-size: larger;
      font-weight: bold;
      color: white;
    "
  >
    <span>Project Launch Date</span>
    <br />
    <span>{{ completionDate | date : "EEEE, MMMM d, y" }}</span>
    <div
      style="right: 125%"
      id="chart-container-days-progress"
      class="char4"
    ></div>
  </div>
</div>

<div [@slideInOut]="animationState15" class="divs7">
  <button (click)="toggleShowDiv16('divs7')" style="position: relative;z-index: 999;background-color: black;color: white;left: 90%;top: 10px;border: none;cursor: pointer;">
    <mat-icon style="color: #e5c800">exit_to_app</mat-icon>
  </button>
  <div style="text-align: center; height: 100px">
    <mat-select [(ngModel)]="selectedMilestone" class="select-item" id="dropdown" (selectionChange)="getSelectedData($event.value)" placeholder="Select Milestones">
      <mat-option *ngFor="let i of milestones" [value]="i">{{i.key}}</mat-option>
    </mat-select>
  </div>
  <a class="amount-chart" *ngIf="outerBackButton">
    <span class="material-icons icon-class" (click)="back(true)">arrow_back</span></a>
  <button style="margin-top: -6%" class="amount-chart" mat-raised-button color="primary" *ngIf="amountOuterChart" (click)="amountChart()">
    Amount chart
  </button>
  <div *ngIf="outerChart " id="chart-container-main" style="width: 100%; height: 450px; margin-top: -6%" class="char4"></div>
  <!-- <hr /> -->
  <a *ngIf="!outerChart">
    <span class="material-icons nav-item-1" style="margin-top: -7%" (click)="back(!backButton)">arrow_back</span></a>
  <div  *ngIf="innerChart && showInnerChart" id="chart-container-inner" style="width: 100%; height: 450px; margin-top: -5%; z-index: 1;"class="char4"></div>
</div>

