<div class="dialog-container">
    <!-- Close Button -->
    <button mat-icon-button class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  
    <h2 class="dialog-title">Rectangle Polygon</h2>
  
    <div class="dialog-content">
      <!-- Polygon Height -->
      <!-- <mat-form-field appearance="outline">
        <mat-label>Polygon Height</mat-label>
        <input matInput type="number" [(ngModel)]="polygonHeight" min="0" />
      </mat-form-field> -->
  
      <!-- Reference Elevation -->
      <mat-form-field appearance="outline">
        <mat-label>Reference Elevation</mat-label>
        <input matInput type="number" [(ngModel)]="referenceElevation" min="0" />
      </mat-form-field>
  
      <!-- Color Picker -->
      <!-- <div class="color-picker">
        <label>Choose Color:</label>
        <input matInput type="color" [(ngModel)]="selectedColor"/>

      </div> -->
    </div>
  
    <!-- Submit Button -->
    <button mat-raised-button color="primary" class="submit-button" (click)="submitForm()">Submit</button>
  </div>
  